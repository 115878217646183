import * as React from "react";
import {MedicalField, Term} from "../../../../types/internal";
import {Controller, useFormContext} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {first, tail, trim} from "lodash";
import {getMedicalFieldLabelId} from "../../../../helpers/MedicalFieldUtils";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {ReactNode, useCallback, useMemo, useState} from "react";
import ExpandMore from "../../../common/ExpandMore";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import ArrowRightOutlined from "@mui/icons-material/ArrowRightOutlined";
import ArrowLeftOutlined from "@mui/icons-material/ArrowLeftOutlined";
import useTheme from "@mui/material/styles/useTheme";

export interface TermClauseFormProps {
  id: string;
  groupIndex: number;
  index: number;
  remove: (index: number, focusPrevious: boolean) => void;
  insert: (index: number, texts?: string[], indent?: number) => void;
  focus: (index: number) => void;
  startAction?: ReactNode;
  disabled?: boolean;
  unmountOnExit?: boolean;
}

const TermClauseForm = ({
  groupIndex,
  index,
  remove,
  insert,
  focus,
  startAction,
  disabled,
  unmountOnExit,
}: TermClauseFormProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const {control, getValues, setValue} = useFormContext<Term>();

  const [expanded, setExpanded] = useState<boolean>(false);
  const autoFocus = useMemo<boolean>(() => !getValues(`stages.${groupIndex}.clauses.${index}.text`), []);

  const handleExpandClick = useCallback((): void => {
    setExpanded((currentExpanded) => !currentExpanded);
  }, [setExpanded]);

  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      if (e.repeat) {
        return;
      }
      if (e.key === "Enter") {
        e.preventDefault();
        insert(index + 1, [""], getValues(`stages.${groupIndex}.clauses.${index}.indent`));
      }
      if (e.key === "Backspace" && (e.target as HTMLInputElement | HTMLTextAreaElement).value === "") {
        e.preventDefault();
        remove(index, true);
      }
      if (e.key === "ArrowUp") {
        focus(index - 1);
      }
      if (e.key === "ArrowDown") {
        focus(index + 1);
      }
    },
    [insert, index, groupIndex, getValues, focus, remove]
  );

  const pasteHandler = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const pastedText = e.clipboardData.getData("text/plain");

      const texts = trim(pastedText)
        .split("\n")
        .filter((t) => !!trim(t));
      if (texts.length <= 1) {
        return;
      }

      e.preventDefault();

      setValue(
        `stages.${groupIndex}.clauses.${index}.text`,
        getValues(`stages.${groupIndex}.clauses.${index}.text`) + first(texts)
      );
      insert(index + 1, tail(texts), getValues(`stages.${groupIndex}.clauses.${index}.indent`));
    },
    [index, groupIndex, setValue, getValues, insert]
  );

  return (
    <Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {startAction}

        <Controller
          name={`stages.${groupIndex}.clauses.${index}.indent`}
          control={control}
          render={({field: {value, onChange}}) => {
            return (value || 0) > 0 ? (
              <Tooltip title={<FormattedMessage id="subClauseCancel" />}>
                <span>
                  <IconButton
                    color={"inherit"}
                    onClick={() => onChange(Math.max((value || 0) - 1, 0))}
                    disabled={value <= 0 || disabled}
                  >
                    {theme.direction === "ltr" ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <></>
            );
          }}
        />

        <Controller
          name={`stages.${groupIndex}.clauses.${index}.indent`}
          control={control}
          render={({field: {value, onChange}}) => {
            return (
              <Tooltip title={<FormattedMessage id="subClause" />}>
                <span>
                  <IconButton
                    color={"inherit"}
                    onClick={() => onChange((value || 0) + 1)}
                    disabled={value > 0 || disabled}
                  >
                    {theme.direction === "ltr" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
                  </IconButton>
                </span>
              </Tooltip>
            );
          }}
        />

        <Controller
          name={`stages.${groupIndex}.clauses.${index}.text`}
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                required
                fullWidth
                label={intl.formatMessage({id: "text"})}
                type="text"
                autoFocus={autoFocus}
                inputProps={{
                  maxLength: 500,
                  onKeyDown: keyDownHandler,
                  onPaste: pasteHandler,
                }}
                error={invalid}
                helperText={error?.message}
                disabled={disabled}
              />
            );
          }}
        />

        <Tooltip title={<FormattedMessage id="removeClause" />}>
          <span>
            <IconButton color={"error"} onClick={() => remove(index, false)} disabled={disabled}>
              <DeleteOutlined />
            </IconButton>
          </span>
        </Tooltip>

        <ExpandMore expand={expanded} onClick={handleExpandClick} color={"primary"} />
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit={unmountOnExit}>
        <Stack spacing={3} sx={{mt: 3}}>
          <Controller
            name={`stages.${groupIndex}.clauses.${index}.medicalFields`}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "medicalFields"})}
                  error={invalid}
                  helperText={error?.message || <FormattedMessage id="selectNonForAll" />}
                  disabled={disabled}
                  select
                  value={field?.value || []}
                  SelectProps={{
                    multiple: true,
                    renderValue: (value: MedicalField[]) =>
                      value
                        .map((medicalField) => intl.formatMessage({id: getMedicalFieldLabelId(medicalField)}))
                        .join(", "),
                  }}
                >
                  {Object.values(MedicalField).map((m) => (
                    <MenuItem key={m} value={m}>
                      <FormattedMessage id={getMedicalFieldLabelId(m)} />
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Controller
            name={`stages.${groupIndex}.clauses.${index}.tooltip`}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "tooltip"})}
                  type="text"
                  inputProps={{
                    maxLength: 500,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name={`stages.${groupIndex}.clauses.${index}.checked`}
            control={control}
            render={({field}) => {
              return (
                <Box>
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} disabled={disabled} />}
                    label={<FormattedMessage id="checkedByDefault" />}
                  />
                </Box>
              );
            }}
          />
        </Stack>
      </Collapse>

      <Divider sx={{mt: 3}} />
    </Stack>
  );
};

export default TermClauseForm;
