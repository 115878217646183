import * as React from "react";
import {ReactNode} from "react";
import {FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";

interface ErrorMessageProps {
  message?: string | ReactNode;
}

const ErrorMessage = ({message}: ErrorMessageProps) => {
  return (
    <Typography variant={"body2"} sx={{p: 3, textAlign: "center", color: "text.secondary"}}>
      {message || <FormattedMessage id="networkError" />}
    </Typography>
  );
};

export default ErrorMessage;
