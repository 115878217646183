import * as React from "react";
import {alpha} from "@mui/system/colorManipulator";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AccountPopover from "./navs/AccountPopover";
import LanguagePopover from "./navs/LanguagePopover";
import {NAVBAR_DESKTOP_HEIGHT, NAVBAR_MOBILE_HEIGHT} from "../../constants/ui";
import Logo from "../../components/svg/Logo";
import SearchIcon from "./navs/SearchIcon";
import BackIcon from "./navs/BackIcon";
import {useLocation} from "react-router-dom";
import {useMemo} from "react";
import {defaultAppUrl} from "../../constants/defaultValues";
import FiltersPopover from "./navs/FiltersPopover";
import useOffice from "../../contexts/office/hooks/useOffice";
import IntakePopover from "./navs/IntakePopover";
import PrepPopover from "./navs/PrepPopover";
import HomeIcon from "./navs/HomeIcon";
import {Link as RouterLink} from "react-router-dom";

const RootStyle = styled(AppBar)(({theme}) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  minHeight: NAVBAR_MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    minHeight: NAVBAR_DESKTOP_HEIGHT,
    padding: theme.spacing(0, 5),
  },
}));

type AppNavbarProps = {};

export default function AppNavbar({}: AppNavbarProps) {
  const {pathname} = useLocation();
  const {isOfficeActive} = useOffice();

  const showBack = useMemo<boolean>(() => isOfficeActive && pathname !== defaultAppUrl, [pathname]);

  return (
    <RootStyle>
      <ToolbarStyle>
        {showBack ? (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <BackIcon />
            <HomeIcon />
          </Stack>
        ) : (
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        )}

        <Box sx={{flexGrow: 1}} />

        <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1}}>
          <LanguagePopover />
          <PrepPopover />
          <IntakePopover />
          <FiltersPopover />
          <SearchIcon />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
