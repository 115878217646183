import * as React from "react";
import {FormattedMessage} from "react-intl";
import {FunctionComponent, useMemo} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {Term} from "../../types/internal";
import DialogContentText from "@mui/material/DialogContentText";
import {some} from "lodash";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import TermCardHeader from "../terms/TermCardHeader";
import {useAppSelector} from "../../redux/hooks";
import {selectUserId} from "../../redux/auth/selector";
import Box from "@mui/material/Box";

export type NewTermsDialogProps = {
  terms: Term[];
};

const NewTermsDialog: FunctionComponent<NewTermsDialogProps> = NiceModal.create(({terms}) => {
  const modal = useModal();

  const userId = useAppSelector(selectUserId);

  const hasPublishedTerms = useMemo<boolean>(
    () => some(terms, (term) => term.userIds?.includes(userId)),
    [terms, userId]
  );
  const publishedTermReasons = useMemo<string[]>(
    () =>
      terms
        .filter((term) => term.userIds?.includes(userId))
        .map<string>((term) => term.publishReason || "")
        .filter((reason) => !!reason),
    [terms, userId]
  );

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
      maxWidth="xs"
      hideBackdrop
    >
      <DialogTitleEnhanced onClose={modal.hide}>
        <FormattedMessage id="newTerms" />
      </DialogTitleEnhanced>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText sx={{textAlign: "center"}}>
            <FormattedMessage id="newTermsAdded" />
          </DialogContentText>

          {hasPublishedTerms && (
            <Box>
              <DialogContentText sx={{textAlign: "center"}}>
                <FormattedMessage id="newUserPublishedTerms" />
              </DialogContentText>
              {publishedTermReasons.map((reason) => (
                <DialogContentText sx={{textAlign: "center", color: "text.primary"}}>{reason}</DialogContentText>
              ))}
            </Box>
          )}

          {terms.map((term) => (
            <Card sx={{border: (theme) => `solid 1px ${theme.palette.divider}`}} key={term.id}>
              <TermCardHeader term={term} />
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={modal.hide}>
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default NewTermsDialog;
