import * as React from "react";
import {IntakeTemplateContentPayloadProps} from "./IntakeTemplateContentPayload";
import TextField from "@mui/material/TextField";
import {useIntl} from "react-intl";
import {useCallback, useEffect, useRef, useState} from "react";
import {ContentTypePayloadTable, ContentTypePayloadTableCell} from "../../../../../types/internal";
import Stack from "@mui/material/Stack";
import {useUpdateEffect} from "react-use";
import {v4 as uuidv4} from "uuid";
import useTheme from "@mui/material/styles/useTheme";
import {range} from "lodash";
import Scrollbar from "../../../../common/Scrollbar";
import Box from "@mui/material/Box";
import {useDebounceFn} from "@crud-studio/react-crud-core";
import {getTableCellKey} from "../../../../../helpers/IntakeTemplateUtils";
import MenuItem from "@mui/material/MenuItem";
import IntakeTemplateContentPayloadTableCell from "./IntakeTemplateContentPayloadTableCell";

const MIN_ROWS = 1;
const MAX_ROWS = 30;
const DEFAULT_ROWS = 3;
const MIN_COLUMNS = 1;
const MAX_COLUMNS = 8;
const DEFAULT_COLUMNS = 3;

const IntakeTemplateContentPayloadTable = ({
  type,
  field: {value, ref, onChange, ...field},
  fieldState: {invalid, error},
  disabled,
}: IntakeTemplateContentPayloadProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const generateEmptyItem = useCallback(
    (row: number, column: number): ContentTypePayloadTableCell => ({
      uuid: uuidv4(),
      payload: "",
      example: false,
      assistNotes: false,
      extendedFormat: false,
      title: false,
    }),
    []
  );

  const [payload, setPayload] = useState<ContentTypePayloadTable>(() => {
    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {}
    }
    return {rows: DEFAULT_ROWS, columns: DEFAULT_COLUMNS, cells: {}};
  });
  const itemRefs = useRef<{[key: string]: any}>({});
  const [focusCell, setFocusCell] = useState<string>("");

  useEffect(() => {
    if (focusCell) {
      itemRefs.current[focusCell]?.focus();
      setFocusCell("");
    }
  }, [focusCell]);

  useUpdateEffect(() => {
    if (onChange) {
      onChange(JSON.stringify(payload));
    }
  }, [payload]);

  const inputRefHandler = useCallback((row: number, column: number, input: any): void => {
    itemRefs.current[getTableCellKey(row, column)] = input;
  }, []);

  const rowsChangeHandler = useCallback(
    (rowsStr: string): void => {
      let rows = parseInt(rowsStr) || DEFAULT_ROWS;
      if (rows < MIN_ROWS) {
        rows = MIN_ROWS;
      }
      if (rows > MAX_ROWS) {
        rows = MAX_ROWS;
      }
      setPayload((currentPayload) => ({
        ...currentPayload,
        rows: rows,
      }));
    },
    [setPayload]
  );

  const columnsChangeHandler = useCallback(
    (columnsStr: string): void => {
      let columns = parseInt(columnsStr) || DEFAULT_COLUMNS;
      if (columns < MIN_COLUMNS) {
        columns = MIN_COLUMNS;
      }
      if (columns > MAX_COLUMNS) {
        columns = MAX_COLUMNS;
      }
      setPayload((currentPayload) => ({
        ...currentPayload,
        columns: columns,
      }));
    },
    [setPayload]
  );

  const itemChangeHandler = useCallback(
    (row: number, column: number, item: ContentTypePayloadTableCell): void => {
      setPayload((currentPayload) => ({
        ...currentPayload,
        cells: {...currentPayload.cells, [getTableCellKey(row, column)]: item},
      }));
    },
    [setPayload]
  );

  const textChangeHandler = useCallback(
    (row: number, column: number, text: string): void => {
      setPayload((currentPayload) => {
        const cellKey = getTableCellKey(row, column);
        const cell = currentPayload.cells[cellKey] || generateEmptyItem(row, column);
        return {
          ...currentPayload,
          cells: {...currentPayload.cells, [getTableCellKey(row, column)]: {...cell, payload: text}},
        };
      });
    },
    [setPayload]
  );
  const textChangeHandlerDebounced = useDebounceFn(textChangeHandler, 500);

  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, row: number, column: number): void => {
      if (e.repeat) {
        return;
      }
      if (e.key === "ArrowUp") {
        setFocusCell(getTableCellKey(row - 1, column));
      }
      if (e.key === "ArrowDown") {
        setFocusCell(getTableCellKey(row + 1, column));
      }
      if (e.key === "ArrowRight") {
        setFocusCell(getTableCellKey(row, column + (theme.direction === "ltr" ? 1 : -1)));
      }
      if (e.key === "ArrowLeft") {
        setFocusCell(getTableCellKey(row, column + (theme.direction === "ltr" ? -1 : 1)));
      }
    },
    [setFocusCell, theme.direction]
  );

  return (
    <Box>
      <Stack direction={"row"} spacing={1}>
        <TextField
          value={payload.rows}
          onChange={(e) => rowsChangeHandler(e.target.value)}
          required
          fullWidth
          label={intl.formatMessage({id: "rows"})}
          disabled={disabled}
          select
        >
          {range(MIN_ROWS, MAX_ROWS + 1).map((rows) => (
            <MenuItem key={rows} value={rows}>
              {rows}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          value={payload.columns}
          onChange={(e) => columnsChangeHandler(e.target.value)}
          required
          fullWidth
          label={intl.formatMessage({id: "columns"})}
          disabled={disabled}
          select
        >
          {range(MIN_COLUMNS, MAX_COLUMNS + 1).map((rows) => (
            <MenuItem key={rows} value={rows}>
              {rows}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Scrollbar>
        <Stack spacing={3} sx={{pt: 3}}>
          {range(payload.rows).map((row) => (
            <Stack direction={{xs: "column", md: "row"}} spacing={1} key={row}>
              {range(payload.columns).map((column) => {
                const cellKey = getTableCellKey(row, column);
                const cell = payload.cells[cellKey] || generateEmptyItem(row, column);
                return (
                  <IntakeTemplateContentPayloadTableCell
                    cell={cell}
                    row={row}
                    column={column}
                    disabled={disabled}
                    inputRefHandler={inputRefHandler}
                    itemChangeHandler={itemChangeHandler}
                    textChangeHandler={textChangeHandlerDebounced}
                    keyDownHandler={keyDownHandler}
                    key={cellKey}
                  />
                );
              })}
            </Stack>
          ))}
        </Stack>
      </Scrollbar>
    </Box>
  );
};

export default IntakeTemplateContentPayloadTable;
