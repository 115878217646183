import * as React from "react";
import {FunctionComponent, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  AddTextLocation,
  addTextToDocument,
  getAddTextHtml,
  getAddTextLocationLabelId,
} from "../../helpers/AddTextLocationUtils";
import {FormattedMessage, useIntl} from "react-intl";
import {isEmpty} from "lodash";
import MenuPopover from "../common/MenuPopover";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {selectAddTextLocation} from "../../redux/auth/selector";
import {setAddTextLocation} from "../../redux/auth/slice";
import {useSnackbar} from "notistack";
import NotificationActionAddSection from "../notifications/NotificationActionAddSection";
import {sectionTitlePrefix} from "../../helpers/IntakeTemplateUtils";
import useTheme from "@mui/material/styles/useTheme";
import {StageType, Term, TermClause} from "../../types/internal";
import {getStageTypeLabelId} from "../../helpers/TermUtils";
import useIntakeTemplateGenerator from "../../contexts/intake-template-generator/hooks/useIntakeTemplateGenerator";

export interface AddTermStageTextButtonProps {
  term: Term;
  stageType: StageType;
  clauses: TermClause[];
}

const AddTermStageTextButton: FunctionComponent<AddTermStageTextButtonProps> = ({term, stageType, clauses}) => {
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {addMedicalRecordFormat} = useIntakeTemplateGenerator();

  const addTextLocation = useAppSelector(selectAddTextLocation);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleAddText = async (location: AddTextLocation): Promise<void> => {
    handleClose();

    dispatch(setAddTextLocation({location: location}));

    if (isEmpty(clauses)) {
      return;
    }

    switch (location) {
      case AddTextLocation.Start:
      case AddTextLocation.End:
      case AddTextLocation.Selection:
      case AddTextLocation.BeforeSelection:
      case AddTextLocation.AfterSelection:
      case AddTextLocation.BeforeTerm:
      case AddTextLocation.AfterTerm:
        await addTextToDocument(
          location,
          getAddTextHtml(term, stageType, clauses, intl, {
            direction: theme.direction,
            medicalRecordFormat: addMedicalRecordFormat,
          }),
          {
            keywords: term.keywords,
            select: true,
          }
        );
        break;
      case AddTextLocation.StageStart:
        const stageTitle = intl.formatMessage({id: getStageTypeLabelId(stageType)});
        const html = getAddTextHtml(term, StageType.Other, clauses, intl, {
          direction: theme.direction,
          medicalRecordFormat: addMedicalRecordFormat,
        });
        try {
          await addTextToDocument(location, html, {
            keywords: [sectionTitlePrefix + stageTitle],
            select: true,
          });
        } catch (e) {
          try {
            await addTextToDocument(location, html, {
              keywords: [stageTitle],
              select: true,
            });
          } catch (e) {
            enqueueSnackbar(<FormattedMessage id="stageNotFound" />, {
              variant: "warning",
              action: <NotificationActionAddSection term={term} stageType={stageType} clauses={clauses} />,
            });
          }
        }
        break;
    }
  };

  const handleAddTextDefault = (): void => {
    handleAddText(addTextLocation);
  };

  const disabled = useMemo<boolean>(() => !clauses || isEmpty(clauses), [clauses]);

  return (
    <>
      <ButtonGroup color="primary" variant="outlined" disabled={disabled} disableElevation>
        <Button onClick={handleAddTextDefault}>
          <FormattedMessage id={getAddTextLocationLabelId(addTextLocation)} />
        </Button>

        <Button onClick={handleMenu}>
          <KeyboardArrowDown fontSize="small" />
        </Button>
      </ButtonGroup>

      <MenuPopover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <AddTextMenuItem location={AddTextLocation.StageStart} handleAddText={handleAddText} />
        <Divider sx={{m: "0!important"}} />
        <AddTextMenuItem location={AddTextLocation.BeforeTerm} handleAddText={handleAddText} />
        <AddTextMenuItem location={AddTextLocation.AfterTerm} handleAddText={handleAddText} />
        <Divider sx={{m: "0!important"}} />
        <AddTextMenuItem location={AddTextLocation.BeforeSelection} handleAddText={handleAddText} />
        <AddTextMenuItem location={AddTextLocation.AfterSelection} handleAddText={handleAddText} />
        <Divider sx={{m: "0!important"}} />
        <AddTextMenuItem location={AddTextLocation.Start} handleAddText={handleAddText} />
        <AddTextMenuItem location={AddTextLocation.End} handleAddText={handleAddText} />
      </MenuPopover>
    </>
  );
};

interface AddTextMenuItemProps {
  location: AddTextLocation;
  handleAddText: (location: AddTextLocation) => void;
}

const AddTextMenuItem: FunctionComponent<AddTextMenuItemProps> = ({location, handleAddText}) => {
  return (
    <MenuItem onClick={() => handleAddText(location)} sx={{typography: "body2"}} key={location}>
      <ListItemText primary={<FormattedMessage id={getAddTextLocationLabelId(location)} />} disableTypography />
    </MenuItem>
  );
};

export default AddTermStageTextButton;
