import * as React from "react";
import {FunctionComponent, PropsWithChildren, ReactNode} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FormattedMessage} from "react-intl";
import Button from "@mui/material/Button";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import Stack from "@mui/material/Stack";
import AddOutlined from "@mui/icons-material/AddOutlined";

export interface FormListHeaderProps extends PropsWithChildren<any> {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  buttonText?: string | ReactNode;
  buttonDisabled?: boolean;
  onAddItem?: () => void;
  sx?: SxProps<Theme>;
}

const FormListHeader: FunctionComponent<FormListHeaderProps> = ({
  title,
  subtitle,
  buttonText,
  buttonDisabled,
  onAddItem,
  sx,
  children,
}) => {
  return (
    <Box sx={{display: "flex", alignItems: "center", ...sx}}>
      <Box sx={{flexGrow: 1}}>
        {title && (
          <Typography variant="h4" sx={{display: "inline-block"}}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <>
            &nbsp;
            <Typography variant="h6" sx={{display: "inline-block", color: "text.secondary"}}>
              {subtitle}
            </Typography>
          </>
        )}
      </Box>

      <Stack direction={"row"} spacing={1} sx={{flexShrink: 0}}>
        {onAddItem && (
          <Button variant="outlined" startIcon={<AddOutlined />} disabled={buttonDisabled} onClick={onAddItem}>
            {buttonText || <FormattedMessage id="addNew" />}
          </Button>
        )}
        {children}
      </Stack>
    </Box>
  );
};

export default FormListHeader;
