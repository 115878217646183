import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {firebaseConfig} from "../constants/defaultValues";
// import {firebaseConfig, recaptchaSiteKey} from "../constants/defaultValues";
// import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const auth = getAuth(app);
auth.useDeviceLanguage();

const firestore = getFirestore(app);

// if (window.location.hostname === "localhost") {
//   // @ts-ignore
//   // eslint-disable-next-line no-restricted-globals
//   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider(recaptchaSiteKey),
//   isTokenAutoRefreshEnabled: true,
// });

// export {app, analytics, auth, firestore, appCheck};
export {app, analytics, auth, firestore};
