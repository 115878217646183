import * as React from "react";
import {FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import useEntity from "../entity/hooks/useEntity";
import {Entity} from "../../types/entity";
import useEntityCrud from "../entity-crud/hooks/useEntityCrud";
import {Prep, PrepStage, PrepTerm, Term, TermClause, StageType, TermFilters} from "../../types/internal";
import {prepEntityId} from "../../entities/prep.entity";
import {v4 as uuidv4} from "uuid";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {selectActivePrepId, selectLoggedIn} from "../../redux/auth/selector";
import {isEmpty, isString} from "lodash";
import {useSnackbar} from "notistack";
import {FormattedMessage} from "react-intl";
import NotificationActionSignUp from "../../components/notifications/NotificationActionSignUp";
import {updateUserProfile} from "../../redux/auth/slice";
import {getInsertIndexByEnum} from "../../helpers/EntityUtils";
import {matchPath, useLocation} from "react-router-dom";
import {urls} from "../../routes/urls";
import {useUpdateEffect} from "react-use";

export type ActivePrepContextProps = {
  activePrep: Prep | undefined;
  loading: boolean;
  error: boolean;
  isActivePrepUrl: boolean;
  newPrep: () => void;
  selectPrep: (prepId: string | Prep) => void;
  refreshPrep: () => void;
  addClauses: (stageType: StageType, term: Term, clauses: TermClause[]) => void;
  removeStage: (stageType: StageType) => void;
  removeTerm: (stageType: StageType, termId: string) => void;
};

const ActivePrepContext = React.createContext<ActivePrepContextProps>(undefined!);

export interface ActivePrepProviderProps extends PropsWithChildren<any> {}

const ActivePrepProvider: FunctionComponent<ActivePrepProviderProps> = ({children}) => {
  const {getEntity} = useEntity();
  const {lastChangeTime, lastDeletionTime, refreshItems, getItem, saveItem} = useEntityCrud();
  const {enqueueSnackbar} = useSnackbar();
  const {pathname} = useLocation();

  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(selectLoggedIn);
  const activePrepId = useAppSelector(selectActivePrepId);

  const entity = useMemo<Entity<Prep, TermFilters>>(() => getEntity(prepEntityId), []);

  const [activePrep, setActivePrep] = useState<Prep | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [saveFlag, setSaveFlag] = useState<number>(0);

  const isActivePrepUrl = useMemo<boolean>(() => {
    const match = matchPath({path: urls.entityDetailsId.url}, pathname);
    return match?.params?.entity === entity.id && match?.params?.id === activePrepId;
  }, [pathname]);

  const loadActivePrep = useCallback(async (): Promise<void> => {
    setLoading(true);
    const item = await getItem(entity, activePrepId);
    setLoading(false);
    if (item) {
      setActivePrep(item);
    } else {
      setError(true);
    }
  }, [activePrepId, getItem, setActivePrep, setLoading, setError]);

  useEffect(() => {
    if (activePrepId) {
      (async () => {
        await loadActivePrep();
      })();
    } else {
      setActivePrep(entity.generateEmptyItem());
    }
  }, [activePrepId]);

  useUpdateEffect(() => {
    if (activePrepId && lastChangeTime[entity.id]?.ids[activePrepId]) {
      (async () => {
        await loadActivePrep();
      })();
    }
  }, [lastChangeTime[entity.id]?.ids[activePrepId]]);

  useUpdateEffect(() => {
    if (activePrepId && lastDeletionTime[entity.id]?.ids[activePrepId]) {
      newPrep();
    }
  }, [lastDeletionTime[entity.id]?.ids[activePrepId]]);

  useUpdateEffect(() => {
    if (!activePrep) {
      return;
    }

    const itemToSave = entity.generateSaveItem(activePrep, activePrep);
    saveItem(entity, itemToSave).then((savedItem) => {
      if (savedItem) {
        setActivePrep(savedItem);
        dispatch(updateUserProfile({userProfile: {activePrepId: savedItem.id}}));
      }
    });
  }, [saveFlag]);

  const newPrep = useCallback((): void => {
    dispatch(updateUserProfile({userProfile: {activePrepId: ""}}));
  }, []);

  const selectPrep = useCallback((prepId: string | Prep): void => {
    const id = isString(prepId) ? prepId : prepId.id;
    if (!id) {
      enqueueSnackbar(<FormattedMessage id="savePrepToSelect" />, {variant: "warning"});
      return;
    }
    dispatch(updateUserProfile({userProfile: {activePrepId: id}}));
    enqueueSnackbar(<FormattedMessage id="prepSelectedSuccessfully" />, {variant: "success"});
  }, []);

  const refreshPrep = useCallback((): void => {
    refreshItems(entity);
    enqueueSnackbar(<FormattedMessage id="refreshing" />, {variant: "info"});
  }, []);

  const canUpdateActivePrep = useCallback((): boolean => {
    if (!loggedIn) {
      enqueueSnackbar(<FormattedMessage id="addToPrepSignUp" />, {
        variant: "warning",
        action: <NotificationActionSignUp />,
      });
      return false;
    }
    if (!activePrep || loading) {
      enqueueSnackbar(<FormattedMessage id="addToPrepLoading" />, {variant: "info"});
      return false;
    }
    if (isActivePrepUrl) {
      enqueueSnackbar(<FormattedMessage id="cannotUpdatePrepWhileInPage" />, {variant: "warning"});
      return false;
    }
    return true;
  }, [activePrep, isActivePrepUrl, loggedIn, loading]);

  const addClauses = useCallback(
    (stageType: StageType, term: Term, clauses: TermClause[]): void => {
      if (!canUpdateActivePrep()) {
        return;
      }

      if (isEmpty(clauses)) {
        enqueueSnackbar(<FormattedMessage id="addToPrepNoClausesSelected" />, {variant: "warning"});
        return;
      }

      setActivePrep((currentActivePrep) => {
        if (!currentActivePrep) {
          return currentActivePrep;
        }

        let prepStage: PrepStage | undefined = currentActivePrep.stages.find((s) => s.type === stageType);
        if (!prepStage) {
          prepStage = {
            uuid: uuidv4(),
            type: stageType,
            terms: [],
            comments: "",
          };

          const insertIndex = getInsertIndexByEnum(
            StageType,
            stageType,
            currentActivePrep.stages.map((s) => s.type)
          );
          currentActivePrep.stages.splice(insertIndex, 0, prepStage);
        }

        let prepTerm: PrepTerm | undefined = prepStage.terms.find((t) => t.termId === term.id);
        if (!prepTerm) {
          prepTerm = {
            uuid: uuidv4(),
            termId: term.id,
            title: term.title,
            clauses: [],
          };
          prepStage.terms.push(prepTerm);
        }

        clauses.forEach((clause) => {
          if (!prepTerm.clauses.find((c) => c.text === clause.text)) {
            prepTerm.clauses.push({
              uuid: uuidv4(),
              text: clause.text,
              tooltip: clause.tooltip,
              indent: clause.indent,
            });
          }
        });

        return {...currentActivePrep};
      });
      setSaveFlag((currentSaveFlag) => currentSaveFlag + 1);

      enqueueSnackbar(<FormattedMessage id="addToPrepSuccess" />, {variant: "success"});
    },
    [setActivePrep, setSaveFlag, canUpdateActivePrep]
  );

  const removeStage = useCallback(
    (stageType: StageType): void => {
      if (!canUpdateActivePrep()) {
        return;
      }

      setActivePrep((currentActivePrep) => {
        if (!currentActivePrep) {
          return currentActivePrep;
        }
        currentActivePrep.stages = currentActivePrep.stages.filter((stage) => stage.type !== stageType);
        return {...currentActivePrep};
      });
      setSaveFlag((currentSaveFlag) => currentSaveFlag + 1);
    },
    [setActivePrep, setSaveFlag, canUpdateActivePrep]
  );

  const removeTerm = useCallback(
    (stageType: StageType, termId: string): void => {
      if (!canUpdateActivePrep()) {
        return;
      }

      setActivePrep((currentActivePrep) => {
        if (!currentActivePrep) {
          return currentActivePrep;
        }

        const stage: PrepStage | undefined = currentActivePrep.stages.find((s) => s.type === stageType);
        if (!stage) {
          return currentActivePrep;
        }

        stage.terms = stage.terms.filter((t) => t.termId !== termId);
        return {...currentActivePrep};
      });
      setSaveFlag((currentSaveFlag) => currentSaveFlag + 1);
    },
    [setActivePrep, setSaveFlag, canUpdateActivePrep]
  );

  return (
    <ActivePrepContext.Provider
      value={{
        activePrep,
        loading,
        error,
        isActivePrepUrl,
        newPrep,
        selectPrep,
        refreshPrep,
        addClauses,
        removeStage,
        removeTerm,
      }}
    >
      {children}
    </ActivePrepContext.Provider>
  );
};

export {ActivePrepContext, ActivePrepProvider};
