import {Entity, EntityFactory} from "../types/entity";
import {MedicalField, Prep, PrepStage, TermCategory, TermFilters} from "../types/internal";
import {getStageTypeLabelId} from "../helpers/TermUtils";
import PrepForm from "../components/entity/forms/prep/PrepForm";
import {cleanEntityData, cleanSearchString} from "../helpers/EntityUtils";
import {getMedicalFieldLabelId} from "../helpers/MedicalFieldUtils";
import {checkMedicalFieldsFilter, isMedicalFieldsFilterActive} from "../helpers/FiltersUtils";
import {termEntityId} from "./term.entity";
import PrintOutlined from "@mui/icons-material/PrintOutlined";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import PostAddOutlined from "@mui/icons-material/PostAddOutlined";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";
import PlaylistAddOutlined from "@mui/icons-material/PlaylistAddOutlined";
import ContactPageOutlined from "@mui/icons-material/ContactPageOutlined";

export const prepEntityId = "prep";

export const prepEntityFactory: EntityFactory<Prep, TermFilters> = (intl): Entity<Prep, TermFilters> => ({
  id: prepEntityId,
  filtersId: termEntityId,
  titleId: "preps",
  collection: "preps",
  persist: true,
  creatable: true,
  deletable: true,
  shareable: true,
  publishable: false,
  roles: {},
  defaultOrderBy: {field: "creationTime", direction: "desc"},
  actions: [
    {
      id: "print",
      Icon: PrintOutlined,
      titleId: "print",
      officeActive: false,
    },
    {
      id: "addToStage",
      Icon: PlaylistAddOutlined,
      titleId: "addToStage",
      tooltipId: "addPrepToStagesExplanation",
      officeActive: true,
    },
    {
      id: "addToDocument",
      Icon: PostAddOutlined,
      titleId: "addToDocument",
      tooltipId: "addPrepToDocumentExplanation",
      officeActive: true,
    },
    {
      id: "copy",
      Icon: ContentCopyOutlined,
      titleId: "copy",
      tooltipId: "copyPrepExplanation",
    },
    {
      id: "select",
      Icon: AssignmentOutlined,
      titleId: "select",
      tooltipId: "selectPrepExplanation",
    },
    {
      id: "createIntake",
      Icon: ContactPageOutlined,
      titleId: "createIntake",
    },
  ],
  columns: [
    {
      field: "title",
      headerNameId: "title",
      minWidth: 100,
      sortComparator: (v1, v2) => {
        return (v1 as string).localeCompare(v2 as string, undefined, {numeric: true});
      },
    },
    {
      field: "medicalField",
      headerNameId: "medicalFields",
      valueFormatter: (params) => {
        return params.value ? intl.formatMessage({id: getMedicalFieldLabelId(params.value as MedicalField)}) : "";
      },
      sortComparator: (v1, v2) => {
        const v1Str = v1 ? intl.formatMessage({id: getMedicalFieldLabelId(v1 as MedicalField)}) : "";
        const v2Str = v2 ? intl.formatMessage({id: getMedicalFieldLabelId(v2 as MedicalField)}) : "";
        return v1Str.localeCompare(v2Str);
      },
    },
    {
      field: "stages",
      headerNameId: "stages",
      valueFormatter: (params) => {
        return ((params.value || []) as PrepStage[])
          .map((stage) => intl.formatMessage({id: getStageTypeLabelId(stage.type)}))
          .join(", ");
      },
      sortComparator: (v1, v2) => {
        const v1Str = ((v1 || []) as PrepStage[])
          .map((stage) => intl.formatMessage({id: getStageTypeLabelId(stage.type)}))
          .join(", ");
        const v2Str = ((v2 || []) as PrepStage[])
          .map((stage) => intl.formatMessage({id: getStageTypeLabelId(stage.type)}))
          .join(", ");
        return v1Str.localeCompare(v2Str);
      },
    },
    // {
    //   field: "creationTime",
    //   headerNameId: "creationTime",
    //   valueFormatter: (params) => {
    //     try {
    //       return `${intl.formatDate(params.value as number)} ${intl.formatTime(params.value as number)}`;
    //     } catch (e) {
    //       return "";
    //     }
    //   },
    // },
    {
      field: "lastUpdateTime",
      headerNameId: "lastUpdateTime",
      valueFormatter: (params) => {
        try {
          return `${intl.formatDate(params.value as number)} ${intl.formatTime(params.value as number)}`;
        } catch (e) {
          return "";
        }
      },
    },
  ],
  formComponent: PrepForm,
  getItemTitle: (item) => item.title,
  generateEmptyItem: () => ({
    id: "",
    creationTime: 0,
    lastUpdateTime: 0,
    userIds: [],
    deleted: false,
    title: "",
    userId: "",
    medicalField: undefined,
    stages: [],
  }),
  generateSaveItem: (item, data) => {
    const now = new Date().getTime();
    return {
      ...item,
      ...cleanEntityData(data),
      title: data.title
        ? data.title
        : `${intl.formatMessage({id: "new"})} ${intl.formatDate(now)} ${intl.formatTime(now)}`,
    };
  },
  generateCloneItem: (item) => {
    item.title = item.title + " - " + intl.formatMessage({id: "clone"});
    return item;
  },
  generateEmptyFilters: (): TermFilters => ({
    categories: [],
    medicalFields: [],
  }),
  generateFullFilters: (): TermFilters => ({
    categories: Object.values(TermCategory),
    medicalFields: Object.values(MedicalField),
  }),
  hasActiveFilters: (filters: TermFilters) => filters.medicalFields.length < Object.values(MedicalField).length,
  hasEmptyFilters: (filters: TermFilters) => filters.medicalFields.length === 0,
  filterItems: (items, filters) => {
    const filterMedicalFields = isMedicalFieldsFilterActive(filters);
    const filterAny = filterMedicalFields;

    if (!filterAny) {
      return items;
    }

    return items.filter((item) => {
      if (checkMedicalFieldsFilter(filterMedicalFields, filters, item.medicalField)) {
        return false;
      }
      return true;
    });
  },
  filterDataItems: (items) => items,
  searchItems: (items, search) => {
    const cleanedSearch = cleanSearchString(search);
    if (!cleanedSearch) {
      return items;
    }
    return items.filter((item) => item.title.toLowerCase().includes(cleanedSearch));
  },
});
