import {useMemo} from "react";
import {useIntl} from "react-intl";
import {supportEmail} from "../constants/defaultValues";

const useSupportUrl = (): string => {
  const intl = useIntl();

  return useMemo<string>(
    () => `mailto:${supportEmail}?subject=${intl.formatMessage({id: "contactSupportSubject"})}`,
    []
  );
};

export default useSupportUrl;
