import * as React from "react";
import {MedicalField} from "../types/internal";
import {ComponentType} from "react";
import ChildCare from "@mui/icons-material/ChildCare";
import LocalHospital from "@mui/icons-material/LocalHospital";
import PregnantWoman from "@mui/icons-material/PregnantWoman";
import Carpenter from "@mui/icons-material/Carpenter";
import Psychology from "@mui/icons-material/Psychology";
import AirlineSeatReclineExtra from "@mui/icons-material/AirlineSeatReclineExtra";
import {SvgIconProps} from "@mui/material/SvgIcon";

export const getMedicalFieldLabelId = (field: MedicalField): string => {
  switch (field) {
    case MedicalField.InternalMedicine:
      return "medicalFieldInternalMedicine";
    case MedicalField.Paediatrics:
      return "medicalFieldPaediatrics";
    case MedicalField.Gynecology:
      return "medicalFieldGynecology";
    case MedicalField.Surgery:
      return "medicalFieldSurgery";
    case MedicalField.Psychiatry:
      return "medicalFieldPsychiatry";
    case MedicalField.Neurology:
      return "medicalFieldNeurology";
  }
};

export const getMedicalFieldIcon = (field: MedicalField): ComponentType<SvgIconProps> => {
  switch (field) {
    case MedicalField.InternalMedicine:
      return LocalHospital;
    case MedicalField.Paediatrics:
      return ChildCare;
    case MedicalField.Gynecology:
      return PregnantWoman;
    case MedicalField.Surgery:
      return Carpenter;
    case MedicalField.Psychiatry:
      return AirlineSeatReclineExtra;
    case MedicalField.Neurology:
      return Psychology;
  }
};
