import * as React from "react";
import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {Term, TermFilters} from "../../types/internal";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TermCard from "../../components/terms/TermCard";
import Page from "../../components/common/Page";
import {isEmpty} from "lodash";
import EmptySearch from "../../components/pages/search/EmptySearch";
import FilteredResults from "../../components/terms/FilteredResults";
import {useLocalStorageState, usePagination} from "@crud-studio/react-crud-core";
import CircularProgress from "@mui/material/CircularProgress";
import {termEntityId} from "../../entities/term.entity";
import useEntityFilters from "../../contexts/entity-filters/hooks/useEntityFilters";
import Stack from "@mui/material/Stack";
import SearchTextField from "../../components/common/SearchTextField";
import {localStorageKeyPageSize} from "../../constants/localStorageKeys";
import {pageSizes} from "../../constants/ui";
import Pagination from "@mui/material/Pagination";
import useSearchState from "../../helpers/useSearchState";
import ErrorMessage from "../../components/common/ErrorMessage";
import useEntity from "../../contexts/entity/hooks/useEntity";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {Entity} from "../../types/entity";
import {sortEntityItems} from "../../helpers/EntityUtils";

export interface SearchProps {}

const Search: FunctionComponent<SearchProps> = ({}) => {
  const {getEntity} = useEntity();
  const {getItems, lastChangeTime} = useEntityCrud();
  const {filtersChangeFlag, filterItems, searchItems} = useEntityFilters();

  const entity = useMemo<Entity<Term, TermFilters>>(() => getEntity(termEntityId), []);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [terms, setTerms] = useState<Term[] | undefined>(undefined);
  const [searchTerms, setSearchTerms] = useState<Term[] | undefined>(undefined);
  const [filteredTerms, setFilteredTerms] = useState<Term[]>([]);

  const [pageSize, setPageSize] = useLocalStorageState(localStorageKeyPageSize, pageSizes[0]);
  const [{pageItems, totalPages, currentPage}, setCurrentPage] = usePagination(filteredTerms, pageSize);

  const {pendingSearch, setPendingSearch, search} = useSearchState();
  const [activeSearch, setActiveSearch] = useState<string>("");

  useEffect(() => {
    (async () => {
      const items = await getItems(entity);
      setLoading(false);
      if (items) {
        setTerms(sortEntityItems(entity, entity.filterDataItems(items)));
      } else {
        setError(true);
      }
    })();
  }, [lastChangeTime[entity.id]]);

  useEffect(() => {
    if (!terms) {
      return;
    }

    setActiveSearch(search);

    const searchTerms = searchItems(entity, terms, search);
    setSearchTerms(searchTerms);
    setFilteredTerms(filterItems(entity, searchTerms));
    setCurrentPage(1);
  }, [search, terms, filtersChangeFlag]);

  const isTermsEmpty = useMemo<boolean>(
    () => !!activeSearch && pendingSearch.includes(activeSearch) && isEmpty(searchTerms),
    [activeSearch, pendingSearch, searchTerms]
  );
  const hasFilteredResults = useMemo<boolean>(
    () => !!searchTerms && searchTerms.length > filteredTerms.length,
    [searchTerms, filteredTerms]
  );
  const showPaging = useMemo<boolean>(() => totalPages > 1, [totalPages]);

  return (
    <>
      <Page sx={{height: "100%"}}>
        <Container sx={{height: "100%"}}>
          <Stack spacing={2}>
            <SearchTextField value={pendingSearch} onChangeValue={setPendingSearch} autoFocus />

            {loading && (
              <Box sx={{textAlign: "center"}}>
                <CircularProgress />
              </Box>
            )}

            {error && !terms && <ErrorMessage />}

            {pageItems.map((term) => (
              <TermCard term={term} key={term.id} />
            ))}

            {showPaging && (
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(e, page) => setCurrentPage(page)}
                size={"large"}
                variant="outlined"
                shape="rounded"
                color={"primary"}
                showFirstButton
                showLastButton
                sx={{display: "flex", justifyContent: "center"}}
              />
            )}

            {hasFilteredResults && <FilteredResults entityId={termEntityId} />}

            {isTermsEmpty && <EmptySearch />}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default Search;
