import * as React from "react";
import {ComponentType, useCallback, useMemo} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon";
import {ActionsMenuItem} from "../menus/ActionsMenuGeneric";
import ActionsMenuIconButton from "../menus/ActionsMenuIconButton";
import {useFormContext, useWatch} from "react-hook-form";
import {find, get} from "lodash";

interface FormTogglesMenuIconButtonProps {
  MenuIcon?: ComponentType<SvgIconProps>;
  actions: FormToggleMenuItem[];
  fontSize?: "inherit" | "large" | "medium" | "small";
}

export type FormToggleMenuItem = ActionsMenuItem & {
  fieldName: string;
  activeColor: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
};

const FormTogglesMenuIconButton = ({MenuIcon, actions, fontSize}: FormTogglesMenuIconButtonProps) => {
  const {control, getValues, setValue} = useFormContext();

  const watchFields = useMemo<string[]>(() => actions.map((action) => action.fieldName), [actions]);
  const values: (boolean | undefined)[] = useWatch({control: control, name: watchFields});

  const actionsInternal = useMemo<ActionsMenuItem[]>(
    () =>
      actions.map((action, index) => ({
        ...action,
        color: get(values, `[${index}]`) ? action.activeColor : undefined,
      })),
    [actions, values]
  );

  const actionHandler = useCallback(
    (actionId: string): void => {
      const action = find(actions, (action) => action.id === actionId);
      if (!action) {
        return;
      }
      const value = getValues(action.fieldName);
      setValue(action.fieldName, !value);
    },
    [actions, getValues, setValue]
  );

  return (
    <ActionsMenuIconButton
      MenuIcon={MenuIcon}
      actions={actionsInternal}
      fontSize={fontSize}
      onActionClick={actionHandler}
    />
  );
};

export default FormTogglesMenuIconButton;
