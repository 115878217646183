import * as React from "react";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import {ContentTypePayloadListItem} from "../../../../../types/internal";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import LightBulbOutlined from "@mui/icons-material/LightBulbOutlined";
import AssistantOutlined from "@mui/icons-material/AssistantOutlined";
import MoreOutlined from "@mui/icons-material/MoreOutlined";
import {ReactNode, useCallback, useMemo} from "react";
import TooltipIconButton from "../../../../common/TooltipIconButton";
import FormTogglesMenuIconButton, {FormToggleMenuItem} from "../../../../forms/FormTogglesMenuIconButton";
import {ActionsMenuItem} from "../../../../menus/ActionsMenuGeneric";
import {MHidden} from "../../../../@material-extend";
import FormToggleIconButton from "../../../../forms/FormToggleIconButton";
import ActionsMenuIconButton from "../../../../menus/ActionsMenuIconButton";

export interface IntakeTemplateContentPayloadListItemProps {
  item: ContentTypePayloadListItem;
  index: number;
  removeDisabled?: boolean;
  startAction?: ReactNode;
  disabled?: boolean;
  inputRefHandler: (index: number, input: any) => void;
  itemChangeHandler: (index: number, item: ContentTypePayloadListItem) => void;
  textChangeHandler: (index: number, text: string) => void;
  removeHandler: (index: number) => void;
  keyDownHandler: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
  pasteHandler: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
}

const IntakeTemplateContentPayloadListItem = ({
  item,
  index,
  removeDisabled,
  startAction,
  disabled,
  inputRefHandler,
  itemChangeHandler,
  textChangeHandler,
  removeHandler,
  keyDownHandler,
  pasteHandler,
}: IntakeTemplateContentPayloadListItemProps) => {
  const intl = useIntl();

  const actions = useMemo<ActionsMenuItem[]>(
    () => [
      {
        id: "example",
        Icon: LightBulbOutlined,
        color: item.example ? "error" : undefined,
        titleId: "example",
        disabled: disabled,
      },
      {
        id: "assistNotes",
        Icon: AssistantOutlined,
        color: item.assistNotes ? "info" : undefined,
        titleId: "assistNotes",
        disabled: disabled,
      },
      {
        id: "extendedFormat",
        Icon: MoreOutlined,
        color: item.extendedFormat ? "success" : undefined,
        titleId: "extendedFormat",
        disabled: disabled,
      },
    ],
    [item, disabled]
  );

  const actionsHandler = useCallback(
    (actionId: string): void => {
      switch (actionId) {
        case "example":
          itemChangeHandler(index, {...item, example: !item.example});
          break;
        case "assistNotes":
          itemChangeHandler(index, {...item, assistNotes: !item.assistNotes});
          break;
        case "extendedFormat":
          itemChangeHandler(index, {...item, extendedFormat: !item.extendedFormat});
          break;
      }
    },
    [item, index, itemChangeHandler]
  );

  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"}>
      {startAction}

      <TextField
        defaultValue={item.payload}
        inputRef={(input) => inputRefHandler(index, input)}
        onChange={(e) => textChangeHandler(index, e.target.value)}
        fullWidth
        label={intl.formatMessage({id: "item"})}
        disabled={disabled}
        inputProps={{
          maxLength: 500,
          onKeyDown: (e) => keyDownHandler(e, index),
          onPaste: (e) => pasteHandler(e, index),
        }}
      />

      <MHidden width={"lgDown"}>
        {actions.map((action) => (
          <TooltipIconButton
            Icon={action.Icon}
            tooltipId={action.titleId}
            color={action.color}
            disabled={action.disabled}
            onClick={() => actionsHandler(action.id)}
            key={action.id}
          />
        ))}
      </MHidden>

      <MHidden width={"lgUp"}>
        <ActionsMenuIconButton actions={actions} fontSize={"medium"} onActionClick={actionsHandler} />
      </MHidden>

      <TooltipIconButton
        Icon={DeleteOutlined}
        color={"error"}
        tooltipId={"remove"}
        disabled={removeDisabled || disabled}
        onClick={() => removeHandler(index)}
      />
    </Stack>
  );
};

export default IntakeTemplateContentPayloadListItem;
