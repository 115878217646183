import {useState} from "react";
import {useDebounceFn} from "@crud-studio/react-crud-core";
import {useUpdateEffect} from "react-use";

const useSearchState = (): {pendingSearch: string; setPendingSearch: (search: string) => void; search: string} => {
  const [search, setSearch] = useState<string>("");
  const setSearchDebounced = useDebounceFn(setSearch, 250);
  const [pendingSearch, setPendingSearch] = useState<string>("");

  useUpdateEffect(() => {
    setSearchDebounced(pendingSearch);
    if (!pendingSearch) {
      setSearch(pendingSearch);
    }
  }, [pendingSearch]);

  return {pendingSearch, setPendingSearch, search};
};

export default useSearchState;
