import * as React from "react";
import Container from "@mui/material/Container";
import Page from "../../../components/common/Page";
import EntityDataGrid from "../../../components/entity/EntityDataGrid";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useEntity from "../../../contexts/entity/hooks/useEntity";
import {useCallback, useMemo} from "react";
import {Entity} from "../../../types/entity";
import useEntityNavigation from "../../../helpers/useEntityNavigation";
import {urlParamUserId} from "../../../constants/urlParams";
import {useAppSelector} from "../../../redux/hooks";
import {selectUserId} from "../../../redux/auth/selector";

export interface EntityAdminGridProps {}

const EntityGrid = ({}: EntityAdminGridProps) => {
  const {getEntity} = useEntity();
  const params = useParams();
  const navigate = useNavigate();
  const {search} = useLocation();
  const {getEntityDetailsUrl, getEntityCreateUrl} = useEntityNavigation();

  const userId = useAppSelector(selectUserId);

  const entity = useMemo<Entity<any, any>>(() => getEntity(params.entity), [params]);
  const entityUserId = useMemo<string>(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get(urlParamUserId) || userId;
  }, [search, userId]);
  const key = useMemo<string | undefined>(() => `${entity.id}_${entityUserId}`, [entity, entityUserId]);

  const selectedItemHandler = useCallback(
    (itemId: string): void => {
      navigate(getEntityDetailsUrl(entity, itemId));
    },
    [entity, getEntityDetailsUrl]
  );

  const newItemHandler = useCallback((): void => {
    navigate(getEntityCreateUrl(entity, entityUserId));
  }, [entity, entityUserId, getEntityCreateUrl]);

  return (
    <Page>
      <Container>
        <EntityDataGrid
          entity={entity}
          entityUserId={entityUserId}
          onSelectedItem={selectedItemHandler}
          onNewItem={newItemHandler}
          key={key}
        />
      </Container>
    </Page>
  );
};

export default EntityGrid;
