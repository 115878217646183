import {Entity, EntityFactory} from "../types/entity";
import {Share} from "../types/internal";
import {cleanEntityData} from "../helpers/EntityUtils";
import {termEntityId} from "./term.entity";
import ShareForm from "../components/entity/forms/share/ShareForm";
import NavigationUtils from "../helpers/NavigationUtils";

export const shareEntityId = "share";

export const shareEntityFactory: EntityFactory<Share, any> = (intl): Entity<Share, any> => ({
  id: shareEntityId,
  filtersId: termEntityId,
  titleId: "shares",
  collection: "shares",
  persist: false,
  creatable: false,
  deletable: false,
  shareable: false,
  publishable: false,
  roles: {},
  defaultOrderBy: {field: "creationTime", direction: "desc"},
  actions: [],
  columns: [],
  formComponent: ShareForm,
  getItemTitle: (item) => "",
  getItemDetailsUrl: (itemId) => NavigationUtils.getShareUrl(itemId),
  generateEmptyItem: () => ({
    id: "",
    creationTime: 0,
    lastUpdateTime: 0,
    userIds: [],
    deleted: false,
  }),
  generateSaveItem: (item, data) => {
    return {
      ...item,
      ...cleanEntityData(data),
    };
  },
  generateCloneItem: (item) => {
    return item;
  },
  generateEmptyFilters: (): any => undefined,
  generateFullFilters: (): any => undefined,
  hasActiveFilters: (filters: any) => false,
  hasEmptyFilters: (filters: any) => false,
  filterItems: (items, filters) => items,
  filterDataItems: (items) => items,
  searchItems: (items, search) => items,
});
