import * as React from "react";
import IntakeTemplateContentForm, {IntakeTemplateContentFormProps} from "./IntakeTemplateContentForm";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Box from "@mui/material/Box";
import DragHandle from "../../../common/DragHandle";
import {MHidden} from "../../../@material-extend";

const IntakeTemplateContentFormSortable = (props: IntakeTemplateContentFormProps) => {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: props.id});
  const {disabled} = props;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <IntakeTemplateContentForm
        {...props}
        startAction={
          !disabled && (
            <MHidden width={"smDown"}>
              <DragHandle {...listeners} {...attributes} />
            </MHidden>
          )
        }
      />
    </Box>
  );
};

export default IntakeTemplateContentFormSortable;
