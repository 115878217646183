import * as React from "react";
import {FunctionComponent, PropsWithChildren} from "react";

export type OfficeContextProps = {
  isOfficeInitialized: boolean;
  isOfficeActive: boolean;
};

const OfficeContext = React.createContext<OfficeContextProps>(undefined!);

export interface OfficeProviderProps extends PropsWithChildren<any> {
  isOfficeInitialized: boolean;
  isOfficeActive: boolean;
}

const OfficeProvider: FunctionComponent<OfficeProviderProps> = ({children, isOfficeInitialized, isOfficeActive}) => {
  return (
    <OfficeContext.Provider
      value={{
        isOfficeInitialized,
        isOfficeActive,
      }}
    >
      {children}
    </OfficeContext.Provider>
  );
};

export {OfficeContext, OfficeProvider};
