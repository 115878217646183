import * as React from "react";
import Container from "@mui/material/Container";
import Page from "../../../components/common/Page";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useEntity from "../../../contexts/entity/hooks/useEntity";
import {useCallback, useEffect, useMemo, useState} from "react";
import {BaseEntityRO, Entity} from "../../../types/entity";
import EntityForm from "../../../components/entity/EntityForm";
import useEntityNavigation from "../../../helpers/useEntityNavigation";
import {urlParamUserId} from "../../../constants/urlParams";

export interface EntityAdminDetailsProps {}

const EntityDetails = ({}: EntityAdminDetailsProps) => {
  const {getEntity} = useEntity();
  const params = useParams();
  const navigate = useNavigate();
  const {search} = useLocation();
  const {getEntityDetailsUrl} = useEntityNavigation();

  const [keyItemId, setKeyItemId] = useState<string>(params.id || "");
  const [lastItemId, setLastItemId] = useState<string>(params.id || "");

  const entity = useMemo<Entity<any, any>>(() => getEntity(params.entity), [params]);
  const entityUserId = useMemo<string | undefined>(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get(urlParamUserId) || undefined;
  }, [search]);
  const itemId = useMemo<string>(() => params.id || "", [params]);
  const key = useMemo<string>(() => `${entity.id}_${keyItemId}`, [entity, keyItemId]);

  useEffect(() => {
    if (keyItemId && keyItemId !== itemId) {
      setKeyItemId(itemId);
    }

    if (lastItemId && itemId && lastItemId !== itemId) {
      setKeyItemId(itemId);
    }
    setLastItemId(itemId);
  }, [itemId]);

  const itemSavedHandler = useCallback(
    (item: BaseEntityRO) => {
      if (item.id !== itemId) {
        navigate(getEntityDetailsUrl(entity, item.id), {replace: true});
      }
    },
    [entity, itemId, getEntityDetailsUrl]
  );

  return (
    <Page>
      <Container>
        <EntityForm
          entity={entity}
          entityUserId={entityUserId}
          itemId={itemId}
          onItemSaved={itemSavedHandler}
          key={key}
        />
      </Container>
    </Page>
  );
};

export default EntityDetails;
