import {useContext} from "react";
import {NegateKeywordsContext, NegateKeywordsContextProps} from "../NegateKeywordsContext";

const useNegateKeywords = (): NegateKeywordsContextProps => {
  const context = useContext(NegateKeywordsContext);

  if (!context) throw new Error("NegateKeywordsContext must be used inside NegateKeywordsProvider");

  return context;
};
export default useNegateKeywords;
