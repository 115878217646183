import * as React from "react";
import {Suspense, lazy} from "react";
import {Navigate, Outlet, useRoutes} from "react-router-dom";
import {urls} from "./urls";
import AppLayout from "../layout/app";
import componentLoader from "../helpers/ComponentLoader";
import LoadingCenter from "../components/common/LoadingCenter";
import LogoOnlyLayout from "../layout/LogoOnlyLayout";
import {defaultAppUrl} from "../constants/defaultValues";
import useOffice from "../contexts/office/hooks/useOffice";
import EntityGuard from "./guards/EntityGuard";
import RoleGuard from "./guards/RoleGuard";
import {roleAdmin} from "../constants/roles";
import AuthGuard from "./guards/AuthGuard";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingCenter />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const {isOfficeActive} = useOffice();

  return useRoutes([
    {
      path: "/",
      element: <Navigate to={urls.app.url} replace />,
    },

    // App Routes
    {
      path: urls.app.path,
      element: <AppLayout />,
      children: [
        {path: "", element: <Navigate to={defaultAppUrl} replace />},
        {
          path: urls.home.path,
          element: isOfficeActive ? <KeywordsExplorer /> : <HomeDesktop />,
        },
        {
          path: urls.search.path,
          element: <Search />,
        },
        {
          path: urls.newIntake.path,
          element: <NewIntake />,
        },
        {
          path: urls.support.path,
          element: <Support />,
        },
        {
          path: urls.comingSoon.path,
          element: <ComingSoon />,
        },
        {
          path: urls.termsOfService.path,
          element: <TermsOfService />,
        },
        {
          path: urls.privacyPolicy.path,
          element: <PrivacyPolicy />,
        },
        {
          path: urls.entity.path,
          element: (
            <AuthGuard>
              <EntityGuard>
                <Outlet />
              </EntityGuard>
            </AuthGuard>
          ),
          children: [
            {
              path: urls.entityGrid.path,
              element: <EntityGrid />,
            },
            {
              path: urls.entityDetailsId.path,
              element: <EntityDetails />,
            },
            {
              path: urls.entityDetails.path,
              element: <EntityDetails />,
            },
          ],
        },
        {
          path: urls.entityShareId.path,
          element: <EntityShare />,
        },
        {
          path: urls.entityPublishRequestId.path,
          element: (
            <AuthGuard>
              <RoleGuard role={roleAdmin}>
                <EntityPublishRequest />
              </RoleGuard>
            </AuthGuard>
          ),
        },
        {
          path: urls.entityEditRequestId.path,
          element: (
            <AuthGuard>
              <RoleGuard role={roleAdmin}>
                <EntityEditRequest />
              </RoleGuard>
            </AuthGuard>
          ),
        },
        // {
        //   path: urls.migration.path,
        //   element: (
        //     <AuthGuard>
        //       <RoleGuard role={roleAdmin}>
        //         <Migration />
        //       </RoleGuard>
        //     </AuthGuard>
        //   ),
        // },
      ],
    },

    // General Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        {path: urls.error.path, element: <Error />},
        {path: "*", element: <Navigate to={urls.error.url} replace />},
      ],
    },
    {path: "*", element: <Navigate to={urls.error.url} replace />},
  ]);
}

// IMPORT COMPONENTS

// APP
const KeywordsExplorer = Loadable(lazy(() => componentLoader(() => import("../pages/app/keywords-explorer"))));
const HomeDesktop = Loadable(lazy(() => componentLoader(() => import("../pages/app/home-desktop"))));
const Search = Loadable(lazy(() => componentLoader(() => import("../pages/app/search"))));
const NewIntake = Loadable(lazy(() => componentLoader(() => import("../pages/app/new-intake"))));
const Support = Loadable(lazy(() => componentLoader(() => import("../pages/app/support"))));
const ComingSoon = Loadable(lazy(() => componentLoader(() => import("../pages/app/coming-soon"))));

// CONTENT
const TermsOfService = Loadable(lazy(() => componentLoader(() => import("../pages/app/content/terms-of-service"))));
const PrivacyPolicy = Loadable(lazy(() => componentLoader(() => import("../pages/app/content/privacy-policy"))));

// ENTITY
const EntityGrid = Loadable(lazy(() => componentLoader(() => import("../pages/app/entity/entity-grid"))));
const EntityDetails = Loadable(lazy(() => componentLoader(() => import("../pages/app/entity/entity-details"))));
const EntityShare = Loadable(lazy(() => componentLoader(() => import("../pages/app/entity/entity-share"))));
const EntityPublishRequest = Loadable(
  lazy(() => componentLoader(() => import("../pages/app/entity/entity-publish-request")))
);
const EntityEditRequest = Loadable(
  lazy(() => componentLoader(() => import("../pages/app/entity/entity-edit-request")))
);

// GENERAL

// ERRORS
const Error = Loadable(lazy(() => componentLoader(() => import("../pages/general/error"))));

// const Migration = Loadable(lazy(() => componentLoader(() => import("../pages/app/migration/migration"))));
