import {urls} from "../routes/urls";
import {buildUrl, UrlOptions} from "@crud-studio/react-crud-core";
import {urlParamPrepId} from "../constants/urlParams";

const NavigationUtils = {
  getShareUrl: function (shareId: string, options?: UrlOptions): string {
    const url: string = urls.entityShareId.url.replace(":id", shareId);
    return buildUrl(url, options);
  },

  getPublishRequestUrl: function (publishRequestId: string, options?: UrlOptions): string {
    const url: string = urls.entityPublishRequestId.url.replace(":id", publishRequestId);
    return buildUrl(url, options);
  },

  getEditRequestUrl: function (editRequestId: string, options?: UrlOptions): string {
    const url: string = urls.entityEditRequestId.url.replace(":id", editRequestId);
    return buildUrl(url, options);
  },

  getNewIntakeUrl: function (prepId?: string): string {
    const url: string = urls.newIntake.url;
    return buildUrl(url, {queryStringParams: prepId ? {[urlParamPrepId]: prepId} : {}});
  },
};

export default NavigationUtils;
