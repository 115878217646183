import * as React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Box from "@mui/material/Box";
import DragHandle from "../../../common/DragHandle";
import PrepStageForm, {PrepStageFormProps} from "./PrepStageForm";
import {MHidden} from "../../../@material-extend";

const PrepStageFormSortable = (props: PrepStageFormProps) => {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: props.id});
  const {disabled} = props;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <PrepStageForm
        {...props}
        // startAction={
        //   !disabled && (
        //     <MHidden width={"smDown"}>
        //       <DragHandle {...listeners} {...attributes} />
        //     </MHidden>
        //   )
        // }
        unmountOnExit={isDragging}
      />
    </Box>
  );
};

export default PrepStageFormSortable;
