import * as React from "react";
import {FormattedMessage} from "react-intl";
import {FunctionComponent} from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {selectRegExp} from "../../helpers/SelectTextUtils";
import {useSnackbar} from "notistack";
import {fillCharacter, listItemPrefix} from "../../helpers/IntakeTemplateUtils";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {
  localStorageKeyValidateIntakeFillCharacters,
  localStorageKeyValidateIntakeTermClauses,
} from "../../constants/localStorageKeys";

export type ValidateIntakeDialogProps = {};

const ValidateIntakeDialog: FunctionComponent<ValidateIntakeDialogProps> = NiceModal.create(({}) => {
  const modal = useModal();
  const {enqueueSnackbar} = useSnackbar();

  const [searchFillCharacters, setSearchFillCharacters] = useLocalStorageState<boolean>(
    localStorageKeyValidateIntakeFillCharacters,
    true
  );
  const [searchTermClauses, setSearchTermClauses] = useLocalStorageState<boolean>(
    localStorageKeyValidateIntakeTermClauses,
    true
  );

  const validateHandler = async (): Promise<void> => {
    if (searchFillCharacters) {
      const regExp = new RegExp(`${fillCharacter}+`);
      const selectedRegExpCharacters = await selectRegExp(regExp);
      if (selectedRegExpCharacters) {
        enqueueSnackbar(<FormattedMessage id="fillCharactersFound" values={{character: fillCharacter}} />, {
          variant: "warning",
        });
        return;
      }
    }

    if (searchTermClauses) {
      const regExp = new RegExp(`${listItemPrefix}+`);
      const selectedRegExpCharacters = await selectRegExp(regExp);
      if (selectedRegExpCharacters) {
        enqueueSnackbar(<FormattedMessage id="termClausesFound" values={{character: listItemPrefix}} />, {
          variant: "warning",
        });
        return;
      }
    }

    enqueueSnackbar(<FormattedMessage id="validateIntakeSuccess" />, {variant: "success"});
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleEnhanced onClose={modal.hide}>
        <FormattedMessage id="validateIntake" />
      </DialogTitleEnhanced>
      <DialogContent>
        <Stack direction="column">
          <FormControlLabel
            control={
              <Checkbox
                checked={searchFillCharacters}
                onChange={(event, currentChecked) => setSearchFillCharacters(currentChecked)}
              />
            }
            label={<FormattedMessage id="searchFillCharacters" values={{character: fillCharacter}} />}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchTermClauses}
                onChange={(event, currentChecked) => setSearchTermClauses(currentChecked)}
              />
            }
            label={<FormattedMessage id="searchTermClauses" values={{character: listItemPrefix}} />}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={modal.hide}>
          <FormattedMessage id="close" />
        </Button>
        <Button variant="contained" color="primary" onClick={validateHandler}>
          <FormattedMessage id="validate" />
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ValidateIntakeDialog;
