import * as React from "react";
import {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {NegateKeyword, NegateKeywordType} from "../../../../types/internal";
import Scrollbar from "../../../../components/common/Scrollbar";
import useNegateKeywords from "../../../../contexts/negate-keywords/hooks/useNegateKeywords";
import {isEmpty} from "lodash";
import Divider from "@mui/material/Divider";
import TooltipIcon from "../../../common/TooltipIcon";
import Tooltip from "@mui/material/Tooltip";
import KeywordFinderSettingsPopoverNewForm from "./KeywordFinderSettingsPopoverNewForm";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import DisabledByDefaultOutlined from "@mui/icons-material/DisabledByDefaultOutlined";

export interface KeywordFinderSettingsPopoverDetailsProps {}

export default function KeywordFinderSettingsPopoverDetails({}: KeywordFinderSettingsPopoverDetailsProps) {
  const {negateKeywords, updateNegateKeyword, removeNegateKeyword, restoreDefaultNegateKeywords} = useNegateKeywords();

  const negateKeywordsEmpty = useMemo<boolean>(() => isEmpty(negateKeywords), [negateKeywords]);

  const updateHandler = useCallback(
    (negateKeyword: NegateKeyword): void => {
      updateNegateKeyword(negateKeyword);
    },
    [updateNegateKeyword]
  );

  const removeHandler = useCallback(
    (negateKeyword: NegateKeyword): void => {
      removeNegateKeyword(negateKeyword);
    },
    [removeNegateKeyword]
  );

  return (
    <>
      <Typography variant="h5" sx={{px: 2, py: 1}}>
        <FormattedMessage id="negateKeywords" />
        <TooltipIcon tooltip={<FormattedMessage id="negateKeywordsExplanation" />} />
      </Typography>

      <Divider sx={{m: "0!important"}} />

      {negateKeywordsEmpty ? (
        <Typography variant={"body2"} sx={{p: 2, textAlign: "center", color: "text.secondary"}}>
          <FormattedMessage id="prepEmpty" />
        </Typography>
      ) : (
        <Scrollbar sx={{maxHeight: 250}}>
          <Stack spacing={1} sx={{p: 2}}>
            {negateKeywords.map((keyword) => (
              <Stack direction={"row"} spacing={1} alignItems={"center"} key={keyword.uuid}>
                <Typography variant={"body1"} sx={{color: "text.primary", flexGrow: 1}} noWrap>
                  {keyword.keyword}
                </Typography>

                <Tooltip
                  title={
                    <FormattedMessage
                      id={
                        keyword.type === NegateKeywordType.Sentence
                          ? "negateKeywordTypeSentenceExplanation"
                          : "negateKeywordTypeWordExplanation"
                      }
                    />
                  }
                >
                  <IconButton
                    color={keyword.type === NegateKeywordType.Sentence ? "warning" : "inherit"}
                    onClick={() =>
                      updateHandler({
                        ...keyword,
                        type:
                          keyword.type === NegateKeywordType.Sentence
                            ? NegateKeywordType.Word
                            : NegateKeywordType.Sentence,
                      })
                    }
                  >
                    <DisabledByDefaultOutlined fontSize={"small"} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={<FormattedMessage id={"remove"} />}>
                  <IconButton color={"error"} onClick={() => removeHandler(keyword)}>
                    <DeleteOutlined fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              </Stack>
            ))}
          </Stack>
        </Scrollbar>
      )}

      <Divider sx={{m: "0!important"}} />

      <KeywordFinderSettingsPopoverNewForm />

      <Divider sx={{m: "0!important"}} />

      <Box sx={{p: 2}}>
        <Button variant={"outlined"} size={"medium"} color={"inherit"} fullWidth onClick={restoreDefaultNegateKeywords}>
          <FormattedMessage id="restoreDefaults" />
        </Button>
      </Box>
    </>
  );
}
