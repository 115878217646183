import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {NegateKeyword, Term} from "../../../types/internal";
import {chain, isNil, some, trim} from "lodash";
import {useShallowCompareEffect} from "react-use";
import {buildReversedKeywordsRegex, buildReversedKeywordsString} from "../../../helpers/RegexUtils";
import {reverseString} from "../../../helpers/StringUtils";

export interface KeywordFinderProps {
  terms: {[key: string]: Term};
  text?: string;
  negateKeywords?: NegateKeyword[];
  onTermsFoundChanged: (terms: Term[]) => void;
}

const KeywordFinder: FunctionComponent<KeywordFinderProps> = ({terms, text, negateKeywords, onTermsFoundChanged}) => {
  const keywords = useMemo<string[]>(
    () =>
      chain(terms)
        .values()
        .map<string[]>((term) =>
          chain(term.keywords)
            .filter((keyword) => !!trim(keyword))
            .uniq()
            .filter((keyword, i, arr) => !some(arr, (s) => keyword !== s && keyword.includes(s)))
            .value()
        )
        .flatten()
        .map<string>((keyword) => trim(keyword.toLowerCase()))
        .value(),
    [terms]
  );
  const keywordsTermIds = useMemo<{[key: string]: string}>(
    () =>
      chain(terms)
        .reduce((result, term) => {
          term.keywords
            .filter((keyword) => !!trim(keyword))
            .forEach((keyword) => {
              result[keyword.toLowerCase()] = term.id;
            });
          return result;
        }, {})
        .value(),
    [terms]
  );
  const keywordsRegExp = useMemo<RegExp>(
    () => buildReversedKeywordsRegex(keywords, negateKeywords),
    [keywords, negateKeywords]
  );

  const [keywordsTerms, setKeywordsTerms] = useState<Term[] | undefined>(undefined);

  useEffect(() => {
    if (isNil(text)) {
      return;
    }

    if (text) {
      const match = buildReversedKeywordsString(text).match(keywordsRegExp);
      const keywordsTerms = chain(match)
        .uniq()
        .reverse()
        .map<string>((keywordMatch) => keywordsTermIds[reverseString(keywordMatch.trim().toLowerCase())])
        .map<Term>((termId) => terms[termId])
        .filter((term) => !!term)
        .uniq()
        .value();
      setKeywordsTerms(keywordsTerms);
    } else {
      setKeywordsTerms([]);
    }
  }, [text, keywordsTermIds]);

  useShallowCompareEffect(() => {
    if (keywordsTerms) {
      onTermsFoundChanged(keywordsTerms);
    }
  }, [keywordsTerms]);

  return null;
};

export default KeywordFinder;
