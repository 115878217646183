import * as React from "react";
import CardHeader from "@mui/material/CardHeader";
import {ComponentType, FunctionComponent, ReactNode, useCallback, useMemo} from "react";
import {Term} from "../../types/internal";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import {FormattedMessage} from "react-intl";
import {getTermCategoryIcon, getTermCategoryLabelId} from "../../helpers/TermUtils";
import TooltipIcon from "../common/TooltipIcon";
import {SvgIconProps} from "@mui/material/SvgIcon";
import MAvatar from "../@material-extend/MAvatar";
import Box from "@mui/material/Box";
import {publicUserId} from "../../contexts/entity-crud/EntityCrudContext";

export interface TermCardHeaderProps {
  term: Term;
  action?: ReactNode;
  sx?: SxProps<Theme>;
  onClick?: (term: Term) => void;
}

const TermCardHeader: FunctionComponent<TermCardHeaderProps> = ({term, action, sx, onClick}) => {
  const CategoryIcon = useMemo<ComponentType<SvgIconProps>>(() => getTermCategoryIcon(term.category), [term]);
  const categoryLabelId = useMemo<string>(() => getTermCategoryLabelId(term.category), [term]);
  const userOwned = useMemo<boolean>(() => !term.userIds?.includes(publicUserId), [term]);
  const publisher = useMemo<string>(() => term.publishFullName || "", [term]);

  const clickHandler = useCallback((): void => {
    onClick?.(term);
  }, [onClick]);

  return (
    <CardHeader
      avatar={
        <MAvatar color="primary">
          <CategoryIcon />
        </MAvatar>
      }
      action={<Box onClick={(e) => e.stopPropagation()}>{action}</Box>}
      title={
        <>
          {term.title}
          {term.tooltip && <TooltipIcon tooltip={term.tooltip} />}
        </>
      }
      subheader={
        <>
          <FormattedMessage id={categoryLabelId} />
          {userOwned && (
            <>
              {" ("}
              <FormattedMessage id="private" />
              {")"}
            </>
          )}
          {publisher && <>{` (${publisher})`}</>}
        </>
      }
      onClick={clickHandler}
      sx={{...(onClick ? {cursor: "pointer"} : {}), p: 3, ...sx}}
    />
  );
};

export default TermCardHeader;
