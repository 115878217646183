import {useContext} from "react";
import {ActivePrepContext, ActivePrepContextProps} from "../ActivePrepContext";

const useActivePrep = (): ActivePrepContextProps => {
  const context = useContext(ActivePrepContext);

  if (!context) throw new Error("ActivePrepContext must be used inside ActivePrepProvider");

  return context;
};
export default useActivePrep;
