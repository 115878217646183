import {useContext} from "react";
import {OfficeContext, OfficeContextProps} from "../OfficeContext";

const useOffice = (): OfficeContextProps => {
  const context = useContext(OfficeContext);

  if (!context) throw new Error("OfficeContext must be used inside OfficeProvider");

  return context;
};
export default useOffice;
