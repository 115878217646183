import * as React from "react";
import {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Button from "@mui/material/Button";
import NiceModal from "@ebay/nice-modal-react";
import LoginDialog from "../dialogs/LoginDialog";

interface NotificationActionSignUpProps {}

const NotificationActionSignUp: FunctionComponent<NotificationActionSignUpProps> = ({}) => {
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    NiceModal.show(LoginDialog, {});
  };

  return (
    <Button variant="text" size="small" color="inherit" onClick={clickHandler}>
      <FormattedMessage id="signUp" />
    </Button>
  );
};
export default NotificationActionSignUp;
