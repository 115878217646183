import * as React from "react";
import {ReactNode, useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import Button from "@mui/material/Button";
import {Entity, EntityPublishStatus, PublishableEntityRO} from "../../../../types/entity";
import Tooltip from "@mui/material/Tooltip";
import PublicOutlined from "@mui/icons-material/PublicOutlined";
import NiceModal from "@ebay/nice-modal-react";
import PublishEntityItemDialog from "../../../dialogs/PublishEntityItemDialog";
import {useAppSelector} from "../../../../redux/hooks";
import {selectUserId} from "../../../../redux/auth/selector";
import {publicUserId} from "../../../../contexts/entity-crud/EntityCrudContext";

export interface PublishEntityItemButtonProps<EntityRO extends PublishableEntityRO> {
  entity: Entity<EntityRO, any>;
  item?: EntityRO;
}

const PublishEntityItemButton = <EntityRO extends PublishableEntityRO>({
  entity,
  item,
}: PublishEntityItemButtonProps<EntityRO>) => {
  const userId = useAppSelector(selectUserId);

  const labelId = useMemo<string>(() => {
    const publishStatus = item?.publishStatus || EntityPublishStatus.None;
    switch (publishStatus) {
      case EntityPublishStatus.Approved:
        return "published";
      case EntityPublishStatus.None:
        return "publish";
      case EntityPublishStatus.Rejected:
        return "rejected";
      case EntityPublishStatus.WaitingForApproval:
        return "processing";
    }
  }, [item]);

  const tooltip = useMemo<ReactNode>(() => {
    const publishStatus = item?.publishStatus || EntityPublishStatus.None;
    switch (publishStatus) {
      case EntityPublishStatus.Approved:
        return (
          <>
            <div>
              <FormattedMessage id={"publishEntityApprovedExplanation"} />
            </div>
            <div>{item.publishReason || <FormattedMessage id={"publishEntityApprovedReason"} />}</div>
          </>
        );
      case EntityPublishStatus.None:
        return <FormattedMessage id={"publishEntityPublishExplanation"} />;
      case EntityPublishStatus.Rejected:
        return (
          <>
            <div>
              <FormattedMessage id={"publishEntityRejectedExplanation"} />
            </div>
            <div>{item.publishReason || <FormattedMessage id={"publishEntityRejectedReason"} />}</div>
          </>
        );
      case EntityPublishStatus.WaitingForApproval:
        return <FormattedMessage id={"publishEntityWaitingForApprovalExplanation"} />;
    }
  }, [item]);

  const color = useMemo<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning">(() => {
    const publishStatus = item?.publishStatus || EntityPublishStatus.None;
    switch (publishStatus) {
      case EntityPublishStatus.Approved:
        return "success";
      case EntityPublishStatus.None:
        return "primary";
      case EntityPublishStatus.Rejected:
        return "error";
      case EntityPublishStatus.WaitingForApproval:
        return "inherit";
    }
  }, [item]);

  const disabled = useMemo<boolean>(
    () =>
      !item?.id ||
      item?.publishStatus === EntityPublishStatus.WaitingForApproval ||
      item?.publishStatus === EntityPublishStatus.Approved,
    [item]
  );
  const hidden = useMemo<boolean>(() => {
    const publishStatus = item?.publishStatus || EntityPublishStatus.None;
    return (
      !entity.publishable ||
      !item?.userIds?.includes(userId) ||
      (item?.publishUserId !== userId && publishStatus !== EntityPublishStatus.None) ||
      (item?.userIds?.includes(publicUserId) && publishStatus !== EntityPublishStatus.Approved)
    );
  }, [entity, item, userId]);

  const publishHandler = useCallback((): void => {
    if (!item?.id) {
      return;
    }
    NiceModal.show(PublishEntityItemDialog, {entityId: entity, item: item});
  }, [entity, item]);

  if (hidden) {
    return null;
  }

  return (
    <Tooltip title={tooltip}>
      <span>
        <Button
          variant={"outlined"}
          color={color}
          startIcon={<PublicOutlined fontSize={"small"} />}
          disabled={disabled}
          onClick={publishHandler}
        >
          <FormattedMessage id={labelId} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default PublishEntityItemButton;
