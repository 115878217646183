import * as React from "react";
import {FunctionComponent, useCallback} from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {FormattedMessage} from "react-intl";
import useEntityFilters from "../../contexts/entity-filters/hooks/useEntityFilters";
import {Entity} from "../../types/entity";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";

export interface FilteredResultsProps {
  entityId?: Entity<any, any> | string;
  onClear?: () => void;
  sx?: SxProps<Theme>;
}

const FilteredResults: FunctionComponent<FilteredResultsProps> = ({entityId, onClear, sx}) => {
  const {clearFilters} = useEntityFilters();

  const clearHandler = useCallback((): void => {
    if (entityId) {
      clearFilters(entityId);
    }
    onClear?.();
  }, [clearFilters]);

  return (
    <Stack direction="column" alignItems="center" sx={{...sx}}>
      <Typography variant="body2" sx={{textAlign: "center", mb: 1, color: "text.secondary"}}>
        <FormattedMessage id="hasFilteredResults" />
      </Typography>

      <Button variant="outlined" onClick={clearHandler} size="small">
        <FormattedMessage id="clearFilters" />
      </Button>
    </Stack>
  );
};

export default FilteredResults;
