import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export interface PageHeaderProps {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  action?: ReactNode;
}

const PageHeader: FunctionComponent<PageHeaderProps> = ({title, subtitle, action}) => {
  return (
    <Stack
      direction={{xs: "column", md: "row"}}
      spacing={1}
      justifyContent={"space-between"}
      alignItems={{xs: "stretch", md: "center"}}
      sx={{mb: 3}}
    >
      <Box>
        <Typography variant="h3" sx={{display: "inline-block"}}>
          {title}
        </Typography>
        {subtitle && (
          <>
            &nbsp;
            <Typography variant="h6" sx={{display: "inline-block", color: "text.secondary"}}>
              {subtitle}
            </Typography>
          </>
        )}
      </Box>

      {action}
    </Stack>
  );
};

export default PageHeader;
