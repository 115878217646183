export class MissingKeywordsError extends Error {
  constructor() {
    super("Missing keywords");

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, MissingKeywordsError.prototype);
  }
}

export class KeywordsNotFoundError extends Error {
  constructor() {
    super("Keywords not found");

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, KeywordsNotFoundError.prototype);
  }
}
