import * as React from "react";
import {ComponentType} from "react";
import Coronavirus from "@mui/icons-material/Coronavirus";
import Medication from "@mui/icons-material/Medication";
import PersonSearch from "@mui/icons-material/PersonSearch";
import Biotech from "@mui/icons-material/Biotech";
import Attribution from "@mui/icons-material/Attribution";
import {MedicalField, StageType, TermCategory} from "../types/internal";
import Cake from "@mui/icons-material/Cake";
import {SvgIconProps} from "@mui/material/SvgIcon";
import {intersection, isArray, isEmpty} from "lodash";

export const getTermCategoryIcon = (category: TermCategory): ComponentType<SvgIconProps> => {
  switch (category) {
    // case TermCategory.Other:
    //   return MoreHoriz;
    case TermCategory.Drug:
      return Medication;
    case TermCategory.Symptom:
      return PersonSearch;
    case TermCategory.Age:
      return Cake;
    case TermCategory.Disease:
      return Coronavirus;
    case TermCategory.SignInExam:
      return Attribution;
    case TermCategory.LabResult:
      return Biotech;
  }
};

export const getTermCategoryLabelId = (category: TermCategory): string => {
  switch (category) {
    // case TermCategory.Other:
    //   return "categoryOther";
    case TermCategory.Drug:
      return "categoryDrug";
    case TermCategory.Symptom:
      return "categorySymptom";
    case TermCategory.Disease:
      return "categoryDisease";
    case TermCategory.Age:
      return "categoryAge";
    case TermCategory.SignInExam:
      return "categorySignInExam";
    case TermCategory.LabResult:
      return "categoryLabResult";
  }
};

export const getStageTypeLabelId = (type: StageType): string => {
  switch (type) {
    case StageType.CurrentDiseaseQuestions:
      return "stageTypeCurrentDiseaseQuestions";
    case StageType.PhysicalExaminationEmphasis:
      return "stageTypePhysicalExaminationEmphasis";
    case StageType.PregnancyAndChildbirth:
      return "stageTypePregnancyAndChildbirth";
    case StageType.Vaccines:
      return "stageTypeVaccines";
    case StageType.Diet:
      return "stageTypeDiet";
    case StageType.GrowthAndDevelopment:
      return "stageTypeGrowthAndDevelopment";
    case StageType.LabEmphasis:
      return "stageTypeLabEmphasis";
    case StageType.BackgroundDisease:
      return "stageTypeBackgroundDisease";
    case StageType.RegularMedications:
      return "stageTypeRegularMedications";
    case StageType.GeneralMedicalBackground:
      return "stageTypeGeneralMedicalBackground";
    case StageType.RelevantFamilyBackground:
      return "stageTypeRelevantFamilyBackground";
    case StageType.Other:
      return "stageTypeOther";
    case StageType.ScreeningTests:
      return "stageTypeScreeningTests";
    case StageType.GynecologicalHistory:
      return "stageTypeGynecologicalHistory";
    case StageType.ObstetricHistory:
      return "stageTypeObstetricHistory";
    case StageType.CurrentPregnancy:
      return "stageTypeCurrentPregnancy";
    case StageType.SummaryAndDiscussion:
      return "stageTypeSummaryAndDiscussion";
    case StageType.Plan:
      return "stageTypePlan";
  }
};

export const getStageTypeMedicalFields = (type: StageType): MedicalField[] | undefined => {
  switch (type) {
    case StageType.PregnancyAndChildbirth:
      return [MedicalField.Paediatrics];
    case StageType.Diet:
      return [MedicalField.Paediatrics];
    case StageType.GrowthAndDevelopment:
      return [MedicalField.Paediatrics];
    case StageType.ScreeningTests:
      return [MedicalField.InternalMedicine];
    case StageType.GynecologicalHistory:
      return [MedicalField.Gynecology];
    case StageType.ObstetricHistory:
      return [MedicalField.Gynecology];
    case StageType.CurrentPregnancy:
      return [MedicalField.Gynecology];
    default:
      return undefined;
  }
};

export const getStageTypes = (
  medicalFields?: MedicalField | MedicalField[],
  selectedStageTypes?: StageType | StageType[]
): StageType[] => {
  let stageTypes = Object.values(StageType);
  if (medicalFields) {
    const medicalFieldsArray = isArray(medicalFields) ? medicalFields : [medicalFields];
    if (!isEmpty(medicalFieldsArray)) {
      const selectedStageTypesArray = selectedStageTypes
        ? isArray(selectedStageTypes)
          ? selectedStageTypes
          : [selectedStageTypes]
        : [];
      stageTypes = stageTypes.filter((stageType) => {
        const stageTypeMedicalFields = getStageTypeMedicalFields(stageType);
        if (!stageTypeMedicalFields || isEmpty(stageTypeMedicalFields)) {
          return true;
        }
        if (selectedStageTypesArray.includes(stageType)) {
          return true;
        }
        return !isEmpty(intersection(medicalFieldsArray, stageTypeMedicalFields));
      });
    }
  }
  return stageTypes;
};
