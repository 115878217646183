export const localStorageKeyEntityFilters = "entityFilters";
export const localStorageKeyEntityCrudData = "entityCrudData";
export const localStorageKeyEntityDeletionTime = "entityDeletionTime";
export const localStorageKeyPageSize = "pageSize";

export const localStorageKeyIntakeTemplateAddExamples = "intakeTemplateAddExamples";
export const localStorageKeyIntakeTemplateAddAssistNotes = "intakeTemplateAddAssistNotes";
export const localStorageKeyIntakeTemplateAddExtendedFormat = "intakeTemplateAddExtendedFormat";
export const localStorageKeyIntakeTemplateUpdateFilters = "intakeTemplateUpdateFilters";
export const localStorageKeyIntakeTemplateClearDocumentText = "intakeTemplateClearDocumentText";
export const localStorageKeyIntakeTemplateMedicalRecordFormat = "intakeTemplateMedicalRecordFormat";

export const localStorageKeyValidateIntakeFillCharacters = "validateIntakeFillCharacters";
export const localStorageKeyValidateIntakeTermClauses = "validateIntakeTermClauses";

export const localStorageKeyPublishEntityItemAnonymous = "publishEntityItemAnonymous";

export const localStorageKeyShares = "shares";
