import * as React from "react";
import {FunctionComponent, PropsWithChildren, useCallback, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {selectLocale, selectLoggedIn, selectUserProfile} from "../../redux/auth/selector";
import {defaultNegateKeywords} from "../../constants/keywords";
import {NegateKeyword} from "../../types/internal";
import {maxNegateKeywords} from "../../constants/defaultValues";
import {FormattedMessage} from "react-intl";
import {useSnackbar} from "notistack";
import {chain} from "lodash";
import {useUpdateEffect} from "react-use";
import {updateUserProfile} from "../../redux/auth/slice";

export type NegateKeywordsContextProps = {
  negateKeywords: NegateKeyword[];
  newNegateKeyword: (negateKeyword: NegateKeyword) => void;
  updateNegateKeyword: (negateKeyword: NegateKeyword) => void;
  removeNegateKeyword: (negateKeyword: NegateKeyword) => void;
  restoreDefaultNegateKeywords: () => void;
};

const NegateKeywordsContext = React.createContext<NegateKeywordsContextProps>(undefined!);

export interface NegateKeywordsProviderProps extends PropsWithChildren<any> {}

const NegateKeywordsProvider: FunctionComponent<NegateKeywordsProviderProps> = ({children}) => {
  const {enqueueSnackbar} = useSnackbar();

  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const loggedIn = useAppSelector(selectLoggedIn);
  const currentLocale = useAppSelector(selectLocale);

  const [updateUserProfileFlag, setUpdateUserProfileFlag] = useState<number>(0);
  const [allNegateKeywords, setAllNegateKeywords] = useState<NegateKeyword[]>(
    userProfile?.negateKeywords || defaultNegateKeywords
  );

  useUpdateEffect(() => {
    dispatch(updateUserProfile({userProfile: {negateKeywords: allNegateKeywords}}));
  }, [updateUserProfileFlag]);

  useUpdateEffect(() => {
    if (!updateUserProfileFlag) {
      setAllNegateKeywords(userProfile?.negateKeywords || defaultNegateKeywords);
    }
  }, [userProfile]);

  useUpdateEffect(() => {
    setAllNegateKeywords(userProfile?.negateKeywords || defaultNegateKeywords);
  }, [loggedIn]);

  const negateKeywords = useMemo<NegateKeyword[]>(
    () =>
      chain(allNegateKeywords)
        .filter((k) => k.locale === currentLocale)
        .sortBy(["keyword"])
        .value(),
    [allNegateKeywords, currentLocale]
  );

  const newNegateKeyword = useCallback(
    (negateKeyword: NegateKeyword): void => {
      if (allNegateKeywords.filter((k) => k.locale === negateKeyword.locale).length >= maxNegateKeywords) {
        enqueueSnackbar(<FormattedMessage id="reachedMaximumAllowed" values={{max: maxNegateKeywords}} />, {
          variant: "info",
        });
        return;
      }

      setAllNegateKeywords((currentAllNegateKeywords) => [...currentAllNegateKeywords, negateKeyword]);
      setUpdateUserProfileFlag((currentUpdateUserProfileFlag) => currentUpdateUserProfileFlag + 1);
    },
    [allNegateKeywords, setAllNegateKeywords, setUpdateUserProfileFlag]
  );

  const updateNegateKeyword = useCallback(
    (negateKeyword: NegateKeyword): void => {
      setAllNegateKeywords((currentAllNegateKeywords) => [
        ...currentAllNegateKeywords.filter((k) => k.uuid !== negateKeyword.uuid),
        negateKeyword,
      ]);
      setUpdateUserProfileFlag((currentUpdateUserProfileFlag) => currentUpdateUserProfileFlag + 1);
    },
    [setAllNegateKeywords, setUpdateUserProfileFlag]
  );

  const removeNegateKeyword = useCallback(
    (negateKeyword: NegateKeyword): void => {
      setAllNegateKeywords((currentAllNegateKeywords) =>
        currentAllNegateKeywords.filter((k) => k.uuid !== negateKeyword.uuid)
      );
      setUpdateUserProfileFlag((currentUpdateUserProfileFlag) => currentUpdateUserProfileFlag + 1);
    },
    [setAllNegateKeywords, setUpdateUserProfileFlag]
  );

  const restoreDefaultNegateKeywords = useCallback((): void => {
    setAllNegateKeywords(defaultNegateKeywords);
    setUpdateUserProfileFlag((currentUpdateUserProfileFlag) => currentUpdateUserProfileFlag + 1);
  }, [setAllNegateKeywords, setUpdateUserProfileFlag]);

  return (
    <NegateKeywordsContext.Provider
      value={{negateKeywords, newNegateKeyword, updateNegateKeyword, removeNegateKeyword, restoreDefaultNegateKeywords}}
    >
      {children}
    </NegateKeywordsContext.Provider>
  );
};

export {NegateKeywordsContext, NegateKeywordsProvider};
