import * as React from "react";
import Container from "@mui/material/Container";
import Page from "../../../components/common/Page";
import {useParams} from "react-router-dom";
import {useMemo} from "react";
import EntityShareForm from "../../../components/entity/EntityShareForm";

export interface EntityShareProps {}

const EntityShare = ({}: EntityShareProps) => {
  const params = useParams();

  const itemId = useMemo<string>(() => params.id, [params]);

  return (
    <Page>
      <Container>
        <EntityShareForm shareId={itemId} key={itemId} />
      </Container>
    </Page>
  );
};

export default EntityShare;
