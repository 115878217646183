import * as React from "react";
import {FormattedMessage} from "react-intl";
import {FunctionComponent, useCallback} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {Term} from "../../types/internal";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import TermCardHeader from "../terms/TermCardHeader";
import {termEntityId} from "../../entities/term.entity";
import {useNavigate} from "react-router-dom";
import useEntityNavigation from "../../helpers/useEntityNavigation";

export type EditRequestTermsDialogProps = {
  terms: Term[];
};

const EditRequestTermsDialog: FunctionComponent<EditRequestTermsDialogProps> = NiceModal.create(({terms}) => {
  const modal = useModal();
  const navigate = useNavigate();
  const {getEntityDetailsUrl} = useEntityNavigation();

  const termClickHandler = useCallback(
    (term: Term): void => {
      navigate(getEntityDetailsUrl(termEntityId, term.id));
      modal.hide();
    },
    [navigate, getEntityDetailsUrl]
  );

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
      maxWidth="xs"
      hideBackdrop
    >
      <DialogTitleEnhanced onClose={modal.hide}>
        <FormattedMessage id="editRequests" />
      </DialogTitleEnhanced>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText sx={{textAlign: "center"}}>
            <FormattedMessage id="editRequestsApprovedExplanation" />
          </DialogContentText>

          {terms.map((term) => (
            <Card sx={{border: (theme) => `solid 1px ${theme.palette.divider}`}} key={term.id}>
              <TermCardHeader term={term} onClick={termClickHandler} />
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={modal.hide}>
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default EditRequestTermsDialog;
