import * as React from "react";
import {FunctionComponent, useEffect, useMemo, useState} from "react";
import TextLoader from "../../components/pages/keywords-explorer/TextLoader";
import KeywordFinder from "../../components/pages/keywords-explorer/KeywordFinder";
import {Term, TermFilters} from "../../types/internal";
import Container from "@mui/material/Container";
import TermCard from "../../components/terms/TermCard";
import Page from "../../components/common/Page";
import {isEmpty, keyBy} from "lodash";
import EmptyKeywords from "../../components/pages/keywords-explorer/EmptyKeywords";
import FilteredResults from "../../components/terms/FilteredResults";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {termEntityId} from "../../entities/term.entity";
import useEntityFilters from "../../contexts/entity-filters/hooks/useEntityFilters";
import Stack from "@mui/material/Stack";
import FilterTextField from "../../components/common/FilterTextField";
import useSearchState from "../../helpers/useSearchState";
import ErrorMessage from "../../components/common/ErrorMessage";
import useEntity from "../../contexts/entity/hooks/useEntity";
import {Entity} from "../../types/entity";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import KeywordFinderSettingsPopover from "../../components/pages/keywords-explorer/settings/KeywordFinderSettingsPopover";
import useNegateKeywords from "../../contexts/negate-keywords/hooks/useNegateKeywords";

export interface KeywordsExplorerProps {}

const KeywordsExplorer: FunctionComponent<KeywordsExplorerProps> = ({}) => {
  const {getEntity} = useEntity();
  const {getItems, lastChangeTime} = useEntityCrud();
  const {filtersChangeFlag, filterItems, searchItems} = useEntityFilters();
  const {negateKeywords} = useNegateKeywords();

  const entity = useMemo<Entity<Term, TermFilters>>(() => getEntity(termEntityId), []);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [terms, setTerms] = useState<{[key: string]: Term} | undefined>(undefined);
  const [documentText, setDocumentText] = useState<string | undefined>(undefined);
  const [documentTerms, setDocumentTerms] = useState<Term[] | undefined>(undefined);

  const {pendingSearch, setPendingSearch, search} = useSearchState();

  const filteredTerms = useMemo<Term[]>(() => {
    return documentTerms ? filterItems(termEntityId, searchItems(termEntityId, documentTerms, search)) : [];
  }, [documentTerms, search, filtersChangeFlag]);

  useEffect(() => {
    (async () => {
      const items = await getItems(entity);
      setLoading(false);
      if (items) {
        setTerms(keyBy(entity.filterDataItems(items), "id"));
      } else {
        setError(true);
      }
    })();
  }, [lastChangeTime[entity.id]]);

  const isLoadedAndEmpty = useMemo<boolean>(() => !!documentTerms && isEmpty(documentTerms), [documentTerms]);
  const isLoadedAndNotEmpty = useMemo<boolean>(() => !!documentTerms && !isEmpty(documentTerms), [documentTerms]);
  const hasFilteredResults = useMemo<boolean>(
    () => !!documentTerms && documentTerms.length > filteredTerms.length,
    [documentTerms, filteredTerms]
  );

  return (
    <>
      <Page sx={{height: "100%"}}>
        <Container sx={{height: "100%"}}>
          <Stack spacing={2}>
            {isLoadedAndNotEmpty && (
              <>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <FilterTextField value={pendingSearch} onChangeValue={setPendingSearch} />
                  <KeywordFinderSettingsPopover />
                </Stack>

                {filteredTerms?.map((term) => (
                  <TermCard term={term} key={term.id} />
                ))}

                {hasFilteredResults && <FilteredResults entityId={termEntityId} onClear={() => setPendingSearch("")} />}
              </>
            )}

            {isLoadedAndEmpty && <EmptyKeywords />}

            {loading && (
              <Box sx={{textAlign: "center"}}>
                <CircularProgress />
              </Box>
            )}

            {error && !terms && <ErrorMessage />}
          </Stack>
        </Container>
      </Page>

      <TextLoader onTextLoaded={setDocumentText} />
      {terms && (
        <KeywordFinder
          terms={terms}
          text={documentText}
          negateKeywords={negateKeywords}
          onTermsFoundChanged={setDocumentTerms}
        />
      )}
    </>
  );
};

export default KeywordsExplorer;
