import * as React from "react";
import {FunctionComponent, PropsWithChildren, useCallback, useMemo} from "react";
import {BaseEntityRO, Entity, EntityAccessType, EntityFactory} from "../../types/entity";
import {find, isString} from "lodash";
import {useIntl} from "react-intl";
import {useAppSelector} from "../../redux/hooks";
import {selectRole} from "../../redux/auth/selector";

export type EntityContextProps = {
  entities: Entity<any, any>[];
  getEntity: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string
  ) => Entity<EntityRO, FiltersRO> | undefined;
  hasEntityAccess: (entityId: Entity<any, any> | string, accessType: EntityAccessType) => boolean;
};

const EntityContext = React.createContext<EntityContextProps>(undefined!);

export interface EntityProviderProps extends PropsWithChildren<any> {
  entityFactories: EntityFactory<any, any>[];
}

const EntityProvider: FunctionComponent<EntityProviderProps> = ({children, entityFactories}) => {
  const intl = useIntl();

  const role = useAppSelector(selectRole);

  const entities = useMemo<Entity<any, any>[]>(
    () => entityFactories.map<Entity<any, any>>((factory) => factory(intl)),
    [entityFactories]
  );

  const getEntity = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string
    ): Entity<EntityRO, FiltersRO> => {
      if (isString(entityId)) {
        const entity = find(entities, (e) => e.id === entityId || e.deprecatedIds?.includes(entityId));
        if (!entity) {
          throw new Error(`entity with ID "${entityId}" not found.`);
        }
        return entity;
      }
      return entityId;
    },
    [entities]
  );

  const hasEntityAccess = useCallback(
    (entityId: Entity<any, any> | string, accessType: EntityAccessType): boolean => {
      try {
        const entity = getEntity(entityId);
        const entityRole = entity.roles[accessType];
        return !entityRole || entityRole === role;
      } catch (e) {
        return false;
      }
    },
    [getEntity, role]
  );

  return <EntityContext.Provider value={{entities, getEntity, hasEntityAccess}}>{children}</EntityContext.Provider>;
};

export {EntityContext, EntityProvider};
