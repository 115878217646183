import * as React from "react";
import {useCallback} from "react";
import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import NiceModal from "@ebay/nice-modal-react";
import LoginDialog from "../dialogs/LoginDialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export interface SignUpMessageProps {
  messageId: string;
  onSignUpClick?: () => void;
}

export default function SignUpMessage({messageId, onSignUpClick}: SignUpMessageProps) {
  const signUpHandler = useCallback((): void => {
    onSignUpClick?.();
    NiceModal.show(LoginDialog, {});
  }, [onSignUpClick]);

  return (
    <Stack sx={{p: 2}} spacing={1.5}>
      <Typography variant={"body2"} sx={{textAlign: "center", color: "text.secondary"}}>
        <FormattedMessage id={messageId} />
      </Typography>

      <Button fullWidth color="inherit" variant="outlined" onClick={signUpHandler}>
        <FormattedMessage id={"signUpOrLogin"} />
      </Button>
    </Stack>
  );
}
