import * as React from "react";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {ContentType, IntakeTemplate, IntakeTemplateContent, MedicalField, StageType} from "../../../../types/internal";
import {Controller, get, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import {getStageTypeLabelId, getStageTypes} from "../../../../helpers/TermUtils";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import ExpandMore from "../../../common/ExpandMore";
import Collapse from "@mui/material/Collapse";
import {isEmpty} from "lodash";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import {maxIntakeTemplateContents} from "../../../../constants/defaultValues";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IntakeTemplateContentFormSortable from "./IntakeTemplateContentFormSortable";
import SortableDndContext from "../../../dnd/SortableDndContext";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import AddOutlined from "@mui/icons-material/AddOutlined";
import MoreOutlined from "@mui/icons-material/MoreOutlined";
import MedicalServicesOutlined from "@mui/icons-material/MedicalServicesOutlined";
import Link from "@mui/material/Link";
import FormToggleIconButton from "../../../forms/FormToggleIconButton";
import TooltipIconButton from "../../../common/TooltipIconButton";
import {MHidden} from "../../../@material-extend";
import FormTogglesMenuIconButton, {FormToggleMenuItem} from "../../../forms/FormTogglesMenuIconButton";

export interface IntakeTemplateSectionFormProps {
  id: string;
  index: number;
  remove: (index?: number | number[]) => void;
  startAction?: ReactNode;
  disabled?: boolean;
  unmountOnExit?: boolean;
  sx?: SxProps<Theme>;
}

const IntakeTemplateSectionForm = ({
  index,
  remove: removeGroup,
  startAction,
  disabled,
  unmountOnExit,
  sx,
}: IntakeTemplateSectionFormProps) => {
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();

  const {
    control,
    formState: {errors, isSubmitting},
  } = useFormContext<IntakeTemplate>();
  const {fields, insert, remove, move} = useFieldArray<IntakeTemplate>({name: `sections.${index}.contents`, control});

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = useCallback((): void => {
    setExpanded((currentExpanded) => !currentExpanded);
  }, [setExpanded]);

  useEffect(() => {
    if (get(errors, `sections.${index}.contents`)) {
      setExpanded(true);
    }
  }, [isSubmitting]);

  const medicalField: MedicalField | undefined = useWatch({control: control, name: `medicalField`});
  const stageType: StageType = useWatch({control: control, name: `sections.${index}.type`});

  const availableStageTypes = useMemo<StageType[]>(
    () => getStageTypes(medicalField, stageType),
    [medicalField, stageType]
  );
  const addContentDisabled = useMemo<boolean>(() => fields.length >= maxIntakeTemplateContents, [fields]);
  const sectionSubtitle = useMemo<string>(() => `(${fields.length})`, [fields]);

  const actions = useMemo<FormToggleMenuItem[]>(
    () => [
      {
        id: "medicalRecordFormat",
        fieldName: `sections.${index}.medicalRecordFormat`,
        Icon: MedicalServicesOutlined,
        activeColor: "warning",
        titleId: "medicalRecordFormat",
        disabled: disabled,
      },
      {
        id: "extendedFormat",
        fieldName: `sections.${index}.extendedFormat`,
        Icon: MoreOutlined,
        activeColor: "success",
        titleId: "extendedFormat",
        disabled: disabled,
      },
    ],
    [index, disabled]
  );

  const getNewContentDefaultValue = useCallback((): IntakeTemplateContent => {
    return {
      type: ContentType.Text,
      payload: "",
      example: false,
      assistNotes: false,
      extendedFormat: false,
    };
  }, []);

  const insertContentHandler = useCallback(
    (index: number): void => {
      if (fields.length + 1 > maxIntakeTemplateContents) {
        enqueueSnackbar(<FormattedMessage id="reachedMaximumAllowed" values={{max: maxIntakeTemplateContents}} />, {
          variant: "info",
        });
        return;
      }

      insert(index, getNewContentDefaultValue());
      setExpanded(true);
    },
    [insert, fields]
  );

  const addContentHandler = useCallback((): void => {
    insertContentHandler(fields.length);
  }, [insertContentHandler]);

  const removeContentHandler = useCallback(
    (removeIndex: number): void => {
      remove(removeIndex);
    },
    [remove, index]
  );

  return (
    <Card sx={{...sx}}>
      <CardContent>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {startAction}

          <Controller
            name={`sections.${index}.type`}
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "type"})}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                  select
                >
                  {availableStageTypes.map((t) => (
                    <MenuItem key={t} value={t}>
                      <FormattedMessage id={getStageTypeLabelId(t)} />
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Controller
            name={`sections.${index}.title`}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "title"})}
                  type="text"
                  inputProps={{
                    maxLength: 200,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                  sx={stageType === StageType.Other ? {} : {display: "none"}}
                />
              );
            }}
          />

          <MHidden width={"lgDown"}>
            {actions.map((action) => (
              <FormToggleIconButton
                fieldName={action.fieldName}
                Icon={action.Icon}
                activeColor={action.activeColor}
                tooltipId={action.titleId}
                disabled={action.disabled}
                key={action.id}
              />
            ))}
          </MHidden>

          <MHidden width={"lgUp"}>
            <FormTogglesMenuIconButton actions={actions} fontSize={"medium"} />
          </MHidden>

          <TooltipIconButton
            Icon={DeleteOutlined}
            color={"error"}
            tooltipId={"removeStage"}
            disabled={disabled}
            onClick={() => removeGroup(index)}
          />
        </Stack>

        <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{mt: 3}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="h6" sx={{display: "inline-block"}}>
              <Link onClick={handleExpandClick} sx={{color: "text.primary", cursor: "pointer"}}>
                <FormattedMessage id="contents" />
              </Link>
            </Typography>
            &nbsp;
            <Typography variant="h6" sx={{display: "inline-block", color: "text.secondary"}}>
              {sectionSubtitle}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            startIcon={<AddOutlined />}
            disabled={addContentDisabled || disabled}
            onClick={addContentHandler}
          >
            <FormattedMessage id="addNewContent" />
          </Button>

          <ExpandMore expand={expanded} onClick={handleExpandClick} color={"primary"} />
        </Stack>

        <Collapse in={expanded} timeout="auto" unmountOnExit={unmountOnExit}>
          <SortableDndContext items={fields} move={move}>
            <Stack spacing={3} sx={{mt: 3}}>
              {fields.map((field, detailIndex) => (
                <IntakeTemplateContentFormSortable
                  id={field.id}
                  sectionIndex={index}
                  index={detailIndex}
                  remove={removeContentHandler}
                  disabled={disabled}
                  key={field.id}
                />
              ))}
            </Stack>
          </SortableDndContext>

          {isEmpty(fields) && (
            <Typography variant="body2" sx={{color: "text.secondary"}}>
              <FormattedMessage id="noResultsFound" />
            </Typography>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default IntakeTemplateSectionForm;
