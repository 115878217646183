import * as React from "react";
import {Prep, PrepClause, PrepTerm} from "../../../../types/internal";
import {get, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import Stack from "@mui/material/Stack";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import ExpandMore from "../../../common/ExpandMore";
import Collapse from "@mui/material/Collapse";
import {isEmpty} from "lodash";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useSnackbar} from "notistack";
import {maxPrepClauses} from "../../../../constants/defaultValues";
import {v4 as uuidv4} from "uuid";
import PrepClauseFormSortable from "./PrepClauseFormSortable";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SortableDndContext from "../../../dnd/SortableDndContext";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import Link from "@mui/material/Link";

export interface PrepTermFormProps {
  id: string;
  stageIndex: number;
  index: number;
  remove: (index?: number | number[]) => void;
  startAction?: ReactNode;
  disabled?: boolean;
  unmountOnExit?: boolean;
  sx?: SxProps<Theme>;
}

const PrepTermForm = ({
  stageIndex,
  index,
  remove: removeTerm,
  startAction,
  disabled,
  unmountOnExit,
  sx,
}: PrepTermFormProps) => {
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();

  const {
    control,
    formState: {errors, isSubmitting},
    setFocus,
  } = useFormContext<Prep>();
  const {fields, insert, remove, move} = useFieldArray<Prep>({
    name: `stages.${stageIndex}.terms.${index}.clauses`,
    control,
  });

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = useCallback((): void => {
    setExpanded((currentExpanded) => !currentExpanded);
  }, [setExpanded]);

  useEffect(() => {
    if (get(errors, `stages.${stageIndex}.terms.${index}.clauses`)) {
      setExpanded(true);
    }
  }, [isSubmitting]);

  const term: PrepTerm = useWatch({control: control, name: `stages.${stageIndex}.terms.${index}`});
  const addClauseDisabled = useMemo<boolean>(() => term.clauses.length >= maxPrepClauses, [term]);
  const termSubtitle = useMemo<string>(() => `(${term.clauses.length})`, [term]);

  const getNewClauseDefaultValue = useCallback((text?: string, indent?: number): PrepClause => {
    return {
      uuid: uuidv4(),
      text: text || "",
      tooltip: "",
      indent: indent || 0,
    };
  }, []);

  const [focusDetailIndex, setFocusDetailIndex] = useState<number>(-1);

  useEffect(() => {
    if (focusDetailIndex >= 0) {
      if (focusDetailIndex < fields.length) {
        setFocus(`stages.${stageIndex}.terms.${index}.clauses.${focusDetailIndex}.text`);
      }
      setFocusDetailIndex(-1);
    }
  }, [focusDetailIndex]);

  const insertClauseHandler = useCallback(
    (index: number, texts?: string[], indent?: number): void => {
      if (fields.length + (texts?.length || 1) > maxPrepClauses) {
        enqueueSnackbar(<FormattedMessage id="reachedMaximumAllowed" values={{max: maxPrepClauses}} />, {
          variant: "info",
        });
        return;
      }

      insert(
        index,
        texts ? texts.map<PrepClause>((text) => getNewClauseDefaultValue(text, indent)) : getNewClauseDefaultValue()
      );
      setExpanded(true);

      if (texts) {
        setFocusDetailIndex(index + texts.length - 1);
      }
    },
    [insert, fields]
  );

  const removeClauseHandler = useCallback(
    (removeIndex: number, focusPrevious: boolean): void => {
      remove(removeIndex);
      if (focusPrevious && removeIndex > 0) {
        setFocusDetailIndex(removeIndex - 1);
      }
    },
    [remove, index]
  );

  const addClauseHandler = useCallback((): void => {
    insertClauseHandler(fields.length);
  }, [insertClauseHandler]);

  return (
    <Box sx={{...sx}}>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {startAction}

        <Box sx={{flexGrow: 1}}>
          <Typography variant="h6" sx={{display: "inline-block"}}>
            <Link onClick={handleExpandClick} sx={{color: "text.primary", cursor: "pointer"}}>
              {term.title}
            </Link>
          </Typography>
          &nbsp;
          <Typography variant="h6" sx={{display: "inline-block", color: "text.secondary"}}>
            {termSubtitle}
          </Typography>
        </Box>

        {/*<Controller*/}
        {/*  name={`stages.${stageIndex}.terms.${index}.title`}*/}
        {/*  rules={{*/}
        {/*    required: intl.formatMessage({id: "requiredField"}),*/}
        {/*  }}*/}
        {/*  control={control}*/}
        {/*  render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {*/}
        {/*    return (*/}
        {/*      <TextField*/}
        {/*        {...field}*/}
        {/*        inputRef={ref}*/}
        {/*        required*/}
        {/*        fullWidth*/}
        {/*        label={intl.formatMessage({id: "title"})}*/}
        {/*        type="text"*/}
        {/*        inputProps={{*/}
        {/*          maxLength: 200,*/}
        {/*        }}*/}
        {/*        error={invalid}*/}
        {/*        helperText={error?.message}*/}
        {/*        disabled={disabled}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}

        <Tooltip title={<FormattedMessage id="removeTerm" />}>
          <span>
            <IconButton color={"error"} onClick={() => removeTerm(index)} disabled={disabled}>
              <DeleteOutlined />
            </IconButton>
          </span>
        </Tooltip>

        <ExpandMore expand={expanded} onClick={handleExpandClick} color={"primary"} />
      </Stack>

      <Box>
        {/*<FormListHeader*/}
        {/*  title={<FormattedMessage id="clauses" />}*/}
        {/*  subtitle={termSubtitle}*/}
        {/*  buttonText={<FormattedMessage id="addNewClause" />}*/}
        {/*  buttonDisabled={addClauseDisabled || disabled}*/}
        {/*  onAddItem={addClauseHandler}*/}
        {/*>*/}
        {/*  <ExpandMore expand={expanded} onClick={handleExpandClick} color={"primary"} />*/}
        {/*</FormListHeader>*/}

        <Collapse in={expanded} timeout="auto" unmountOnExit={unmountOnExit}>
          <SortableDndContext items={fields} move={move}>
            <Stack spacing={3} sx={{mt: 3}}>
              {fields.map((field, clauseIndex) => (
                <PrepClauseFormSortable
                  id={field.id}
                  stageIndex={stageIndex}
                  termIndex={index}
                  index={clauseIndex}
                  remove={removeClauseHandler}
                  insert={insertClauseHandler}
                  focus={setFocusDetailIndex}
                  disabled={disabled}
                  key={field.id}
                />
              ))}
            </Stack>
          </SortableDndContext>

          {isEmpty(fields) && (
            <Typography variant="body2" sx={{color: "text.secondary"}}>
              <FormattedMessage id="noResultsFound" />
            </Typography>
          )}
        </Collapse>
      </Box>

      <Divider sx={{mt: 3}} />
    </Box>
  );
};

export default PrepTermForm;
