import * as React from "react";
import Container from "@mui/material/Container";
import Page from "../../../components/common/Page";
import {useParams} from "react-router-dom";
import {useMemo} from "react";
import EntityPublishRequestForm from "../../../components/entity/EntityPublishRequestForm";

export interface EntityPublishRequestProps {}

const EntityPublishRequest = ({}: EntityPublishRequestProps) => {
  const params = useParams();

  const itemId = useMemo<string>(() => params.id, [params]);

  return (
    <Page>
      <Container>
        <EntityPublishRequestForm publishRequestId={itemId} key={itemId} />
      </Container>
    </Page>
  );
};

export default EntityPublishRequest;
