export default {
  brandName: "Shelpi",
  titleLong: "Shelpi | פשוט חיים קלים יותר",
  titleShort: "Shelpi",
  homepage: "דף הבית",
  officeLoading: "טוען תוסף. אם הוא לא נטען תוך מספר דקות, סגור ופתח אותו מחדש.",
  contactSupportSubject: "תמיכה טכנית של Shelpi",
  welcomeToPlugin: "ברוכים הבאים ל-Shelpi!",
  welcomeExplanation: "בעת כתיבת המסמך שלך, המונחים הרפואיים הרלוונטיים יהיו זמינים כאן.",
  welcomeTryIt: 'נסה את זה עכשיו על ידי כתיבת "כאב ראש" במסמך והקסם יקרה מעצמו.',
  tryItSentence: "זהו משפט לדוגמה עם המילה כאב ראש.",
  tryItNow: "נסה עכשיו!",
  search: "חיפוש",
  noResultsFound: "לא נמצאו תוצאות",
  searchNoResultsHelp: "החיפוש מתבצע לפי הכותרת ומילות המפתח של המונחים. נסה לשנות/לקצר את החיפוש שלך.",
  filter: "סינון",
  filters: "סינון",
  filtersExplanation: "החל סינון על מונחים בכל מקום",
  categories: "קטגוריות",
  medicalFields: "סוג מחלקה",
  errorPageNotFound: "מצטערים, הדף לא נמצא!",
  errorPageNotFoundExplanation:
    "מצטערים, לא הצלחנו למצוא את הדף שאתה מחפש. אולי טעית בכתובת האתר? הקפד לבדוק את האיות שלך או ליצור קשר עם התמיכה.",
  goToHome: "עבור לדף הבית",
  contactUs: "צור קשר",
  contactSupport: "צור קשר עם תמיכה טכנית",
  clear: "נקה",
  clearFilters: "נקה סינון",
  hasFilteredResults: "חלק מהתוצאות מסוננות ואינן מוצגות",
  lightMode: "מצב יום",
  darkMode: "מצב לילה",
  intakes: "קבלות",
  newIntakeTitle: "יצירת קבלה חדשה",
  newIntakeDescription: "תהליך קצר ליצירת קבלה מותאמת אישית לצרכים שלך.",
  newIntakePrep: "יצירת קבלה חדשה עבור Prep:",
  newIntake: "קבלה חדשה",
  createIntake: "צור קבלה",
  copyIntake: "העתק קבלה",
  printIntake: "הדפס קבלה",
  validateIntake: "בדיקת קבלה",
  applyRelevantFilters: "החל סינון רלוונטי.",
  applyRelevantFiltersExplanation: "מחיל את הסינון הרלוונטי לסוג המחלקה שנבחר. תמיד ניתן לשנות אותו מאוחר יותר.",
  clearDocumentText: "נקה את המסמך.",
  clearDocumentTextExplanation: "מוחק את הטקסט הנוכחי במסמך. בטל את הסימון על מנת להוסיף את הקבלה אחרי הטקסט הנוכחי.",
  selectMedicalFieldToContinue: "בחר סוג מחלקה על מנת להמשיך.",
  continue: "המשך",
  cancel: "ביטול",
  menu: "תפריט",
  termNotFound: "מונח לא נמצא במסמך. הוסף את המונח או בחר אפשרות אחרת.",
  stageNotFound: "השלב לא נמצא במסמך. הוסף את השלב או בחר באפשרות אחרת.",
  addSection: "הוסף שלב",
  support: "תמיכה",
  supportContactUs: "אנחנו זמינים לכל שאלה / בקשה / בעיה בכתובת:",
  supportAdditionalUrls: "במקרה שהאתר לא נטען כראוי ברשת מאובטחת של בית חולים ניתן לנסות את אחת מכתובות האתר הבאות:",
  supportAdditionalUrlsNotWorking:
    "אם אף אחת מכתובות האתר לא עובדת, יש ליצור קשר עם מחלקת ה-IT של בית החולים כדי לאשר את האתר.",
  share: "שיתוף",
  shareWithTheWorld: "שתף את Shelpi עם העולם",
  close: "סגור",
  copy: "העתק",
  copiedSuccessfully: "הועתק בהצלחה.",
  print: "הדפס",
  download: "הורדה",
  downloadAddIn: "הורד את תוסף Shelpi לוורד",
  whatIsTheTool:
    "Shelpi הוא כלי שעוזר לכתוב קבלות טובות יותר מהר יותר. השתמש בתוסף Office וורד ו/או באתר שלנו לפרודוקטיביות מירבית במחשב / טאבלט / סמארטפון.",
  getThePlugin: "הורד את התוסף",
  officeWordPlugin: "תוסף Office וורד",
  pluginExplanation:
    "תוסף Shelpi לוורד זמין להורדה ושימוש בחינם. התוסף מוצא מילות מפתח רלוונטיות במסמך שלך ומאפשר להוסיף את המידע הנדרש תוך שניות. אין עוד צורך לזכור הכל ולהקליד שוב ושוב ושוב...",
  downloadForFree: "הורד בחינם",
  goToSearch: "עבור לחיפוש",
  knowledgeBase: "מאגר המידע",
  searchKnowledgeBase: "חיפוש מאגר המידע",
  searchKnowledgeBaseExplanation:
    "נמאס לך לזכור הכל? השתמשי במאגר המידע שלנו כדי לקבל את כל המידע הנדרש בכמה קליקים. משהו חסר? ב-Shelpi ניתן לבנות מאגר מידע אישי שלך שיהיה איתך בכל זמן ובכל מקום.",
  advancedTemplates: "תבניות מתקדמות",
  advancedTemplatesExplanation:
    "השתמש בתבניות המתקדמות של Shelpi כדי לבנות קבלות בשניות ולצמצם את זמן העבודה הדרוש להשלמת קבלה. הוסף תבניות אישיות משלך המותאמות לצרכים המדויקים שלך.",
  supportExplanation:
    "יש לך בעיה? אנחנו כאן בשבילך. חשבת על רעיון מגניב לשיפור הכלי? ספר לנו! צריך עזרה במשהו? פשוט צור איתנו קשר.",
  validate: "בדוק",
  searchFillCharacters: "חיפוש תווים להשלמה ({character}).",
  fillCharactersFound: "נמצאו תווים להשלמה ({character}).",
  searchTermClauses: "חיפוש סעיפים שהוספו ({character}).",
  termClausesFound: "נמצאו סעיפים שהוספו ({character}).",
  validateIntakeSuccess: "הקבלה עברה את הבדיקה בהצלחה.",
  comingSoon: "בקרוב אצלכם",
  comingSoonExplanation: "התוסף נמצא בשלבי אישור אחרונים ויהיה זמין בקרוב. הישארו מעודכנים!",
  login: "התחבר",
  logout: "התנתק",
  loginSuccess: "התחברת למערכת בהצלחה.",
  logoutSuccess: "התנתקת מהמערכת בהצלחה.",
  loginFailed: "התחברות נכשלה. אנא בדוק את האימייל/סיסמה שלך.",
  logoutFailed: "התנתקות נכשלה.",
  terms: "מונחים",
  manageTerms: "ניהול מונחים",
  manage: "ניהול",
  title: "כותרת",
  description: "תיאור",
  tooltip: "הסבר",
  category: "קטגוריה",
  status: "סטטוס",
  addNew: "הוסף חדש",
  details: "פרטים",
  save: "שמור",
  requiredField: "שדה חובה.",
  keywords: "מילות מפתח",
  commaSeparatedValues: "ערכים מופרדים בפסיקים.",
  selectNonForAll: "אם לא נבחרו ערכים, הפריט יהיה זמין עבור כל הערכים.",
  type: "סוג",
  text: "טקסט",
  removeTerm: "מחק מונח",
  stages: "שלבים",
  addNewStage: "הוסף שלב",
  removeStage: "מחק שלב",
  clauses: "סעיפים",
  addNewClause: "הוסף סעיף",
  removeClause: "מחק סעיף",
  checkedByDefault: "מסומן כברירת מחדל",
  email: "אימייל",
  password: "סיסמה",
  invalidEmail: "אימייל לא תקין.",
  invalidPassword: "סיסמה לא תקינה.",
  username: "שם משתמש",
  signUp: "הרשם",
  signUpOrLogin: "הרשם / התחבר",
  doNotHaveAccount: "אין לך חשבון? הרשם",
  alreadyHaveAccount: "כבר יש לך חשבון? התחבר",
  creationTime: "תאריך יצירה",
  lastUpdateTime: "תאריך עדכון אחרון",
  savedSuccessfully: "נשמר בהצלחה.",
  formInvalidValues: "בחלק מהשדות יש ערכים לא חוקיים.",
  clearSearch: "נקה חיפוש",
  networkError: "בעיה ברשת. בבקשה נסה שוב מאוחר יותר.",
  reachedMaximumAllowed: "הגעת למקסימום המותר ({max}).",
  prep: "Prep",
  preps: "Preps",
  prepEmpty: "Prep ריק.",
  addToPrep: "הוסף ל-Prep",
  addToPrepSuccess: "התווסף ל-Prep בהצלחה.",
  addToPrepSignUp: "הרשם בשביל להתחיל prepping.",
  addToPrepNoClausesSelected: "לא נבחרו סעיפים להוספה.",
  addToPrepLoading: "טוען Prep. נסה בבקשה בעוד מספר רגעים.",
  cannotUpdatePrepWhileInPage:
    "לא ניתן לעדכן את ה-Prep כאשר נמצאים בעמוד שלו. השתמש בטופס או נווט לדף אחר כדי לעדכן אותו.",
  new: "חדש",
  open: "פתח",
  showAll: "הצג הכל",
  comments: "הערות",
  back: "חזור",
  acceptPrivacyAndTerms: "על ידי הרשמה, אני מסכים ל{privacyPolicy} ו{termsOfService} של Shelpi.",
  privacyPolicy: "מדיניות פרטיות",
  termsOfService: "תנאי השירות",
  select: "בחר",
  savePrepToSelect: "שמור Prep בשביל לבחור אותו.",
  prepSelectedSuccessfully: "Prep נבחר בהצלחה.",
  templates: "תבניות",
  manageTemplates: "ניהול תבניות",
  sections: "חלקים",
  addNewSection: "הוסף חלק",
  contents: "תכנים",
  addNewContent: "הוסף תוכן",
  example: "דוגמה",
  assistNotes: "הערות עזר",
  extendedFormat: "פורמט מורחב",
  medicalRecordFormat: "פורמט רשומה רפואית",
  remove: "מחק",
  item: "פריט",
  items: "פריטים",
  addNewItem: "הוסף פריט",
  rows: "שורות",
  columns: "עמודות",
  cell: "תא",
  selectMedicalField: "בחר סוג מחלקה",
  selectTemplate: "בחר תבנית",
  templateSettings: "הגדרות תבנית",
  lastStep: "שלב אחרון",
  finish: "סיום",
  noTemplatesFound: "לא נמצאו תבניות :( אנו עובדים על הוספת תבניות נוספות.",
  addExamples: "הוסף דוגמאות.",
  addAssistNotes: "הוסף הערות עזר.",
  addExtendedFormat: "פורמט מורחב.",
  addMedicalRecordFormat: "פורמט רשומה רפואית.",
  addExamplesExplanation: "הוסף דוגמאות לסעיפים רלוונטים.",
  addAssistNotesExplanation: "הוסף הערות עזר לעבודה במחלקה בבית חולים.",
  addExtendedFormatExplanation: "פורמט מורחב לסטודנטים לרפואה.",
  addMedicalRecordFormatExplanation: "פורמט פשוט ללא עיצוב המוכן להעתקה לתוך מערכות רפואיות (כגון קמיליון).",
  keywordsFinderSettings: "הגדרות סריקת מסמך",
  keywordsFinderSettingsSignUp: "הרשם לעדכון הגדרות ברירת מחדל.",
  negateKeyword: "מילת שלילה",
  negateKeywords: "מילות שלילה",
  negateKeywordsExplanation:
    'המילים המוגדרות מבטלות מילות מפתח של מונחים שמופיעות לאחר מכן. לדוגמה: "ללא כאב ראש" יבטל את מילת המפתח "כאב ראש".',
  negateKeywordTypeWordExplanation: "מבטל את מילת המפתח המופיעה ישירות אחרי המילת שלילה.",
  negateKeywordTypeSentenceExplanation: "מבטל את המשפט כולו אחרי המילת שלילה.",
  restoreDefaults: "שחזור ברירת מחדל",
  private: "אישי",
  privateTerms: "מונחים אישיים",
  managePrivateTerms: "ניהול מונחים אישיים",
  privateTemplates: "תבניות אישיות",
  managePrivateTemplates: "ניהול תבניות אישיות",
  error: "שגיאה",
  addPrivateTemplate: "הוסף תבנית אישית",
  addPrivateTerm: "הוסף מונח אישי",
  refresh: "רענן",
  refreshing: "מרענן...",
  rememberMe: "זכור אותי",
  rememberMeExplanation:
    "אם אפשרות זו מסומנת, החשבון שלך יישאר מחובר לאחר שתסגור את החלון. למען בטיחותך, בטל את הסימון במחשבים ציבוריים.",
  newTerms: "מונחים חדשים!",
  newUserPublishedTerms: "ויותר חשוב. המונחים שלך זמינים עכשיו לכולם. עבודה מדהימה!",
  newTermsAdded: "מונחים חדשים זמינים עכשיו:",
  openWebsite: "פתח את אתר Shelpi",
  previewModeExplanation:
    "שים לב! גרסה זו של Microsoft Office תומכת רק בגרסת הדגמה בסיסית של Shelpi. כדי להשתמש בגרסה המלאה:",
  or: "או",
  upgradeOfficeVersion: "שדרג את גירסת Office",
  useOurWebsite: "השתמש באתר Shelpi",
  permanentListExplanation: "סוג רשימה. קובע איזה תו יתווסף בתחילת כל פריט (פעיל: {permanent}, אחרת: {temporary}).",
  delete: "מחיקה",
  clone: "שכפול",
  clonedSuccessfully: "שוכפל בהצלחה",
  deletedSuccessfully: "נמחק בהצלחה",
  findInDocument: "מצא במסמך",
  termNotFoundInDocument: "המונח לא נמצא במסמך.",
  shareFailed: "שיתוף נכשל. בבקשה נסה שוב מאוחר יותר.",
  addToMyAccount: "הוסף לחשבון שלי",
  addToAll: "הוסף לכולם",
  addToDocument: "הוסף למסמך",
  addToStage: "הוסף לשלב",
  actions: "פעולות",
  prepAddToStagesSuccessfully: "Prep התווסף לשלבים הרלוונטיים בהצלחה.",
  addPrepToStagesExplanation: "מוסיף את שלבי ה-Prep לשלבים הרלוונטיים במסמך. אם לא יימצא שלב הוא יתווסף לסוף המסמך.",
  addPrepToDocumentExplanation: "מוסיף את ה-Prep המלא למסמך.",
  copyPrepExplanation: "מעתיק את ה-Prep המלא.",
  selectPrepExplanation: "בוחר את ה-Prep כ-Prep הפעיל הזמין בתפריט העליון.",
  intakeCreatedSuccessfully: "הקבלה נוצרה בהצלחה.",
  subClause: "תת סעיף",
  subClauseCancel: "ביטול תת סעיף",
  addedToPrep: "נוסף ל-Prep",
  fullName: "שם מלא",
  myAccount: "החשבון שלי",
  published: "פורסם",
  publish: "פרסם",
  rejected: "נדחה",
  processing: "בתהליך",
  publishEntityApprovedExplanation: "הפרסום שלך אושר!",
  publishEntityApprovedReason: "עבודה מדהימה! תודה על היותך חלק חשוב מקהילת Shelpi.",
  publishEntityPublishExplanation:
    "פרסמי את עבודתך כדי שתהיה זמינה לכולם ותעזרי להפוך את העולם למקום טוב יותר :) הפרסום יבדק ואם ימצא מתאים יהיה זמין לכולם.",
  publishEntityRejectedExplanation: "הפרסום נדחה עקב השגיאה הבאה. ניתן תמיד לתקן את השגיאה ולפרסם מחדש:",
  publishEntityRejectedReason: "פרסום לא תקין.",
  publishEntityWaitingForApprovalExplanation: "הפרסום שלך ממתין לבדיקה.",
  publishNameRequired: "אתה נדרש לעדכן את שמך כדי לפרסם.",
  updateName: "עדכון שם",
  publishAnonymously: "פרסום אנונימי",
  publishAnonymouslyExplanation:
    "פרסומים שלך יופיעו במערכת עם השם שלך כיאה לעבודה הקשה שהשקעת. אם מכל סיבה את מעדיפה שהשם שלך לא יופיע יש לסמן את התיבה.",
  publishExplanation:
    "שימי לב: לאחר פרסום העבודה שלך העורכים שלנו יכולים לעדכן ולשפר אותה. את יכולה להמשיך לעדכן ולשפר אותה בכל עת.",
  publishRequestSuccess: "בקשת פרסום נשלחה בהצלחה.",
  managePublishRequests: "ניהול בקשות פרסום",
  publishRequests: "בקשות פרסום",
  approve: "אישור",
  reject: "דחייה",
  underMaintenance: "עבודות תחזוקה",
  underMaintenanceGetBackSoon: "נחזור בקרוב טובים וחזקים יותר.",
  confirmDelete: "אישור מחיקה",
  confirmDeleteExplanation: "האם אתה בטוח שברצונך למחוק?",
  edit: "עריכה",
  editRequests: "בקשות עריכה",
  editRequestSuccess: "בקשת עריכה נשלחה בהצלחה.",
  editNameRequired: "אתה נדרש לעדכן את שמך כדי לבקש לערוך.",
  sendEditRequest: "שלח בקשת עריכה",
  manageEditRequests: "ניהול בקשות עריכה",
  submit: "שלח",
  editRequestsApprovedExplanation: "בקשות העריכה הבאות אושרו! ניתן לערוך כבר עכשיו:",

  stageTypeCurrentDiseaseQuestions: "מחלה נוכחית",
  stageTypePhysicalExaminationEmphasis: "בדיקה גופנית",
  stageTypePregnancyAndChildbirth: "מהלך הריון ולידה",
  stageTypeVaccines: "חיסונים",
  stageTypeDiet: "תזונה",
  stageTypeGrowthAndDevelopment: "גדילה והתפתחות",
  stageTypeLabEmphasis: "מעבדה",
  stageTypeBackgroundDisease: "מחלות רקע",
  stageTypeRegularMedications: "תרופות קבועות",
  stageTypeGeneralMedicalBackground: "רקע רפואי כללי",
  stageTypeRelevantFamilyBackground: "רקע משפחתי רלוונטי",
  stageTypeOther: "אחר",
  stageTypeScreeningTests: "בדיקות סקר",
  stageTypeGynecologicalHistory: "היסטוריה גניקולוגית",
  stageTypeObstetricHistory: "היסטוריה מיילדותית",
  stageTypeCurrentPregnancy: "הריון נוכחי",
  stageTypePlan: "תוכנית",
  stageTypeSummaryAndDiscussion: "סיכום ודיון",

  addTextLocationBeforeSelection: "הוסף פסקה לפני בחירה",
  addTextLocationAfterSelection: "הוסף פסקה לאחר בחירה",
  addTextLocationSelection: "הוסף לבחירה",
  addTextLocationBeforeTerm: "הוסף פסקה לפני מונח",
  addTextLocationAfterTerm: "הוסף פסקה אחרי מונח",
  addTextLocationStart: "הוסף לתחילת המסמך",
  addTextLocationEnd: "הוסף לסוף מסמך",
  addTextLocationStageStart: "הוסף לשלב הרלוונטי",

  categoryOther: "אחר",
  categoryDrug: "תרופה",
  categorySymptom: "סימפטום",
  categoryDisease: "מחלה",
  categoryAge: "גיל",
  categorySignInExam: "סימן בבדיקה",
  categoryLabResult: "תוצאות מעבדה",

  medicalFieldInternalMedicine: "פנימית",
  medicalFieldPaediatrics: "ילדים",
  medicalFieldGynecology: "גינקולוגיה",
  medicalFieldSurgery: "כירורגיה",
  medicalFieldPsychiatry: "פסיכיאטריה",
  medicalFieldNeurology: "נוירולוגיה",

  dataEntityStatusDraft: "טיוטא",
  dataEntityStatusPublished: "זמין",
  dataEntityStatusArchived: "ארכיון",

  publishEntityStatusApproved: "מאושר",
  publishEntityStatusNone: "ללא",
  publishEntityStatusRejected: "נדחה",
  publishEntityStatusWaitingForApproval: "מחכה",

  contentTypeText: "טקסט",
  contentTypeList: "רשימה",
  contentTypeTable: "טבלה",
};
