import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Entity, EntityPublishStatus, PublishableEntityRO} from "../../types/entity";
import PageHeader from "../pages/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {FormProvider, useForm} from "react-hook-form";
import ErrorMessage from "../common/ErrorMessage";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {PublishRequest} from "../../types/internal";
import useEntity from "../../contexts/entity/hooks/useEntity";
import {publishRequestEntityId} from "../../entities/publish-request.entity";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import {publicUserId} from "../../contexts/entity-crud/EntityCrudContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import {useNavigate} from "react-router-dom";
import useEntityNavigation from "../../helpers/useEntityNavigation";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export interface EntityPublishRequestFormProps {
  publishRequestId: string;
}

const EntityPublishRequestForm = ({publishRequestId}: EntityPublishRequestFormProps) => {
  const intl = useIntl();
  const {getEntity} = useEntity();
  const {getItem, saveItem, deleteItem} = useEntityCrud();
  const navigate = useNavigate();
  const {getEntityDetailsUrl, getEntityGridUrl} = useEntityNavigation();

  const [publishRequest, setPublishRequest] = useState<PublishRequest | undefined>(undefined);
  const [targetItem, setTargetItem] = useState<PublishableEntityRO | undefined>(undefined);
  const [reason, setReason] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const entity = useMemo<Entity<PublishRequest, any>>(() => getEntity(publishRequestEntityId), []);
  const targetEntity = useMemo<Entity<any, any> | undefined>(
    () => (publishRequest ? getEntity(publishRequest.targetEntityId) : undefined),
    [publishRequest]
  );

  const disabled = useMemo<boolean>(
    () => publishRequest?.status !== EntityPublishStatus.WaitingForApproval,
    [publishRequest]
  );

  const methods = useForm<any>({});
  const {reset} = methods;

  useEffect(() => {
    (async () => {
      const item = await getItem(entity, publishRequestId);
      if (item) {
        setPublishRequest(item);
        setReason(item.reason);
      } else {
        setLoading(false);
        setError(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!publishRequest) {
      return;
    }

    (async () => {
      const targetItem = await getItem(targetEntity, publishRequest.targetId);
      setLoading(false);
      if (targetItem) {
        reset(targetItem);
        setTargetItem(targetItem);
      } else {
        setError(true);
      }
    })();
  }, [publishRequest]);

  const subtitle = useMemo<string | undefined>(
    () => (targetEntity ? `${intl.formatMessage({id: targetEntity?.titleId})} - ${publishRequest?.targetTitle}` : ""),
    [publishRequest, targetEntity]
  );

  const approveHandler = useCallback(async (): Promise<void> => {
    if (!targetItem) {
      return;
    }

    setSaving(true);

    const now = new Date().getTime();
    targetItem.userIds.push(publicUserId);
    targetItem.publishStatus = EntityPublishStatus.Approved;
    targetItem.publishReason = reason;
    targetItem.publishTime = now;
    targetItem.publishFullName = publishRequest.anonymous ? undefined : publishRequest.fullName;
    const savedTargetItem = await saveItem(targetEntity, targetItem);
    if (!savedTargetItem) {
      setSaving(false);
      return;
    }

    publishRequest.status = EntityPublishStatus.Approved;
    publishRequest.reason = reason;
    const savedPublishRequest = await saveItem(entity, publishRequest);
    if (!savedPublishRequest) {
      setSaving(false);
      return;
    }

    setPublishRequest(savedPublishRequest);

    const deletedPublishRequest = await deleteItem(entity, savedPublishRequest);

    setSaving(false);

    if (savedTargetItem) {
      navigate(getEntityDetailsUrl(targetEntity, savedTargetItem.id));
    }
  }, [setSaving, targetItem, targetEntity, publishRequest, reason, saveItem]);

  const rejectHandler = useCallback(async (): Promise<void> => {
    if (!targetItem) {
      return;
    }

    setSaving(true);

    targetItem.publishStatus = EntityPublishStatus.Rejected;
    targetItem.publishReason = reason;
    const savedTargetItem = await saveItem(targetEntity, targetItem);
    if (!savedTargetItem) {
      setSaving(false);
      return;
    }

    publishRequest.status = EntityPublishStatus.Rejected;
    publishRequest.reason = reason;
    const savedPublishRequest = await saveItem(entity, publishRequest);
    if (!savedPublishRequest) {
      setSaving(false);
      return;
    }

    setPublishRequest(savedPublishRequest);

    const deletedPublishRequest = await deleteItem(entity, savedPublishRequest);

    setSaving(false);

    if (deletedPublishRequest) {
      navigate(getEntityGridUrl(entity, false));
    }
  }, [setSaving, targetItem, targetEntity, publishRequest, reason, saveItem, deleteItem]);

  return (
    <>
      <PageHeader title={<FormattedMessage id={"publishRequests"} />} subtitle={subtitle} />

      {loading && (
        <Box sx={{textAlign: "center"}}>
          <CircularProgress />
        </Box>
      )}

      {error && !publishRequest && <ErrorMessage />}

      {publishRequest && targetItem && (
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <TextField
                  value={publishRequest.fullName}
                  fullWidth
                  label={intl.formatMessage({id: "fullName"})}
                  type="text"
                  disabled={true}
                />

                <TextField
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  fullWidth
                  label={intl.formatMessage({id: "comments"})}
                  type="text"
                  inputProps={{
                    maxLength: 250,
                  }}
                  disabled={disabled}
                />

                <Stack direction={"row"} spacing={1}>
                  <FormControlLabel
                    control={<Checkbox checked={publishRequest.anonymous} disabled={true} />}
                    label={<FormattedMessage id="publishAnonymously" />}
                  />

                  <Box sx={{flexGrow: 1}} />

                  <LoadingButton
                    variant={publishRequest.status === EntityPublishStatus.Rejected ? "contained" : "outlined"}
                    color={"error"}
                    startIcon={<CloseOutlined />}
                    loading={saving}
                    disabled={disabled}
                    onClick={rejectHandler}
                  >
                    <FormattedMessage id="reject" />
                  </LoadingButton>

                  <LoadingButton
                    variant={publishRequest.status === EntityPublishStatus.Approved ? "contained" : "outlined"}
                    color={"success"}
                    startIcon={<CheckOutlined />}
                    loading={saving}
                    disabled={disabled}
                    onClick={approveHandler}
                  >
                    <FormattedMessage id="approve" />
                  </LoadingButton>
                </Stack>
              </Stack>
            </CardContent>
          </Card>

          <FormProvider {...methods}>
            <form autoComplete="off" noValidate>
              <targetEntity.formComponent entity={targetEntity} item={targetItem} disabled={true} />
            </form>
          </FormProvider>
        </Stack>
      )}
    </>
  );
};

export default EntityPublishRequestForm;
