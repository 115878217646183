import IconButton, {IconButtonProps} from "@mui/material/IconButton";
import styled from "@mui/material/styles/styled";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const {expand, ...other} = props;
  return (
    <IconButton {...other}>
      <ExpandMoreIcon />
    </IconButton>
  );
})(({theme, expand}) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMore;
