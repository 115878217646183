import * as React from "react";
import Progress from "./components/Progress";
import {FunctionComponent, useMemo} from "react";
import {IntlProvider} from "react-intl";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ThemeConfig from "./theme";
import RtlLayout from "./components/common/RtlLayout";
import GlobalStyles from "./theme/globalStyles";
import locales, {LocaleInfo} from "./lang";
import {HashRouter, MemoryRouter} from "react-router-dom";
import Router from "./routes/routes";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {selectDarkMode, selectLocale} from "./redux/auth/selector";
import NotistackProvider from "./components/notifications/NotistackProvider";
import useOffice from "./contexts/office/hooks/useOffice";
import TitleManager from "./managers/TitleManager";
import NiceModal from "@ebay/nice-modal-react";
import {EntityProvider} from "./contexts/entity/EntityContext";
import {entityFactories} from "./entities/entityFactories";
import {EntityCrudProvider} from "./contexts/entity-crud/EntityCrudContext";
import {EntityFiltersProvider} from "./contexts/entity-filters/EntityFiltersContext";
import {ActivePrepProvider} from "./contexts/active-prep/ActivePrepContext";
import {useEffectOnce} from "react-use";
import {refreshUser} from "./redux/auth/slice";
import UserProfileManager from "./managers/UserProfileManager";
import {NegateKeywordsProvider} from "./contexts/negate-keywords/NegateKeywordsContext";
import TermsNotificationsManager from "./managers/TermsNotificationsManager";
import {EntityShareProvider} from "./contexts/entity-share/EntityShareContext";
import {IntakeTemplateGeneratorProvider} from "./contexts/intake-template-generator/IntakeTemplateGeneratorContext";
import {underMaintenance} from "./constants/defaultValues";
import Maintenance from "./pages/general/maintenance";

export interface AppProps {}

const App: FunctionComponent<AppProps> = ({}) => {
  const {isOfficeInitialized, isOfficeActive} = useOffice();

  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectLocale);
  const currentLocale = useMemo<LocaleInfo>(() => locales[locale], [locale]);
  const darkMode = useAppSelector(selectDarkMode);

  useEffectOnce(() => {
    dispatch(refreshUser({force: false}));
  });

  return (
    <IntlProvider
      locale={currentLocale.locale}
      messages={currentLocale.messages}
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          console.warn("Missing translation", err.message);
          return;
        }
        throw err;
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLocale.dateLocalization}>
        <ThemeConfig
          isDarkMode={darkMode}
          isRtl={currentLocale.direction === "rtl"}
          localization={currentLocale.materialUiLocalization}
        >
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <UserProfileManager />

              {underMaintenance ? (
                <Maintenance />
              ) : (
                <EntityProvider entityFactories={entityFactories}>
                  <EntityCrudProvider>
                    <EntityFiltersProvider>
                      <EntityShareProvider>
                        <NegateKeywordsProvider>
                          <IntakeTemplateGeneratorProvider>
                            {isOfficeActive ? (
                              <>
                                {isOfficeInitialized ? (
                                  <MemoryRouter>
                                    <NiceModal.Provider>
                                      <ActivePrepProvider>
                                        <TermsNotificationsManager />
                                        <Router />
                                      </ActivePrepProvider>
                                    </NiceModal.Provider>
                                  </MemoryRouter>
                                ) : (
                                  <Progress />
                                )}
                              </>
                            ) : (
                              <HashRouter>
                                <NiceModal.Provider>
                                  <ActivePrepProvider>
                                    <TitleManager />
                                    <TermsNotificationsManager />
                                    <Router />
                                  </ActivePrepProvider>
                                </NiceModal.Provider>
                              </HashRouter>
                            )}
                          </IntakeTemplateGeneratorProvider>
                        </NegateKeywordsProvider>
                      </EntityShareProvider>
                    </EntityFiltersProvider>
                  </EntityCrudProvider>
                </EntityProvider>
              )}
            </NotistackProvider>
          </RtlLayout>
        </ThemeConfig>
      </LocalizationProvider>
    </IntlProvider>
  );
};
export default App;
