import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Entity} from "../../types/entity";
import PageHeader from "../pages/PageHeader";
import {useCallback, useEffect, useMemo, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {FormProvider, useForm} from "react-hook-form";
import ErrorMessage from "../common/ErrorMessage";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {Share} from "../../types/internal";
import {shareEntityId} from "../../entities/share.entity";
import useEntity from "../../contexts/entity/hooks/useEntity";
import {cloneDeep} from "lodash";
import AddOutlined from "@mui/icons-material/AddOutlined";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../redux/hooks";
import {selectLoggedIn, selectUserId} from "../../redux/auth/selector";
import NiceModal from "@ebay/nice-modal-react";
import LoginDialog from "../dialogs/LoginDialog";
import RoleHidden from "../common/RoleHidden";
import {roleAdmin} from "../../constants/roles";
import useEntityNavigation from "../../helpers/useEntityNavigation";
import {publicUserId} from "../../contexts/entity-crud/EntityCrudContext";

export interface EntityShareFormProps {
  shareId: string;
}

const EntityShareForm = ({shareId}: EntityShareFormProps) => {
  const intl = useIntl();
  const {getEntity} = useEntity();
  const {getItem, saveItem} = useEntityCrud();
  const navigate = useNavigate();
  const {getEntityDetailsUrl} = useEntityNavigation();

  const loggedIn = useAppSelector(selectLoggedIn);
  const userId = useAppSelector(selectUserId);

  const [share, setShare] = useState<Share | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const entity = useMemo<Entity<Share, any>>(() => getEntity(shareEntityId), []);
  const dataEntity = useMemo<Entity<any, any> | undefined>(
    () => (share ? getEntity(share.entityId) : undefined),
    [share]
  );

  const methods = useForm<any>({});
  const {reset} = methods;

  useEffect(() => {
    (async () => {
      const item = await getItem(entity, shareId);
      setLoading(false);
      if (item) {
        reset(item.data);
        setShare(item);
      } else {
        setError(true);
      }
    })();
  }, []);

  const subtitle = useMemo<string | undefined>(
    () =>
      share && dataEntity
        ? `${intl.formatMessage({id: dataEntity?.titleId})} - ${dataEntity.getItemTitle(share?.data)}`
        : "",
    [share, dataEntity]
  );

  const cloneHandler = useCallback(
    async (itemToClone: any, entity: Entity<any, any>): Promise<void> => {
      setSaving(true);

      const item = entity.generateCloneItem(cloneDeep(itemToClone));
      item.id = "";
      item.creationTime = 0;
      item.lastUpdateTime = 0;
      const savedItem = await saveItem(entity, item);

      setSaving(false);

      if (savedItem) {
        navigate(getEntityDetailsUrl(entity, savedItem.id));
      }
    },
    [saveItem, setSaving, getEntityDetailsUrl]
  );

  const addToAccountHandler = useCallback(async (): Promise<void> => {
    if (!loggedIn) {
      NiceModal.show(LoginDialog, {});
      return;
    }

    const itemToClone = share.data;
    if (!itemToClone) {
      return;
    }

    if (itemToClone.userIds?.includes(userId)) {
      navigate(getEntityDetailsUrl(dataEntity, itemToClone.id));
      return;
    }

    itemToClone.userIds = [];

    await cloneHandler(itemToClone, dataEntity);
  }, [share, dataEntity, cloneHandler, loggedIn, userId, getEntityDetailsUrl]);

  const addToAllHandler = useCallback(async (): Promise<void> => {
    const itemToClone = share.data;
    if (!itemToClone) {
      return;
    }

    if (itemToClone.userIds?.includes(publicUserId)) {
      navigate(getEntityDetailsUrl(dataEntity, itemToClone.id));
      return;
    }

    itemToClone.userIds = [publicUserId];

    await cloneHandler(itemToClone, dataEntity);
  }, [share, dataEntity, cloneHandler]);

  return (
    <>
      <PageHeader
        title={<FormattedMessage id={"share"} />}
        subtitle={subtitle}
        action={
          share && (
            <Stack direction={"row-reverse"} spacing={1} flexGrow={1}>
              <LoadingButton
                variant="contained"
                startIcon={<AddOutlined />}
                loading={saving}
                onClick={addToAccountHandler}
              >
                <FormattedMessage id="addToMyAccount" />
              </LoadingButton>

              <RoleHidden role={roleAdmin}>
                <LoadingButton
                  variant="contained"
                  startIcon={<AddCircleOutlineOutlined />}
                  loading={saving}
                  onClick={addToAllHandler}
                >
                  <FormattedMessage id="addToAll" />
                </LoadingButton>
              </RoleHidden>
            </Stack>
          )
        }
      />

      {loading && (
        <Box sx={{textAlign: "center"}}>
          <CircularProgress />
        </Box>
      )}

      {error && !share && <ErrorMessage />}

      {dataEntity && share && (
        <FormProvider {...methods}>
          <form autoComplete="off" noValidate>
            <dataEntity.formComponent entity={dataEntity} item={share.data} disabled={true} />
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default EntityShareForm;
