import * as React from "react";
import {motion, MotionProps} from "framer-motion";
import Box from "@mui/material/Box";
import {BoxProps} from "@mui/material/Box";
import {varFadeInUp} from "./variants";

// ----------------------------------------------------------------------

type Props = BoxProps & MotionProps;

interface TextAnimateProps extends Props {
  text: string;
}

export default function TextAnimate({text, variants, sx, ...other}: TextAnimateProps) {
  return (
    <Box
      component={motion.h1}
      sx={{
        typography: "h1",
        overflow: "hidden",
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      {text.split("").map((letter, index) => (
        <motion.span key={index} variants={variants || varFadeInUp}>
          {letter}
        </motion.span>
      ))}
    </Box>
  );
}
