import * as React from "react";
import {useCallback, useEffect, useMemo} from "react";
import {EntityFormComponentProps} from "../../../../types/entity";
import {MedicalField, Prep, StageType, TermFilters} from "../../../../types/internal";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import {isEmpty} from "lodash";
import FormListHeader from "../common/FormListHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import {maxPrepStages} from "../../../../constants/defaultValues";
import {v4 as uuidv4} from "uuid";
import PrepStageFormSortable from "./PrepStageFormSortable";
import {getInsertIndexByEnum} from "../../../../helpers/EntityUtils";
import {getPrepHtml, getPrepStageHtml} from "../../../../helpers/PrepUtils";
import {getMedicalFieldLabelId} from "../../../../helpers/MedicalFieldUtils";
import MenuItem from "@mui/material/MenuItem";
import useTheme from "@mui/material/styles/useTheme";
import {printRawHtml} from "../../../../helpers/PrintUtils";
import useActivePrep from "../../../../contexts/active-prep/hooks/useActivePrep";
import SortableDndContext from "../../../dnd/SortableDndContext";
import {AddTextLocation, addTextToDocument} from "../../../../helpers/AddTextLocationUtils";
import useCopyToClipboard from "../../../../helpers/useCopyToClipboard";
import {getStageTypeLabelId, getStageTypes} from "../../../../helpers/TermUtils";
import {sectionTitlePrefix} from "../../../../helpers/IntakeTemplateUtils";
import {useNavigate} from "react-router-dom";
import NavigationUtils from "../../../../helpers/NavigationUtils";
import useIntakeTemplateGenerator from "../../../../contexts/intake-template-generator/hooks/useIntakeTemplateGenerator";

const PrepForm = ({entity, item, disabled, action}: EntityFormComponentProps<Prep, TermFilters>) => {
  const intl = useIntl();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const {selectPrep} = useActivePrep();
  const copyToClipboard = useCopyToClipboard();
  const navigate = useNavigate();
  const {addMedicalRecordFormat} = useIntakeTemplateGenerator();

  const {control, getValues} = useFormContext<Prep>();
  const {fields, insert, remove, move} = useFieldArray<Prep>({name: "stages", control});

  const medicalField: MedicalField | undefined = useWatch({control: control, name: `medicalField`});

  const availableStageTypes = useMemo<StageType[]>(() => getStageTypes(medicalField), [medicalField]);

  useEffect(() => {
    if (action) {
      const prep: Prep = getValues();
      switch (action.actionId) {
        case "print":
          printRawHtml(getPrepHtml(prep, intl, {direction: theme.direction}), theme.direction);
          break;
        case "addToStage":
          addPrepToStageHandler(prep);
          break;
        case "addToDocument":
          addTextToDocument(
            AddTextLocation.End,
            getPrepHtml(prep, intl, {direction: theme.direction, medicalRecordFormat: addMedicalRecordFormat}),
            {select: true}
          );
          break;
        case "copy":
          copyToClipboard(
            getPrepHtml(prep, intl, {direction: theme.direction, medicalRecordFormat: addMedicalRecordFormat}),
            "text/html"
          );
          break;
        case "select":
          selectPrep(prep);
          break;
        case "createIntake":
          createIntakeHandler(prep);
          break;
      }
    }
  }, [action]);

  const getNewStageType = useCallback((): StageType => {
    for (let stageType of availableStageTypes) {
      if (!fields.find((f) => f["type"] === stageType)) {
        return stageType as StageType;
      }
    }
    return StageType.CurrentDiseaseQuestions;
  }, [fields, availableStageTypes]);

  const addStageHandler = useCallback((): void => {
    if (fields.length + 1 > maxPrepStages) {
      enqueueSnackbar(<FormattedMessage id="reachedMaximumAllowed" values={{max: maxPrepStages}} />, {
        variant: "info",
      });
      return;
    }

    const insertType = getNewStageType();
    const insertIndex = getInsertIndexByEnum(
      StageType,
      insertType,
      fields.map((f) => f["type"])
    );

    insert(insertIndex, {
      uuid: uuidv4(),
      type: insertType,
      comments: "",
      terms: [],
    });
  }, [insert, fields, getNewStageType]);

  const addPrepToStageHandler = useCallback(
    async (prep: Prep): Promise<void> => {
      for (const prepStage of prep.stages) {
        const stageTitle = intl.formatMessage({id: getStageTypeLabelId(prepStage.type)});
        const stageFoundHtml = getPrepStageHtml(prepStage, intl, {
          direction: theme.direction,
          medicalRecordFormat: addMedicalRecordFormat,
          hideTitle: true,
        });
        try {
          await addTextToDocument(AddTextLocation.StageStart, stageFoundHtml, {
            keywords: [sectionTitlePrefix + stageTitle],
          });
        } catch (e) {
          try {
            await addTextToDocument(AddTextLocation.StageStart, stageFoundHtml, {keywords: [stageTitle]});
          } catch (e) {
            const stageNotFoundHtml = getPrepStageHtml(prepStage, intl, {
              direction: theme.direction,
              medicalRecordFormat: addMedicalRecordFormat,
            });
            await addTextToDocument(AddTextLocation.End, stageNotFoundHtml);
          }
        }
      }

      enqueueSnackbar(<FormattedMessage id="prepAddToStagesSuccessfully" />, {variant: "success"});
    },
    [intl, theme.direction, addMedicalRecordFormat]
  );

  const createIntakeHandler = useCallback(
    (prep: Prep): void => {
      if (!prep.id) {
        return;
      }
      navigate(NavigationUtils.getNewIntakeUrl(prep.id));
    },
    [navigate]
  );

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Controller
                name="title"
                rules={{
                  required: intl.formatMessage({id: "requiredField"}),
                }}
                control={control}
                render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={ref}
                      required
                      fullWidth
                      label={intl.formatMessage({id: "title"})}
                      type="text"
                      autoFocus={!item.id}
                      inputProps={{
                        maxLength: 200,
                      }}
                      error={invalid}
                      helperText={error?.message}
                      disabled={disabled}
                    />
                  );
                }}
              />
            </Stack>

            <Controller
              name="medicalField"
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    fullWidth
                    label={intl.formatMessage({id: "medicalFields"})}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                    select
                    value={field?.value || ""}
                  >
                    {Object.values(MedicalField).map((m) => (
                      <MenuItem key={m} value={m}>
                        <FormattedMessage id={getMedicalFieldLabelId(m)} />
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />
          </Stack>
        </CardContent>
      </Card>

      <FormListHeader
        title={<FormattedMessage id="stages" />}
        buttonText={<FormattedMessage id="addNewStage" />}
        buttonDisabled={disabled}
        onAddItem={addStageHandler}
        sx={{mt: 3}}
      />

      {isEmpty(fields) && (
        <Card sx={{mt: 3}}>
          <CardContent>
            <Typography variant="body2" sx={{color: "text.secondary"}}>
              <FormattedMessage id="noResultsFound" />
            </Typography>
          </CardContent>
        </Card>
      )}

      <SortableDndContext items={fields} move={move}>
        {fields.map((field, index) => (
          <PrepStageFormSortable
            id={field.id}
            index={index}
            remove={remove}
            sx={{mt: 3}}
            disabled={disabled}
            key={field.id}
          />
        ))}
      </SortableDndContext>

      <FormListHeader
        buttonText={<FormattedMessage id="addNewStage" />}
        buttonDisabled={disabled}
        onAddItem={addStageHandler}
        sx={{mt: 3}}
      />
    </>
  );
};

export default PrepForm;
