import * as React from "react";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardProps} from "@mui/material/Card";
import SeoIllustration from "../../svg/SeoIllustration";
import {FormattedMessage} from "react-intl";
import {addInDownloadUrl} from "../../../constants/defaultValues";

const RootStyle = styled(Card)(({theme}) => ({
  boxShadow: "none",
  textAlign: "center",
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// ----------------------------------------------------------------------

interface HomeWelcomeProps extends CardProps {}

export default function HomeWelcome({}: HomeWelcomeProps) {
  return (
    <RootStyle>
      <CardContent
        sx={{
          color: "grey.800",
        }}
      >
        <Typography gutterBottom variant="h4">
          <FormattedMessage id="welcomeToPlugin" />
        </Typography>

        <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: "auto"}}>
          <FormattedMessage id="whatIsTheTool" />
        </Typography>

        <Button variant="contained" href={addInDownloadUrl} target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="getThePlugin" />
        </Button>
      </CardContent>

      <SeoIllustration
        sx={{
          p: 3,
          width: "100%",
          maxWidth: 360,
          margin: {xs: "auto", md: "inherit"},
        }}
      />
    </RootStyle>
  );
}
