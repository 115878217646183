import {termEntityFactory} from "./term.entity";
import {intakeTemplateEntityFactory} from "./intake-template.entity";
import {prepEntityFactory} from "./prep.entity";
import {shareEntityFactory} from "./share.entity";
import {publishRequestEntityFactory} from "./publish-request.entity";
import {editRequestEntityFactory} from "./edit-request.entity";

export const entityFactories = [
  termEntityFactory,
  intakeTemplateEntityFactory,
  prepEntityFactory,
  shareEntityFactory,
  publishRequestEntityFactory,
  editRequestEntityFactory,
];
