import * as React from "react";
import Container from "@mui/material/Container";
import Page from "../../../components/common/Page";
import {useParams} from "react-router-dom";
import {useMemo} from "react";
import EntityEditRequestForm from "../../../components/entity/EntityEditRequestForm";

export interface EntityEditRequestProps {}

const EntityEditRequest = ({}: EntityEditRequestProps) => {
  const params = useParams();

  const itemId = useMemo<string>(() => params.id, [params]);

  return (
    <Page>
      <Container>
        <EntityEditRequestForm editRequestId={itemId} key={itemId} />
      </Container>
    </Page>
  );
};

export default EntityEditRequest;
