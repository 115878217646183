import * as React from "react";
import {ComponentType, ReactNode} from "react";
import {SnackbarProvider} from "notistack";
import {alpha} from "@mui/system/colorManipulator";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import GlobalStyles from "@mui/material/GlobalStyles";
import {ColorSchema} from "../../types/theme";
import Fade from "@mui/material/Fade";
import {SvgIconProps} from "@mui/material/SvgIcon";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlined from "@mui/icons-material/ErrorOutlined";
import WarningOutlined from "@mui/icons-material/WarningOutlined";

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <GlobalStyles
      styles={{
        "#container": {
          "& .SnackbarContent-root": {
            width: "100%",
            padding: theme.spacing(1.5),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            "&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo":
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
          },
          "& .SnackbarItem-message": {
            padding: "0 !important",
            fontWeight: theme.typography.fontWeightMedium,
          },
          "& .SnackbarItem-action": {
            marginRight: 0,
            color: theme.palette.action.active,
            "& svg": {width: 20, height: 20},
          },
        },
      }}
    />
  );
}

type SnackbarIconProps = {
  Icon: ComponentType<SvgIconProps>;
  color: ColorSchema;
};

function SnackbarIcon({Icon, color}: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        minWidth: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Icon fontSize={"medium"} />
    </Box>
  );
}

type NotistackProviderProps = {
  children: ReactNode;
};

export default function NotistackProvider({children}: NotistackProviderProps) {
  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        TransitionComponent={Fade}
        iconVariant={{
          success: <SnackbarIcon Icon={CheckCircleOutlined} color="success" />,
          error: <SnackbarIcon Icon={ErrorOutlined} color="error" />,
          warning: <SnackbarIcon Icon={WarningOutlined} color="warning" />,
          info: <SnackbarIcon Icon={InfoOutlined} color="info" />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
