import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {BaseEntityRO, Entity, EntityPublishStatus, PublishableEntityRO} from "../../types/entity";
import PageHeader from "../pages/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {FormProvider, useForm} from "react-hook-form";
import ErrorMessage from "../common/ErrorMessage";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {EditRequest, PublishRequest} from "../../types/internal";
import useEntity from "../../contexts/entity/hooks/useEntity";
import {publishRequestEntityId} from "../../entities/publish-request.entity";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import {publicUserId} from "../../contexts/entity-crud/EntityCrudContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import {useNavigate} from "react-router-dom";
import useEntityNavigation from "../../helpers/useEntityNavigation";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {editRequestEntityId} from "../../entities/edit-request.entity";
import {useAppSelector} from "../../redux/hooks";
import {selectUserId} from "../../redux/auth/selector";
import {uniq} from "lodash";

export interface EntityEditRequestFormProps {
  editRequestId: string;
}

const EntityEditRequestForm = ({editRequestId}: EntityEditRequestFormProps) => {
  const intl = useIntl();
  const {getEntity} = useEntity();
  const {getItem, saveItem, deleteItem} = useEntityCrud();
  const navigate = useNavigate();
  const {getEntityDetailsUrl, getEntityGridUrl} = useEntityNavigation();

  const userId = useAppSelector(selectUserId);

  const [editRequest, setEditRequest] = useState<EditRequest | undefined>(undefined);
  const [targetItem, setTargetItem] = useState<BaseEntityRO | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const entity = useMemo<Entity<EditRequest, any>>(() => getEntity(editRequestEntityId), []);
  const targetEntity = useMemo<Entity<any, any> | undefined>(
    () => (editRequest ? getEntity(editRequest.targetEntityId) : undefined),
    [editRequest]
  );

  const disabled = useMemo<boolean>(
    () => editRequest?.status !== EntityPublishStatus.WaitingForApproval,
    [editRequest]
  );

  const methods = useForm<any>({});
  const {reset} = methods;

  useEffect(() => {
    (async () => {
      const item = await getItem(entity, editRequestId);
      if (item) {
        setEditRequest(item);
      } else {
        setLoading(false);
        setError(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!editRequest) {
      return;
    }

    (async () => {
      const targetItem = await getItem(targetEntity, editRequest.targetId);
      setLoading(false);
      if (targetItem) {
        reset(targetItem);
        setTargetItem(targetItem);
      } else {
        setError(true);
      }
    })();
  }, [editRequest]);

  const subtitle = useMemo<string | undefined>(
    () => (targetEntity ? `${intl.formatMessage({id: targetEntity?.titleId})} - ${editRequest?.targetTitle}` : ""),
    [editRequest, targetEntity]
  );

  const approveHandler = useCallback(async (): Promise<void> => {
    if (!targetItem) {
      return;
    }

    setSaving(true);

    targetItem.userIds = uniq([...targetItem.userIds, ...editRequest.userIds]);
    const savedTargetItem = await saveItem(targetEntity, targetItem);
    if (!savedTargetItem) {
      setSaving(false);
      return;
    }

    editRequest.status = EntityPublishStatus.Approved;
    const savedEditRequest = await saveItem(entity, editRequest);
    if (!savedEditRequest) {
      setSaving(false);
      return;
    }

    setEditRequest(savedEditRequest);

    const deletedPublishRequest = await deleteItem(entity, savedEditRequest);

    setSaving(false);

    if (savedTargetItem) {
      navigate(getEntityGridUrl(entity, false));
    }
  }, [setSaving, targetItem, targetEntity, editRequest, saveItem]);

  const rejectHandler = useCallback(async (): Promise<void> => {
    if (!targetItem) {
      return;
    }

    setSaving(true);

    editRequest.status = EntityPublishStatus.Rejected;
    const savedPublishRequest = await saveItem(entity, editRequest);
    if (!savedPublishRequest) {
      setSaving(false);
      return;
    }

    setEditRequest(savedPublishRequest);

    const deletedEditRequest = await deleteItem(entity, savedPublishRequest);

    setSaving(false);

    if (savedPublishRequest) {
      navigate(getEntityGridUrl(entity, false));
    }
  }, [setSaving, targetItem, editRequest, saveItem, deleteItem]);

  return (
    <>
      <PageHeader title={<FormattedMessage id={"editRequests"} />} subtitle={subtitle} />

      {loading && (
        <Box sx={{textAlign: "center"}}>
          <CircularProgress />
        </Box>
      )}

      {error && !editRequest && <ErrorMessage />}

      {editRequest && targetItem && (
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <TextField
                  value={editRequest.fullName}
                  fullWidth
                  label={intl.formatMessage({id: "fullName"})}
                  type="text"
                  disabled={true}
                />

                <TextField
                  value={editRequest.reason}
                  fullWidth
                  label={intl.formatMessage({id: "comments"})}
                  type="text"
                  disabled={true}
                />

                <Stack direction={"row"} spacing={1}>
                  <Box sx={{flexGrow: 1}} />

                  <LoadingButton
                    variant={editRequest.status === EntityPublishStatus.Rejected ? "contained" : "outlined"}
                    color={"error"}
                    startIcon={<CloseOutlined />}
                    loading={saving}
                    disabled={disabled}
                    onClick={rejectHandler}
                  >
                    <FormattedMessage id="reject" />
                  </LoadingButton>

                  <LoadingButton
                    variant={editRequest.status === EntityPublishStatus.Approved ? "contained" : "outlined"}
                    color={"success"}
                    startIcon={<CheckOutlined />}
                    loading={saving}
                    disabled={disabled}
                    onClick={approveHandler}
                  >
                    <FormattedMessage id="approve" />
                  </LoadingButton>
                </Stack>
              </Stack>
            </CardContent>
          </Card>

          <FormProvider {...methods}>
            <form autoComplete="off" noValidate>
              <targetEntity.formComponent entity={targetEntity} item={targetItem} disabled={true} />
            </form>
          </FormProvider>
        </Stack>
      )}
    </>
  );
};

export default EntityEditRequestForm;
