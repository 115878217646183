import * as React from "react";
import {IntakeTemplateContentPayloadProps} from "./IntakeTemplateContentPayload";
import {FormattedMessage} from "react-intl";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ContentTypePayloadList, ContentTypePayloadListItem} from "../../../../../types/internal";
import Stack from "@mui/material/Stack";
import {useUpdateEffect} from "react-use";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {v4 as uuidv4} from "uuid";
import {first, tail, trim} from "lodash";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddOutlined from "@mui/icons-material/AddOutlined";
import TagOutlined from "@mui/icons-material/TagOutlined";
import {listItemPermanentPrefix, listItemPrefix} from "../../../../../helpers/IntakeTemplateUtils";
import SortableDndContext from "../../../../dnd/SortableDndContext";
import IntakeTemplateContentPayloadListItemSortable from "./IntakeTemplateContentPayloadListItemSortable";
import {arraySwap} from "@dnd-kit/sortable";

export type ContentTypePayloadListItemId = ContentTypePayloadListItem & {id: string};

const IntakeTemplateContentPayloadList = ({
  type,
  field: {value, ref, onChange, ...field},
  fieldState: {invalid, error},
  disabled,
}: IntakeTemplateContentPayloadProps) => {
  const generateEmptyItem = useCallback(
    (text?: string): ContentTypePayloadListItem => ({
      uuid: uuidv4(),
      payload: text || "",
      example: false,
      assistNotes: false,
      extendedFormat: false,
      indent: 0,
    }),
    []
  );

  const [payload, setPayload] = useState<ContentTypePayloadList>(() => {
    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {}
    }
    return {items: [generateEmptyItem()], permanent: false};
  });
  const itemRefs = useRef<{[key: number]: any}>({});
  const [focusIndex, setFocusIndex] = useState<number>(-1);
  const [keySuffix, setKeySuffix] = useState<number>(0);
  const items = useMemo<ContentTypePayloadListItemId[]>(
    () => payload.items.map((item) => ({...item, id: item.uuid})),
    [payload.items]
  );
  const removeDisabled = useMemo<boolean>(() => payload.items.length <= 1, [payload]);
  const itemsSubtitle = useMemo<string>(() => `(${payload.items.length})`, [payload.items]);

  useEffect(() => {
    if (focusIndex > -1) {
      itemRefs.current[focusIndex]?.focus();
      setFocusIndex(-1);
    }
  }, [focusIndex]);

  useUpdateEffect(() => {
    if (onChange) {
      onChange(JSON.stringify(payload));
    }
  }, [payload]);

  const inputRefHandler = useCallback((index: number, input: any): void => {
    itemRefs.current[index] = input;
  }, []);

  const togglePermanentHandler = useCallback((): void => {
    setPayload((currentPayload) => ({
      ...currentPayload,
      permanent: !currentPayload.permanent,
    }));
  }, [setPayload]);

  const itemChangeHandler = useCallback(
    (index: number, item: ContentTypePayloadListItem): void => {
      setPayload((currentPayload) => ({
        ...currentPayload,
        items: [...currentPayload.items.slice(0, index), item, ...currentPayload.items.slice(index + 1)],
      }));
    },
    [setPayload]
  );

  const textChangeHandler = useCallback(
    (index: number, text: string): void => {
      setPayload((currentPayload) => {
        const item = currentPayload.items[index];
        return {
          ...currentPayload,
          items: [
            ...currentPayload.items.slice(0, index),
            {...item, payload: text},
            ...currentPayload.items.slice(index + 1),
          ],
        };
      });
    },
    [setPayload]
  );

  const addHandler = useCallback((): void => {
    setPayload((currentPayload) => ({
      ...currentPayload,
      items: [...currentPayload.items, generateEmptyItem()],
    }));
    setFocusIndex(payload.items.length);
  }, [setPayload]);

  const removeHandler = useCallback(
    (index: number): void => {
      setPayload((currentPayload) => ({
        ...currentPayload,
        items: currentPayload.items.filter((item, i) => i !== index),
      }));
    },
    [setPayload]
  );

  const moveHandler = useCallback(
    (oldIndex: number, newIndex: number): void => {
      setPayload((currentPayload) => ({
        ...currentPayload,
        items: arraySwap(currentPayload.items, oldIndex, newIndex),
      }));
    },
    [setPayload]
  );

  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
      if (e.repeat) {
        return;
      }
      if (e.key === "Enter") {
        e.preventDefault();
        setPayload((currentPayload) => ({
          ...currentPayload,
          items: [
            ...currentPayload.items.slice(0, index + 1),
            generateEmptyItem(),
            ...currentPayload.items.slice(index + 1),
          ],
        }));
        setFocusIndex(index + 1);
      }
      if (
        e.key === "Backspace" &&
        (e.target as HTMLInputElement | HTMLTextAreaElement).value === "" &&
        !removeDisabled
      ) {
        e.preventDefault();
        setPayload((currentPayload) => ({
          ...currentPayload,
          items: currentPayload.items.filter((item, i) => i !== index),
        }));
        setFocusIndex(Math.max(index - 1, 0));
      }
      if (e.key === "ArrowUp") {
        setFocusIndex(index - 1);
      }
      if (e.key === "ArrowDown") {
        setFocusIndex(index + 1);
      }
    },
    [setPayload, setFocusIndex, removeDisabled]
  );

  const pasteHandler = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
    const pastedText = e.clipboardData.getData("text/plain");

    const texts = trim(pastedText)
      .split("\n")
      .filter((t) => !!trim(t));
    if (texts.length <= 1) {
      return;
    }

    e.preventDefault();

    setPayload((currentPayload) => {
      const item = currentPayload.items[index];
      return {
        ...currentPayload,
        items: [
          ...currentPayload.items.slice(0, index),
          {...item, payload: item.payload + first(texts)},
          ...tail(texts).map((text) => generateEmptyItem(text)),
          ...currentPayload.items.slice(index + 1),
        ],
      };
    });
    setKeySuffix(new Date().getTime());
    setFocusIndex(index + texts.length - 1);
  };

  return (
    <Stack spacing={3}>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Box sx={{flexGrow: 1}}>
          <Typography variant={"body1"} sx={{display: "inline-block"}}>
            <FormattedMessage id="items" />
          </Typography>
          &nbsp;
          <Typography variant={"body1"} sx={{display: "inline-block", color: "text.secondary"}}>
            {itemsSubtitle}
          </Typography>
        </Box>

        <Button variant="outlined" startIcon={<AddOutlined />} onClick={addHandler} disabled={disabled}>
          <FormattedMessage id="addNewItem" />
        </Button>

        <Tooltip
          title={
            <FormattedMessage
              id="permanentListExplanation"
              values={{permanent: listItemPermanentPrefix, temporary: listItemPrefix}}
            />
          }
        >
          <span>
            <IconButton
              color={payload.permanent ? "primary" : "inherit"}
              onClick={togglePermanentHandler}
              disabled={disabled}
            >
              <TagOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>

      <SortableDndContext items={items} move={moveHandler}>
        {items.map((item, index) => (
          <IntakeTemplateContentPayloadListItemSortable
            item={item}
            index={index}
            removeDisabled={removeDisabled}
            inputRefHandler={inputRefHandler}
            itemChangeHandler={itemChangeHandler}
            textChangeHandler={textChangeHandler}
            removeHandler={removeHandler}
            keyDownHandler={keyDownHandler}
            pasteHandler={pasteHandler}
            disabled={disabled}
            key={`${item.uuid}_${keySuffix}`}
          />
        ))}
      </SortableDndContext>
    </Stack>
  );
};

export default IntakeTemplateContentPayloadList;
