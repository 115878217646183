import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Page from "../../components/common/Page";
import HomeWelcome from "../../components/pages/home-desktop/HomeWelcome";
import HomeDownloadAddIn from "../../components/pages/home-desktop/HomeDownloadAddIn";
import HomeSearch from "../../components/pages/home-desktop/HomeSearch";
import HomeTemplates from "../../components/pages/home-desktop/HomeTemplates";
import HomeSupport from "../../components/pages/home-desktop/HomeSupport";

export default function HomeDesktop() {
  return (
    <Page>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <HomeWelcome />
          </Grid>

          <Grid item xs={12} md={4}>
            <HomeDownloadAddIn />
          </Grid>

          <Grid item xs={12} md={4}>
            <HomeTemplates />
          </Grid>

          <Grid item xs={12} md={4}>
            <HomeSearch />
          </Grid>

          <Grid item xs={12} md={4}>
            <HomeSupport />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
