import * as React from "react";
import {FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {IntakeTemplate, MedicalField, TermFilters} from "../../../types/internal";
import ButtonGroup from "@mui/material/ButtonGroup";
import useEntity from "../../../contexts/entity/hooks/useEntity";
import useEntityCrud from "../../../contexts/entity-crud/hooks/useEntityCrud";
import {Entity} from "../../../types/entity";
import {intakeTemplateEntityId} from "../../../entities/intake-template.entity";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import {isEmpty} from "lodash";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";
import {sortEntityItems} from "../../../helpers/EntityUtils";
import {useAppSelector} from "../../../redux/hooks";
import {selectDarkMode} from "../../../redux/auth/selector";
import Stack from "@mui/material/Stack";
import useNavigateLoggedIn from "../../../helpers/useNavigateLoggedIn";
import useEntityNavigation from "../../../helpers/useEntityNavigation";
import {publicUserId} from "../../../contexts/entity-crud/EntityCrudContext";

export interface IntakeTemplateButtonGroupProps {
  medicalField?: MedicalField;
  toggle?: boolean;
  onIntakeTemplateSelected: (intakeTemplate: IntakeTemplate) => void;
  sx?: SxProps<Theme>;
}

const IntakeTemplateButtonGroup: FunctionComponent<IntakeTemplateButtonGroupProps> = ({
  medicalField,
  toggle,
  onIntakeTemplateSelected,
  sx,
}) => {
  const {getEntity} = useEntity();
  const {getItems, lastChangeTime} = useEntityCrud();
  const navigateLoggedIn = useNavigateLoggedIn();
  const {getEntityCreateUrl} = useEntityNavigation();

  const darkMode = useAppSelector(selectDarkMode);

  const entity = useMemo<Entity<IntakeTemplate, TermFilters>>(() => getEntity(intakeTemplateEntityId), []);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IntakeTemplate[] | undefined>(undefined);
  const [filteredTemplates, setFilteredTemplates] = useState<IntakeTemplate[] | undefined>(undefined);
  const [selectedIntakeTemplate, setSelectedIntakeTemplate] = useState<IntakeTemplate | undefined>(undefined);

  const showEmpty = useMemo<boolean>(() => !filteredTemplates || isEmpty(filteredTemplates), [filteredTemplates]);

  const intakeTemplateSelectedHandler = useCallback(
    (intakeTemplate: IntakeTemplate): void => {
      if (toggle) {
        setSelectedIntakeTemplate(intakeTemplate);
      }
      onIntakeTemplateSelected(intakeTemplate);
    },
    [toggle, onIntakeTemplateSelected, setSelectedIntakeTemplate]
  );

  useEffect(() => {
    (async () => {
      const items = await getItems(entity);
      setLoading(false);
      if (items) {
        setTemplates(sortEntityItems(entity, entity.filterDataItems(items)));
      } else {
        setError(true);
      }
    })();
  }, [lastChangeTime[entity.id]]);

  useEffect(() => {
    if (medicalField && templates) {
      setFilteredTemplates(templates.filter((t) => t.medicalField === medicalField));
    }
  }, [templates, medicalField]);

  const addUserTemplateHandler = useCallback((): void => {
    navigateLoggedIn(getEntityCreateUrl(entity, true));
  }, [navigateLoggedIn, getEntityCreateUrl]);

  return (
    <>
      {loading && (
        <Box sx={{textAlign: "center"}}>
          <CircularProgress />
        </Box>
      )}

      {showEmpty ? (
        <Stack spacing={1}>
          <Typography variant="body2" sx={{color: "text.secondary"}}>
            <FormattedMessage id="noTemplatesFound" />
          </Typography>

          <Button color="inherit" variant="outlined" onClick={addUserTemplateHandler}>
            <FormattedMessage id={"addPrivateTemplate"} />
          </Button>
        </Stack>
      ) : (
        <ButtonGroup orientation="vertical" sx={{...sx}}>
          {filteredTemplates?.map((template) => {
            const userOwned = !template.userIds?.includes(publicUserId);
            const publisher = template.publishFullName || "";
            return (
              <Button
                variant={selectedIntakeTemplate === template ? "contained" : "outlined"}
                sx={{
                  color: (theme) =>
                    userOwned
                      ? darkMode
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark
                      : theme.palette.primary.main,
                }}
                onClick={() => intakeTemplateSelectedHandler(template)}
                key={template.id}
              >
                {template.title}
                {userOwned && (
                  <ButtonInfo>
                    <FormattedMessage id="private" />
                  </ButtonInfo>
                )}
                {publisher && <ButtonInfo>{publisher}</ButtonInfo>}
              </Button>
            );
          })}
        </ButtonGroup>
      )}
    </>
  );
};

export default IntakeTemplateButtonGroup;

interface ButtonInfoProps extends PropsWithChildren<any> {}

function ButtonInfo({children}: ButtonInfoProps) {
  return (
    <Box
      component={"span"}
      sx={{
        display: "inline-block",
        position: "absolute",
        right: 3,
        bottom: -2,
        fontSize: 10,
        fontWeight: "normal",
        color: "text.secondary",
      }}
    >
      {children}
    </Box>
  );
}
