import {Entity, EntityFactory, EntityPublishStatus} from "../types/entity";
import {PublishRequest} from "../types/internal";
import {cleanEntityData, cleanSearchString, getPublishEntityStatusLabelId} from "../helpers/EntityUtils";
import {termEntityId} from "./term.entity";
import NavigationUtils from "../helpers/NavigationUtils";
import PublishRequestForm from "../components/entity/forms/publish-request/PublishRequestForm";
import {roleAdmin} from "../constants/roles";

export const publishRequestEntityId = "publishRequest";

export const publishRequestEntityFactory: EntityFactory<PublishRequest, any> = (intl): Entity<PublishRequest, any> => ({
  id: publishRequestEntityId,
  filtersId: termEntityId,
  titleId: "publishRequests",
  collection: "publishRequests",
  persist: true,
  creatable: false,
  deletable: false,
  shareable: false,
  publishable: false,
  roles: {read: roleAdmin},
  defaultOrderBy: {field: "creationTime", direction: "desc"},
  actions: [],
  columns: [
    {
      field: "targetTitle",
      headerNameId: "title",
      minWidth: 100,
      sortComparator: (v1, v2) => {
        return ((v1 || "") as string).localeCompare((v2 || "") as string, undefined, {numeric: true});
      },
    },
    {
      field: "fullName",
      headerNameId: "fullName",
      minWidth: 100,
      sortComparator: (v1, v2) => {
        return ((v1 || "") as string).localeCompare((v2 || "") as string, undefined, {numeric: true});
      },
    },
    {
      field: "status",
      headerNameId: "status",
      valueFormatter: (params) => {
        return intl.formatMessage({id: getPublishEntityStatusLabelId(params.value as EntityPublishStatus)});
      },
      sortComparator: (v1, v2) => {
        const v1Str = intl.formatMessage({id: getPublishEntityStatusLabelId(v1 as EntityPublishStatus)});
        const v2Str = intl.formatMessage({id: getPublishEntityStatusLabelId(v2 as EntityPublishStatus)});
        return v1Str.localeCompare(v2Str);
      },
    },
    {
      field: "creationTime",
      headerNameId: "creationTime",
      valueFormatter: (params) => {
        try {
          return `${intl.formatDate(params.value as number)} ${intl.formatTime(params.value as number)}`;
        } catch (e) {
          return "";
        }
      },
    },
    {
      field: "lastUpdateTime",
      headerNameId: "lastUpdateTime",
      valueFormatter: (params) => {
        try {
          return `${intl.formatDate(params.value as number)} ${intl.formatTime(params.value as number)}`;
        } catch (e) {
          return "";
        }
      },
    },
  ],
  formComponent: PublishRequestForm,
  getItemTitle: (item) => item.targetTitle,
  getItemDetailsUrl: (itemId) => NavigationUtils.getPublishRequestUrl(itemId),
  generateEmptyItem: () => ({
    id: "",
    creationTime: 0,
    lastUpdateTime: 0,
    userIds: [],
    deleted: false,
    status: EntityPublishStatus.None,
  }),
  generateSaveItem: (item, data) => {
    return {
      ...item,
      ...cleanEntityData(data),
    };
  },
  generateCloneItem: (item) => {
    return item;
  },
  generateEmptyFilters: (): any => undefined,
  generateFullFilters: (): any => undefined,
  hasActiveFilters: (filters: any) => false,
  hasEmptyFilters: (filters: any) => false,
  filterItems: (items, filters) => items,
  filterDataItems: (items) => items,
  searchItems: (items, search) => {
    const cleanedSearch = cleanSearchString(search);
    if (!cleanedSearch) {
      return items;
    }
    return items.filter(
      (item) =>
        item.targetTitle?.toLowerCase().includes(cleanedSearch) || item.fullName?.toLowerCase().includes(cleanedSearch)
    );
  },
});
