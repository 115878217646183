import * as React from "react";
import {MIconButton} from "../../../components/@material-extend";
import {useNavigate} from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import {FormattedMessage} from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import {ComponentType, useCallback, useMemo} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";

export default function BackIcon() {
  const navigate = useNavigate();
  const theme = useTheme();

  const Icon = useMemo<ComponentType<SvgIconProps>>(
    () => (theme.direction === "rtl" ? ArrowForwardOutlined : ArrowBackOutlined),
    [theme.direction]
  );

  const handleClick = useCallback((): void => {
    // Go back
    navigate(-1);
  }, []);

  return (
    <Tooltip title={<FormattedMessage id="back" />}>
      <MIconButton onClick={handleClick}>
        <Icon fontSize={"small"} />
      </MIconButton>
    </Tooltip>
  );
}
