import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Page from "../../components/common/Page";
import {FormattedMessage} from "react-intl";
import ComingSoonIllustration from "../../components/svg/ComingSoonIllustration";

export default function ComingSoon() {
  return (
    <Page>
      <Container>
        <Stack direction="column" alignItems="center">
          <Typography variant="h3" paragraph sx={{mt: 3, color: "text.primary"}}>
            <FormattedMessage id="comingSoon" />
          </Typography>
          <Typography sx={{width: 320, textAlign: "center", color: "text.secondary"}}>
            <FormattedMessage id="comingSoonExplanation" />
          </Typography>

          <Box sx={{my: 4}}>
            <ComingSoonIllustration sx={{height: 260}} />
          </Box>
        </Stack>
      </Container>
    </Page>
  );
}
