import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import {getDocumentText} from "../../../helpers/RangeUtils";

export interface TextLoaderProps {
  active?: boolean;
  onTextLoaded: (text: string) => void;
}

const TextLoader: FunctionComponent<TextLoaderProps> = ({active = true, onTextLoaded}) => {
  const [refresh, setRefresh] = useState<number>(0);
  const [lastLoadTime, setLastLoadTime] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh((currentRefresh) => currentRefresh + 1);
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!active) {
      return;
    }

    if (loading) {
      return;
    }

    if (new Date().getTime() - lastLoadTime < 1000) {
      return;
    }

    (async () => {
      setLoading(true);

      const text = await getDocumentText();
      onTextLoaded(text);

      setLoading(false);
      setLastLoadTime(new Date().getTime());
    })();
  }, [refresh]);

  return null;
};

export default TextLoader;
