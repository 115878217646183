import * as React from "react";
import {FunctionComponent, useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {useSnackbar} from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAppSelector} from "../../redux/hooks";
import {BaseEntityRO, Entity, EntityPublishStatus, PublishableEntityRO} from "../../types/entity";
import {selectUserId, selectUserProfile} from "../../redux/auth/selector";
import {isString, trim} from "lodash";
import Alert from "@mui/material/Alert";
import UserProfileDialog from "./UserProfileDialog";
import {PublishRequest} from "../../types/internal";
import useEntity from "../../contexts/entity/hooks/useEntity";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {publicUserId} from "../../contexts/entity-crud/EntityCrudContext";
import {editRequestEntityId} from "../../entities/edit-request.entity";
import TextField from "@mui/material/TextField";

export type EditEntityItemDialogProps = {
  entityId: Entity<PublishableEntityRO, any> | string;
  item: BaseEntityRO;
};

const EditEntityItemDialog: FunctionComponent<EditEntityItemDialogProps> = NiceModal.create(({entityId, item}) => {
  const modal = useModal();
  const intl = useIntl();
  const {getEntity} = useEntity();
  const {saveItem} = useEntityCrud();
  const {enqueueSnackbar} = useSnackbar();

  const userProfile = useAppSelector(selectUserProfile);
  const userId = useAppSelector(selectUserId);

  const [saving, setSaving] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");

  const entity = useMemo<Entity<PublishableEntityRO, any>>(
    () => (isString(entityId) ? getEntity(entityId) : entityId),
    [entityId]
  );
  const editRequestEntity = useMemo<Entity<PublishRequest, any>>(() => getEntity(editRequestEntityId), []);

  const hasName = useMemo<boolean>(() => !!trim(userProfile?.fullName), [userProfile]);
  const submitDisabled = useMemo<boolean>(
    () => !hasName || !item.id || item.userIds?.includes(userId),
    [hasName, item, userId]
  );

  const updateNameHandler = useCallback((): void => {
    NiceModal.show(UserProfileDialog, {});
  }, []);

  const submitHandler = useCallback(async (): Promise<void> => {
    if (submitDisabled) {
      return;
    }

    setSaving(true);

    const editRequest = editRequestEntity.generateEmptyItem();
    editRequest.userIds = [userId, publicUserId];
    editRequest.status = EntityPublishStatus.WaitingForApproval;
    editRequest.targetId = item.id;
    editRequest.targetEntityId = entity.id;
    editRequest.targetTitle = entity.getItemTitle(item);
    editRequest.fullName = userProfile.fullName;
    editRequest.reason = reason;
    const savedEditRequest = await saveItem(editRequestEntity, editRequest);

    if (savedEditRequest) {
      enqueueSnackbar(<FormattedMessage id="editRequestSuccess" />, {variant: "success"});
    }

    setSaving(false);

    modal.hide();
  }, [submitDisabled, entity, editRequestEntity, item, userProfile, userId, reason, modal, saveItem]);

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleEnhanced onClose={modal.hide}>
        <FormattedMessage id={"sendEditRequest"} />
      </DialogTitleEnhanced>
      <DialogContent>
        <Stack spacing={2}>
          {!hasName && (
            <Alert
              severity={"info"}
              variant={"outlined"}
              action={
                <Button color={"info"} size={"small"} variant={"outlined"} onClick={updateNameHandler}>
                  <FormattedMessage id="updateName" />
                </Button>
              }
            >
              <FormattedMessage id="editNameRequired" />
            </Alert>
          )}

          <TextField
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            label={intl.formatMessage({id: "comments"})}
            type="text"
            inputProps={{
              maxLength: 250,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={modal.hide}>
          <FormattedMessage id="close" />
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitHandler}
          loading={saving}
          disabled={submitDisabled}
        >
          <FormattedMessage id={"submit"} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default EditEntityItemDialog;
