import {NegateKeyword, NegateKeywordType} from "../types/internal";
import {listItemPrefix} from "../helpers/IntakeTemplateUtils";

export const defaultNegateKeywords: NegateKeyword[] = [
  {
    uuid: "8deab217-be52-45b7-bf14-e7ad519b625e",
    locale: "en",
    keyword: listItemPrefix,
    type: NegateKeywordType.Sentence,
  },
  {
    uuid: "aee31d01-6e97-47c6-981f-632340d3d22f",
    locale: "en",
    keyword: "no",
    type: NegateKeywordType.Word,
  },
  {
    uuid: "1b331df7-16ff-44cb-91f8-d379fc7da62a",
    locale: "en",
    keyword: "not",
    type: NegateKeywordType.Word,
  },
  {
    uuid: "fe7fd61e-3355-4eb4-8844-c2f8e8462f8f",
    locale: "he",
    keyword: listItemPrefix,
    type: NegateKeywordType.Sentence,
  },
  {
    uuid: "9aecbdf8-7da6-4a9c-b8bf-7897a67ca855",
    locale: "he",
    keyword: "לא",
    type: NegateKeywordType.Word,
  },
  {
    uuid: "77f8030b-b49a-4bdc-9416-adde2ffa4a6a",
    locale: "he",
    keyword: "ללא",
    type: NegateKeywordType.Word,
  },
  {
    uuid: "6cc85552-f219-4841-9bd8-e2acc6c65672",
    locale: "he",
    keyword: "שולל",
    type: NegateKeywordType.Word,
  },
  {
    uuid: "f2ffb04f-5608-44f3-9de8-ba6f0cb427f4",
    locale: "he",
    keyword: "בלי",
    type: NegateKeywordType.Word,
  },
];
