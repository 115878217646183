import * as React from "react";
import {FunctionComponent, useCallback} from "react";
import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import {Term, TermClause, StageType} from "../../types/internal";
import useActivePrep from "../../contexts/active-prep/hooks/useActivePrep";

export interface AddToPrepButtonProps {
  term: Term;
  stageType: StageType;
  clauses: TermClause[];
}

const AddToPrepButton: FunctionComponent<AddToPrepButtonProps> = ({term, stageType, clauses}) => {
  const {addClauses} = useActivePrep();

  const clickHandler = useCallback((): void => {
    addClauses(stageType, term, clauses);
  }, [clauses, addClauses]);

  return (
    <Button color="primary" variant="outlined" disableElevation onClick={clickHandler}>
      <FormattedMessage id="addToPrep" />
    </Button>
  );
};

export default AddToPrepButton;
