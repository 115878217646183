import * as React from "react";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardProps} from "@mui/material/Card";
import {FormattedMessage} from "react-intl";
import SupportIllustration from "../../svg/SupportIllustration";
import {urls} from "../../../routes/urls";
import {Link as RouterLink} from "react-router-dom";

const RootStyle = styled(Card)(({theme}) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface HomeSupportProps extends CardProps {}

export default function HomeSupport({}: HomeSupportProps) {
  return (
    <RootStyle>
      <CardContent sx={{height: "100%"}}>
        <Typography gutterBottom variant="h4">
          <FormattedMessage id="support" />
        </Typography>

        <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: "auto"}}>
          <FormattedMessage id="supportExplanation" />
        </Typography>

        <Button variant="outlined" to={urls.support.url} component={RouterLink}>
          <FormattedMessage id="contactUs" />
        </Button>
      </CardContent>

      <SupportIllustration
        sx={{
          p: 3,
          pl: 0,
          width: 240,
          height: 200,
          margin: {xs: "auto", md: "inherit"},
        }}
      />
    </RootStyle>
  );
}
