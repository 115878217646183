import print, {Configuration} from "print-js";

export const printRawHtml = (html: string, direction: "ltr" | "rtl"): void => {
  const printConfiguration: Configuration = {
    type: "raw-html",
    printable: html,
    style: `html,body {direction: ${direction};}`,
  };
  print(printConfiguration);
};
