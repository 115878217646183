import * as React from "react";
import {forwardRef, ReactNode, useEffect} from "react";
import Box from "@mui/material/Box";
import {BoxProps} from "@mui/material/Box";
import {analytics} from "../../firebase/firebase";
import {logEvent} from "firebase/analytics";
import {useLocation} from "react-router-dom";

interface PageProps extends BoxProps {
  children: ReactNode;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({children, ...other}, ref) => {
  const {pathname} = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: pathname,
    });
  }, []);

  return (
    <Box ref={ref} {...other}>
      {children}
    </Box>
  );
});

export default Page;
