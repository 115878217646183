import * as React from "react";
import {FormattedMessage} from "react-intl";
import {FunctionComponent, ReactNode, useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import CopyClipboard from "../common/CopyClipboard";
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {isString} from "lodash";
import {useSnackbar} from "notistack";

export type ShareDialogProps = {
  url: string | (() => Promise<string>);
  title?: string | ReactNode;
};

const ShareDialog: FunctionComponent<ShareDialogProps> = NiceModal.create(({url, title}) => {
  const modal = useModal();
  const {enqueueSnackbar} = useSnackbar();

  const [shareUrl, setShareUrl] = useState<string>(isString(url) ? url : "");

  useEffect(() => {
    if (isString(url)) {
      setShareUrl(url);
    } else {
      setShareUrl("");
      (async () => {
        const generatedUrl = await url();
        if (generatedUrl) {
          setShareUrl(generatedUrl);
        } else {
          enqueueSnackbar(<FormattedMessage id="shareFailed" />, {variant: "error"});
          modal.hide();
        }
      })();
    }
  }, [url]);

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleEnhanced onClose={modal.hide}>{title || <FormattedMessage id="share" />}</DialogTitleEnhanced>
      <DialogContent>
        {shareUrl ? (
          <>
            <CopyClipboard value={shareUrl} />
            <Stack direction="row" spacing={1} sx={{mt: 2}}>
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <TelegramShareButton url={shareUrl}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <RedditShareButton url={shareUrl}>
                <RedditIcon size={32} round />
              </RedditShareButton>
            </Stack>
          </>
        ) : (
          <Box sx={{textAlign: "center"}}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default ShareDialog;
