import * as React from "react";
import {useIntl} from "react-intl";
import SearchTextField, {SearchTextFieldProps} from "./SearchTextField";
import {FilterAltOutlined} from "@mui/icons-material";

export type FilterTextFieldProps = SearchTextFieldProps;

const FilterTextField = (props: FilterTextFieldProps) => {
  const intl = useIntl();
  return <SearchTextField placeholder={intl.formatMessage({id: "filter"})} icon={FilterAltOutlined} {...props} />;
};

export default FilterTextField;
