import * as React from "react";
import {Term, TermClause, StageType, MedicalField} from "../../../../types/internal";
import {Controller, get, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import {getStageTypeLabelId, getStageTypes} from "../../../../helpers/TermUtils";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import ExpandMore from "../../../common/ExpandMore";
import Collapse from "@mui/material/Collapse";
import {isEmpty} from "lodash";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TermClauseFormSortable from "./TermClauseFormSortable";
import {useSnackbar} from "notistack";
import {maxTermClauses} from "../../../../constants/defaultValues";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SortableDndContext from "../../../dnd/SortableDndContext";
import {getMedicalFieldLabelId} from "../../../../helpers/MedicalFieldUtils";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import AddOutlined from "@mui/icons-material/AddOutlined";
import Link from "@mui/material/Link";

export interface TermStageFormProps {
  id: string;
  index: number;
  remove: (index?: number | number[]) => void;
  startAction?: ReactNode;
  disabled?: boolean;
  unmountOnExit?: boolean;
  sx?: SxProps<Theme>;
}

const TermStageForm = ({index, remove: removeGroup, startAction, disabled, unmountOnExit, sx}: TermStageFormProps) => {
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();

  const {
    control,
    formState: {errors, isSubmitting},
    setFocus,
  } = useFormContext<Term>();
  const {fields, insert, remove, move} = useFieldArray<Term>({name: `stages.${index}.clauses`, control});

  const [expanded, setExpanded] = useState<boolean>(false);

  const medicalFields: MedicalField[] | undefined = useWatch({control: control, name: `medicalFields`});
  const stageMedicalFields: MedicalField[] | undefined = useWatch({
    control: control,
    name: `stages.${index}.medicalFields`,
  });
  const stageType: StageType = useWatch({control: control, name: `stages.${index}.type`});

  const availableStageTypes = useMemo<StageType[]>(() => {
    const filterMedicalFields = stageMedicalFields && !isEmpty(stageMedicalFields) ? stageMedicalFields : medicalFields;
    return getStageTypes(filterMedicalFields, stageType);
  }, [medicalFields, stageMedicalFields, stageType]);

  const handleExpandClick = useCallback((): void => {
    setExpanded((currentExpanded) => !currentExpanded);
  }, [setExpanded]);

  useEffect(() => {
    if (get(errors, `stages.${index}.clauses`)) {
      setExpanded(true);
    }
  }, [isSubmitting]);

  const addClauseDisabled = useMemo<boolean>(() => fields.length >= maxTermClauses, [fields]);
  const stageSubtitle = useMemo<string>(() => `(${fields.length})`, [fields]);

  const getNewClauseDefaultValue = useCallback((text?: string, indent?: number): TermClause => {
    return {
      text: text || "",
      tooltip: "",
      medicalFields: [],
      checked: true,
      indent: indent || 0,
    };
  }, []);

  const [focusDetailIndex, setFocusDetailIndex] = useState<number>(-1);

  useEffect(() => {
    if (focusDetailIndex >= 0) {
      if (focusDetailIndex < fields.length) {
        setFocus(`stages.${index}.clauses.${focusDetailIndex}.text`);
      }
      setFocusDetailIndex(-1);
    }
  }, [focusDetailIndex]);

  const insertClauseHandler = useCallback(
    (index: number, texts?: string[], indent?: number): void => {
      if (fields.length + (texts?.length || 1) > maxTermClauses) {
        enqueueSnackbar(<FormattedMessage id="reachedMaximumAllowed" values={{max: maxTermClauses}} />, {
          variant: "info",
        });
        return;
      }

      insert(
        index,
        texts ? texts.map<TermClause>((text) => getNewClauseDefaultValue(text, indent)) : getNewClauseDefaultValue()
      );
      setExpanded(true);

      if (texts) {
        setFocusDetailIndex(index + texts.length - 1);
      }
    },
    [insert, fields]
  );

  const removeClauseHandler = useCallback(
    (removeIndex: number, focusPrevious: boolean): void => {
      remove(removeIndex);
      if (focusPrevious && removeIndex > 0) {
        setFocusDetailIndex(removeIndex - 1);
      }
    },
    [remove, index]
  );

  const addClauseHandler = useCallback((): void => {
    insertClauseHandler(fields.length);
  }, [insertClauseHandler]);

  return (
    <Card sx={{...sx}}>
      <CardContent>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {startAction}

          <Controller
            name={`stages.${index}.type`}
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "type"})}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                  select
                >
                  {availableStageTypes.map((t) => (
                    <MenuItem key={t} value={t}>
                      <FormattedMessage id={getStageTypeLabelId(t)} />
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Tooltip title={<FormattedMessage id="removeStage" />}>
            <span>
              <IconButton color={"error"} onClick={() => removeGroup(index)} disabled={disabled}>
                <DeleteOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>

        <Controller
          name={`stages.${index}.medicalFields`}
          control={control}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={intl.formatMessage({id: "medicalFields"})}
                error={invalid}
                helperText={error?.message || <FormattedMessage id="selectNonForAll" />}
                disabled={disabled}
                select
                value={field?.value || []}
                SelectProps={{
                  multiple: true,
                  renderValue: (value: MedicalField[]) =>
                    value
                      .map((medicalField) => intl.formatMessage({id: getMedicalFieldLabelId(medicalField)}))
                      .join(", "),
                }}
                sx={{mt: 3}}
              >
                {Object.values(MedicalField).map((m) => (
                  <MenuItem key={m} value={m}>
                    <FormattedMessage id={getMedicalFieldLabelId(m)} />
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />

        <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{mt: 3}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="h6" sx={{display: "inline-block"}}>
              <Link onClick={handleExpandClick} sx={{color: "text.primary", cursor: "pointer"}}>
                <FormattedMessage id="clauses" />
              </Link>
            </Typography>
            &nbsp;
            <Typography variant="h6" sx={{display: "inline-block", color: "text.secondary"}}>
              {stageSubtitle}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            startIcon={<AddOutlined />}
            disabled={addClauseDisabled || disabled}
            onClick={addClauseHandler}
          >
            <FormattedMessage id="addNewClause" />
          </Button>

          <ExpandMore expand={expanded} onClick={handleExpandClick} color={"primary"} />
        </Stack>

        <Collapse in={expanded} timeout="auto" unmountOnExit={unmountOnExit}>
          <SortableDndContext items={fields} move={move}>
            <Stack spacing={3} sx={{mt: 3}}>
              {fields.map((field, detailIndex) => (
                <TermClauseFormSortable
                  id={field.id}
                  groupIndex={index}
                  index={detailIndex}
                  disabled={disabled}
                  remove={removeClauseHandler}
                  insert={insertClauseHandler}
                  focus={setFocusDetailIndex}
                  key={field.id}
                />
              ))}
            </Stack>
          </SortableDndContext>

          {isEmpty(fields) && (
            <Typography variant="body2" sx={{color: "text.secondary"}}>
              <FormattedMessage id="noResultsFound" />
            </Typography>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default TermStageForm;
