import * as React from "react";
import {FunctionComponent, useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import Button from "@mui/material/Button";
import {MedicalField} from "../../../types/internal";
import {getMedicalFieldIcon, getMedicalFieldLabelId} from "../../../helpers/MedicalFieldUtils";
import ButtonGroup from "@mui/material/ButtonGroup";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";

export interface MedicalFieldButtonGroupProps {
  toggle?: boolean;
  onMedicalFieldSelected: (medicalField: MedicalField) => void;
  sx?: SxProps<Theme>;
}

const MedicalFieldButtonGroup: FunctionComponent<MedicalFieldButtonGroupProps> = ({
  toggle = false,
  onMedicalFieldSelected,
  sx,
}) => {
  const [selectedMedicalField, setSelectedMedicalField] = useState<MedicalField | undefined>(undefined);

  const medicalFieldSelectedHandler = useCallback(
    (medicalField: MedicalField): void => {
      if (toggle) {
        setSelectedMedicalField(medicalField);
      }
      onMedicalFieldSelected(medicalField);
    },
    [toggle, onMedicalFieldSelected, setSelectedMedicalField]
  );

  return (
    <ButtonGroup orientation="vertical" sx={{...sx}}>
      {Object.values(MedicalField).map((field) => {
        const Icon = getMedicalFieldIcon(field);
        return (
          <Button
            variant={selectedMedicalField === field ? "contained" : "outlined"}
            startIcon={<Icon />}
            onClick={() => medicalFieldSelectedHandler(field)}
            key={field}
          >
            <FormattedMessage id={getMedicalFieldLabelId(field)} />
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default MedicalFieldButtonGroup;
