import {urls} from "../routes/urls";

export const underMaintenance = false;

export const defaultAppUrl = urls.home.url;
export const addInDownloadUrl = "https://appsource.microsoft.com/en-us/product/office/WA200003654?tab=Overview";
export const supportEmail = "support@shelpi.app";
export const siteUrl = "https://shelpi.app/";
export const additionalSiteUrls = ["https://officemed-250bb.firebaseapp.com/", "https://officemed-250bb.web.app/"];
export const upgradeOfficeUrl =
  "https://docs.microsoft.com/en-us/office/dev/add-ins/concepts/browsers-used-by-office-web-add-ins";

export const maxTermStages = 12;
export const maxTermClauses = 100;
export const maxIntakeTemplateSections = 100;
export const maxIntakeTemplateContents = 100;
export const maxPrepStages = 12;
export const maxPrepClauses = 100;
export const maxNegateKeywords = 10;

export const intakeTemplateColorExample = "#ff0000";
export const intakeTemplateColorAssistNotes = "#0070c0";
export const intakeTemplateColorExtendedFormat = "#6aa84f";

// PROD project
export const firebaseConfig = {
  apiKey: "AIzaSyAjAsMrusQOT1z4bvl2IyZxvJ1asjkbG88",
  authDomain: "officemed-250bb.firebaseapp.com",
  projectId: "officemed-250bb",
  storageBucket: "officemed-250bb.appspot.com",
  messagingSenderId: "218499706414",
  appId: "1:218499706414:web:cbbd4571d7b9c3f62c79bf",
  measurementId: "G-C6EWMGWB04",
};
// DEV project
// export const firebaseConfig = {
//   apiKey: "AIzaSyDCn8Mg8dRmPS9Nf3gsL_TGYt1Wwx7AHRU",
//   authDomain: "officemeddev.firebaseapp.com",
//   projectId: "officemeddev",
//   storageBucket: "officemeddev.appspot.com",
//   messagingSenderId: "935041186343",
//   appId: "1:935041186343:web:12f21c653888ed63a0ec02",
// };
export const recaptchaSiteKey = "6Lffcg0eAAAAADJrHNyOFf_NPPJDin_-ivvBWpb1";

export const activeLocales = ["he"]; //, "en"];
export const defaultLocale = activeLocales[0];

const getNavigatorLanguage = (): string => {
  const navigator: any = window.navigator;
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || defaultLocale;
  }
};

export const getUserDefaultLocale = (): string => {
  if (activeLocales.length === 1) {
    return activeLocales[0];
  }

  let navigatorLanguage = getNavigatorLanguage();
  if (navigatorLanguage && navigatorLanguage.length > 2) {
    navigatorLanguage = navigatorLanguage.substring(0, 2).toLowerCase();
    if (navigatorLanguage === "iw") {
      navigatorLanguage = "he";
    }
    if (activeLocales.includes(navigatorLanguage)) {
      return navigatorLanguage;
    }
  }

  return defaultLocale;
};
