import {chain, isEmpty, some, trim} from "lodash";
import {reverseString} from "./StringUtils";
import {NegateKeywordType} from "../types/internal";

// Not working because safari does not support negative lookbehind
// export const buildKeywordsRegex = (keywords: string[], negateKeywords?: string[]): RegExp => {
//   if (negateKeywords && !isEmpty(negateKeywords)) {
//     return new RegExp(`(?<!${negateKeywords.join("|")})(?<!\\s)\\s*(${keywords.join("|")})`, "gi");
//   }
//   return new RegExp(keywords.join("|"), "gi");
// };

export const buildReversedKeywordsRegex = (
  keywords: string[],
  negateKeywords?: {keyword: string; type: NegateKeywordType}[],
  addFollowingCharacters?: boolean
): RegExp => {
  const keywordsRegex = `(${keywords.map<string>((s) => reverseString(s)).join("|")})`;
  const followingCharactersRegex = `${addFollowingCharacters ? "[a-zA-Zא-ת]*" : ""}`;

  const negateKeywordsWord = chain(negateKeywords || [])
    .filter((keyword) => keyword.type === NegateKeywordType.Word && !!trim(keyword.keyword))
    .uniq()
    .filter(
      (keyword, i, arr) => !some(arr, (k) => keyword.keyword !== k.keyword && keyword.keyword.endsWith(k.keyword))
    )
    .value();
  const hasNegateKeywordsWord = !!negateKeywordsWord && !isEmpty(negateKeywordsWord);
  const negateKeywordsWordRegex = hasNegateKeywordsWord
    ? `(?!\\s*(${negateKeywordsWord.map<string>((n) => reverseString(n.keyword)).join("|")}))`
    : "";

  const negateKeywordsSentence = chain(negateKeywords || [])
    .filter((keyword) => keyword.type === NegateKeywordType.Sentence && !!trim(keyword.keyword))
    .uniq()
    .filter(
      (keyword, i, arr) => !some(arr, (k) => keyword.keyword !== k.keyword && keyword.keyword.endsWith(k.keyword))
    )
    .value();
  const hasNegateKeywordsSentence = !!negateKeywordsSentence && !isEmpty(negateKeywordsSentence);
  const negateKeywordsSentenceRegex = hasNegateKeywordsSentence
    ? `(?![^\r]*(${negateKeywordsSentence.map<string>((n) => reverseString(n.keyword)).join("|")}))`
    : "";

  return new RegExp(
    `${followingCharactersRegex}${keywordsRegex}${negateKeywordsWordRegex}${negateKeywordsSentenceRegex}`,
    "gi"
  );
};

export const buildReversedKeywordsString = (str: string): string => {
  return reverseString(str);
};
