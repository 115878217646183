import {
  ContentType,
  ContentTypePayloadList,
  ContentTypePayloadListItem,
  ContentTypePayloadTable,
  ContentTypePayloadTableCell,
  IntakeTemplate,
  IntakeTemplateContent,
  IntakeTemplateSection,
  StageType,
} from "../types/internal";
import {IntlShape} from "react-intl";
import {getStageTypeLabelId} from "./TermUtils";
import {
  intakeTemplateColorAssistNotes,
  intakeTemplateColorExample,
  intakeTemplateColorExtendedFormat,
} from "../constants/defaultValues";
import {repeat, trim} from "lodash";
import {replaceAll} from "./StringUtils";

export const sectionTitlePrefix = "\u200B";
export const listItemPrefix = "-";
export const listItemPermanentPrefix = "#";
export const fillCharacter = "@";
export const htmlFontStyle = "font-family: sans-serif;";
export const indentCharacters = "&nbsp;&nbsp;&nbsp;&nbsp;";

export type IntakeTemplateHtmlOptions = {
  direction?: "ltr" | "rtl";
  example?: boolean;
  assistNotes?: boolean;
  extendedFormat?: boolean;
  medicalRecordFormat?: boolean;
};

export const getContentTypeLabelId = (type: ContentType): string => {
  switch (type) {
    case ContentType.Text:
      return "contentTypeText";
    case ContentType.List:
      return "contentTypeList";
    case ContentType.Table:
      return "contentTypeTable";
  }
};

export const getDirectionHtmlAttributes = (direction?: "ltr" | "rtl"): string => {
  // return direction === "rtl" ? 'dir="rtl" style="direction: rtl;"' : "";
  return direction === "rtl" ? ' dir="rtl" ' : "";
};

export const getContentFlagsStyle = (options: {
  example?: boolean;
  assistNotes?: boolean;
  extendedFormat?: boolean;
  medicalRecordFormat?: boolean;
}): string => {
  let style = "";

  let color = "";
  if (options.extendedFormat) {
    color = intakeTemplateColorExtendedFormat;
  }
  if (options.assistNotes) {
    color = intakeTemplateColorAssistNotes;
  }
  if (options.example) {
    color = intakeTemplateColorExample;
  }
  if (color) {
    style += `color: ${color};`;
  }

  if (options.assistNotes && !options.example && !options?.medicalRecordFormat) {
    style += `font-size: 9pt;`;
  }

  style += htmlFontStyle;

  return style;
};

export const getContentFlagsHtmlAttribute = (options: {
  example?: boolean;
  assistNotes?: boolean;
  extendedFormat?: boolean;
  medicalRecordFormat?: boolean;
}): string => {
  const style = getContentFlagsStyle(options);
  return style ? `style="${style}"` : style;
};

export const getIntakeTemplateHtml = (
  template: IntakeTemplate,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  const directionHtmlAttributes = getDirectionHtmlAttributes(options?.direction);
  return `<div ${directionHtmlAttributes} style="${htmlFontStyle}">${getIntakeTemplateHtmlTitle(
    template,
    intl,
    options
  )}${template.sections
    .map<string>((section) => getIntakeTemplateHtmlSection(section, intl, options))
    .join("")}<br/></div>`;
};

export const getIntakeTemplateHtmlTitle = (
  template: IntakeTemplate,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  return options?.medicalRecordFormat
    ? `<div>${template.title}</div><br/>`
    : `<h1 style="text-align: center">${template.title}</h1>`;
};

export const getIntakeTemplateHtmlSection = (
  section: IntakeTemplateSection,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  if (
    (section.extendedFormat && !options?.extendedFormat) ||
    (section.medicalRecordFormat && !options?.medicalRecordFormat)
  ) {
    return "";
  }

  const contentFlagsHtmlAttributes = getContentFlagsHtmlAttribute({
    ...section,
    medicalRecordFormat: options?.medicalRecordFormat,
  });

  const directionHtmlAttributes = getDirectionHtmlAttributes(options?.direction);
  return `<div ${directionHtmlAttributes} ${contentFlagsHtmlAttributes}>${getIntakeTemplateHtmlSectionTitle(
    section,
    intl,
    options
  )}${section.contents?.map<string>((content) => getIntakeTemplateHtmlContent(content, intl, options)).join("")}${
    section.pageBreak ? `<br/>` : "" //section.pageBreak ? '<br style="page-break-before: always">' : options?.medicalRecordFormat ? `<br/>` : ""
  }<br/></div>`;
};

export const getIntakeTemplateHtmlSectionTitle = (
  section: IntakeTemplateSection,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  if (section.type === StageType.Other && !section.title) {
    return "";
  }

  const titleText =
    section.type === StageType.Other ? section.title : intl.formatMessage({id: getStageTypeLabelId(section.type)});

  return options?.medicalRecordFormat
    ? `<div>${sectionTitlePrefix}${titleText}<br/>${repeat("=", trim(titleText).length)}</div>`
    : `<h2>${sectionTitlePrefix}${titleText}</h2>`;
};

export const getIntakeTemplateHtmlContent = (
  content: IntakeTemplateContent,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  if (
    (content.example && !options?.example) ||
    (content.assistNotes && !options?.assistNotes) ||
    (content.extendedFormat && !options?.extendedFormat)
  ) {
    return "";
  }

  const directionHtmlAttributes = getDirectionHtmlAttributes(options?.direction);
  const contentFlagsHtmlAttributes = getContentFlagsHtmlAttribute({
    ...content,
    medicalRecordFormat: options?.medicalRecordFormat,
  });
  return `<div ${directionHtmlAttributes} ${contentFlagsHtmlAttributes}>${getIntakeTemplateHtmlContentTitle(
    content,
    intl,
    options
  )}${getIntakeTemplateHtmlContentPayload(content, intl, options)}</div>`;
};

export const getIntakeTemplateHtmlContentTitle = (
  content: IntakeTemplateContent,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  if (!content.title) {
    return "";
  }

  return options?.medicalRecordFormat ? `<div>${content.title}:</div>` : `<h3>${content.title}</h3>`;
};

export const getIntakeTemplateHtmlContentPayload = (
  content: IntakeTemplateContent,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  switch (content.type) {
    case ContentType.Text:
      return replaceAll(content.payload, "\n", "<br/>");
    case ContentType.List:
      return getIntakeTemplateHtmlContentPayloadList(content, intl, options);
    case ContentType.Table:
      return getIntakeTemplateHtmlContentPayloadTable(content, intl, options);
  }
  return "";
};

export const getIntakeTemplateHtmlContentPayloadList = (
  content: IntakeTemplateContent,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  if (!content.payload) {
    return "";
  }

  try {
    const payload: ContentTypePayloadList = JSON.parse(content.payload);
    const items: ContentTypePayloadListItem[] = payload.items.filter(
      (item) =>
        (!item.example || options?.example) &&
        (!item.assistNotes || options?.assistNotes) &&
        (!item.extendedFormat || options?.extendedFormat)
    );
    return `${items
      .map((item) => {
        const contentFlagsHtmlAttributes = getContentFlagsHtmlAttribute({
          ...item,
          medicalRecordFormat: options?.medicalRecordFormat,
        });
        return `<span ${contentFlagsHtmlAttributes}>${item.indent ? repeat(indentCharacters, item.indent) : ""}${
          payload.permanent ? listItemPermanentPrefix : listItemPrefix
        } ${item.payload}</span>`;
      })
      .join("<br/>")}`;
  } catch (e) {
    return "";
  }
};

export const getIntakeTemplateHtmlContentPayloadTable = (
  content: IntakeTemplateContent,
  intl: IntlShape,
  options?: IntakeTemplateHtmlOptions
): string => {
  if (!content.payload) {
    return "";
  }

  try {
    const payload: ContentTypePayloadTable = JSON.parse(content.payload);

    let html = `<table style="width: 100%;">`;
    for (let row = 0; row < payload.rows; row++) {
      html += `<tr>`;
      for (let column = 0; column < payload.columns; column++) {
        const cell: ContentTypePayloadTableCell | undefined = payload.cells[getTableCellKey(row, column)];
        const tag = cell?.title ? "th" : "td";
        const showCell =
          cell?.payload &&
          (!cell.example || options?.example) &&
          (!cell.assistNotes || options?.assistNotes) &&
          (!cell.extendedFormat || options?.extendedFormat);
        const colorStyle = showCell ? getContentFlagsStyle(cell) : "";
        html += `<${tag} style="border: 1px solid #000; border-collapse: collapse; ${colorStyle}">`;
        if (showCell) {
          html += cell?.payload || "";
        }
        html += `&nbsp;`;
        html += `</${tag}>`;
      }
      html += `</tr>`;
    }
    html += `</table>`;
    return html;
  } catch (e) {
    return "";
  }
};

export const getTableCellKey = (row: number, column: number): string => {
  return `${row}_${column}`;
};
