import * as React from "react";
import {FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Page from "../../components/common/Page";
import {FormattedMessage} from "react-intl";
import ComingSoonIllustration from "../../components/svg/ComingSoonIllustration";
import {IntakeTemplate, MedicalField, Prep} from "../../types/internal";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Stepper from "@mui/material/Stepper";
import MedicalFieldButtonGroup from "../../components/pages/new-intake/MedicalFieldButtonGroup";
import IntakeTemplateButtonGroup from "../../components/pages/new-intake/IntakeTemplateButtonGroup";
import IntakeTemplateGeneratorForm from "../../components/pages/new-intake/IntakeTemplateGeneratorForm";
import StepButton from "@mui/material/StepButton";
import useOffice from "../../contexts/office/hooks/useOffice";
import {useLocation} from "react-router-dom";
import {urlParamPrepId} from "../../constants/urlParams";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {prepEntityId} from "../../entities/prep.entity";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export interface NewIntakeProps {}

const NewIntake: FunctionComponent<NewIntakeProps> = ({}) => {
  const {isOfficeActive} = useOffice();
  const {search} = useLocation();
  const {getItem} = useEntityCrud();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedMedicalField, setSelectedMedicalField] = useState<MedicalField | undefined>(undefined);
  const [selectedIntakeTemplate, setSelectedIntakeTemplate] = useState<IntakeTemplate | undefined>(undefined);
  const [prep, setPrep] = useState<Prep | undefined>(undefined);

  const prepId = useMemo<string | undefined>(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get(urlParamPrepId) || undefined;
  }, [search]);

  useEffect(() => {
    if (prepId) {
      (async () => {
        const prep = await getItem<Prep, any>(prepEntityId, prepId);
        setPrep(prep);
      })();
    } else {
      setPrep(undefined);
    }
  }, [prepId]);

  const medicalFieldSelectedHandler = useCallback(
    (medicalField: MedicalField): void => {
      setSelectedMedicalField(medicalField);
      setActiveStep(1);
    },
    [setActiveStep, setSelectedMedicalField]
  );

  const intakeTemplateSelectedHandler = useCallback(
    (intakeTemplate: IntakeTemplate): void => {
      setSelectedIntakeTemplate(intakeTemplate);
      setActiveStep(2);
    },
    [setActiveStep, setSelectedIntakeTemplate]
  );

  const steps = useMemo<{labelId: string; content: ReactNode}[]>(
    () => [
      {
        labelId: "selectMedicalField",
        content: <MedicalFieldButtonGroup onMedicalFieldSelected={medicalFieldSelectedHandler} sx={{width: "100%"}} />,
      },
      {
        labelId: "selectTemplate",
        content: (
          <IntakeTemplateButtonGroup
            medicalField={selectedMedicalField}
            onIntakeTemplateSelected={intakeTemplateSelectedHandler}
            sx={{width: "100%"}}
          />
        ),
      },
      {
        labelId: "templateSettings",
        content: <IntakeTemplateGeneratorForm intakeTemplate={selectedIntakeTemplate} prep={prep} />,
      },
    ],
    [selectedMedicalField, selectedIntakeTemplate, prep]
  );

  return (
    <>
      <Page sx={{height: "100%"}}>
        <Container sx={{height: "100%"}}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h3" paragraph sx={{mt: 3, color: "text.primary"}}>
              <FormattedMessage id="newIntakeTitle" />
            </Typography>
            <Typography sx={{textAlign: "center", color: "text.secondary"}}>
              <FormattedMessage id="newIntakeDescription" />
            </Typography>

            {prep && (
              <Box sx={{mt: 3}}>
                <Typography sx={{textAlign: "center", color: "text.secondary"}}>
                  <FormattedMessage id="newIntakePrep" />
                </Typography>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                  <Typography sx={{color: "text.primary"}}>{prep.title}</Typography>
                  <Tooltip title={<FormattedMessage id="remove" />}>
                    <IconButton color={"inherit"} size={"small"} onClick={() => setPrep(undefined)}>
                      <CloseOutlined fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            )}

            <Box sx={{width: 280, mt: 3}}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepButton
                      onClick={() => setActiveStep((currentActiveStep) => Math.min(index, currentActiveStep))}
                    >
                      <FormattedMessage id={step.labelId} />
                    </StepButton>
                    <StepContent TransitionProps={{unmountOnExit: false}}>
                      <Box>{step.content}</Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {!isOfficeActive && <ComingSoonIllustration sx={{height: 220, mt: 6}} />}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default NewIntake;
