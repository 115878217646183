import * as React from "react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LogoIcon from "../../../components/svg/LogoIcon";
import {urls} from "../../../routes/urls";
import {useCallback} from "react";

export default function HomeIcon() {
  const navigate = useNavigate();

  const handleClick = useCallback((): void => {
    navigate(urls.home.url);
  }, []);

  return (
    <Tooltip title={<FormattedMessage id="homepage" />}>
      <IconButton onClick={handleClick}>
        <LogoIcon />
      </IconButton>
    </Tooltip>
  );
}
