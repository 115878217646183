import {
  ContentType,
  ContentTypePayloadList,
  ContentTypePayloadListItem,
  DataEntityStatus,
  IntakeTemplate,
  IntakeTemplateContent,
  IntakeTemplateSection,
  MedicalField,
  Prep,
  PrepStage,
  StageType,
} from "../types/internal";
import {IntlShape} from "react-intl";
import {getIntakeTemplateHtml, getIntakeTemplateHtmlSection} from "./IntakeTemplateUtils";
import {v4 as uuidv4} from "uuid";

export type PrepHtmlOptions = {
  direction?: "ltr" | "rtl";
  medicalRecordFormat?: boolean;
  hideTitle?: boolean;
};

export const getPrepHtml = (prep: Prep, intl: IntlShape, options?: PrepHtmlOptions): string => {
  return getIntakeTemplateHtml(getPrepIntakeTemplate(prep), intl, options);
};

export const getPrepStageHtml = (prepStage: PrepStage, intl: IntlShape, options?: PrepHtmlOptions): string => {
  return getIntakeTemplateHtmlSection(
    {...getPrepStageIntakeTemplateSection(prepStage, options), pageBreak: true},
    intl,
    options
  );
};

export const getPrepIntakeTemplate = (prep: Prep, options?: PrepHtmlOptions): IntakeTemplate => {
  const now = new Date().getTime();
  return {
    id: "",
    creationTime: now,
    lastUpdateTime: now,
    userIds: [],
    deleted: false,
    title: options?.hideTitle ? "" : prep.title,
    status: DataEntityStatus.Published,
    medicalField: prep.medicalField || MedicalField.InternalMedicine,
    sections: prep.stages.map((prepStage) => getPrepStageIntakeTemplateSection(prepStage)),
  };
};

export const getPrepStageIntakeTemplateSection = (
  prepStage: PrepStage,
  options?: PrepHtmlOptions
): IntakeTemplateSection => {
  return {
    type: options?.hideTitle ? StageType.Other : prepStage.type,
    title: undefined,
    contents: [
      ...prepStage.terms.map<IntakeTemplateContent>((term) => {
        const payload: ContentTypePayloadList = {
          items: term.clauses.map<ContentTypePayloadListItem>((clause) => ({
            uuid: uuidv4(),
            indent: clause.indent,
            payload: getClauseText(clause),
          })),
          permanent: false,
        };
        return {type: ContentType.List, title: term.title, payload: JSON.stringify(payload)};
      }),
      ...(prepStage.comments ? [{type: "Text" as ContentType, payload: prepStage.comments}] : []),
    ],
  };
};

export const getClauseText = (clause: {text?: string; tooltip?: string}): string => {
  return `${clause.text}${clause.tooltip ? ` (${clause.tooltip})` : ""}`;
};
