import * as React from "react";
import {useCallback} from "react";
import copy from "copy-to-clipboard";
import {useSnackbar} from "notistack";
import {FormattedMessage} from "react-intl";

const useCopyToClipboard = (): ((text: string, format?: "text/html" | "text/plain") => void) => {
  const {enqueueSnackbar} = useSnackbar();

  const onCopy = useCallback(
    (clipboardData: object): void => {
      if (clipboardData) {
        enqueueSnackbar(<FormattedMessage id="copiedSuccessfully" />, {variant: "success"});
      }
    },
    [enqueueSnackbar]
  );

  const copyToClipboard = useCallback((text: string, format?: "text/html" | "text/plain"): void => {
    copy(text, {onCopy: onCopy, format: format});
  }, []);

  return copyToClipboard;
};

export default useCopyToClipboard;
