import * as React from "react";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardProps} from "@mui/material/Card";
import {FormattedMessage} from "react-intl";
import SearchIllustration from "../../svg/SearchIllustration";
import {Link as RouterLink} from "react-router-dom";
import {urls} from "../../../routes/urls";
import useNavigateLoggedIn from "../../../helpers/useNavigateLoggedIn";
import {useCallback} from "react";
import Stack from "@mui/material/Stack";
import useEntityNavigation from "../../../helpers/useEntityNavigation";
import {termEntityId} from "../../../entities/term.entity";

const RootStyle = styled(Card)(({theme}) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface HomeSearchProps extends CardProps {}

export default function HomeSearch({}: HomeSearchProps) {
  const navigateLoggedIn = useNavigateLoggedIn();
  const {getEntityGridUrl} = useEntityNavigation();

  const navigateToUserTermsHandler = useCallback((): void => {
    navigateLoggedIn(getEntityGridUrl(termEntityId, true));
  }, [navigateLoggedIn, getEntityGridUrl]);

  return (
    <RootStyle>
      <CardContent sx={{height: "100%"}}>
        <Typography gutterBottom variant="h4">
          <FormattedMessage id="searchKnowledgeBase" />
        </Typography>

        <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: "auto"}}>
          <FormattedMessage id="searchKnowledgeBaseExplanation" />
        </Typography>

        <Stack direction={{xs: "column", lg: "row"}} alignItems={{xs: "center", md: "start"}} spacing={1}>
          <Button variant="outlined" to={urls.search.url} component={RouterLink} sx={{whiteSpace: "nowrap"}}>
            <FormattedMessage id="knowledgeBase" />
          </Button>

          <Button variant="outlined" onClick={navigateToUserTermsHandler} sx={{whiteSpace: "nowrap"}}>
            <FormattedMessage id="manageTerms" />
          </Button>
        </Stack>
      </CardContent>

      <SearchIllustration
        sx={{
          p: 3,
          pl: 0,
          width: 240,
          height: 200,
          margin: {xs: "auto", md: "inherit"},
        }}
      />
    </RootStyle>
  );
}
