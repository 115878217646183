import * as React from "react";
import {FunctionComponent, useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {useSnackbar} from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAppSelector} from "../../redux/hooks";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TooltipIcon from "../common/TooltipIcon";
import {Entity, EntityPublishStatus, PublishableEntityRO} from "../../types/entity";
import {selectUserId, selectUserProfile} from "../../redux/auth/selector";
import {isString, trim} from "lodash";
import Alert from "@mui/material/Alert";
import UserProfileDialog from "./UserProfileDialog";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {localStorageKeyPublishEntityItemAnonymous} from "../../constants/localStorageKeys";
import {PublishRequest} from "../../types/internal";
import useEntity from "../../contexts/entity/hooks/useEntity";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {publishRequestEntityId} from "../../entities/publish-request.entity";
import {publicUserId} from "../../contexts/entity-crud/EntityCrudContext";

export type PublishEntityItemDialogProps = {
  entityId: Entity<PublishableEntityRO, any> | string;
  item: PublishableEntityRO;
};

const PublishEntityItemDialog: FunctionComponent<PublishEntityItemDialogProps> = NiceModal.create(
  ({entityId, item}) => {
    const modal = useModal();
    const {getEntity} = useEntity();
    const {saveItem} = useEntityCrud();
    const {enqueueSnackbar} = useSnackbar();

    const userProfile = useAppSelector(selectUserProfile);
    const userId = useAppSelector(selectUserId);

    const [saving, setSaving] = useState<boolean>(false);
    const [anonymous, setAnonymous] = useLocalStorageState<boolean>(localStorageKeyPublishEntityItemAnonymous, false);

    const entity = useMemo<Entity<PublishableEntityRO, any>>(
      () => (isString(entityId) ? getEntity(entityId) : entityId),
      [entityId]
    );
    const publishRequestEntity = useMemo<Entity<PublishRequest, any>>(() => getEntity(publishRequestEntityId), []);

    const hasName = useMemo<boolean>(() => !!trim(userProfile?.fullName), [userProfile]);
    const publishDisabled = useMemo<boolean>(
      () =>
        !hasName ||
        !item.id ||
        item.userIds?.includes(publicUserId) ||
        item.publishStatus === EntityPublishStatus.WaitingForApproval ||
        item.publishStatus === EntityPublishStatus.Approved,
      [hasName, item]
    );

    const updateNameHandler = useCallback((): void => {
      NiceModal.show(UserProfileDialog, {});
    }, []);

    const publishHandler = useCallback(async (): Promise<void> => {
      if (publishDisabled) {
        return;
      }

      setSaving(true);

      const publishRequest = publishRequestEntity.generateEmptyItem();
      publishRequest.userIds = [userId, publicUserId];
      publishRequest.status = EntityPublishStatus.WaitingForApproval;
      publishRequest.targetId = item.id;
      publishRequest.targetEntityId = entity.id;
      publishRequest.targetTitle = entity.getItemTitle(item);
      publishRequest.fullName = userProfile.fullName;
      publishRequest.anonymous = anonymous;
      const savedPublishRequest = await saveItem(publishRequestEntity, publishRequest);

      item.publishStatus = EntityPublishStatus.WaitingForApproval;
      item.publishReason = "";
      item.publishRequestId = savedPublishRequest.id;
      item.publishUserId = userId;
      const savedItem = await saveItem(entity, item);

      if (savedItem) {
        enqueueSnackbar(<FormattedMessage id="publishRequestSuccess" />, {variant: "success"});
      }

      setSaving(false);

      modal.hide();
    }, [publishDisabled, entity, publishRequestEntity, item, userProfile, userId, anonymous, modal, saveItem]);

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitleEnhanced onClose={modal.hide}>
          <FormattedMessage id={"publish"} />
        </DialogTitleEnhanced>
        <DialogContent>
          <Stack spacing={2}>
            {!hasName && (
              <Alert
                severity={"info"}
                variant={"outlined"}
                action={
                  <Button color={"info"} size={"small"} variant={"outlined"} onClick={updateNameHandler}>
                    <FormattedMessage id="updateName" />
                  </Button>
                }
              >
                <FormattedMessage id="publishNameRequired" />
              </Alert>
            )}

            <Box>
              <FormControlLabel
                control={
                  <Checkbox checked={anonymous} onChange={(event, currentChecked) => setAnonymous(currentChecked)} />
                }
                label={
                  <>
                    <FormattedMessage id="publishAnonymously" />
                    <TooltipIcon tooltip={<FormattedMessage id="publishAnonymouslyExplanation" />} />
                  </>
                }
              />
            </Box>

            <Typography variant={"body2"} sx={{color: "text.secondary"}}>
              <FormattedMessage id="publishExplanation" />
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={modal.hide}>
            <FormattedMessage id="close" />
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={publishHandler}
            loading={saving}
            disabled={publishDisabled}
          >
            <FormattedMessage id={"publish"} />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);

export default PublishEntityItemDialog;
