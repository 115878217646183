import * as React from "react";
import {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import {Link as RouterLink} from "react-router-dom";
import Page from "../../components/common/Page";
import {MotionContainer, varBounceIn} from "../../components/animate";
import {motion} from "framer-motion";
import PageNotFoundIllustration from "../../components/svg/PageNotFoundIllustration";

const RootStyle = styled(Page)(({theme}) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));

interface IProps {}

const Error: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <RootStyle>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{maxWidth: 480, margin: "auto", textAlign: "center"}}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                <FormattedMessage id="errorPageNotFound" />
              </Typography>
            </motion.div>
            <Typography sx={{color: "text.secondary"}}>
              <FormattedMessage id="errorPageNotFoundExplanation" />
            </Typography>

            <motion.div variants={varBounceIn}>
              <PageNotFoundIllustration sx={{height: 260, my: {xs: 3, sm: 6}}} />
            </motion.div>

            <Stack direction={{xs: "column", sm: "row"}} spacing={2} sx={{display: "inline-flex"}}>
              <Button size="large" variant="contained" to="/" component={RouterLink}>
                <FormattedMessage id="goToHome" />
              </Button>
            </Stack>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default Error;
