import {EntityPublishStatus} from "../../types/entity";

export default {
  brandName: "Shelpi",
  titleLong: "Shelpi | Make Your Life Easier",
  titleShort: "Shelpi",
  homepage: "Home",
  officeLoading: "Loading add-in. If it does not load within few minutes please close and reopen it.",
  contactSupportSubject: "Shelpi Technical Support",
  welcomeToPlugin: "Welcome to Shelpi!",
  welcomeExplanation: "As you write your document the relevant medical terms will be available here.",
  welcomeTryIt: 'Try it now by writing "Headache" in the document and wait for the magic to happen.',
  tryItSentence: "This is an example sentence with the word headache.",
  tryItNow: "Try It Now!",
  search: "Search",
  noResultsFound: "No Results Found",
  searchNoResultsHelp: "Search is performed by the title and keywords of terms. Try to change/shorten your search.",
  filter: "Filter",
  filters: "Filters",
  filtersExplanation: "Apply filters to terms everywhere",
  categories: "Categories",
  medicalFields: "Medical Fields",
  errorPageNotFound: "Sorry, page not found!",
  errorPageNotFoundExplanation:
    "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling or contact support.",
  goToHome: "Go To Home",
  contactUs: "Contact Us",
  contactSupport: "Contact Support",
  clear: "Clear",
  clearFilters: "Clear Filters",
  hasFilteredResults: "Some results are filtered and not displayed",
  lightMode: "Light Mode",
  darkMode: "Dark Mode",
  intakes: "Intakes",
  newIntakeTitle: "Create New Intake",
  newIntakeDescription: "Short wizard to generate intake tailor made to your needs.",
  newIntakePrep: "Creating new intake for Prep:",
  newIntake: "New Intake",
  createIntake: "Create Intake",
  copyIntake: "Copy Intake",
  printIntake: "Print Intake",
  validateIntake: "Validate Intake",
  applyRelevantFilters: "Apply relevant filters.",
  applyRelevantFiltersExplanation:
    "Applies the relevant filters for the selected medical field. You can always change them later.",
  clearDocumentText: "Clear document text.",
  clearDocumentTextExplanation:
    "Deletes the current text in the document. Uncheck to add the intake after the current text.",
  selectMedicalFieldToContinue: "Select medical field to continue.",
  continue: "Continue",
  cancel: "Cancel",
  menu: "Menu",
  termNotFound: "Term not found in document. Either add the term or select a different option.",
  stageNotFound: "Stage not found in document. Either add the stage or select a different option.",
  addSection: "Add Stage",
  support: "Support",
  supportContactUs: "Please feel free to contact us for any question/request/issue at:",
  supportAdditionalUrls:
    "In case the site is not loading properly in a hospital secured network you can also try one of the following urls:",
  supportAdditionalUrlsNotWorking:
    "If non of the urls are working the hospital IT department must be contacted to approve the site.",
  share: "Share",
  shareWithTheWorld: "Share Shelpi with the World",
  close: "Close",
  copy: "Copy",
  copiedSuccessfully: "Copied successfully.",
  print: "Print",
  download: "Download",
  downloadAddIn: "Download Shelpi plugin for Word",
  whatIsTheTool:
    "Shelpi is a tool to help you write better intakes faster. Use our Office Word plugin and/or website for maximum productivity on your computer / tablet / smartphone.",
  getThePlugin: "Get The Plugin",
  officeWordPlugin: "Office Word Plugin",
  pluginExplanation:
    "Download the Shelpi Word plugin for free and accelerate your work. The plugin finds relevant keywords in your document and lets you add the required information within seconds. No more remembering and typing again and again and again...",
  downloadForFree: "Download For Free",
  goToSearch: "Go To Search",
  knowledgeBase: "Knowledge Base",
  searchKnowledgeBase: "Search Knowledge Base",
  searchKnowledgeBaseExplanation:
    "Tired of remembering everything? Use Shelpi knowledge base to get all the required info with a few clicks. Something missing? Build your own knowledge base and have it available anywhere/anytime.",
  advancedTemplates: "Advanced Templates",
  advancedTemplatesExplanation:
    "Use Shelpi advanced templates to kick-off your intakes and reduce the work time required to complete an intake. Add your own personal templates tailor made for your exact needs.",
  supportExplanation:
    "Got an issue? We are here for you. Thought of a cool idea to improve the tool? Let us know! Need help with something? Just contact us.",
  validate: "Validate",
  searchFillCharacters: "Search for fill characters ({character}).",
  fillCharactersFound: "Fill characters found ({character}).",
  searchTermClauses: "Search for added clauses ({character}).",
  termClausesFound: "Add clauses found ({character}).",
  validateIntakeSuccess: "The intake passed the validation successfully.",
  comingSoon: "Coming Soon",
  comingSoonExplanation: "The plugin is in the final approval steps and will be available soon. Stay tuned!",
  login: "Login",
  logout: "Logout",
  loginSuccess: "Logged in to the system successfully.",
  logoutSuccess: "Logged out from the system successfully.",
  loginFailed: "Connection failed. Please check your email/password.",
  logoutFailed: "Logout failed.",
  terms: "Terms",
  manageTerms: "Manage Terms",
  manage: "Manage",
  title: "Title",
  description: "Description",
  tooltip: "Tooltip",
  category: "Category",
  status: "Status",
  addNew: "Add New",
  details: "Details",
  save: "Save",
  requiredField: "Required field.",
  keywords: "Keywords",
  commaSeparatedValues: "Comma separated values.",
  selectNonForAll: "If no value is selected the item will be available for all values.",
  type: "Type",
  text: "Text",
  removeTerm: "Remove Term",
  stages: "Stages",
  addNewStage: "Add Stage",
  removeStage: "Remove Stage",
  clauses: "Clauses",
  addNewClause: "Add Clause",
  removeClause: "Remove Clause",
  checkedByDefault: "Checked By Default",
  email: "Email",
  password: "Password",
  invalidEmail: "Invalid email.",
  invalidPassword: "Invalid password.",
  username: "Username",
  signUp: "Sign Up",
  signUpOrLogin: "Sign Up / Login",
  doNotHaveAccount: "Don't have an account? Sign Up",
  alreadyHaveAccount: "Already have an account? Login",
  creationTime: "Creation Time",
  lastUpdateTime: "Last Update Time",
  savedSuccessfully: "Saved successfully.",
  formInvalidValues: "Some fields have invalid values.",
  clearSearch: "Clear Search",
  networkError: "Network error. Please try again later.",
  reachedMaximumAllowed: "You have reached the maximum allowed ({max}).",
  prep: "Prep",
  preps: "Preps",
  prepEmpty: "Prep is empty.",
  addToPrep: "Add To Prep",
  addToPrepSuccess: "Added to prep successfully.",
  addToPrepSignUp: "Sign up to start prepping.",
  addToPrepNoClausesSelected: "No clauses selected.",
  addToPrepLoading: "Loading prep. Please try again in a few moments.",
  cannotUpdatePrepWhileInPage:
    "Cannot update the Prep while in its page. Use the form or navigate to a different page to update it.",
  new: "New",
  open: "Open",
  showAll: "Show All",
  comments: "Comments",
  back: "Back",
  acceptPrivacyAndTerms: "By signing up, I agree to the Shelpi {privacyPolicy} and {termsOfService}.",
  privacyPolicy: "Privacy Policy",
  termsOfService: "Terms of Service",
  select: "Select",
  savePrepToSelect: "Save prep to select it.",
  prepSelectedSuccessfully: "Prep selected successfully.",
  templates: "Templates",
  manageTemplates: "Manage Templates",
  sections: "Sections",
  addNewSection: "Add Section",
  contents: "Contents",
  addNewContent: "Add Content",
  example: "Example",
  assistNotes: "Assist Notes",
  extendedFormat: "Extended Format",
  medicalRecordFormat: "Medical record format",
  remove: "Remove",
  item: "Item",
  items: "Items",
  addNewItem: "Add Item",
  rows: "Rows",
  columns: "Columns",
  cell: "Cell",
  selectMedicalField: "Select medical field",
  selectTemplate: "Select template",
  templateSettings: "Template Settings",
  lastStep: "Last Step",
  finish: "Finish",
  noTemplatesFound: "No templates found :( We are working on adding additional templates.",
  addExamples: "Add examples.",
  addAssistNotes: "Add assist notes.",
  addExtendedFormat: "Extended format.",
  addMedicalRecordFormat: "Medical record format.",
  addExamplesExplanation: "Show examples in relevant sections.",
  addAssistNotesExplanation: "Add assist notes for hospital employees.",
  addExtendedFormatExplanation: "Extended format for medical students.",
  addMedicalRecordFormatExplanation: "Simple format without styling ready to copy into healthcare systems.",
  keywordsFinderSettings: "Document Scanning Settings",
  keywordsFinderSettingsSignUp: "Sign up to update default settings.",
  negateKeyword: "Negate Word",
  negateKeywords: "Negate Words",
  negateKeywordsExplanation:
    'The configured words cancel term keywords that appear after. For example: "not headache" will cancel the "headache" keyword.',
  negateKeywordTypeWordExplanation: "Cancels the keyword directly after the negate word.",
  negateKeywordTypeSentenceExplanation: "Cancels the entire sentence after the negate word.",
  restoreDefaults: "Restore Defaults",
  private: "Personal",
  privateTerms: "Personal Terms",
  managePrivateTerms: "Manage Personal Terms",
  privateTemplates: "Personal Templates",
  managePrivateTemplates: "Manage Personal Templates",
  error: "Error",
  addPrivateTemplate: "Add Personal Template",
  addPrivateTerm: "Add Personal Term",
  refresh: "Refresh",
  refreshing: "Refreshing...",
  rememberMe: "Remember me",
  rememberMeExplanation:
    "If this option is checked, your account will stay logged in after you close the window. For your own safety, uncheck in public computers.",
  newTerms: "New Terms!",
  newUserPublishedTerms: "And more importantly. Your terms are now publicly available. Amazing work!",
  newTermsAdded: "New terms available now:",
  openWebsite: "Open Shelpi Website",
  previewModeExplanation:
    "Please note! This version of Microsoft Office only supports a preview version of the Shelpi add-in. To use the full version either:",
  or: "Or",
  upgradeOfficeVersion: "Upgrade Office Version",
  useOurWebsite: "Use Shelpi Website",
  permanentListExplanation:
    "List Type. Determines which character will be added at the beginning of each item (Selected: {permanent}, otherwise: {temporary}).",
  delete: "Delete",
  clone: "Clone",
  clonedSuccessfully: "Cloned successfully.",
  deletedSuccessfully: "Deleted successfully",
  findInDocument: "Find In Document",
  termNotFoundInDocument: "Term not found in document.",
  shareFailed: "Sharing failed. Please try again later.",
  addToMyAccount: "Add To My Account",
  addToAll: "Add To All",
  addToDocument: "Add To Document",
  addToStage: "Add To Stage",
  actions: "Actions",
  prepAddToStagesSuccessfully: "Prep added to relevant stages successfully.",
  addPrepToStagesExplanation:
    "Adds the Prep stages to the relevant stages in the document. If a stage is not found it will be added to the end of the document.",
  addPrepToDocumentExplanation: "Adds the full Prep to the document.",
  copyPrepExplanation: "Copies the full Prep to clipboard.",
  selectPrepExplanation: "Selects the Prep as the active Prep available at the top menu.",
  intakeCreatedSuccessfully: "Intake created successfully",
  subClause: "Sub Clause",
  subClauseCancel: "Cancel Sub Clause",
  addedToPrep: "Added To Prep",
  fullName: "Full Name",
  myAccount: "My Account",
  published: "Published",
  publish: "Publish",
  rejected: "Rejected",
  processing: "Processing",
  publishEntityApprovedExplanation: "Your submission has been approved!",
  publishEntityApprovedReason: "Amazing work! Thank you for being an important part of the Shelpi community.",
  publishEntityPublishExplanation:
    "Submit your work to be available for all and help make the world a better place :) The submission will be reviewed and if found eligible will be available for all.",
  publishEntityRejectedExplanation:
    "The submission was rejected due to the following error. You can always fix the error and resubmit:",
  publishEntityRejectedReason: "Invalid submission.",
  publishEntityWaitingForApprovalExplanation: "Your submission is waiting to be reviewed.",
  publishNameRequired: "You are required to update your name in order to publish.",
  updateName: "Update Name",
  publishAnonymously: "Publish anonymously",
  publishAnonymouslyExplanation:
    "Your work will appear in the system with your name next tp it as appropriate for the hard work you put into it. If for any reason you prefer your name not to appear check the box.",
  publishExplanation:
    "Please note: Once your work is published our editors can work on it with you to improve it. You can also keep updating and improving it at all time.",
  publishRequestSuccess: "Publish request sent successfully.",
  managePublishRequests: "Manage Publish Requests",
  publishRequests: "Publish Requests",
  approve: "Approve",
  reject: "Reject",
  underMaintenance: "Under Maintenance",
  underMaintenanceGetBackSoon: "We will be back soon better and stronger.",
  confirmDelete: "Confirm Delete",
  confirmDeleteExplanation: "Are your sure you want to delete?",
  edit: "Edit",
  editRequests: "Edit Requests",
  editRequestSuccess: "Edit request sent successfully.",
  editNameRequired: "You are required to update your name for edit request.",
  sendEditRequest: "Send Edit Request",
  manageEditRequests: "Manage Edit Requests",
  submit: "Submit",
  editRequestsApprovedExplanation: "The following edit requests has been approved! You can start edit right now:",

  stageTypeCurrentDiseaseQuestions: "Current Disease",
  stageTypePhysicalExaminationEmphasis: "Physical Examination",
  stageTypePregnancyAndChildbirth: "Pregnancy & Childbirth",
  stageTypeVaccines: "Vaccines",
  stageTypeDiet: "Diet",
  stageTypeGrowthAndDevelopment: "Growth & Development",
  stageTypeLabEmphasis: "Lab",
  stageTypeBackgroundDisease: "Background Disease",
  stageTypeRegularMedications: "Regular Medications",
  stageTypeGeneralMedicalBackground: "General Medical Background",
  stageTypeRelevantFamilyBackground: "Relevant Family Background",
  stageTypeOther: "Other",
  stageTypeScreeningTests: "Screening Tests",
  stageTypeGynecologicalHistory: "Gynecological History",
  stageTypeObstetricHistory: "Obstetric History",
  stageTypeCurrentPregnancy: "Current Pregnancy",
  stageTypePlan: "Plan",
  stageTypeSummaryAndDiscussion: "Summary & Discussion",

  addTextLocationBeforeSelection: "Add Paragraph Before Selection",
  addTextLocationAfterSelection: "Add Paragraph After Selection",
  addTextLocationSelection: "Add To Selection",
  addTextLocationBeforeTerm: "Add Paragraph Before Term",
  addTextLocationAfterTerm: "Add Paragraph After Term",
  addTextLocationStart: "Add To Document Start",
  addTextLocationEnd: "Add To Document End",
  addTextLocationStageStart: "Add To Relevant Stage",

  categoryOther: "Other",
  categoryDrug: "Drug",
  categorySymptom: "Symptom",
  categoryDisease: "Disease",
  categoryAge: "Age",
  categorySignInExam: "SignInExam",
  categoryLabResult: "LabResult",

  medicalFieldInternalMedicine: "Internal Medicine",
  medicalFieldPaediatrics: "Paediatrics",
  medicalFieldGynecology: "Gynecology",
  medicalFieldSurgery: "Surgery",
  medicalFieldPsychiatry: "Psychiatry",
  medicalFieldNeurology: "Neurology",

  dataEntityStatusDraft: "Draft",
  dataEntityStatusPublished: "Published",
  dataEntityStatusArchived: "Archived",

  publishEntityStatusApproved: "Approved",
  publishEntityStatusNone: "None",
  publishEntityStatusRejected: "Rejected",
  publishEntityStatusWaitingForApproval: "Waiting For Approval",

  contentTypeText: "Text",
  contentTypeList: "List",
  contentTypeTable: "Table",
};
