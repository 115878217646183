import * as React from "react";
import {Dispatch, FunctionComponent, PropsWithChildren} from "react";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {
  localStorageKeyIntakeTemplateAddAssistNotes,
  localStorageKeyIntakeTemplateAddExamples,
  localStorageKeyIntakeTemplateAddExtendedFormat,
  localStorageKeyIntakeTemplateMedicalRecordFormat,
} from "../../constants/localStorageKeys";

export type IntakeTemplateGeneratorContextProps = {
  addExamples;
  setAddExamples: Dispatch<boolean>;
  addAssistNotes;
  setAddAssistNotes: Dispatch<boolean>;
  addExtendedFormat;
  setAddExtendedFormat: Dispatch<boolean>;
  addMedicalRecordFormat;
  setAddMedicalRecordFormat: Dispatch<boolean>;
};

const IntakeTemplateGeneratorContext = React.createContext<IntakeTemplateGeneratorContextProps>(undefined!);

export interface IntakeTemplateGeneratorProviderProps extends PropsWithChildren<any> {}

const IntakeTemplateGeneratorProvider: FunctionComponent<IntakeTemplateGeneratorProviderProps> = ({children}) => {
  const [addExamples, setAddExamples] = useLocalStorageState<boolean>(localStorageKeyIntakeTemplateAddExamples, true);
  const [addAssistNotes, setAddAssistNotes] = useLocalStorageState<boolean>(
    localStorageKeyIntakeTemplateAddAssistNotes,
    true
  );
  const [addExtendedFormat, setAddExtendedFormat] = useLocalStorageState<boolean>(
    localStorageKeyIntakeTemplateAddExtendedFormat,
    true
  );
  const [addMedicalRecordFormat, setAddMedicalRecordFormat] = useLocalStorageState<boolean>(
    localStorageKeyIntakeTemplateMedicalRecordFormat,
    false
  );

  return (
    <IntakeTemplateGeneratorContext.Provider
      value={{
        addExamples,
        setAddExamples,
        addAssistNotes,
        setAddAssistNotes,
        addExtendedFormat,
        setAddExtendedFormat,
        addMedicalRecordFormat,
        setAddMedicalRecordFormat,
      }}
    >
      {children}
    </IntakeTemplateGeneratorContext.Provider>
  );
};

export {IntakeTemplateGeneratorContext, IntakeTemplateGeneratorProvider};
