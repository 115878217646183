import * as React from "react";
import {ContentType, IntakeTemplate} from "../../../../types/internal";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {ReactNode, useMemo} from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {getContentTypeLabelId} from "../../../../helpers/IntakeTemplateUtils";
import Box from "@mui/material/Box";
import IntakeTemplateContentPayload from "./content-payload/IntakeTemplateContentPayload";
import {useUpdateEffect} from "react-use";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import LightBulbOutlined from "@mui/icons-material/LightBulbOutlined";
import AssistantOutlined from "@mui/icons-material/AssistantOutlined";
import MoreOutlined from "@mui/icons-material/MoreOutlined";
import {MHidden} from "../../../@material-extend";
import FormToggleIconButton from "../../../forms/FormToggleIconButton";
import FormTogglesMenuIconButton, {FormToggleMenuItem} from "../../../forms/FormTogglesMenuIconButton";
import MedicalServicesOutlined from "@mui/icons-material/MedicalServicesOutlined";
import TooltipIconButton from "../../../common/TooltipIconButton";

export interface IntakeTemplateContentFormProps {
  id: string;
  sectionIndex: number;
  index: number;
  remove: (index: number) => void;
  startAction?: ReactNode;
  disabled?: boolean;
}

const IntakeTemplateContentForm = ({
  sectionIndex,
  index,
  remove,
  startAction,
  disabled,
}: IntakeTemplateContentFormProps) => {
  const intl = useIntl();

  const {control, setValue} = useFormContext<IntakeTemplate>();

  const contentType: ContentType = useWatch({
    control: control,
    name: `sections.${sectionIndex}.contents.${index}.type`,
  });

  const actions = useMemo<FormToggleMenuItem[]>(
    () => [
      {
        id: "example",
        fieldName: `sections.${sectionIndex}.contents.${index}.example`,
        Icon: LightBulbOutlined,
        activeColor: "error",
        titleId: "example",
        disabled: disabled,
      },
      {
        id: "assistNotes",
        fieldName: `sections.${sectionIndex}.contents.${index}.assistNotes`,
        Icon: AssistantOutlined,
        activeColor: "info",
        titleId: "assistNotes",
        disabled: disabled,
      },
      {
        id: "extendedFormat",
        fieldName: `sections.${sectionIndex}.contents.${index}.extendedFormat`,
        Icon: MoreOutlined,
        activeColor: "success",
        titleId: "extendedFormat",
        disabled: disabled,
      },
    ],
    [sectionIndex, index, disabled]
  );

  useUpdateEffect(() => {
    setValue(`sections.${sectionIndex}.contents.${index}.payload`, "");
  }, [contentType]);

  return (
    <Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {startAction}

        <Controller
          name={`sections.${sectionIndex}.contents.${index}.type`}
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                required
                fullWidth
                label={intl.formatMessage({id: "type"})}
                error={invalid}
                helperText={error?.message}
                disabled={disabled}
                select
              >
                {Object.values(ContentType).map((t) => (
                  <MenuItem key={t} value={t}>
                    <FormattedMessage id={getContentTypeLabelId(t)} />
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />

        <MHidden width={"lgDown"}>
          {actions.map((action) => (
            <FormToggleIconButton
              fieldName={action.fieldName}
              Icon={action.Icon}
              activeColor={action.activeColor}
              tooltipId={action.titleId}
              disabled={action.disabled}
              key={action.id}
            />
          ))}
        </MHidden>

        <MHidden width={"lgUp"}>
          <FormTogglesMenuIconButton actions={actions} fontSize={"medium"} />
        </MHidden>

        <TooltipIconButton
          Icon={DeleteOutlined}
          color={"error"}
          tooltipId={"remove"}
          disabled={disabled}
          onClick={() => remove(index)}
        />
      </Stack>

      <Controller
        name={`sections.${sectionIndex}.contents.${index}.payload`}
        control={control}
        render={({field, fieldState}) => {
          return (
            <Box sx={{mt: 3}}>
              <IntakeTemplateContentPayload
                type={contentType}
                field={field}
                fieldState={fieldState}
                disabled={disabled}
              />
            </Box>
          );
        }}
      />

      <Divider sx={{mt: 3}} />
    </Stack>
  );
};

export default IntakeTemplateContentForm;
