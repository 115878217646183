import IconButton, {IconButtonProps} from "@mui/material/IconButton";
import * as React from "react";
import DragIndicator from "@mui/icons-material/DragIndicator";

export interface DragHandleProps extends IconButtonProps {}

const DragHandle = (props: DragHandleProps) => {
  return (
    <IconButton color={"inherit"} {...props}>
      <DragIndicator />
    </IconButton>
  );
};

export default DragHandle;
