import * as React from "react";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import {FunctionComponent, useCallback, useMemo, useState} from "react";
import {Term, TermClause, TermFilters, TermStage} from "../../types/internal";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import TermStageView from "./TermStageView";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {selectKeywords} from "../../helpers/SelectTextUtils";
import useNegateKeywords from "../../contexts/negate-keywords/hooks/useNegateKeywords";
import {termEntityId} from "../../entities/term.entity";
import {checkMedicalFieldsFilter, isMedicalFieldsFilterActive} from "../../helpers/FiltersUtils";
import useEntityFilters from "../../contexts/entity-filters/hooks/useEntityFilters";
import TermCardHeader from "./TermCardHeader";
import useOffice from "../../contexts/office/hooks/useOffice";
import AddToPrepButton from "../common/AddToPrepButton";
import AddTermStageTextButton from "./AddTermStageTextButton";
import {useSnackbar} from "notistack";
import {FormattedMessage} from "react-intl";
import FindInPageOutlined from "@mui/icons-material/FindInPageOutlined";
import ShareOutlined from "@mui/icons-material/ShareOutlined";
import NiceModal from "@ebay/nice-modal-react";
import ShareDialog from "../dialogs/ShareDialog";
import useEntityShare from "../../contexts/entity-share/hooks/useEntityShare";
import ActionsMenuIconButton from "../menus/ActionsMenuIconButton";
import {ActionsMenuItem} from "../menus/ActionsMenuGeneric";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import MAvatar from "../@material-extend/MAvatar";
import useActivePrep from "../../contexts/active-prep/hooks/useActivePrep";
import {some} from "lodash";
import Tooltip from "@mui/material/Tooltip";
import CopyTermStageTextButton from "./CopyTermStageTextButton";
import {publicUserId} from "../../contexts/entity-crud/EntityCrudContext";
import LoginDialog from "../dialogs/LoginDialog";
import {useAppSelector} from "../../redux/hooks";
import {selectLoggedIn, selectRole, selectUserId} from "../../redux/auth/selector";
import {useNavigate} from "react-router-dom";
import {roleAdmin} from "../../constants/roles";
import useEntityNavigation from "../../helpers/useEntityNavigation";
import EditEntityItemDialog from "../dialogs/EditEntityItemDialog";

export interface TermCardProps {
  term: Term;
  sx?: SxProps<Theme>;
}

const TermCard: FunctionComponent<TermCardProps> = ({term, sx}) => {
  const {negateKeywords} = useNegateKeywords();
  const {filtersChangeFlag, getFilters} = useEntityFilters();
  const {generateShareUrl} = useEntityShare();
  const {isOfficeActive} = useOffice();
  const {enqueueSnackbar} = useSnackbar();
  const {activePrep} = useActivePrep();
  const navigate = useNavigate();
  const {getEntityDetailsUrl} = useEntityNavigation();

  const loggedIn = useAppSelector(selectLoggedIn);
  const userId = useAppSelector(selectUserId);
  const role = useAppSelector(selectRole);

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = useCallback((): void => {
    setExpanded((currentExpanded) => !currentExpanded);
  }, [setExpanded]);

  const handleShareClick = useCallback(async (): Promise<void> => {
    const urlGenerator = async (): Promise<string> => {
      return await generateShareUrl(termEntityId, term);
    };

    NiceModal.show(ShareDialog, {
      url: urlGenerator,
    });
  }, [term, generateShareUrl]);

  const handleEditClick = useCallback(async (): Promise<void> => {
    if (!loggedIn) {
      NiceModal.show(LoginDialog, {});
      return;
    }

    if (term.userIds?.includes(userId) || role === roleAdmin) {
      navigate(getEntityDetailsUrl(termEntityId, term.id));
      return;
    }

    NiceModal.show(EditEntityItemDialog, {entityId: termEntityId, item: term});
  }, [term, loggedIn, userId, role, navigate, getEntityDetailsUrl]);

  const handleFindClick = useCallback(async (): Promise<void> => {
    const selected = await selectKeywords(term.keywords, negateKeywords);

    if (!selected) {
      enqueueSnackbar(<FormattedMessage id="termNotFoundInDocument" />, {variant: "warning"});
    }
  }, [term, negateKeywords]);

  const actionsHandler = useCallback(
    (actionId: string): void => {
      switch (actionId) {
        case "share":
          handleShareClick();
          return;
        case "edit":
          handleEditClick();
          return;
        case "find":
          handleFindClick();
          return;
      }
    },
    [handleShareClick, handleFindClick]
  );

  const actions = useMemo<ActionsMenuItem[]>(
    () => [
      {id: "share", titleId: "share", Icon: ShareOutlined},
      {id: "edit", titleId: "edit", Icon: EditOutlined},
      ...(isOfficeActive ? [{id: "find", titleId: "findInDocument", Icon: FindInPageOutlined}] : []),
    ],
    [term, isOfficeActive]
  );

  const filterStages = useCallback(
    (items: TermStage[]): TermStage[] => {
      const filters = getFilters<Term, TermFilters>(termEntityId);

      const filterMedicalFields = isMedicalFieldsFilterActive(filters);
      const filterAny = filterMedicalFields;

      if (!filterAny) {
        return items;
      }

      return items.filter((item) => {
        if (checkMedicalFieldsFilter(filterMedicalFields, filters, item.medicalFields)) {
          return false;
        }
        return true;
      });
    },
    [getFilters]
  );

  const filterClauses = useCallback(
    <T extends TermClause>(items: T[]): T[] => {
      const filters = getFilters<Term, TermFilters>(termEntityId);

      const filterMedicalFields = isMedicalFieldsFilterActive(filters);
      const filterAny = filterMedicalFields;

      if (!filterAny) {
        return items;
      }

      const filteredIndexes = [];

      return items.filter((item, index, arr) => {
        if (item.indent) {
          for (let i = index - 1; i >= 0; i--) {
            const itemToCheck = arr[i];
            if (itemToCheck.indent === item.indent) {
              continue;
            }
            if ((itemToCheck.indent || 0) < item.indent) {
              if (filteredIndexes.includes(i)) {
                filteredIndexes.push(index);
                return false;
              }
              break;
            }
          }
        }

        if (checkMedicalFieldsFilter(filterMedicalFields, filters, item.medicalFields)) {
          filteredIndexes.push(index);
          return false;
        }
        return true;
      });
    },
    [getFilters]
  );

  const stages = useMemo<TermStage[]>(() => filterStages(term.stages || []), [term, filtersChangeFlag]);
  const userOwned = useMemo<boolean>(() => !term.userIds?.includes(publicUserId), [term]);
  const isInActivePrep = useMemo<boolean>(
    () => !!activePrep && some(activePrep.stages, (stage) => some(stage.terms, (t) => t.termId === term.id)),
    [activePrep]
  );

  return (
    <Card
      sx={{
        ...sx,
        overflow: "visible",
        bgcolor: (theme) => (userOwned ? theme.palette.background.paperAlternate : theme.palette.background.paper),
      }}
    >
      <TermCardHeader
        term={term}
        action={<ActionsMenuIconButton actions={actions} onActionClick={actionsHandler} />}
        onClick={handleExpandClick}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {term.description && (
          <CardContent sx={{py: 0}}>
            <Typography component="p" variant="body2" sx={{color: "text.secondary"}}>
              {term.description}
            </Typography>
          </CardContent>
        )}
        {stages.map((stage) => (
          <TermStageView
            term={term}
            stage={stage}
            filtersChangeFlag={filtersChangeFlag}
            filterClauses={filterClauses}
            AddAction={AddTermStageTextButton}
            CopyAction={CopyTermStageTextButton}
            PrepAction={AddToPrepButton}
            key={stage.type}
          />
        ))}
      </Collapse>

      {isInActivePrep && (
        <Tooltip title={<FormattedMessage id="addedToPrep" />}>
          <MAvatar color="primary" sx={{width: 24, height: 24, position: "absolute", top: -12, right: -12}}>
            <AssignmentOutlined fontSize={"extraSmall"} />
          </MAvatar>
        </Tooltip>
      )}
    </Card>
  );
};

export default TermCard;
