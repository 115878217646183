import * as React from "react";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridEnrichedColDef,
  GridLocaleText,
  GridSelectionModel,
} from "@mui/x-data-grid";
import locales from "../../lang";
import {useAppSelector} from "../../redux/hooks";
import {selectLocale} from "../../redux/auth/selector";
import {pageSizes} from "../../constants/ui";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {localStorageKeyPageSize} from "../../constants/localStorageKeys";
import {cloneDeep, find, first, isString} from "lodash";
import {BaseEntityRO, Entity} from "../../types/entity";
import PageHeader from "../pages/PageHeader";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import useEntityFilters from "../../contexts/entity-filters/hooks/useEntityFilters";
import FilteredResults from "../terms/FilteredResults";
import Stack from "@mui/material/Stack";
import FilterTextField from "../common/FilterTextField";
import useSearchState from "../../helpers/useSearchState";
import ErrorMessage from "../common/ErrorMessage";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import {useSnackbar} from "notistack";
import AddOutlined from "@mui/icons-material/AddOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import CopyAllOutlined from "@mui/icons-material/CopyAllOutlined";
import ShareOutlined from "@mui/icons-material/ShareOutlined";
import useEntityShare from "../../contexts/entity-share/hooks/useEntityShare";
import NiceModal from "@ebay/nice-modal-react";
import ShareDialog from "../dialogs/ShareDialog";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export interface EntityDataGridProps<EntityRO extends BaseEntityRO, FiltersRO> {
  entity: Entity<EntityRO, FiltersRO>;
  entityUserId: string;
  onSelectedItem: (itemId: string) => void;
  onNewItem: () => void;
}

const EntityDataGrid = <EntityRO extends BaseEntityRO, FiltersRO>({
  entity,
  entityUserId,
  onSelectedItem,
  onNewItem,
}: EntityDataGridProps<EntityRO, FiltersRO>) => {
  const intl = useIntl();
  const {getItems, saveItem, deleteItem, lastChangeTime} = useEntityCrud();
  const {filtersChangeFlag, filterItems, searchItems} = useEntityFilters();
  const {generateShareUrl} = useEntityShare();
  const {enqueueSnackbar} = useSnackbar();

  const locale = useAppSelector(selectLocale);
  const gridLocale = useMemo<Partial<GridLocaleText>>(() => locales[locale].dataGridLocalization, [locale]);

  const [pageSize, setPageSize] = useLocalStorageState(localStorageKeyPageSize, pageSizes[0]);
  const [items, setItems] = useState<EntityRO[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [performingAction, setPerformingAction] = useState<boolean>(false);

  const {pendingSearch, setPendingSearch, search} = useSearchState();

  const filteredItems = useMemo<EntityRO[]>(
    () => (items ? filterItems(entity, searchItems(entity, items, search)) : []),
    [items, filtersChangeFlag, search]
  );

  useEffect(() => {
    (async () => {
      const items = await getItems(entity);
      setLoading(false);
      if (items) {
        setItems(items.filter((item) => item.userIds?.includes(entityUserId)));
      } else {
        setError(error);
      }
    })();
  }, [lastChangeTime[entity.id]]);

  const deleteHandler = useCallback(
    async (id: string): Promise<void> => {
      const itemToDelete = find(items, (item) => item.id === id);
      if (!itemToDelete) {
        return;
      }

      const confirmed = await NiceModal.show(ConfirmationDialog, {
        title: <FormattedMessage id="confirmDelete" />,
        text: <FormattedMessage id="confirmDeleteExplanation" />,
        continueText: <FormattedMessage id="delete" />,
        continueColor: "error",
      });

      if (!confirmed) {
        return;
      }

      setPerformingAction(true);

      const deletedItem = await deleteItem(entity, itemToDelete);

      setPerformingAction(false);

      if (deletedItem) {
        enqueueSnackbar(<FormattedMessage id="deletedSuccessfully" />, {variant: "success"});
      }
    },
    [items, entity, deleteItem, setPerformingAction]
  );

  const cloneHandler = useCallback(
    async (id: string): Promise<void> => {
      const itemToClone = find(items, (item) => item.id === id);
      if (!itemToClone) {
        return;
      }

      setPerformingAction(true);

      const item = entity.generateCloneItem(cloneDeep(itemToClone));
      item.id = "";
      item.creationTime = 0;
      item.lastUpdateTime = 0;
      const savedItem = await saveItem(entity, item);

      setPerformingAction(false);

      if (savedItem) {
        enqueueSnackbar(<FormattedMessage id="clonedSuccessfully" />, {variant: "success"});
      }
    },
    [items, entity, saveItem, setPerformingAction]
  );

  const shareHandler = useCallback(
    async (id: string): Promise<void> => {
      const itemToShare = find(items, (item) => item.id === id);
      if (!itemToShare) {
        return;
      }

      const urlGenerator = async (): Promise<string> => {
        return await generateShareUrl(entity, itemToShare);
      };

      NiceModal.show(ShareDialog, {
        url: urlGenerator,
      });
    },
    [items, entity, generateShareUrl]
  );

  const columns = useMemo<GridColumns>(
    () => [
      ...entity.columns.map<GridEnrichedColDef>((column) => ({
        headerName: intl.formatMessage({id: column.headerNameId}),
        description: column.descriptionId ? intl.formatMessage({id: column.descriptionId}) : undefined,
        editable: false,
        filterable: false,
        flex: 1,
        ...column,
      })),
      {
        field: "actions",
        type: "actions",
        getActions: (params) => [
          ...(entity.shareable
            ? [
                <GridActionsCellItem
                  icon={<ShareOutlined />}
                  label={intl.formatMessage({id: "share"})}
                  title={intl.formatMessage({id: "share"})}
                  disabled={performingAction}
                  onClick={() => shareHandler(params.id.toString())}
                />,
              ]
            : []),
          ...(entity.creatable
            ? [
                <GridActionsCellItem
                  icon={<CopyAllOutlined />}
                  label={intl.formatMessage({id: "clone"})}
                  title={intl.formatMessage({id: "clone"})}
                  disabled={performingAction}
                  onClick={() => cloneHandler(params.id.toString())}
                  showInMenu
                />,
              ]
            : []),
          ...(entity.deletable
            ? [
                <GridActionsCellItem
                  icon={<DeleteOutlined />}
                  label={intl.formatMessage({id: "delete"})}
                  title={intl.formatMessage({id: "delete"})}
                  disabled={performingAction}
                  onClick={() => deleteHandler(params.id.toString())}
                  showInMenu
                />,
              ]
            : []),
        ],
      },
    ],
    [entity, performingAction, cloneHandler, deleteHandler]
  );

  const selectionModelChangeHandler = useCallback((selectionModel: GridSelectionModel): void => {
    const rowId = first(selectionModel);
    if (rowId && isString(rowId)) {
      onSelectedItem(rowId);
    }
  }, []);

  const hasFilteredItems = useMemo<boolean>(
    () => !!items && items.length > filteredItems.length,
    [items, filteredItems]
  );

  return (
    <>
      <PageHeader
        title={<FormattedMessage id={entity.titleId} />}
        action={
          <Stack spacing={1} direction={"row"}>
            <FilterTextField
              value={pendingSearch}
              onChangeValue={setPendingSearch}
              size={"small"}
              sx={{width: {sx: "auto", md: 250}}}
            />

            {entity.creatable && (
              <Button
                variant="contained"
                startIcon={<AddOutlined />}
                onClick={onNewItem}
                sx={{whiteSpace: "nowrap", minWidth: "max-content"}}
              >
                <FormattedMessage id="new" />
              </Button>
            )}
          </Stack>
        }
      />

      <Card>
        {error && !items ? (
          <ErrorMessage />
        ) : (
          <DataGrid
            rows={filteredItems}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={pageSizes}
            getRowId={(row) => row.id}
            onSelectionModelChange={selectionModelChangeHandler}
            checkboxSelection={false}
            loading={loading}
            autoHeight
            localeText={gridLocale}
            disableColumnMenu
          />
        )}
      </Card>

      {hasFilteredItems && <FilteredResults entityId={entity} onClear={() => setPendingSearch("")} sx={{mt: 2}} />}
    </>
  );
};

export default EntityDataGrid;
