import * as React from "react";
import {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@mui/material/Button";
import {AddTextLocation, addTextToDocument, getAddTextHtml} from "../../helpers/AddTextLocationUtils";
import useTheme from "@mui/material/styles/useTheme";
import {StageType, Term, TermClause} from "../../types/internal";
import useIntakeTemplateGenerator from "../../contexts/intake-template-generator/hooks/useIntakeTemplateGenerator";

interface NotificationActionAddSectionProps {
  term: Term;
  stageType: StageType;
  clauses?: TermClause[];
}

const NotificationActionAddSection: FunctionComponent<NotificationActionAddSectionProps> = ({
  term,
  stageType,
  clauses,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const {addMedicalRecordFormat} = useIntakeTemplateGenerator();

  const clickHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    const html = getAddTextHtml(term, stageType, clauses, intl, {
      direction: theme.direction,
      medicalRecordFormat: addMedicalRecordFormat,
    });
    await addTextToDocument(AddTextLocation.End, html, {
      select: true,
    });
  };

  return (
    <Button variant="text" size="small" color="inherit" onClick={clickHandler}>
      <FormattedMessage id="addSection" />
    </Button>
  );
};
export default NotificationActionAddSection;
