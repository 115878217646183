import * as React from "react";
import {ReactNode, useCallback, useMemo} from "react";
import {Prep, PrepStage} from "../../../../types/internal";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import {getStageTypeLabelId} from "../../../../helpers/TermUtils";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {Theme} from "@mui/material/styles/createTheme";
import {isEmpty} from "lodash";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PrepTermFormSortable from "./PrepTermFormSortable";
import Typography from "@mui/material/Typography";
import {getPrepStageHtml} from "../../../../helpers/PrepUtils";
import SortableDndContext from "../../../dnd/SortableDndContext";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import useTheme from "@mui/material/styles/useTheme";
import {ActionsMenuItem} from "../../../menus/ActionsMenuGeneric";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import ActionsMenuIconButton from "../../../menus/ActionsMenuIconButton";
import useCopyToClipboard from "../../../../helpers/useCopyToClipboard";
import PlaylistAddOutlined from "@mui/icons-material/PlaylistAddOutlined";
import useOffice from "../../../../contexts/office/hooks/useOffice";
import {AddTextLocation, addTextToDocument} from "../../../../helpers/AddTextLocationUtils";
import {sectionTitlePrefix} from "../../../../helpers/IntakeTemplateUtils";
import useIntakeTemplateGenerator from "../../../../contexts/intake-template-generator/hooks/useIntakeTemplateGenerator";

export interface PrepStageFormProps {
  id: string;
  index: number;
  remove: (index?: number | number[]) => void;
  startAction?: ReactNode;
  disabled?: boolean;
  unmountOnExit?: boolean;
  sx?: SxProps<Theme>;
}

const PrepStageForm = ({index, remove: removeGroup, startAction, disabled, unmountOnExit, sx}: PrepStageFormProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const {isOfficeActive} = useOffice();
  const copyToClipboard = useCopyToClipboard();
  const {addMedicalRecordFormat} = useIntakeTemplateGenerator();

  const {control} = useFormContext<Prep>();
  const {fields, remove, move} = useFieldArray<Prep>({name: `stages.${index}.terms`, control});

  const stage: PrepStage = useWatch({control: control, name: `stages.${index}`});
  const commentsRows = useMemo<number>(() => (stage.comments ? 3 : 1), [stage]);

  const addToStageHandler = useCallback(async (): Promise<void> => {
    const stageTitle = intl.formatMessage({id: getStageTypeLabelId(stage.type)});
    const stageFoundHtml = getPrepStageHtml(stage, intl, {
      direction: theme.direction,
      medicalRecordFormat: addMedicalRecordFormat,
      hideTitle: true,
    });
    try {
      await addTextToDocument(AddTextLocation.StageStart, stageFoundHtml, {
        keywords: [sectionTitlePrefix + stageTitle],
        select: true,
      });
    } catch (e) {
      try {
        await addTextToDocument(AddTextLocation.StageStart, stageFoundHtml, {
          keywords: [stageTitle],
          select: true,
        });
      } catch (e) {
        const stageNotFoundHtml = getPrepStageHtml(stage, intl, {
          direction: theme.direction,
          medicalRecordFormat: addMedicalRecordFormat,
          hideTitle: false,
        });
        await addTextToDocument(AddTextLocation.End, stageNotFoundHtml, {
          select: true,
        });
      }
    }
  }, [stage, getPrepStageHtml, theme.direction, addMedicalRecordFormat]);

  const actions = useMemo<ActionsMenuItem[]>(
    () => [
      {id: "copy", titleId: "copy", Icon: ContentCopyOutlined},
      ...(isOfficeActive ? [{id: "addToStage", Icon: PlaylistAddOutlined, titleId: "addToStage"}] : []),
    ],
    []
  );

  const actionsHandler = useCallback(
    (actionId: string): void => {
      switch (actionId) {
        case "copy":
          copyToClipboard(
            getPrepStageHtml(stage, intl, {direction: theme.direction, medicalRecordFormat: addMedicalRecordFormat}),
            "text/html"
          );
          break;
        case "addToStage":
          addToStageHandler();
          break;
      }
    },
    [stage, addToStageHandler, copyToClipboard, getPrepStageHtml, theme.direction, addMedicalRecordFormat]
  );

  const removeTermHandler = useCallback(
    (removeIndex: number): void => {
      remove(removeIndex);
    },
    [remove, index]
  );

  return (
    <Card sx={{...sx}}>
      <CardContent>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {startAction}

          <Typography variant="h5" sx={{flexGrow: 1}}>
            <FormattedMessage id={getStageTypeLabelId(stage.type)} />
          </Typography>

          <Tooltip title={<FormattedMessage id="removeStage" />}>
            <span>
              <IconButton color={"error"} onClick={() => removeGroup(index)} disabled={disabled}>
                <DeleteOutlined />
              </IconButton>
            </span>
          </Tooltip>

          <ActionsMenuIconButton actions={actions} fontSize={"medium"} onActionClick={actionsHandler} />
        </Stack>

        <SortableDndContext items={fields} move={move}>
          <Stack spacing={3} sx={{mt: 3}}>
            {fields.map((field, termIndex) => (
              <PrepTermFormSortable
                id={field.id}
                stageIndex={index}
                index={termIndex}
                remove={removeTermHandler}
                disabled={disabled}
                key={field.id}
              />
            ))}
          </Stack>
        </SortableDndContext>

        {isEmpty(fields) && (
          <Typography variant="body2" sx={{color: "text.secondary"}}>
            <FormattedMessage id="noResultsFound" />
          </Typography>
        )}

        <Controller
          name={`stages.${index}.comments`}
          control={control}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                multiline
                rows={commentsRows}
                label={intl.formatMessage({id: "comments"})}
                type="text"
                inputProps={{
                  maxLength: 1000,
                }}
                error={invalid}
                helperText={error?.message}
                disabled={disabled}
                sx={{mt: 3}}
              />
            );
          }}
        />
      </CardContent>
    </Card>
  );
};

export default PrepStageForm;
