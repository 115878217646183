import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {ComponentType, FunctionComponent, useCallback, useMemo, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import {findIndex, isEmpty, uniqueId} from "lodash";
import {StageType, Term, TermClause, TermStage} from "../../types/internal";
import {FormattedMessage, useIntl} from "react-intl";
import {getStageTypeLabelId} from "../../helpers/TermUtils";
import TermClauseView from "./TermClauseView";
import useOffice from "../../contexts/office/hooks/useOffice";
import useTheme from "@mui/material/styles/useTheme";

export interface TermStageViewProps {
  term: Term;
  stage: TermStage;
  filtersChangeFlag?: number;
  filterClauses?: <T extends TermClause>(items: T[]) => T[];
  AddAction?: ComponentType<{term: Term; stageType: StageType; clauses: TermClause[]}>;
  CopyAction?: ComponentType<{term: Term; stageType: StageType; clauses: TermClause[]}>;
  PrepAction?: ComponentType<{term: Term; stageType: StageType; clauses: TermClause[]}>;
}

export type TermClauseId = TermClause & {id: string};

const TermStageView: FunctionComponent<TermStageViewProps> = ({
  term,
  stage,
  filtersChangeFlag,
  filterClauses,
  AddAction,
  CopyAction,
  PrepAction,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const {isOfficeActive} = useOffice();

  const allClauses = useMemo<TermClauseId[]>(
    () => stage.clauses?.map<TermClauseId>((clause) => ({...clause, id: uniqueId()})) || [],
    [stage]
  );
  const filteredClauses = useMemo<TermClauseId[]>(
    () => filterClauses?.(allClauses) || allClauses,
    [allClauses, filtersChangeFlag]
  );

  const [checkedIds, setCheckedIds] = useState<string[]>(
    allClauses.filter((detail) => detail.checked).map((detail) => detail.id)
  );

  const checkedFilteredClauses = useMemo<TermClauseId[]>(
    () => filteredClauses.filter((detail) => checkedIds.includes(detail.id)),
    [filteredClauses, checkedIds]
  );

  const titleChecked = useMemo<boolean>(
    () => checkedFilteredClauses.length === filteredClauses.length,
    [checkedFilteredClauses, filteredClauses]
  );
  const titleIndeterminate = useMemo<boolean>(
    () => !isEmpty(checkedFilteredClauses) && checkedFilteredClauses.length < filteredClauses.length,
    [checkedFilteredClauses, filteredClauses]
  );

  const titleCheckedChangeHandler = useCallback(
    (checked: boolean): void => {
      if (checked) {
        setCheckedIds(allClauses.map<string>((detail) => detail.id));
      } else {
        setCheckedIds([]);
      }
    },
    [setCheckedIds]
  );

  const checkedChangeHandler = useCallback(
    (clause: TermClauseId, checked: boolean): void => {
      const idsToChange = [clause.id];

      const clauseIndex = findIndex(filteredClauses, (c) => c.id === clause.id);
      if (clauseIndex >= 0) {
        // Check/uncheck children
        const filteredClausesLength = filteredClauses.length;
        for (let i = clauseIndex + 1; i < filteredClausesLength; i++) {
          const clauseToCheck = filteredClauses[i];
          if ((clauseToCheck.indent || 0) > (clause.indent || 0)) {
            idsToChange.push(clauseToCheck.id);
          } else {
            break;
          }
        }
        // Check parent if child is checked
        if (checked && clause.indent) {
          for (let i = clauseIndex - 1; i >= 0; i--) {
            const clauseToCheck = filteredClauses[i];
            if ((clauseToCheck.indent || 0) < clause.indent) {
              idsToChange.push(clauseToCheck.id);
              break;
            }
          }
        }
      }

      if (checked) {
        setCheckedIds((currentCheckedIds) => [
          ...currentCheckedIds.filter((id) => !idsToChange.includes(id)),
          ...idsToChange,
        ]);
      } else {
        setCheckedIds((currentCheckedIds) => currentCheckedIds.filter((id) => !idsToChange.includes(id)));
      }
    },
    [setCheckedIds]
  );

  return (
    <CardContent sx={{pt: 1}}>
      <FormControlLabel
        control={
          <Checkbox
            checked={titleChecked}
            indeterminate={titleIndeterminate}
            onChange={(event, currentChecked) => titleCheckedChangeHandler(currentChecked)}
          />
        }
        label={
          <Typography variant="h6">
            <FormattedMessage id={getStageTypeLabelId(stage.type)} />
          </Typography>
        }
      />

      <FormGroup>
        {filteredClauses.map((clause) => {
          const checked = checkedIds.includes(clause.id);
          return (
            <TermClauseView clause={clause} checked={checked} onCheckedChange={checkedChangeHandler} key={clause.id} />
          );
        })}
      </FormGroup>

      <Stack direction="row" spacing={1} sx={{mt: 2}}>
        {isOfficeActive && AddAction && (
          <AddAction term={term} stageType={stage.type} clauses={checkedFilteredClauses} />
        )}
        {!isOfficeActive && CopyAction && (
          <CopyAction term={term} stageType={stage.type} clauses={checkedFilteredClauses} />
        )}
        {PrepAction && <PrepAction stageType={stage.type} term={term} clauses={checkedFilteredClauses} />}
      </Stack>
    </CardContent>
  );
};

export default TermStageView;
