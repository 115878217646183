import * as React from "react";
import {FunctionComponent} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {TermClauseId} from "./TermStageView";
import TooltipIcon from "../common/TooltipIcon";

export interface TermClauseViewProps {
  clause: TermClauseId;
  checked: boolean;
  disabled?: boolean;
  onCheckedChange: (detail: TermClauseId, checked: boolean) => void;
}

const TermClauseView: FunctionComponent<TermClauseViewProps> = ({clause, checked, disabled, onCheckedChange}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(event, currentChecked) => onCheckedChange(clause, currentChecked)}
        />
      }
      label={
        <>
          {clause.text}
          {clause.tooltip && <TooltipIcon tooltip={clause.tooltip} />}
        </>
      }
      sx={{pl: (clause.indent || 0) * 3}}
    />
  );
};

export default TermClauseView;
