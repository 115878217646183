import * as React from "react";
import {useCallback, useMemo, useRef, useState} from "react";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import {MIconButton} from "../../../components/@material-extend";
import MenuPopover from "../../../components/common/MenuPopover";
import {FormattedMessage} from "react-intl";
import useActivePrep from "../../../contexts/active-prep/hooks/useActivePrep";
import {chain} from "lodash";
import {useAppSelector} from "../../../redux/hooks";
import {selectLoggedIn} from "../../../redux/auth/selector";
import PrepPopoverDetails from "./PrepPopoverDetails";
import SignUpMessage from "../../../components/common/SignUpMessage";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";

export default function PrepPopover() {
  const {activePrep} = useActivePrep();

  const loggedIn = useAppSelector(selectLoggedIn);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const termsCount = useMemo<number>(
    () =>
      activePrep
        ? chain(activePrep.stages)
            .map((s) => s.terms)
            .flatten()
            .map((t) => t.termId)
            .uniq()
            .value().length
        : 0,
    [activePrep]
  );
  const prepEmpty = useMemo<boolean>(() => !activePrep?.stages.length, [activePrep]);

  const openHandler = useCallback((): void => {
    setOpen(true);
  }, [setOpen]);

  const closeHandler = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Tooltip title={<FormattedMessage id="prep" />}>
        <MIconButton ref={anchorRef} onClick={openHandler} color={open ? "primary" : "default"}>
          <Badge badgeContent={termsCount} color={"primary"} variant={"standard"} invisible={prepEmpty}>
            <AssignmentOutlined fontSize={"small"} />
          </Badge>
        </MIconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={closeHandler} anchorEl={anchorRef.current} sx={{width: 280}} arrow={false}>
        {loggedIn ? (
          <PrepPopoverDetails close={closeHandler} />
        ) : (
          <SignUpMessage messageId={"addToPrepSignUp"} onSignUpClick={closeHandler} />
        )}
      </MenuPopover>
    </>
  );
}
