import * as React from "react";
import {ContentType} from "../../../../../types/internal";
import {ControllerFieldState, ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {ComponentType, useCallback, useMemo, useState} from "react";
import IntakeTemplateContentPayloadText from "./IntakeTemplateContentPayloadText";
import IntakeTemplateContentPayloadList from "./IntakeTemplateContentPayloadList";
import IntakeTemplateContentPayloadTable from "./IntakeTemplateContentPayloadTable";
import {useUpdateEffect} from "react-use";

export interface IntakeTemplateContentPayloadProps {
  type: ContentType;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  disabled?: boolean;
}

const IntakeTemplateContentPayload = ({type, field, fieldState, disabled}: IntakeTemplateContentPayloadProps) => {
  const getTypeComponent = useCallback((type: ContentType): ComponentType<IntakeTemplateContentPayloadProps> => {
    switch (type) {
      default:
      case ContentType.Text:
        return IntakeTemplateContentPayloadText;
      case ContentType.List:
        return IntakeTemplateContentPayloadList;
      case ContentType.Table:
        return IntakeTemplateContentPayloadTable;
    }
  }, []);

  // We use the render component state to delay the component type change so the payload is cleared.
  const [renderComponent, setRenderComponent] = useState<number>(0);

  useUpdateEffect(() => {
    setRenderComponent((currentRenderComponent) => currentRenderComponent + 1);
  }, [type]);

  const TypeComponent = useMemo<ComponentType<IntakeTemplateContentPayloadProps>>(
    () => getTypeComponent(type),
    [renderComponent]
  );

  if (!TypeComponent) {
    return null;
  }

  return <TypeComponent type={type} field={field} fieldState={fieldState} disabled={disabled} />;
};

export default IntakeTemplateContentPayload;
