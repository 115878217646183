import * as React from "react";
import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {NegateKeywordType} from "../../../../types/internal";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import {Controller, useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";
import {useAppSelector} from "../../../../redux/hooks";
import {selectLocale} from "../../../../redux/auth/selector";
import {trim} from "lodash";
import useNegateKeywords from "../../../../contexts/negate-keywords/hooks/useNegateKeywords";
import AddOutlined from "@mui/icons-material/AddOutlined";
import DisabledByDefaultOutlined from "@mui/icons-material/DisabledByDefaultOutlined";

export interface KeywordFinderSettingsPopoverNewFormProps {}

export default function KeywordFinderSettingsPopoverNewForm({}: KeywordFinderSettingsPopoverNewFormProps) {
  const intl = useIntl();
  const {newNegateKeyword} = useNegateKeywords();

  const locale = useAppSelector(selectLocale);

  const [newKeywordType, setNewKeywordType] = useState<NegateKeywordType>(NegateKeywordType.Word);

  const {handleSubmit, control, setValue} = useForm<{keyword: string}>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onSubmit = handleSubmit((data): void => {
    const keyword = trim(data.keyword);
    if (!keyword) {
      return;
    }

    newNegateKeyword({
      uuid: uuidv4(),
      keyword: keyword,
      type: newKeywordType,
      locale: locale,
    });

    setValue("keyword", "");
  });

  return (
    <form autoComplete="off" noValidate onSubmit={onSubmit}>
      <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{p: 2}}>
        <Controller
          name="keyword"
          rules={{required: intl.formatMessage({id: "requiredField"})}}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                required
                fullWidth
                label={intl.formatMessage({id: "negateKeyword"})}
                type="text"
                inputProps={{
                  maxLength: 25,
                }}
                error={invalid}
                helperText={error?.message}
                size={"small"}
              />
            );
          }}
        />

        <Tooltip
          title={
            <FormattedMessage
              id={
                newKeywordType === NegateKeywordType.Sentence
                  ? "negateKeywordTypeSentenceExplanation"
                  : "negateKeywordTypeWordExplanation"
              }
            />
          }
        >
          <IconButton
            color={newKeywordType === NegateKeywordType.Sentence ? "warning" : "inherit"}
            onClick={() =>
              setNewKeywordType((currentNewKeywordType) =>
                currentNewKeywordType === NegateKeywordType.Sentence
                  ? NegateKeywordType.Word
                  : NegateKeywordType.Sentence
              )
            }
          >
            <DisabledByDefaultOutlined fontSize={"small"} />
          </IconButton>
        </Tooltip>

        <Tooltip title={<FormattedMessage id={"addNew"} />}>
          <IconButton color={"inherit"} onClick={onSubmit}>
            <AddOutlined fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Stack>
    </form>
  );
}
