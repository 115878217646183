import {KeywordsNotFoundError, MissingKeywordsError} from "../types/exceptions";
import {
  ContentType,
  ContentTypePayloadList,
  ContentTypePayloadListItem,
  StageType,
  Term,
  TermClause,
} from "../types/internal";
import {IntlShape} from "react-intl";
import {getIntakeTemplateHtmlSection} from "./IntakeTemplateUtils";
import {v4 as uuidv4} from "uuid";
import {getClauseText} from "./PrepUtils";
import {first} from "lodash";

export enum AddTextLocation {
  BeforeTerm = "BeforeTerm",
  AfterTerm = "AfterTerm",
  Start = "Start",
  End = "End",
  Selection = "Selection",
  BeforeSelection = "BeforeSelection",
  AfterSelection = "AfterSelection",
  StageStart = "StageStart",
}

export const getAddTextLocationLabelId = (location: AddTextLocation): string => {
  switch (location) {
    case AddTextLocation.BeforeSelection:
      return "addTextLocationBeforeSelection";
    case AddTextLocation.AfterSelection:
      return "addTextLocationAfterSelection";
    case AddTextLocation.Selection:
      return "addTextLocationSelection";
    case AddTextLocation.BeforeTerm:
      return "addTextLocationBeforeTerm";
    case AddTextLocation.AfterTerm:
      return "addTextLocationAfterTerm";
    case AddTextLocation.Start:
      return "addTextLocationStart";
    case AddTextLocation.End:
      return "addTextLocationEnd";
    case AddTextLocation.StageStart:
      return "addTextLocationStageStart";
  }
};

const addTextToDocumentStart = (context: Word.RequestContext, text: string): Word.Range => {
  const newParagraph = context.document.body.insertParagraph("", Word.InsertLocation.start);
  newParagraph.styleBuiltIn = "Normal";
  return newParagraph.insertHtml(text, Word.InsertLocation.start);
};

const addTextToDocumentEnd = (context: Word.RequestContext, text: string): Word.Range => {
  const newParagraph = context.document.body.insertParagraph("", Word.InsertLocation.end);
  newParagraph.styleBuiltIn = "Normal";
  return newParagraph.insertHtml(text, Word.InsertLocation.start);
};

const addTextToDocumentSelection = (context: Word.RequestContext, text: string): Word.Range => {
  const selection = context.document.getSelection();
  return selection.insertText(text, Word.InsertLocation.after);
};

const addTextToDocumentBeforeSelection = async (context: Word.RequestContext, text: string): Promise<Word.Range> => {
  const paragraphs = context.document.getSelection().paragraphs;
  paragraphs.load();

  await context.sync();

  const newParagraph = paragraphs.items[0].insertParagraph("", Word.InsertLocation.before);
  newParagraph.styleBuiltIn = "Normal";
  return newParagraph.insertHtml(text, Word.InsertLocation.start);
};

const addTextToDocumentAfterSelection = async (context: Word.RequestContext, text: string): Promise<Word.Range> => {
  const paragraphs = context.document.getSelection().paragraphs;
  paragraphs.load();

  await context.sync();

  const newParagraph = paragraphs.items[paragraphs.items.length - 1].insertParagraph("", Word.InsertLocation.after);
  newParagraph.styleBuiltIn = "Normal";
  return newParagraph.insertHtml(text, Word.InsertLocation.start);
};

const addTextToDocumentBeforeTerm = async (
  context: Word.RequestContext,
  text: string,
  keywords: string[]
): Promise<Word.Range> => {
  const documentBody = context.document.body;
  context.load(documentBody);

  await context.sync();

  const regExp = new RegExp(keywords.join("|"), "gi");
  const match = regExp.exec(documentBody.text);

  if (!match) {
    throw new KeywordsNotFoundError();
  }

  const matchString = first(match.filter((m) => !!m)) || "";
  if (!matchString) {
    throw new KeywordsNotFoundError();
  }

  const range = context.document.body.search(matchString).getFirst();
  // const range = await findRange(context, match.index + 1, match.index + 1);

  const paragraphs = range.paragraphs;
  paragraphs.load();

  await context.sync();

  const newParagraph = paragraphs.items[0].insertParagraph("", Word.InsertLocation.before);
  newParagraph.styleBuiltIn = "Normal";
  return newParagraph.insertHtml(text, Word.InsertLocation.start);
};

const addTextToDocumentAfterTerm = async (
  context: Word.RequestContext,
  text: string,
  keywords: string[]
): Promise<Word.Range> => {
  const documentBody = context.document.body;
  context.load(documentBody);

  await context.sync();

  const regExp = new RegExp(keywords.join("|"), "gi");
  const match = regExp.exec(documentBody.text);

  if (!match) {
    throw new KeywordsNotFoundError();
  }

  const matchString = first(match.filter((m) => !!m)) || "";
  if (!matchString) {
    throw new KeywordsNotFoundError();
  }

  const range = context.document.body.search(matchString).getFirst();
  // const range = await findRange(context, match.index + 1, match.index + 1);

  const paragraphs = range.paragraphs;
  paragraphs.load();

  await context.sync();

  const newParagraph = paragraphs.items[paragraphs.items.length - 1].insertParagraph("", Word.InsertLocation.after);
  newParagraph.styleBuiltIn = "Normal";
  return newParagraph.insertHtml(text, Word.InsertLocation.start);
};

export const addTextToDocument = async (
  location: AddTextLocation,
  text: string,
  options?: {
    keywords?: string[];
    select?: boolean;
  }
): Promise<void> => {
  if (!text) {
    return undefined;
  }

  return Word.run(async (context): Promise<void> => {
    let range: Word.Range | undefined = undefined;

    switch (location) {
      case AddTextLocation.Start:
        range = addTextToDocumentStart(context, text);
        break;
      case AddTextLocation.End:
        range = addTextToDocumentEnd(context, text);
        break;
      case AddTextLocation.Selection:
        range = addTextToDocumentSelection(context, text);
        break;
      case AddTextLocation.BeforeSelection:
        range = await addTextToDocumentBeforeSelection(context, text);
        break;
      case AddTextLocation.AfterSelection:
        range = await addTextToDocumentAfterSelection(context, text);
        break;
      case AddTextLocation.BeforeTerm:
        if (!options?.keywords) {
          throw new MissingKeywordsError();
        }
        range = await addTextToDocumentBeforeTerm(context, text, options.keywords);
        break;
      case AddTextLocation.AfterTerm:
        if (!options?.keywords) {
          throw new MissingKeywordsError();
        }
        range = await addTextToDocumentAfterTerm(context, text, options.keywords);
        break;
      case AddTextLocation.StageStart:
        if (!options?.keywords) {
          throw new MissingKeywordsError();
        }
        range = await addTextToDocumentAfterTerm(context, text, options.keywords);
        break;
    }
    await context.sync();

    if (options?.select) {
      range?.select();
    }
  });
};

export const clearDocument = async (): Promise<void> => {
  return Word.run(async (context) => {
    context.document.body.clear();
    await context.sync();
  });
};

export const getAddTextHtml = (
  term: Term,
  stageType: StageType,
  clauses: TermClause[],
  intl: IntlShape,
  options?: {direction?: "ltr" | "rtl"; medicalRecordFormat?: boolean}
): string => {
  const payload: ContentTypePayloadList = {
    items: clauses.map<ContentTypePayloadListItem>((clause) => ({
      uuid: uuidv4(),
      indent: clause.indent,
      payload: getClauseText(clause),
    })),
  };
  return getIntakeTemplateHtmlSection(
    {
      type: stageType,
      title: "",
      contents: [
        {
          type: ContentType.List,
          title: term.title,
          payload: JSON.stringify(payload),
        },
      ],
      pageBreak: true,
    },
    intl,
    options
  );
};
