import * as React from "react";
import {Prep} from "../../../../types/internal";
import {Controller, useFormContext} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import {first, tail, trim} from "lodash";
import Stack from "@mui/material/Stack";
import {ReactNode, useCallback, useMemo} from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import ArrowLeftOutlined from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlined from "@mui/icons-material/ArrowRightOutlined";
import useTheme from "@mui/material/styles/useTheme";

export interface PrepClauseFormProps {
  id: string;
  stageIndex: number;
  termIndex: number;
  index: number;
  remove: (index: number, focusPrevious: boolean) => void;
  insert: (index: number, texts?: string[], indent?: number) => void;
  focus: (index: number) => void;
  startAction?: ReactNode;
  disabled?: boolean;
}

const PrepClauseForm = ({
  stageIndex,
  termIndex,
  index,
  remove,
  insert,
  focus,
  startAction,
  disabled,
}: PrepClauseFormProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const {control, getValues, setValue} = useFormContext<Prep>();

  const autoFocus = useMemo<boolean>(
    () => !getValues(`stages.${stageIndex}.terms.${termIndex}.clauses.${index}.text`),
    []
  );
  const tooltip = useMemo<string>(
    () => getValues(`stages.${stageIndex}.terms.${termIndex}.clauses.${index}.tooltip`) || "",
    []
  );

  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      if (e.repeat) {
        return;
      }
      if (e.key === "Enter") {
        e.preventDefault();
        insert(index + 1, [""], getValues(`stages.${stageIndex}.terms.${termIndex}.clauses.${index}.indent`));
      }
      if (e.key === "Backspace" && (e.target as HTMLInputElement | HTMLTextAreaElement).value === "") {
        e.preventDefault();
        remove(index, true);
      }
      if (e.key === "ArrowUp") {
        focus(index - 1);
      }
      if (e.key === "ArrowDown") {
        focus(index + 1);
      }
    },
    [stageIndex, termIndex, index, insert, index, getValues, remove, focus]
  );

  const pasteHandler = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const pastedText = e.clipboardData.getData("text/plain");

      const texts = trim(pastedText)
        .split("\n")
        .filter((t) => !!trim(t));
      if (texts.length <= 1) {
        return;
      }

      e.preventDefault();

      setValue(
        `stages.${stageIndex}.terms.${termIndex}.clauses.${index}.text`,
        getValues(`stages.${stageIndex}.terms.${termIndex}.clauses.${index}.text`) + first(texts)
      );
      insert(index + 1, tail(texts));
    },
    [stageIndex, termIndex, index, setValue, getValues, insert]
  );

  return (
    <Stack spacing={3}>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {startAction}

        <Controller
          name={`stages.${stageIndex}.terms.${termIndex}.clauses.${index}.indent`}
          control={control}
          render={({field: {value, onChange}}) => {
            return (value || 0) > 0 ? (
              <Tooltip title={<FormattedMessage id="subClauseCancel" />}>
                <span>
                  <IconButton
                    color={"inherit"}
                    onClick={() => onChange(Math.max((value || 0) - 1, 0))}
                    disabled={value <= 0 || disabled}
                  >
                    {theme.direction === "ltr" ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <></>
            );
          }}
        />

        <Controller
          name={`stages.${stageIndex}.terms.${termIndex}.clauses.${index}.indent`}
          control={control}
          render={({field: {value, onChange}}) => {
            return (
              <Tooltip title={<FormattedMessage id="subClause" />}>
                <span>
                  <IconButton
                    color={"inherit"}
                    onClick={() => onChange((value || 0) + 1)}
                    disabled={value > 0 || disabled}
                  >
                    {theme.direction === "ltr" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
                  </IconButton>
                </span>
              </Tooltip>
            );
          }}
        />

        <Controller
          name={`stages.${stageIndex}.terms.${termIndex}.clauses.${index}.text`}
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                required
                fullWidth
                label={intl.formatMessage({id: "text"})}
                type="text"
                autoFocus={autoFocus}
                inputProps={{
                  maxLength: 500,
                  onKeyDown: keyDownHandler,
                  onPaste: pasteHandler,
                }}
                error={invalid}
                helperText={error?.message || tooltip}
                disabled={disabled}
              />
            );
          }}
        />

        <Tooltip title={<FormattedMessage id="removeClause" />}>
          <span>
            <IconButton color={"error"} onClick={() => remove(index, false)} disabled={disabled}>
              <DeleteOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default PrepClauseForm;
