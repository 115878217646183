import * as React from "react";
import {useMemo, useRef, useState} from "react";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import {MIconButton} from "../../../components/@material-extend";
import MenuPopover from "../../../components/common/MenuPopover";
import {FormattedMessage} from "react-intl";
import {getTermCategoryIcon, getTermCategoryLabelId} from "../../../helpers/TermUtils";
import {MedicalField, TermCategory} from "../../../types/internal";
import {getMedicalFieldIcon, getMedicalFieldLabelId} from "../../../helpers/MedicalFieldUtils";
import FiltersMenuGroup from "./FiltersMenuGroup";
import useEntityFilters from "../../../contexts/entity-filters/hooks/useEntityFilters";
import {termEntityId} from "../../../entities/term.entity";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";

export default function FiltersPopover() {
  const {filtersChangeFlag, hasActiveFilters, hasEmptyFilters, clearFilters, emptyFilters} = useEntityFilters();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const isActiveFilters = useMemo<boolean>(() => hasActiveFilters(termEntityId), [filtersChangeFlag]);
  const isEmptyFilters = useMemo<boolean>(() => hasEmptyFilters(termEntityId), [filtersChangeFlag]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAllChange = (): void => {
    if (hasActiveFilters(termEntityId)) {
      clearFilters(termEntityId);
    } else {
      emptyFilters(termEntityId);
    }
  };

  const handleClearFilters = (): void => {
    clearFilters(termEntityId);
  };

  return (
    <>
      <Tooltip title={<FormattedMessage id="filters" />}>
        <MIconButton ref={anchorRef} onClick={handleOpen} color={open ? "primary" : "default"}>
          <Badge
            badgeContent=""
            color={isEmptyFilters ? "error" : "warning"}
            variant="dot"
            invisible={!isActiveFilters}
          >
            <FilterAltOutlined fontSize={"small"} />
          </Badge>
        </MIconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 260}} arrow={false}>
        <Box sx={{display: "flex", alignItems: "center", py: 0.5, px: 2}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="h5">
              <FormattedMessage id="filters" />
            </Typography>
            {/*<Typography variant="body2" sx={{color: "text.secondary"}}>*/}
            {/*  <FormattedMessage id="filtersExplanation" />*/}
            {/*</Typography>*/}
          </Box>

          <Checkbox
            checked={!isActiveFilters}
            indeterminate={isActiveFilters && !isEmptyFilters}
            onChange={() => handleAllChange()}
          />
        </Box>

        <FiltersMenuGroup
          entityId={termEntityId}
          filtersPropertyName="medicalFields"
          filtersPropertyType={MedicalField}
          groupLabelId="medicalFields"
          getSingleLabelId={getMedicalFieldLabelId}
          getSingleIcon={getMedicalFieldIcon}
        />

        <FiltersMenuGroup
          entityId={termEntityId}
          filtersPropertyName="categories"
          filtersPropertyType={TermCategory}
          groupLabelId="categories"
          getSingleLabelId={getTermCategoryLabelId}
          getSingleIcon={getTermCategoryIcon}
        />

        {/*<Stack direction={"row"} spacing={2} sx={{p: 2}}>*/}
        {/*  <Button*/}
        {/*    variant={"contained"}*/}
        {/*    size={"small"}*/}
        {/*    color={"primary"}*/}
        {/*    sx={{flexGrow: 1}}*/}
        {/*    onClick={handleClearFilters}*/}
        {/*  >*/}
        {/*    <FormattedMessage id="clear" />*/}
        {/*  </Button>*/}
        {/*  <Button variant={"outlined"} size={"small"} color={"primary"} sx={{flexGrow: 1}} onClick={handleClose}>*/}
        {/*    <FormattedMessage id="close" />*/}
        {/*  </Button>*/}
        {/*</Stack>*/}
      </MenuPopover>
    </>
  );
}
