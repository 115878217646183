import * as React from "react";
import SimpleBarReact, {Props} from "simplebar-react";
import {alpha} from "@mui/system/colorManipulator";
import styled from "@mui/material/styles/styled";
import Box, {BoxProps} from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import {useMemo} from "react";
import useIsMobile from "../../helpers/useIsMobile";

const RootStyle = styled("div")(({theme}) => ({
  flexGrow: 1,
  height: "100%",
  overflow: "hidden",
}));

const SimpleBarStyle = styled(SimpleBarReact)(({theme}) => ({
  maxHeight: "100%",
  "& .simplebar-scrollbar": {
    "&:before": {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    "&.simplebar-visible:before": {
      opacity: 1,
    },
  },
  "& .simplebar-track.simplebar-vertical": {
    width: 10,
  },
  "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
    height: 6,
  },
  "& .simplebar-mask": {
    zIndex: "inherit",
  },
}));

// ----------------------------------------------------------------------

export default function Scrollbar({children, sx, ...other}: Omit<BoxProps, "ref"> & Props) {
  const theme = useTheme();

  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Box sx={{overflowX: "auto", ...sx}} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle direction={theme.direction} timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
