import * as React from "react";
import {PropsWithChildren, useState} from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {restrictToParentElement, restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {DragStartEvent} from "@dnd-kit/core/dist/types";
import {findIndex} from "lodash";

interface SortableDndContextProps extends PropsWithChildren<any> {
  items: {id: UniqueIdentifier}[];
  move: (oldIndex: number, newIndex: number) => void;
}

const SortableDndContext = ({items, move, children}: SortableDndContextProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [activeId, setActiveId] = useState<string | undefined>(undefined);

  const handleDragStart = (event: DragStartEvent): void => {
    const {active} = event;

    setActiveId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const {active, over} = event;

    if (active.id !== over.id) {
      const oldIndex = findIndex(items, (f) => f.id === active.id);
      const newIndex = findIndex(items, (f) => f.id === over.id);

      move(oldIndex, newIndex);
    }

    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>

      <DragOverlay dropAnimation={null} modifiers={[restrictToParentElement]}>
        {activeId ? <></> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableDndContext;
