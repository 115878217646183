import {useContext} from "react";
import {EntityShareContext, EntityShareContextProps} from "../EntityShareContext";

const useEntityShare = (): EntityShareContextProps => {
  const context = useContext(EntityShareContext);

  if (!context) throw new Error("EntityShareContext must be used inside EntityShareProvider");

  return context;
};
export default useEntityShare;
