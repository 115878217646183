import * as React from "react";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardProps} from "@mui/material/Card";
import {FormattedMessage} from "react-intl";
import TemplatesIllustration from "../../svg/TemplatesIllustration";
import {urls} from "../../../routes/urls";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import useNavigateLoggedIn from "../../../helpers/useNavigateLoggedIn";
import {useCallback} from "react";
import Stack from "@mui/material/Stack";
import useEntityNavigation from "../../../helpers/useEntityNavigation";
import {intakeTemplateEntityId} from "../../../entities/intake-template.entity";

const RootStyle = styled(Card)(({theme}) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface HomeTemplatesProps extends CardProps {}

export default function HomeTemplates({}: HomeTemplatesProps) {
  const navigate = useNavigate();
  const navigateLoggedIn = useNavigateLoggedIn();
  const {getEntityGridUrl} = useEntityNavigation();

  const navigateToUserIntakeTemplatesHandler = useCallback((): void => {
    navigateLoggedIn(getEntityGridUrl(intakeTemplateEntityId, true));
  }, [navigateLoggedIn, getEntityGridUrl]);

  const navigateToNewIntakeHandler = useCallback((): void => {
    navigate(urls.newIntake.url);
  }, [navigateLoggedIn]);

  return (
    <RootStyle>
      <CardContent sx={{height: "100%"}}>
        <Typography gutterBottom variant="h4">
          <FormattedMessage id="advancedTemplates" />
        </Typography>

        <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: "auto"}}>
          <FormattedMessage id="advancedTemplatesExplanation" />
        </Typography>

        <Stack direction={{xs: "column", lg: "row"}} alignItems={{xs: "center", md: "start"}} spacing={1}>
          <Button variant="outlined" onClick={navigateToUserIntakeTemplatesHandler} sx={{whiteSpace: "nowrap"}}>
            <FormattedMessage id="manageTemplates" />
          </Button>

          <Button variant="outlined" onClick={navigateToNewIntakeHandler} sx={{whiteSpace: "nowrap"}}>
            <FormattedMessage id="newIntake" />
          </Button>
        </Stack>
      </CardContent>

      <TemplatesIllustration
        sx={{
          p: 3,
          pl: 0,
          width: 240,
          height: 200,
          margin: {xs: "auto", md: "inherit"},
        }}
      />
    </RootStyle>
  );
}
