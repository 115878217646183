import * as React from "react";
import {FunctionComponent, PropsWithChildren} from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {FormattedMessage} from "react-intl";
import NoResultsIllustration from "../../svg/NoResultsIllustration";

export interface EmptySearchProps extends PropsWithChildren<any> {}

const EmptySearch: FunctionComponent<EmptySearchProps> = ({children}) => {
  return (
    <Stack direction="column" alignItems="center">
      <Typography variant="h3" paragraph sx={{mt: 3, color: "text.primary"}}>
        <FormattedMessage id="noResultsFound" />
      </Typography>
      <Typography sx={{textAlign: "center", color: "text.secondary", maxWidth: 400}}>
        <FormattedMessage id="searchNoResultsHelp" />
      </Typography>

      {children}

      <NoResultsIllustration sx={{height: 260, my: {xs: 3, sm: 6}}} />
    </Stack>
  );
};

export default EmptySearch;
