import Range = Word.Range;

export const findRangeFromZeroToPosition = async (context: Word.RequestContext, position: number): Promise<Range> => {
  const bodyRange = context.document.body;
  const searchString = `?{${position}}`;
  const range = bodyRange.search(searchString, {matchWildcards: true}).getFirst();
  await context.sync();
  return range;
};

export const findRange = async (context: Word.RequestContext, start: number, end: number): Promise<Range> => {
  const range_0_to_end = await findRangeFromZeroToPosition(context, end);
  if (start <= 0) {
    return range_0_to_end;
  }
  const range_0_to_start = await findRangeFromZeroToPosition(context, start);
  const range_start_to_start = range_0_to_start.getRange(Word.RangeLocation.end);
  const range_end_to_end = range_0_to_end.getRange(Word.RangeLocation.end);
  const range_start_to_end = range_start_to_start.expandTo(range_end_to_end);
  return range_start_to_end;
};

export const getRangeText = async (start: number, end: number): Promise<string> => {
  return await Word.run(async (context) => {
    const range = await findRange(context, start, end);

    // Schedule getting range text
    range.load("text");

    // Wait until Word API fills actual value of range.text
    await context.sync();

    return range.text;
  });
};

export const getDocumentText = async (): Promise<string> => {
  return await Word.run(async (context) => {
    const documentBody = context.document.body;

    context.load(documentBody);

    // Wait until Word API fills actual value of range.text
    await context.sync();

    return documentBody.text;
  });
};
