import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Page from "../../components/common/Page";
import {FormattedMessage} from "react-intl";
import {additionalSiteUrls, supportEmail} from "../../constants/defaultValues";
import useSupportUrl from "../../helpers/useSupportUrl";

export default function Support() {
  const supportUrl = useSupportUrl();

  return (
    <Page>
      <Container>
        <Stack direction="column" alignItems="center">
          <Typography variant="h3" paragraph sx={{mt: 3, color: "text.primary"}}>
            <FormattedMessage id="support" />
          </Typography>
          <Typography sx={{textAlign: "center", color: "text.secondary"}}>
            <FormattedMessage id="supportContactUs" />
          </Typography>
          <Link href={supportUrl} target="_blank" rel="noopener noreferrer" sx={{cursor: "pointer"}}>
            {supportEmail}
          </Link>

          <Typography sx={{textAlign: "center", color: "text.secondary", mt: 3}}>
            <FormattedMessage id="supportAdditionalUrls" />
          </Typography>
          {additionalSiteUrls.map((additionalSiteUrl, index) => (
            <Link
              href={additionalSiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              // We flip the direction manually and the plugin flips it again to stay ltr.
              sx={{cursor: "pointer", direction: (theme) => (theme.direction === "ltr" ? "ltr" : "rtl")}}
              key={index}
            >
              {additionalSiteUrl}
            </Link>
          ))}
          <Typography sx={{textAlign: "center", color: "text.secondary", mt: 3}}>
            <FormattedMessage id="supportAdditionalUrlsNotWorking" />
          </Typography>

          <Box sx={{my: 4}}>
            <img height="200" src={require("../../../../assets/illustration_support.png")} />
          </Box>

          <Button size="large" variant="contained" href={supportUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="contactUs" />
          </Button>
        </Stack>
      </Container>
    </Page>
  );
}
