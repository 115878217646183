import * as React from "react";
import {ComponentType, useMemo} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {FormattedMessage} from "react-intl";
import {isEmpty} from "lodash";
import useEntityFilters from "../../../contexts/entity-filters/hooks/useEntityFilters";
import {SvgIconProps} from "@mui/material/SvgIcon";

export type FiltersMenuGroupProps = {
  entityId: string;
  filtersPropertyName: string;
  filtersPropertyType: {[key: string]: string};
  groupLabelId: string;
  getSingleLabelId: (single: string) => string;
  getSingleIcon: (single: string) => ComponentType<SvgIconProps>;
};

export default function FiltersMenuGroup({
  entityId,
  filtersPropertyName,
  filtersPropertyType,
  groupLabelId,
  getSingleLabelId,
  getSingleIcon,
}: FiltersMenuGroupProps) {
  const {filtersChangeFlag, getFilters, setFilters} = useEntityFilters();

  const handleGroupChange = (): void => {
    setFilters<any, any>(entityId, (currentFilters) => ({
      ...currentFilters,
      [filtersPropertyName]:
        currentFilters[filtersPropertyName].length === Object.values(filtersPropertyType).length
          ? []
          : Object.values(filtersPropertyType),
    }));
  };

  const handleSingleChange = (single: string, checked: boolean): void => {
    setFilters<any, any>(entityId, (currentFilters) => ({
      ...currentFilters,
      [filtersPropertyName]: checked
        ? [...currentFilters[filtersPropertyName].filter((c) => c !== single), single]
        : currentFilters[filtersPropertyName].filter((c) => c !== single),
    }));
  };

  const groupChecked = useMemo<boolean>(
    () => getFilters<any, any>(entityId)[filtersPropertyName].length === Object.values(filtersPropertyType).length,
    [filtersChangeFlag]
  );
  const groupIndeterminate = useMemo<boolean>(
    () => !isEmpty(getFilters<any, any>(entityId)[filtersPropertyName]),
    [filtersChangeFlag]
  );

  return (
    <Box>
      <Divider sx={{m: "0!important"}} />

      <FilterMenuHeader
        checked={groupChecked}
        indeterminate={groupIndeterminate}
        labelId={groupLabelId}
        onClick={handleGroupChange}
      />

      <Divider sx={{m: "0!important"}} />

      {Object.values(filtersPropertyType).map((single) => {
        const checked = getFilters<any, any>(entityId)[filtersPropertyName].includes(single);
        return (
          <FilterMenuItem
            checked={checked}
            labelId={getSingleLabelId(single)}
            Icon={getSingleIcon(single)}
            onClick={() => handleSingleChange(single, !checked)}
            key={single}
          />
        );
      })}
    </Box>
  );
}

type FilterMenuHeaderProps = {
  checked: boolean;
  indeterminate: boolean;
  labelId: string;
  Icon?: ComponentType<SvgIconProps>;
  onClick: () => void;
};

function FilterMenuHeader({checked, indeterminate, labelId, Icon, onClick}: FilterMenuHeaderProps) {
  return (
    <MenuItem
      sx={{typography: "h6", py: 0.5, px: 2, textTransform: "uppercase", color: "text.primary"}}
      onClick={onClick}
    >
      {Icon && (
        <ListItemIcon>
          <Icon fontSize={"small"} />
        </ListItemIcon>
      )}
      <ListItemText disableTypography>
        <FormattedMessage id={labelId} />
      </ListItemText>
      <Checkbox checked={checked} indeterminate={!checked && indeterminate} />
    </MenuItem>
  );
}

type FilterMenuItemProps = {
  checked: boolean;
  labelId: string;
  Icon?: ComponentType<SvgIconProps>;
  onClick: () => void;
};

function FilterMenuItem({checked, labelId, Icon, onClick}: FilterMenuItemProps) {
  return (
    <MenuItem sx={{typography: "body2", py: 0, px: 2, color: "text.secondary"}} onClick={onClick}>
      {Icon && (
        <ListItemIcon>
          <Icon fontSize={"small"} />
        </ListItemIcon>
      )}
      <ListItemText>
        <FormattedMessage id={labelId} />
      </ListItemText>
      <Checkbox checked={checked} />
    </MenuItem>
  );
}
