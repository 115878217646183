import * as React from "react";
import {useCallback, useMemo} from "react";
import Tooltip from "@mui/material/Tooltip";
import {FormattedMessage} from "react-intl";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorMessage from "../../../components/common/ErrorMessage";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useActivePrep from "../../../contexts/active-prep/hooks/useActivePrep";
import {getStageTypeLabelId} from "../../../helpers/TermUtils";
import {StageType} from "../../../types/internal";
import NavigationUtils from "../../../helpers/NavigationUtils";
import {useNavigate} from "react-router-dom";
import {prepEntityId} from "../../../entities/prep.entity";
import Scrollbar from "../../../components/common/Scrollbar";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import AddOutlined from "@mui/icons-material/AddOutlined";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import FolderOutlined from "@mui/icons-material/FolderOutlined";
import ContactPageOutlined from "@mui/icons-material/ContactPageOutlined";
import useEntityNavigation from "../../../helpers/useEntityNavigation";

export interface PrepPopoverDetailsProps {
  close: () => void;
}

export default function PrepPopoverDetails({close}: PrepPopoverDetailsProps) {
  const {activePrep, loading, error, newPrep, refreshPrep, removeStage, removeTerm} = useActivePrep();
  const navigate = useNavigate();
  const {getEntityGridUrl, getEntityDetailsUrl} = useEntityNavigation();

  const prepEmpty = useMemo<boolean>(() => !activePrep?.stages.length, [activePrep]);

  const newHandler = useCallback((): void => {
    newPrep();
  }, [newPrep]);

  const refreshHandler = useCallback((): void => {
    if (!activePrep) {
      return;
    }

    refreshPrep();
  }, [activePrep, refreshPrep]);

  const createIntakeHandler = useCallback((): void => {
    close();
    navigate(NavigationUtils.getNewIntakeUrl(activePrep?.id));
  }, [activePrep, close, navigate]);

  const openDetailsHandler = useCallback((): void => {
    if (!activePrep) {
      return;
    }

    close();
    navigate(getEntityDetailsUrl(prepEntityId, activePrep.id));
  }, [activePrep, close, navigate, getEntityDetailsUrl]);

  const showAllHandler = useCallback((): void => {
    close();
    navigate(getEntityGridUrl(prepEntityId, true));
  }, [close, navigate, getEntityGridUrl]);

  const removeStageHandler = useCallback(
    (stageType: StageType): void => {
      removeStage(stageType);
    },
    [removeStage]
  );

  const removeTermHandler = useCallback(
    (stageType: StageType, termId: string): void => {
      removeTerm(stageType, termId);
    },
    [removeTerm]
  );

  return (
    <>
      {loading && (
        <Box sx={{textAlign: "center"}}>
          <CircularProgress />
        </Box>
      )}

      {error && !activePrep && <ErrorMessage />}

      {activePrep && (
        <>
          {prepEmpty ? (
            <Typography variant={"body2"} sx={{p: 2, textAlign: "center", color: "text.secondary"}}>
              <FormattedMessage id="prepEmpty" />
            </Typography>
          ) : (
            <Scrollbar sx={{maxHeight: 400}}>
              <Stack spacing={2} sx={{p: 2}}>
                {activePrep.stages.map((stage) => (
                  <Box key={stage.uuid}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography variant={"body1"} sx={{color: "text.primary", flexGrow: 1}} noWrap>
                        <FormattedMessage id={getStageTypeLabelId(stage.type)} />
                      </Typography>
                      <Tooltip title={<FormattedMessage id={"removeStage"} />}>
                        <IconButton
                          color={"error"}
                          size={"small"}
                          onClick={() => removeStageHandler(stage.type)}
                          sx={{mr: 1}}
                        >
                          <DeleteOutlined fontSize={"extraSmall"} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Box>
                      {stage.terms.map((term) => (
                        <Stack direction={"row"} alignItems={"center"} key={term.uuid}>
                          <Typography variant={"body2"} sx={{color: "text.secondary", flexGrow: 1}} noWrap>
                            {term.title}{" "}
                            <Box component={"span"} sx={{display: "inline-block", fontWeight: "bold"}}>
                              ({term.clauses.length})
                            </Box>
                          </Typography>
                          <Tooltip title={<FormattedMessage id={"removeTerm"} />}>
                            <IconButton
                              color={"error"}
                              size={"small"}
                              onClick={() => removeTermHandler(stage.type, term.termId)}
                              sx={{mr: 1.25}}
                            >
                              <DeleteOutlined fontSize={"tiny"} />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Scrollbar>
          )}

          <Divider sx={{m: "0!important"}} />

          <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{p: 2}}>
            <Button
              variant={"outlined"}
              size={"medium"}
              color={"inherit"}
              sx={{flexGrow: 1}}
              onClick={openDetailsHandler}
            >
              <FormattedMessage id="open" />
            </Button>

            {/*<Tooltip title={<FormattedMessage id="refresh" />}>*/}
            {/*  <IconButton color={"inherit"} size={"medium"} onClick={refreshHandler}>*/}
            {/*    <RefreshOutlined />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}

            <Tooltip title={<FormattedMessage id="createIntake" />}>
              <IconButton color={"inherit"} size={"medium"} onClick={createIntakeHandler}>
                <ContactPageOutlined />
              </IconButton>
            </Tooltip>

            <Tooltip title={<FormattedMessage id="showAll" />}>
              <IconButton color={"inherit"} size={"medium"} onClick={showAllHandler}>
                <FolderOutlined />
              </IconButton>
            </Tooltip>

            <Tooltip title={<FormattedMessage id="new" />}>
              <IconButton color={"inherit"} size={"medium"} onClick={newHandler}>
                <AddOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
        </>
      )}
    </>
  );
}
