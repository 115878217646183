import * as React from "react";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import {BoxProps} from "@mui/material/Box";

// ----------------------------------------------------------------------

export default function TemplatesIllustration({...other}: BoxProps) {
  const theme = useTheme();
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="100%"
        height="100%"
        viewBox="0 0 461.62247 689.07939"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect
          x="570.14983"
          y="487.64816"
          width="1.99975"
          height="432.73251"
          transform="translate(-661.39398 875.08) rotate(-68.70828)"
          fill="#cacaca"
        />
        <path
          id="a59f2875-b7c9-49fa-9edb-013b668487d1-343"
          data-name="Path 1"
          d="M658.02055,105.76711l-224.11533,59.0803a6.2094,6.2094,0,0,0-2.42184,1.323,9.68663,9.68663,0,0,0-1.96789,2.37668,12.62959,12.62959,0,0,0-1.32222,3.08969,13.02339,13.02339,0,0,0-.48564,3.489V507.59311a12.41417,12.41417,0,0,0,.48564,3.43113,11.1162,11.1162,0,0,0,1.32222,2.93917,8.15978,8.15978,0,0,0,1.96789,2.1485,5.42747,5.42747,0,0,0,2.42184,1.04178l224.11533,32.9978a8.58248,8.58248,0,0,0,3.88191-.29868,8.25751,8.25751,0,0,0,3.18791-1.90136,9.249,9.249,0,0,0,2.16119-3.20774,11.11018,11.11018,0,0,0,.79776-4.19879V114.20908a10.413,10.413,0,0,0-.79776-4.1069,7.86829,7.86829,0,0,0-2.16119-2.9558,7.15864,7.15864,0,0,0-3.18791-1.53138A8.5197,8.5197,0,0,0,658.02055,105.76711Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#f2f2f2"
        />
        <path
          id="ff4771f9-1135-4fb3-96b8-ed4410566af1-344"
          data-name="Path 2"
          d="M439.42941,174.17666a4.42692,4.42692,0,0,0-1.70724.95067,6.82657,6.82657,0,0,0-1.38006,1.67556,8.81837,8.81837,0,0,0-.91819,2.185,9.03141,9.03141,0,0,0-.31689,2.4559V500.41632a8.63531,8.63531,0,0,0,.31689,2.41551,7.77258,7.77258,0,0,0,.9166,2.07563,5.75381,5.75381,0,0,0,1.37927,1.52109,3.8645,3.8645,0,0,0,1.70725.74866L649.356,535.4597a5.83376,5.83376,0,0,0,2.65079-.29075,5.75754,5.75754,0,0,0,2.15883-1.3848,6.55175,6.55175,0,0,0,1.44265-2.26339,7.8432,7.8432,0,0,0,.50307-2.91935V127.532a7.39669,7.39669,0,0,0-.50307-2.86152,5.59,5.59,0,0,0-1.44265-2.09544,4.96033,4.96033,0,0,0-2.15883-1.13368,5.72845,5.72845,0,0,0-2.65079.01823Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="f1f9644a-fa88-428c-8322-79261417ccc5-345"
          data-name="Path 3"
          d="M448.30948,186.92913a2.67623,2.67623,0,0,0-1.15665.63378,4.66682,4.66682,0,0,0-.94037,1.20657,6.72476,6.72476,0,0,0-.63378,1.609,7.4348,7.4348,0,0,0-.23767,1.84113V236.6461a6.36071,6.36071,0,0,0,.23291,1.7429,4.33133,4.33133,0,0,0,.63378,1.35075,2.57157,2.57157,0,0,0,.94355.82391,1.87994,1.87994,0,0,0,1.15665.1632l187.63488-32.31254a4.19172,4.19172,0,0,0,1.71358-.73677,5.87508,5.87508,0,0,0,1.40462-1.44819,7.29489,7.29489,0,0,0,.95066-1.95364,7.498,7.498,0,0,0,.34937-2.24992v-54.399a6.19922,6.19922,0,0,0-.34937-2.10812,4.40235,4.40235,0,0,0-.95066-1.56781,3.19111,3.19111,0,0,0-3.1182-.919Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#f2f2f2"
        />
        <path
          id="ff7d054f-0c1b-4433-9ca3-8172c7ca7ae0-346"
          data-name="Path 4"
          d="M448.30948,262.35305a2.69917,2.69917,0,0,0-1.17091.52287,3.99917,3.99917,0,0,0-.95067,1.04416,5.33069,5.33069,0,0,0-.63378,1.41729,5.83677,5.83677,0,0,0-.2139,1.64229v94.95547a5.43995,5.43995,0,0,0,.22024,1.60425,4.36319,4.36319,0,0,0,.63378,1.30638,3.08246,3.08246,0,0,0,.95067.87779,2.29743,2.29743,0,0,0,1.17012.31689l187.63251-5.34911a4.1235,4.1235,0,0,0,1.73339-.47534,4.72657,4.72657,0,0,0,1.411-1.14636,5.376,5.376,0,0,0,.94275-1.6502,5.69225,5.69225,0,0,0,.33037-1.98057V239.16854a5.221,5.221,0,0,0-.33037-1.9259,4.26926,4.26926,0,0,0-.94275-1.48384,3.6942,3.6942,0,0,0-1.411-.89838,3.7558,3.7558,0,0,0-1.73894-.17033Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#f2f2f2"
        />
        <path
          id="a7eb0bab-ebfd-4dd9-b622-759c7763ba58-347"
          data-name="Rectangle 1"
          d="M464.749,189.50149l147.88968-33.52782v11.32884L464.749,199.21577Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="f23a1c9f-c8e9-45be-b21c-431aeafeca9c-348"
          data-name="Rectangle 2"
          d="M465.00724,209.91876l147.98237-30.13947v5.26276L465.00724,214.42969Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="a3ff34d5-a9ab-425b-b799-bea92feb2951-349"
          data-name="Rectangle 3"
          d="M465.00724,217.90044l147.98237-28.81011v5.26276L465.00724,222.41217Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="be05c005-2968-403e-b18e-d8c6e4e7ee45-350"
          data-name="Rectangle 4"
          d="M465.00724,225.88212l147.98237-27.48076v5.26277L465.00724,230.39385Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="a1b64b68-1df2-49ff-8571-8f25058de55b-351"
          data-name="Path 5"
          d="M547.05814,363.41264l75.04523-2.12475a2.33785,2.33785,0,0,0,.76371-.15211,2.573,2.573,0,0,0,.68448-.39056,2.89409,2.89409,0,0,0,.56249-.59655,3.203,3.203,0,0,0,.39611-.77,3.40016,3.40016,0,0,0,.14894-1.72864,3.18214,3.18214,0,0,0-.2543-.80807,2.85712,2.85712,0,0,0-.46028-.69557l-49.47139-54.84427a2.09619,2.09619,0,0,0-.78351-.55456,1.93534,1.93534,0,0,0-.88967-.12675,2.16993,2.16993,0,0,0-.88809.28757,2.70154,2.70154,0,0,0-.77954.69162l-11.50947,14.87326a5.41093,5.41093,0,0,1-1.59317,1.40779,4.29628,4.29628,0,0,1-1.80627.56327,3.87714,3.87714,0,0,1-1.79994-.29392,4.31681,4.31681,0,0,1-1.57732-1.163h0l-33.92077-38.97675a1.80945,1.80945,0,0,0-.69874-.50385,1.62333,1.62333,0,0,0-.79223-.10062,1.90141,1.90141,0,0,0-.79223.29154,2.55171,2.55171,0,0,0-.69557.67023l-59.59524,82.99443a2.71581,2.71581,0,0,0-.32481.60764,3.21413,3.21413,0,0,0-.179.69082,3.40587,3.40587,0,0,0-.02694.72727,3.205,3.205,0,0,0,.1323.713,2.70007,2.70007,0,0,0,.28124.62745,2.10721,2.10721,0,0,0,.39612.47533,1.62666,1.62666,0,0,0,.484.29867,1.38168,1.38168,0,0,0,.54188.09428l89.4907-2.56919Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="f3c84d7c-5146-4d23-9ceb-c01f372468d7-352"
          data-name="Ellipse 1"
          d="M470.71523,272.32164a5.3508,5.3508,0,0,1,2.852.39611,6.35923,6.35923,0,0,1,2.349,1.79519,8.97987,8.97987,0,0,1,1.58445,2.92649,11.9624,11.9624,0,0,1,.58387,3.76466,13.3339,13.3339,0,0,1-.58387,3.90646,12.03639,12.03639,0,0,1-1.58445,3.3131,9.07673,9.07673,0,0,1-2.345,2.36955,6.34964,6.34964,0,0,1-2.852,1.08693,5.35063,5.35063,0,0,1-2.837-.45157,6.42642,6.42642,0,0,1-2.30379-1.82687,9.05257,9.05257,0,0,1-1.55039-2.90906,11.9848,11.9848,0,0,1-.56565-3.7092,13.33771,13.33771,0,0,1,.56565-3.84706,12.09482,12.09482,0,0,1,1.54642-3.28536,9.19014,9.19014,0,0,1,2.3038-2.387A6.42977,6.42977,0,0,1,470.71523,272.32164Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="b0182b23-dc59-4ff3-b3b4-a3d940efaefe-353"
          data-name="Path 6"
          d="M448.30948,388.34322a2.35062,2.35062,0,0,0-1.17091.35255,3.22978,3.22978,0,0,0-.9475.90709,4.52675,4.52675,0,0,0-.63378,1.32619,5.50969,5.50969,0,0,0-.21707,1.61218v94.95548a5.76469,5.76469,0,0,0,.22024,1.63674,5.14917,5.14917,0,0,0,.63378,1.39749,3.81773,3.81773,0,0,0,.95067,1.014,2.61431,2.61431,0,0,0,1.17012.48564l187.627,21.76325a3.7961,3.7961,0,0,0,1.73339-.225,3.854,3.854,0,0,0,1.411-.94275,4.452,4.452,0,0,0,.94275-1.51394,5.3031,5.3031,0,0,0,.33037-1.9362V392.91041a5.60645,5.60645,0,0,0-.33037-1.97344,5.16389,5.16389,0,0,0-.94275-1.6201,4.5158,4.5158,0,0,0-1.411-1.102,4.03163,4.03163,0,0,0-1.73339-.42146Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#f2f2f2"
        />
        <path
          id="a989dc09-34cc-41d2-9960-aebf8ec98c6b-354"
          data-name="Path 7"
          d="M547.05814,503.67318l75.04206,8.71925a2.21822,2.21822,0,0,0,.76371-.042,2.16765,2.16765,0,0,0,.6837-.29234,2.33382,2.33382,0,0,0,.56249-.51574,2.67548,2.67548,0,0,0,.39611-.713,3.10183,3.10183,0,0,0,.18776-.83738,3.45013,3.45013,0,0,0-.03882-.87145,3.5924,3.5924,0,0,0-.2543-.84531,3.47167,3.47167,0,0,0-.46028-.76212l-49.46744-61.98934a2.57637,2.57637,0,0,0-.7843-.66468,2.11209,2.11209,0,0,0-.88968-.2551,1.96473,1.96473,0,0,0-.88808.15845,2.193,2.193,0,0,0-.77955.57832L559.62285,458.548a4.39286,4.39286,0,0,1-1.59317,1.178,3.90412,3.90412,0,0,1-1.80627.30263,4.259,4.259,0,0,1-1.79994-.55456,5.31323,5.31323,0,0,1-1.57653-1.39036h0L518.92538,414.2015a2.2253,2.2253,0,0,0-.69954-.60447,1.76019,1.76019,0,0,0-.79222-.21469,1.69931,1.69931,0,0,0-.79223.17666,2.06454,2.06454,0,0,0-.69953.56882l-59.59049,74.39007a2.26128,2.26128,0,0,0-.32481.56089,2.88853,2.88853,0,0,0-.179.66468,3.34967,3.34967,0,0,0-.02694.7233,3.45684,3.45684,0,0,0,.13151.73519,3.16241,3.16241,0,0,0,.28124.66864,2.598,2.598,0,0,0,.39611.53317,1.98788,1.98788,0,0,0,.48405.36838,1.52591,1.52591,0,0,0,.54189.17271l89.49228,10.36Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="a8bd342c-5bae-4e5f-bf88-a468257dce96-355"
          data-name="Ellipse 2"
          d="M470.71523,401.54964a5.82449,5.82449,0,0,1,2.852.8049,7.84316,7.84316,0,0,1,2.345,2.139,10.71241,10.71241,0,0,1,1.58445,3.15623,12.76606,12.76606,0,0,1,.58387,3.84864,12.50856,12.50856,0,0,1-.58387,3.82249,10.14035,10.14035,0,0,1-1.58445,3.08335,7.33365,7.33365,0,0,1-2.345,2.03047,5.6383,5.6383,0,0,1-2.852.67419,5.87131,5.87131,0,0,1-2.837-.86194,7.93519,7.93519,0,0,1-2.3038-2.15961,10.77909,10.77909,0,0,1-1.54642-3.13326,12.77782,12.77782,0,0,1-.56565-3.7908,12.52332,12.52332,0,0,1,.56565-3.76546,10.20788,10.20788,0,0,1,1.54642-3.062,7.41851,7.41851,0,0,1,2.3038-2.05425A5.67,5.67,0,0,1,470.71523,401.54964Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="e9ee41aa-d78e-4dad-8172-13013ca5924f-356"
          data-name="Path 8"
          d="M501.23417,230.17044a4.74068,4.74068,0,0,0-1.95125.84927,6.67036,6.67036,0,0,0-1.58445,1.66367,8.39753,8.39753,0,0,0-1.06792,2.24042,8.79348,8.79348,0,0,0-.39612,2.58187V596.73605a9.09244,9.09244,0,0,0,1.46087,4.90388,7.25949,7.25949,0,0,0,1.58445,1.754,5.054,5.054,0,0,0,1.95205.96258l266.15405,61.43082a7.83785,7.83785,0,0,0,3.2521.08873,6.31881,6.31881,0,0,0,2.6698-1.18834,6.12777,6.12777,0,0,0,1.80787-2.26416,7.3416,7.3416,0,0,0,.66626-3.12931V190.88789a7.59826,7.59826,0,0,0-.66626-3.16891,6.64763,6.64763,0,0,0-1.80787-2.36717,6.74417,6.74417,0,0,0-2.669-1.34124,7.84306,7.84306,0,0,0-3.25209-.09665Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#e6e6e6"
        />
        <path
          id="ad8bd23c-a107-4e3a-ac50-43d14955a740-357"
          data-name="Path 9"
          d="M507.37948,239.28342a4.87132,4.87132,0,0,0-1.98691.88334,6.77286,6.77286,0,0,0-1.60426,1.70091,8.449,8.449,0,0,0-1.06554,2.27764,8.81812,8.81812,0,0,0-.37076,2.61911V590.71985a9.14144,9.14144,0,0,0,1.4363,4.98311,7.40325,7.40325,0,0,0,1.60426,1.79757,5.217,5.217,0,0,0,1.9869,1.00218l249.05781,55.53982a7.60616,7.60616,0,0,0,3.20774,0,6.16821,6.16821,0,0,0,4.35724-3.57692,7.50618,7.50618,0,0,0,.60924-3.15227V205.94019a7.764,7.764,0,0,0-.60924-3.18871,6.70142,6.70142,0,0,0-4.35724-3.83913,7.648,7.648,0,0,0-3.20774-.19013Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          id="fe01f6ec-bc29-4a35-b65e-2953f7e4593e-358"
          data-name="Path 10"
          d="M539.17707,286.12777a2.2039,2.2039,0,0,0-.94117.33987,2.89549,2.89549,0,0,0-.76687.72726,3.57613,3.57613,0,0,0-.51733,1.01088,3.825,3.825,0,0,0-.19013,1.18834V303.1646a3.48605,3.48605,0,0,0,.19013,1.15031,2.799,2.799,0,0,0,.51654.90552,2.17394,2.17394,0,0,0,.76687.57595,1.91333,1.91333,0,0,0,.94117.15369l167.75555-14.79958a3.38826,3.38826,0,0,0,1.30083-.39612,3.80753,3.80753,0,0,0,1.064-.85243,4.01992,4.01992,0,0,0,.71935-1.18834,3.89057,3.89057,0,0,0,.267-1.39749V271.05328a3.486,3.486,0,0,0-.2646-1.34678,3.08114,3.08114,0,0,0-.71935-1.04812,2.89639,2.89639,0,0,0-1.064-.64329,3.02481,3.02481,0,0,0-1.3032-.14022Z"
          transform="translate(-369.18877 -105.46031)"
          fill={PRIMARY_MAIN}
        />
        <path
          id="a33e5ebf-72bd-4c05-92f5-6fdaeb6a0cf5-359"
          data-name="Path 11"
          d="M538.27,331.8749a.52768.52768,0,0,0-.23767.07367.67572.67572,0,0,0-.19172.17271.846.846,0,0,0-.12913.24638.93667.93667,0,0,0-.04754.29471v5.3808a.88654.88654,0,0,0,.04754.28916.73188.73188,0,0,0,.12913.23133.57121.57121,0,0,0,.19172.15053.48643.48643,0,0,0,.23767.04674l170.75492-9.25558a.82865.82865,0,0,0,.3264-.08715.90707.90707,0,0,0,.26618-.20439.95773.95773,0,0,0,.24559-.63933v-6.34336a.89339.89339,0,0,0-.06575-.34065.82052.82052,0,0,0-.17984-.27015.76683.76683,0,0,0-.59258-.22341Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="eadd9508-da65-422a-9b99-851349c5ee47-360"
          data-name="Path 12"
          d="M538.27,349.376a.51956.51956,0,0,0-.23767.06972.65822.65822,0,0,0-.19172.16953.82709.82709,0,0,0-.12913.244.92874.92874,0,0,0-.04754.29391v5.38081a.89365.89365,0,0,0,.04754.29074.74856.74856,0,0,0,.12913.23767.58541.58541,0,0,0,.19172.15369.49126.49126,0,0,0,.23767.05071l170.75492-6.15244a.81758.81758,0,0,0,.3264-.07922.88325.88325,0,0,0,.26618-.19964.94121.94121,0,0,0,.24559-.63378v-6.34732a.903.903,0,0,0-.06575-.34145.84132.84132,0,0,0-.17984-.27332.79223.79223,0,0,0-.26618-.17825.77959.77959,0,0,0-.3264-.05545Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="bc09ff08-382d-4367-9371-da9c20848a93-361"
          data-name="Path 13"
          d="M538.27,366.87784a.51265.51265,0,0,0-.23767.065.641.641,0,0,0-.18934.16558.809.809,0,0,0-.12914.24163.92073.92073,0,0,0-.04753.29312v5.38081a.90167.90167,0,0,0,.04753.29153.76688.76688,0,0,0,.12914.23767.602.602,0,0,0,.19172.15845.49744.49744,0,0,0,.23766.05545l170.75255-3.04373a.80655.80655,0,0,0,.3264-.07922.86031.86031,0,0,0,.26618-.1941.91341.91341,0,0,0,.17984-.28441.93388.93388,0,0,0,.06575-.34541V363.4784a.91186.91186,0,0,0-.06575-.343.86259.86259,0,0,0-.17984-.27648.81118.81118,0,0,0-.26618-.187.78661.78661,0,0,0-.3264-.05862Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="ad3c0260-65a9-4a32-af85-b637d7424861-362"
          data-name="Path 14"
          d="M537.96813,401.83324a.49907.49907,0,0,0-.23767.05624.60686.60686,0,0,0-.19171.15845.77241.77241,0,0,0-.12676.23767.90472.90472,0,0,0-.04753.29074v5.37922a.91736.91736,0,0,0,.04753.29313.80183.80183,0,0,0,.12913.23767.63393.63393,0,0,0,.19172.16478.50952.50952,0,0,0,.23767.06338L708.609,411.87708a.79222.79222,0,0,0,.3256-.06337.81839.81839,0,0,0,.26619-.18459.8715.8715,0,0,0,.17984-.27807.91735.91735,0,0,0,.06575-.343v-6.34019a.93247.93247,0,0,0-.06575-.34541.90448.90448,0,0,0-.17984-.28361.85334.85334,0,0,0-.26619-.19252.80327.80327,0,0,0-.3256-.07367Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="a7ce4661-d9dd-4e3c-943a-15eecdd16d68-363"
          data-name="Path 15"
          d="M537.96813,419.32957a.49277.49277,0,0,0-.23767.05229.59093.59093,0,0,0-.19171.15844.75517.75517,0,0,0-.12914.23767.89658.89658,0,0,0-.04515.28362v5.37922a.925.925,0,0,0,.04753.29391.81919.81919,0,0,0,.12913.24322.65138.65138,0,0,0,.19172.168.51666.51666,0,0,0,.23767.06813l170.63846,6.2673a.78186.78186,0,0,0,.3256-.05783.79777.79777,0,0,0,.26619-.17983.84792.84792,0,0,0,.17984-.27491.90788.90788,0,0,0,.06575-.34224v-6.34177a.94132.94132,0,0,0-.06575-.34621.927.927,0,0,0-.17984-.28678.87153.87153,0,0,0-.26619-.19727.8121.8121,0,0,0-.3256-.07922Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="bf855cdb-17eb-4d24-b1bf-95bb84ebbe5f-364"
          data-name="Path 16"
          d="M537.96813,436.82431a.48719.48719,0,0,0-.23529.05071.576.576,0,0,0-.19172.14973.73764.73764,0,0,0-.12913.23212.88794.88794,0,0,0-.04753.28916v5.37922a.9323.9323,0,0,0,.04753.29471.83811.83811,0,0,0,.12913.24559.668.668,0,0,0,.19172.17112.52364.52364,0,0,0,.23767.07209l170.63846,9.372a.77082.77082,0,0,0,.59179-.22657.827.827,0,0,0,.17984-.27174.89836.89836,0,0,0,.06575-.34066v-6.34177a.9507.9507,0,0,0-.24559-.63378.89694.89694,0,0,0-.26619-.202.823.823,0,0,0-.3256-.08556Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="e51942a0-1f8f-4627-b592-b6d8af379f3b-365"
          data-name="Path 17"
          d="M537.96813,454.407a.48249.48249,0,0,0-.23767.04358.56008.56008,0,0,0-.18934.14893.721.721,0,0,0-.12913.22975.88007.88007,0,0,0-.04753.28916v5.37922a.9401.9401,0,0,0,.04753.2955.85712.85712,0,0,0,.12913.248.687.687,0,0,0,.19172.17508.53225.53225,0,0,0,.23767.07922l170.63528,12.48945a.76741.76741,0,0,0,.3256-.04595.75806.75806,0,0,0,.26619-.17032.80814.80814,0,0,0,.17984-.26778.88876.88876,0,0,0,.06575-.33986v-6.34257a.96014.96014,0,0,0-.06575-.34858.97437.97437,0,0,0-.17984-.29312.92117.92117,0,0,0-.26619-.20756.83493.83493,0,0,0-.3256-.09111Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="b42b5129-059d-45a1-85e1-48e92c6af303-366"
          data-name="Path 18"
          d="M537.96813,471.90252a.47529.47529,0,0,0-.23767.03961.54679.54679,0,0,0-.18934.14735.70439.70439,0,0,0-.12913.225.87119.87119,0,0,0-.04753.28758v5.37921a.95049.95049,0,0,0,.04753.2963.877.877,0,0,0,.12913.25034.70521.70521,0,0,0,.19172.17825.54029.54029,0,0,0,.23767.07922l170.63528,15.59894a.7628.7628,0,0,0,.3256-.03961.7407.7407,0,0,0,.26619-.16557.79226.79226,0,0,0,.17984-.26461.88015.88015,0,0,0,.06575-.33828v-6.34256a.9696.9696,0,0,0-.06575-.35017.99836.99836,0,0,0-.17984-.29629.94512.94512,0,0,0-.26619-.21232.846.846,0,0,0-.3256-.09744Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="a62b36ea-5ff6-4d9e-9431-581d8c5a76b9-367"
          data-name="Path 19"
          d="M537.96813,489.39806a.47541.47541,0,0,0-.23767.03485.53257.53257,0,0,0-.18934.141.68774.68774,0,0,0-.12913.22578.86485.86485,0,0,0-.04753.28758v5.37922a.95685.95685,0,0,0,.04753.29708.8961.8961,0,0,0,.12913.25273.72418.72418,0,0,0,.19172.18221.54984.54984,0,0,0,.23767.08477l170.63528,18.70129a.75806.75806,0,0,0,.3256-.03406.72324.72324,0,0,0,.26619-.15845.76818.76818,0,0,0,.17984-.26143.87155.87155,0,0,0,.06575-.33749V507.849a.97919.97919,0,0,0-.06575-.351,1.02272,1.02272,0,0,0-.17984-.29946.97058.97058,0,0,0-.26619-.21707.85955.85955,0,0,0-.3256-.103Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="b5b5e049-9a07-4625-b229-28348b3ffad1-368"
          data-name="Path 20"
          d="M537.96813,506.98152a.47536.47536,0,0,0-.23767.0309.51811.51811,0,0,0-.18934.13389.67177.67177,0,0,0-.12913.22657.85713.85713,0,0,0-.04753.286v5.37921a.965.965,0,0,0,.04753.29788.91587.91587,0,0,0,.12913.2551.74489.74489,0,0,0,.19172.18538.5593.5593,0,0,0,.23767.08952l170.63528,21.82267a.75326.75326,0,0,0,.3256-.02773.70515.70515,0,0,0,.26619-.15844.74941.74941,0,0,0,.17984-.25827.86192.86192,0,0,0,.06575-.3359v-6.34019a.98848.98848,0,0,0-.06575-.35254,1.04738,1.04738,0,0,0-.17984-.30263.99713.99713,0,0,0-.26619-.22182.87124.87124,0,0,0-.3256-.10933Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="f6f9b566-9275-4af8-af1b-7e049f94fc7f-369"
          data-name="Path 21"
          d="M537.96813,524.477a.4675.4675,0,0,0-.23767.02694.50632.50632,0,0,0-.18934.13389.65515.65515,0,0,0-.12913.22023.84992.84992,0,0,0-.04753.28521v5.37921a.97247.97247,0,0,0,.04753.29867.93665.93665,0,0,0,.12913.25748.76626.76626,0,0,0,.19172.18855.5696.5696,0,0,0,.23767.09348l170.63528,24.9282a.75184.75184,0,0,0,.3256-.02218.69.69,0,0,0,.26619-.15052.7306.7306,0,0,0,.17984-.2551.85315.85315,0,0,0,.06575-.33511v-6.34257a.99876.99876,0,0,0-.06575-.35333,1.07249,1.07249,0,0,0-.17984-.3058,1.0299,1.0299,0,0,0-.26619-.22658.88891.88891,0,0,0-.3256-.11487Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="e06574a6-0006-4f8e-a738-8224ce6b4da4-370"
          data-name="Path 22"
          d="M537.96813,541.97257a.46426.46426,0,0,0-.23767.02218.49363.49363,0,0,0-.18934.13072.64091.64091,0,0,0-.12913.21786.842.842,0,0,0-.04753.28441V548.007a.9814.9814,0,0,0,.04753.30025.9571.9571,0,0,0,.12913.25985.78491.78491,0,0,0,.19172.19251.58234.58234,0,0,0,.23767.09745l170.63528,28.03214a.74868.74868,0,0,0,.3256-.01584.67412.67412,0,0,0,.26619-.14577.71294.71294,0,0,0,.17984-.25193.84462.84462,0,0,0,.06575-.33353v-6.34336a1.00756,1.00756,0,0,0-.06575-.35492,1.09809,1.09809,0,0,0-.17984-.30976,1.0537,1.0537,0,0,0-.26619-.23133.90485.90485,0,0,0-.3256-.12121Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ccc"
        />
        <path
          id="e3953bee-b9b0-4931-b9d5-5ac270d957ab-371"
          data-name="Ellipse 3"
          d="M760.0205,567.9394a76.43554,76.43554,0,0,1,26.584,9.39818,90.69969,90.69969,0,0,1,38.335,43.79111,76.648,76.648,0,0,1,5.868,28.92261,59.75843,59.75843,0,0,1-5.868,26.49839A50.3967,50.3967,0,0,1,809.213,695.29535a52.39156,52.39156,0,0,1-22.60856,9.20567,64.46659,64.46659,0,0,1-26.58158-1.58445,80.67269,80.67269,0,0,1-25.43839-11.89449,90.88119,90.88119,0,0,1-19.97678-19.08473,88.65726,88.65726,0,0,1-13.08441-23.6242,75.842,75.842,0,0,1-4.72246-25.947,60.5687,60.5687,0,0,1,4.72246-23.995,50.47351,50.47351,0,0,1,33.06119-29.04777A60.36745,60.36745,0,0,1,760.0205,567.9394Z"
          transform="translate(-369.18877 -105.46031)"
          fill={PRIMARY_MAIN}
        />
        <path
          id="e335b25d-cb3d-4c1d-842d-b9df0290eb25-372"
          data-name="Path 23"
          d="M753.6922,662.456l-19.45074-29.89229,11.23219-6.68085,9.30786,14.11747,32.56923-28.01312,11.29874,12.632Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#fff"
        />
        <path
          d="M611.67929,627.62363l-3.51564-48.71706,6.31392-45.65249-17.932-1.21079L593.006,582.4726l10.00367,47.42293a7.05468,7.05468,0,1,0,8.6696-2.2719Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#9f616a"
        />
        <path
          d="M594.25775,538.60458l4.21066-18.22333A14.03069,14.03069,0,0,1,626.08149,525.11l-2.3188,20.59168Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#cbcbcb"
        />
        <polygon
          points="258.266 680.885 266.72 680.884 270.741 648.277 258.265 648.278 258.266 680.885"
          fill="#9f616a"
        />
        <path
          d="M625.29836,783.58509l16.64839-.00067h.00067a10.61023,10.61023,0,0,1,10.60966,10.60949v.34477l-27.25822.001Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <polygon
          points="300.382 680.608 308.509 678.28 303.397 645.826 291.402 649.262 300.382 680.608"
          fill="#9f616a"
        />
        <path
          d="M666.73775,784.00868l16.00468-4.58469.00064-.00019a10.61022,10.61022,0,0,1,13.12082,7.27808l.09493.33144-26.20429,7.50637Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <path
          d="M622.88564,774.51l3.93249-98.77439L616.60544,631.0355c-13.7431-23.04211,1.08327-59.7963,1.23477-60.165l.05514-.13439.14412-.0197,35.94992-4.87066.505,1.15884a172.15656,172.15656,0,0,1,11.18421,102.25262L661.937,688.39474,680.42936,763.058l-17.50156,8.75085-13.42792-34.82124-9.07662,35.62661Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <path
          d="M613.1053,580.03164,601.37712,545.667c-7.291-12.83976-2.66634-24.23885,2.49736-31.54041,5.57622-7.88443,5.911-12.30613,5.98494-12.35511l.05969-.03938,15.40887-.46693,14.261,14.49113c.89706-.35051,8.4743,18.32257,7.832,19.26136l9.96015,32.19712Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#cbcbcb"
        />
        <path
          d="M708.06927,585.97477l-38.63452-29.88453-29.7936-35.16186-12.86586,12.54955,35.20282,36.2823,41.99925,24.18824a7.05468,7.05468,0,1,0,4.09191-7.9737Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#9f616a"
        />
        <path
          d="M623.119,546.19249l-10.76512-15.29483a14.03068,14.03068,0,0,1,21.94541-17.41382l13.79151,15.46571Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#cbcbcb"
        />
        <circle cx="246.62246" cy="373.2934" r="18.29809" fill="#9f616a" />
        <path
          d="M634.41678,469.47078h-29.0552V456.80569c6.37728-2.53358,12.61779-4.68825,16.39011,0a12.66517,12.66517,0,0,1,12.66509,12.66508Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <path
          d="M609.36614,453.7688c-26.62095-.727-21.58,43.12965-21.58,43.12965s5.0225.66424,7.31427,1.05451l2.76771-1.55861,2.97807,1.95317c1.36742.00682,2.804-.01965,4.29324-.04729l1.36524-2.81114,3.0444,2.76071c5.54155.00824,20.58139.161,20.58139.161S639.194,454.58334,609.36614,453.7688Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <path
          d="M502.10675,528.91077a5.91046,5.91046,0,0,0-6.18919-6.62056l-5.84875-12.1744-8.26522,1.74983,8.501,17.0766a5.9425,5.9425,0,0,0,11.80213-.03147Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#ffb7b7"
        />
        <polygon points="5.947 564.801 12.826 568.322 29.679 543.463 19.526 538.268 5.947 564.801" fill="#ffb7b7" />
        <path
          d="M374.53084,667.11776l13.54749,6.93292.00055.00028a9.69889,9.69889,0,0,1,4.21486,13.05176l-.14358.28055L369.969,676.032Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <polygon points="85.398 574.432 93.125 574.432 96.801 544.625 85.396 544.626 85.398 574.432" fill="#ffb7b7" />
        <path
          d="M452.61527,677.36959l15.21841-.00061h.00062a9.69888,9.69888,0,0,1,9.69836,9.69821v.31515l-24.91693.00093Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <path
          d="M418.43987,436.76888s-5.17476-3.96457-5.17476,5.71947l-.81706,32.00179,9.12391,30.23148,5.31094-9.80481-2.17885-21.24374Z"
          transform="translate(-369.18877 -105.46031)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M473.79467,524.2994s6.00065,29.22316-1.60017,53.20577l-4.20046,92.21-15.40167-1.20013-5.40059-68.40742-5.20056-34.0037L431.19005,597.1073l-35.60387,65.60712L379.1844,649.913s18.1758-49.59388,31.60343-60.00651l6.73439-76.70157Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <circle cx="86.34948" cy="274.67604" r="17.56122" fill="#ffb8b8" />
        <path
          d="M454.29547,383.86115c2.10515.27391,3.69313-1.88,4.42965-3.871s1.2977-4.309,3.128-5.38454c2.50055-1.46937,5.6999.2979,8.55941-.1869,3.22928-.54748,5.32891-3.96988,5.49347-7.2411s-1.13724-6.41744-2.41454-9.43348l-.44593,3.74825a7.433,7.433,0,0,0-3.24817-6.49709l.57475,5.5a5.83741,5.83741,0,0,0-6.71565-4.83024l.09052,3.27709a54.12986,54.12986,0,0,0-11.23309-.54976,18.1674,18.1674,0,0,0-10.3431,3.98512c-4.2411,3.68523-5.79,9.75339-5.27,15.3478s2.82937,10.85,5.23625,15.92684c.60558,1.27736,1.44317,2.71877,2.84741,2.88148a2.86829,2.86829,0,0,0,2.80844-2.11659,7.67206,7.67206,0,0,0-.03406-3.76879,16.02482,16.02482,0,0,1-.46854-5.70261c.33363-1.88954,1.693-3.75677,3.5977-3.98834s3.85378,1.94566,2.938,3.63179Z"
          transform="translate(-369.18877 -105.46031)"
          fill="#2f2e41"
        />
        <path
          d="M438.73037,404.0862l3.6768-5.10469s4.10965,1.39662,15.11574,6.875l.77758,4.78266,19.10438,117.505-34.72534-1.498-9.45636-.2012-6.91061-.147-9.23447-.19648-.13618-33.7721,2.99592-25.87379-4.63005-24.37584s-5.82619-22.38385,16.6137-34.453Z"
          transform="translate(-369.18877 -105.46031)"
          fill={PRIMARY_MAIN}
        />
        <polygon
          points="117.668 417.199 118.929 421.612 127.753 415.864 124.423 412.053 117.668 417.199"
          fill="#cbcbcb"
        />
        <path
          d="M445.29891,418.28831s13.679-17.46333,21.94671,2.00033l2.60661,20.397s10.01567,25.35341,10.31691,35.61131c0,0,6.79042,13.38767,6.728,20.41942l10.045,22.74123-10.30272,6.39619-28.51375-43.71954Z"
          transform="translate(-369.18877 -105.46031)"
          fill={PRIMARY_MAIN}
        />
      </svg>
    </Box>
  );
}
