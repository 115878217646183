import * as React from "react";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import {BoxProps} from "@mui/material/Box";

export default function LogoIcon({sx}: BoxProps) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{width: 24, height: 24, ...sx}}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 121 121"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M57.86,22.35 C65.48,14.4 70.81,7.54 82.55,6.19 C104.59,3.66 124.86,26.22 113.73,48.43 C113.56,48.77 113.37,49.12 113.18,49.47 L105.07,49.47 L105.07,36.69 C105.07,33.47 103.76,30.55 101.64,28.43 C99.53,26.32 96.6,25 93.38,25 L78.64,25 C75.44,25 72.51,26.32 70.39,28.45 C68.28,30.57 66.97,33.48 66.97,36.68 L66.97,49.46 L54.17,49.46 C50.95,49.46 48.03,50.77 45.91,52.89 C43.79,55.01 42.48,57.93 42.48,61.14 L42.48,75.9 C42.48,79.12 43.79,82.04 45.91,84.16 C48.03,86.28 50.95,87.59 54.17,87.59 L66.95,87.59 L66.95,99.12 L57.86,108.13 L44.19,94.98 C27.74,79.14 0.9,59.19 0.02,34.48 C-0.6,17.17 13.06,6.08 28.77,6.28 C42.81,6.47 48.72,13.45 57.86,22.35 L57.86,22.35 L57.86,22.35 L57.86,22.35 Z"
            fill={PRIMARY_MAIN}
          />
          <path
            d="M78.64,31.68 L93.4,31.68 C96.16,31.68 98.41,33.94 98.41,36.69 L98.41,56.15 L117.87,56.15 C120.63,56.15 122.88,58.41 122.88,61.16 L122.88,75.91 C122.88,78.67 120.62,80.93 117.87,80.93 L98.41,80.93 L98.41,99.96 C98.41,102.72 96.15,104.97 93.4,104.97 L78.64,104.97 C75.88,104.97 73.63,102.71 73.63,99.96 L73.63,80.93 L54.17,80.93 C51.41,80.93 49.16,78.67 49.16,75.91 L49.16,61.15 C49.16,58.39 51.42,56.14 54.17,56.14 L73.63,56.14 L73.63,36.69 C73.63,33.93 75.88,31.68 78.64,31.68 L78.64,31.68 L78.64,31.68 Z"
            fill="#DA5652"
          />
        </g>
      </svg>
    </Box>
  );
}
