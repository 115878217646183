import {GridColDef} from "@mui/x-data-grid";
import {IntlShape} from "react-intl";
import {ComponentType} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon";

export interface EntityColumn extends GridColDef {
  headerNameId: string;
  descriptionId?: string;
  type?: "string" | "number" | "date" | "dateTime" | "boolean" | "singleSelect";
}

export interface EntityAction {
  id: string;
  Icon: ComponentType<SvgIconProps>;
  titleId: string;
  tooltipId?: string;
  officeActive?: boolean;
}

export interface Entity<EntityRO extends BaseEntityRO, FiltersRO> {
  id: string;
  deprecatedIds?: string[];
  filtersId?: string;
  titleId: string;
  collection: string;
  persist: boolean;
  creatable: boolean;
  deletable: boolean;
  shareable: boolean;
  publishable: boolean; // If true make sure the RO extends PublishableEntityRO
  roles: Partial<Record<EntityAccessType, string>>;
  defaultOrderBy: {field: string; direction: "asc" | "desc"};
  actions: EntityAction[];
  columns: EntityColumn[];
  formComponent: ComponentType<EntityFormComponentProps<EntityRO, FiltersRO>>;
  getItemTitle: (item: EntityRO) => string | undefined;
  getItemDetailsUrl?: (itemId: string) => string | undefined;
  generateEmptyItem: () => EntityRO;
  generateSaveItem: (item: EntityRO, data: EntityRO) => EntityRO;
  generateCloneItem: (item: EntityRO) => EntityRO;
  generateEmptyFilters: () => FiltersRO;
  generateFullFilters: () => FiltersRO;
  hasActiveFilters: (filters: FiltersRO) => boolean;
  hasEmptyFilters: (filters: FiltersRO) => boolean;
  filterItems: (items: EntityRO[], filters: FiltersRO) => EntityRO[];
  filterDataItems: (items: EntityRO[]) => EntityRO[];
  searchItems: (items: EntityRO[], search: string) => EntityRO[];
}

export interface BaseEntityRO {
  id: string;
  creationTime: number;
  lastUpdateTime: number;
  userIds: string[];
  deleted: boolean;
  deletionTime?: number;
}

export interface PublishableEntityRO extends BaseEntityRO {
  publishStatus?: EntityPublishStatus;
  publishReason?: string;
  publishRequestId?: string;
  publishFullName?: string;
  publishTime?: number;
  publishUserId?: string;
}

export type EntityFactory<EntityRO extends BaseEntityRO, FiltersRO> = (intl: IntlShape) => Entity<EntityRO, FiltersRO>;

export interface EntityFormComponentProps<EntityRO extends BaseEntityRO, FiltersRO> {
  entity: Entity<EntityRO, FiltersRO>;
  item?: EntityRO;
  disabled?: boolean;
  action?: {actionId: string};
}

export type EntityAccessType = "read" | "write";

export enum EntityPublishStatus {
  None = "None",
  WaitingForApproval = "WaitingForApproval",
  Approved = "Approved",
  Rejected = "Rejected",
}
