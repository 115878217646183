import * as React from "react";
import {ComponentType, useCallback, useMemo, useRef, useState} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon";
import {MIconButton} from "../@material-extend";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import {get, isEmpty} from "lodash";
import IconMenuItem from "../common/IconMenuItem";
import MenuPopover from "../common/MenuPopover";
import {ActionsMenuItem} from "./ActionsMenuGeneric";
import Badge from "@mui/material/Badge";

interface ActionsMenuIconButtonProps {
  MenuIcon?: ComponentType<SvgIconProps>;
  actions: ActionsMenuItem[];
  fontSize?: "inherit" | "large" | "medium" | "small";
  onActionClick: (actionId: string) => void;
}

const ActionsMenuIconButton = ({MenuIcon, actions, fontSize, onActionClick}: ActionsMenuIconButtonProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = useCallback((): void => {
    setOpen(true);
  }, [setOpen]);

  const closeHandler = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  const actionHandler = useCallback(
    (actionId: string): void => {
      closeHandler();
      onActionClick(actionId);
    },
    [closeHandler, onActionClick]
  );

  const badges = useMemo<
    ("inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning")[]
  >(() => actions.filter((action) => !!action.color).map((action) => action.color), [actions]);

  const showMenu = useMemo<boolean>(() => !isEmpty(actions), [actions]);

  if (!showMenu) {
    return null;
  }

  return (
    <>
      <Badge
        variant={"dot"}
        overlap={"circular"}
        anchorOrigin={{horizontal: "right", vertical: "top"}}
        color={get(badges, `[${0}]`)}
        invisible={badges.length < 1}
      >
        <Badge
          variant={"dot"}
          overlap={"circular"}
          anchorOrigin={{horizontal: "left", vertical: "top"}}
          color={get(badges, `[${1}]`)}
          invisible={badges.length < 2}
        >
          <Badge
            variant={"dot"}
            overlap={"circular"}
            anchorOrigin={{horizontal: "left", vertical: "bottom"}}
            color={get(badges, `[${2}]`)}
            invisible={badges.length < 3}
          >
            <Badge
              variant={"dot"}
              overlap={"circular"}
              anchorOrigin={{horizontal: "right", vertical: "bottom"}}
              color={get(badges, `[${3}]`)}
              invisible={badges.length < 4}
            >
              <MIconButton ref={anchorRef} onClick={openHandler} color={open ? "primary" : "default"}>
                {MenuIcon ? (
                  <MenuIcon fontSize={fontSize || "small"} />
                ) : (
                  <MoreVertOutlined fontSize={fontSize || "small"} />
                )}
              </MIconButton>
            </Badge>
          </Badge>
        </Badge>
      </Badge>

      <MenuPopover open={open} onClose={closeHandler} anchorEl={anchorRef.current} sx={{width: 220}} arrow={false}>
        {actions.map((action) => (
          <IconMenuItem
            labelId={action.titleId}
            Icon={action.Icon}
            color={action.color}
            onClick={() => actionHandler(action.id)}
            key={action.id}
          />
        ))}
      </MenuPopover>
    </>
  );
};

export default ActionsMenuIconButton;
