import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {FormattedMessage} from "react-intl";
import Box from "@mui/material/Box";

export interface ProgressProps {}

export default class Progress extends React.Component<ProgressProps> {
  render() {
    return (
      <Container>
        <Stack direction="column" alignItems="center" sx={{p: 3}}>
          <img width="90" height="90" src={require("../../../assets/logo-filled.png")} />

          <Typography variant="h3" paragraph sx={{mt: 3, color: "text.primary"}}>
            <FormattedMessage id="brandName" />
          </Typography>
          <Typography sx={{textAlign: "center", color: "text.secondary"}}>
            <FormattedMessage id="officeLoading" />
          </Typography>

          <Box sx={{my: 3}}>
            <CircularProgress size={30} />
          </Box>
        </Stack>
      </Container>
    );
  }
}
