import * as React from "react";
import {FunctionComponent, useMemo} from "react";
import {getAddTextHtml} from "../../helpers/AddTextLocationUtils";
import {useIntl} from "react-intl";
import useTheme from "@mui/material/styles/useTheme";
import {StageType, Term, TermClause} from "../../types/internal";
import useIntakeTemplateGenerator from "../../contexts/intake-template-generator/hooks/useIntakeTemplateGenerator";
import CopyTextButton from "../common/CopyTextButton";

export interface CopyTermStageTextButtonProps {
  term: Term;
  stageType: StageType;
  clauses: TermClause[];
}

const CopyTermStageTextButton: FunctionComponent<CopyTermStageTextButtonProps> = ({term, stageType, clauses}) => {
  const intl = useIntl();
  const theme = useTheme();
  const {addMedicalRecordFormat} = useIntakeTemplateGenerator();

  const copyText = useMemo<string>(
    () =>
      getAddTextHtml(term, stageType, clauses, intl, {
        direction: theme.direction,
        medicalRecordFormat: addMedicalRecordFormat,
      }),
    [term, stageType, clauses, addMedicalRecordFormat]
  );

  return <CopyTextButton text={copyText} format={"text/html"} />;
};

export default CopyTermStageTextButton;
