import * as React from "react";
import {ComponentType, FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Controller, useFormContext} from "react-hook-form";
import {OverridableStringUnion} from "@mui/types";
import {IconButtonPropsColorOverrides} from "@mui/material/IconButton/IconButton";
import {SvgIconProps} from "@mui/material/SvgIcon";
import TooltipIconButton from "../common/TooltipIconButton";

export interface FormToggleIconButtonProps {
  fieldName: string;
  Icon: ComponentType<SvgIconProps>;
  activeColor: OverridableStringUnion<
    "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    IconButtonPropsColorOverrides
  >;
  tooltipId?: string;
  disabled?: boolean;
}

const FormToggleIconButton: FunctionComponent<FormToggleIconButtonProps> = ({
  fieldName,
  Icon,
  activeColor,
  tooltipId,
  disabled,
}) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({field: {value, onChange}}) => (
        <TooltipIconButton
          Icon={Icon}
          activeColor={activeColor}
          tooltipId={tooltipId}
          active={value}
          disabled={disabled}
          onClick={() => onChange(!value)}
        />
      )}
    />
  );
};

export default FormToggleIconButton;
