import * as React from "react";
import {cloneElement, ComponentType, ReactElement, useCallback, useMemo, useRef, useState} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon";
import {isEmpty} from "lodash";
import IconMenuItem from "../common/IconMenuItem";
import MenuPopover from "../common/MenuPopover";

interface GenericActionsMenuProps {
  anchor: ReactElement;
  actions: ActionsMenuItem[];
  onActionClick: (actionId: string) => void;
}

export type ActionsMenuItem = {
  id: string;
  titleId: string;
  Icon: ComponentType<SvgIconProps>;
  color?: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  disabled?: boolean;
};

const ActionsMenuGeneric = ({anchor, actions, onActionClick}: GenericActionsMenuProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = useCallback((): void => {
    setOpen(true);
  }, [setOpen]);

  const closeHandler = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  const actionHandler = useCallback(
    (actionId: string): void => {
      closeHandler();
      onActionClick(actionId);
    },
    [closeHandler, onActionClick]
  );

  const showMenu = useMemo<boolean>(() => !isEmpty(actions), [actions]);

  if (!showMenu) {
    return null;
  }

  return (
    <>
      {cloneElement(anchor, {ref: anchorRef, onClick: openHandler})}

      <MenuPopover open={open} onClose={closeHandler} anchorEl={anchorRef.current} sx={{width: 220}} arrow={false}>
        {actions.map((action) => (
          <IconMenuItem
            labelId={action.titleId}
            Icon={action.Icon}
            color={action.color}
            disabled={action.disabled}
            onClick={() => actionHandler(action.id)}
            key={action.id}
          />
        ))}
      </MenuPopover>
    </>
  );
};

export default ActionsMenuGeneric;
