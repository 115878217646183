import * as React from "react";
import {useCallback, useRef, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import {MIconButton} from "../../../../components/@material-extend";
import MenuPopover from "../../../../components/common/MenuPopover";
import {FormattedMessage} from "react-intl";
import {useAppSelector} from "../../../../redux/hooks";
import {selectLoggedIn} from "../../../../redux/auth/selector";
import KeywordFinderSettingsPopoverDetails from "./KeywordFinderSettingsPopoverDetails";
import SignUpMessage from "../../../common/SignUpMessage";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";

export default function KeywordFinderSettingsPopover() {
  const loggedIn = useAppSelector(selectLoggedIn);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = useCallback((): void => {
    setOpen(true);
  }, [setOpen]);

  const closeHandler = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Tooltip title={<FormattedMessage id="keywordsFinderSettings" />}>
        <MIconButton ref={anchorRef} onClick={openHandler} color={open ? "primary" : "default"}>
          <SettingsOutlined fontSize={"small"} />
        </MIconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={closeHandler} anchorEl={anchorRef.current} sx={{width: 260}} arrow={false}>
        {loggedIn ? (
          <KeywordFinderSettingsPopoverDetails />
        ) : (
          <SignUpMessage messageId={"keywordsFinderSettingsSignUp"} onSignUpClick={closeHandler} />
        )}
      </MenuPopover>
    </>
  );
}
