import * as React from "react";
import {ReactNode} from "react";
import {useAppSelector} from "../../redux/hooks";
import {selectRole} from "../../redux/auth/selector";
import {Navigate, useLocation} from "react-router-dom";
import {defaultAppUrl} from "../../constants/defaultValues";

type RoleGuardProps = {
  role: string;
  children: ReactNode;
};

export default function RoleGuard({role, children}: RoleGuardProps) {
  const userRole = useAppSelector(selectRole);
  const {pathname} = useLocation();

  if (role !== userRole) {
    return <Navigate to={defaultAppUrl} state={{origin: pathname}} />;
  }

  return <>{children}</>;
}
