import * as React from "react";
import {FunctionComponent, useMemo} from "react";
import Button, {ButtonProps} from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import CopyTextComponent from "./CopyTextComponent";

export interface CopyTextButtonProps extends ButtonProps {
  text: string;
  format?: "text/html" | "text/plain";
}

const CopyTextButton: FunctionComponent<CopyTextButtonProps> = ({text, format, ...rest}) => {
  const disabled = useMemo<boolean>(() => !text, [text]);

  return (
    <CopyTextComponent text={text} format={format}>
      <Button color="primary" variant="outlined" disabled={disabled} disableElevation {...rest}>
        <FormattedMessage id="copy" />
      </Button>
    </CopyTextComponent>
  );
};

export default CopyTextButton;
