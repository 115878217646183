import * as React from "react";
import {FormattedMessage} from "react-intl";
import {BaseEntityRO, Entity, EntityAction} from "../../types/entity";
import PageHeader from "../pages/PageHeader";
import {useCallback, useEffect, useMemo, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {useSnackbar} from "notistack";
import {FormProvider, useForm} from "react-hook-form";
import KeyBindingManager, {KeyBindingAction} from "../../managers/KeyBindingManager";
import ErrorMessage from "../common/ErrorMessage";
import useEntityCrud from "../../contexts/entity-crud/hooks/useEntityCrud";
import Button from "@mui/material/Button";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import {isEmpty, isNil} from "lodash";
import useOffice from "../../contexts/office/hooks/useOffice";
import ActionsMenuGeneric from "../menus/ActionsMenuGeneric";
import ArrowDropDownOutlined from "@mui/icons-material/ArrowDropDownOutlined";
import {MHidden} from "../@material-extend";
import Tooltip from "@mui/material/Tooltip";
import PublishEntityItemButton from "./forms/common/PublishEntityItemButton";

export interface EntityFormProps<EntityRO extends BaseEntityRO, FiltersRO> {
  entity: Entity<EntityRO, FiltersRO>;
  entityUserId?: string;
  itemId: string | undefined;
  onItemSaved?: (item: EntityRO) => void;
}

const EntityForm = <EntityRO extends BaseEntityRO, FiltersRO>({
  entity,
  entityUserId,
  itemId,
  onItemSaved,
}: EntityFormProps<EntityRO, FiltersRO>) => {
  const {getItem, saveItem, lastChangeTime} = useEntityCrud();
  const {enqueueSnackbar} = useSnackbar();
  const {isOfficeActive} = useOffice();

  const [item, setItem] = useState<EntityRO | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [action, setAction] = useState<{actionId: string} | undefined>(undefined);

  const methods = useForm<EntityRO>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const {handleSubmit, reset} = methods;

  const initForm = useCallback(
    (initItem: EntityRO): void => {
      if (!item) {
        // @ts-ignore
        reset(initItem);
      }
      setItem(initItem);
    },
    [item, setItem, reset]
  );

  useEffect(() => {
    if (itemId && (item?.id !== itemId || lastChangeTime[entity.id]?.ids[itemId])) {
      (async () => {
        const item = await getItem(entity, itemId);
        setLoading(false);
        if (item) {
          initForm(item);
        } else {
          setError(true);
        }
      })();
    } else {
      setLoading(false);
      initForm(entity.generateEmptyItem());
    }
  }, [lastChangeTime[entity.id]?.ids[itemId]]);

  const subtitle = useMemo<string | undefined>(() => (item ? entity.getItemTitle(item) : undefined), [entity, item]);

  const submitHandler = handleSubmit(
    async (data): Promise<void> => {
      if (loading || saving) {
        return;
      }

      setSaving(true);

      // @ts-ignore
      const itemToSave = entity.generateSaveItem(item, data);
      if (entityUserId && !itemToSave.userIds.includes(entityUserId)) {
        itemToSave.userIds.push(entityUserId);
      }
      const savedItem = await saveItem(entity, itemToSave);

      setSaving(false);

      if (!savedItem) {
        return;
      }

      setItem(savedItem);
      onItemSaved?.(savedItem);

      enqueueSnackbar(<FormattedMessage id="savedSuccessfully" />, {variant: "success"});
    },
    () => {
      enqueueSnackbar(<FormattedMessage id="formInvalidValues" />, {variant: "error"});
    }
  );

  const actionsHandler = useCallback(
    (actionId: string): void => {
      setAction({actionId: actionId});
    },
    [setAction]
  );

  const actions = useMemo<EntityAction[]>(
    () => entity.actions.filter((action) => isNil(action.officeActive) || action.officeActive === isOfficeActive),
    [entity]
  );
  const hasActions = useMemo<boolean>(() => !isEmpty(actions) && !!item?.id, [actions, item]);
  const hasPublish = useMemo<boolean>(() => entity.publishable && !!item?.id, [entity, item]);

  const keyBindingActions = useMemo<KeyBindingAction[]>(() => [{id: "save", keyBinding: "s"}], []);
  const keyBindingActionsHandler = useCallback(
    (actionId: string): void => {
      switch (actionId) {
        case "save":
          submitHandler();
          break;
      }
    },
    [submitHandler]
  );

  return (
    <>
      <KeyBindingManager actions={keyBindingActions} actionsHandler={keyBindingActionsHandler} />

      <PageHeader
        title={<FormattedMessage id={entity.titleId} />}
        subtitle={subtitle}
        action={
          item && (
            <Stack direction={"row-reverse"} spacing={1} flexGrow={1}>
              <LoadingButton variant="contained" startIcon={<SaveOutlined />} loading={saving} onClick={submitHandler}>
                <FormattedMessage id="save" />
              </LoadingButton>

              {hasPublish && <PublishEntityItemButton entity={entity} item={item} />}

              {hasActions && (
                <>
                  <MHidden width={"lgDown"}>
                    {actions.map((action) => (
                      <Tooltip
                        title={action.tooltipId ? <FormattedMessage id={action.tooltipId} /> : ""}
                        key={action.id}
                      >
                        <Button
                          variant={"outlined"}
                          color={"inherit"}
                          startIcon={<action.Icon fontSize={"small"} />}
                          onClick={() => actionsHandler(action.id)}
                        >
                          <FormattedMessage id={action.titleId} />
                        </Button>
                      </Tooltip>
                    ))}
                  </MHidden>

                  <MHidden width={"lgUp"}>
                    <ActionsMenuGeneric
                      anchor={
                        <Button variant={"outlined"} color={"inherit"} startIcon={<ArrowDropDownOutlined />}>
                          <FormattedMessage id={"actions"} />
                        </Button>
                      }
                      actions={actions}
                      onActionClick={actionsHandler}
                    />
                  </MHidden>
                </>
              )}
            </Stack>
          )
        }
      />

      {loading && (
        <Box sx={{textAlign: "center"}}>
          <CircularProgress />
        </Box>
      )}

      {error && !item && <ErrorMessage />}

      {item && (
        <FormProvider {...methods}>
          <form autoComplete="off" noValidate onSubmit={submitHandler}>
            <entity.formComponent entity={entity} item={item} action={action} />
          </form>

          <Stack direction={"row-reverse"} spacing={1} alignItems={"center"} sx={{mt: 3}}>
            <LoadingButton variant="contained" startIcon={<SaveOutlined />} loading={saving} onClick={submitHandler}>
              <FormattedMessage id="save" />
            </LoadingButton>
          </Stack>
        </FormProvider>
      )}
    </>
  );
};

export default EntityForm;
