import {AppContainer as HotLoaderAppContainer} from "react-hot-loader";
import * as React from "react";
import * as ReactDOM from "react-dom";
import AppContainer from "../taskpane/AppContainer";

const render = (Component) => {
  ReactDOM.render(
    <HotLoaderAppContainer>
      <Component isOfficeInitialized={true} isOfficeActive={false} />
    </HotLoaderAppContainer>,
    document.getElementById("container")
  );
};

render(AppContainer);

if ((module as any).hot) {
  (module as any).hot.accept("../taskpane/AppContainer", () => {
    const NextAppContainer = require("../taskpane/AppContainer").default;
    render(NextAppContainer);
  });
}
