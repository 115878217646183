import {useContext} from "react";
import {IntakeTemplateGeneratorContext, IntakeTemplateGeneratorContextProps} from "../IntakeTemplateGeneratorContext";

const useIntakeTemplateGenerator = (): IntakeTemplateGeneratorContextProps => {
  const context = useContext(IntakeTemplateGeneratorContext);

  if (!context) throw new Error("IntakeTemplateGeneratorContext must be used inside IntakeTemplateGeneratorProvider");

  return context;
};
export default useIntakeTemplateGenerator;
