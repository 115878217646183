import * as React from "react";
import {FunctionComponent} from "react";
import configureAppStore from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import LoadingCenter from "./components/common/LoadingCenter";
import {Provider as ReduxProvider} from "react-redux";
import App from "./App";
import {OfficeProvider} from "./contexts/office/OfficeContext";

import "simplebar/src/simplebar.css";

const {store, persistor} = configureAppStore();
export type AppDispatch = typeof store.dispatch;

export interface AppContainerProps {
  isOfficeInitialized: boolean;
  isOfficeActive: boolean;
}

const AppContainer: FunctionComponent<AppContainerProps> = ({isOfficeInitialized, isOfficeActive}) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingCenter />} persistor={persistor}>
        <OfficeProvider isOfficeInitialized={isOfficeInitialized} isOfficeActive={isOfficeActive}>
          <App />
        </OfficeProvider>
      </PersistGate>
    </ReduxProvider>
  );
};
export default AppContainer;
