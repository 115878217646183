import * as React from "react";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardProps} from "@mui/material/Card";
import {FormattedMessage} from "react-intl";
import {addInDownloadUrl} from "../../../constants/defaultValues";

const RootStyle = styled(Card)(({theme}) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface HomeDownloadAddInProps extends CardProps {}

export default function HomeDownloadAddIn({}: HomeDownloadAddInProps) {
  return (
    <RootStyle>
      <CardContent>
        <Typography gutterBottom variant="h4">
          <FormattedMessage id="officeWordPlugin" />
        </Typography>

        <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: "auto"}}>
          <FormattedMessage id="pluginExplanation" />
        </Typography>

        <Button variant="outlined" href={addInDownloadUrl} target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="downloadForFree" />
        </Button>
      </CardContent>
    </RootStyle>
  );
}
