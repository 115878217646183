import * as React from "react";
import {MIconButton} from "../../../components/@material-extend";
import {useNavigate} from "react-router-dom";
import {urls} from "../../../routes/urls";
import {FormattedMessage} from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import SearchOutlined from "@mui/icons-material/SearchOutlined";

export default function SearchIcon() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(urls.search.url);
  };

  return (
    <Tooltip title={<FormattedMessage id="search" />}>
      <MIconButton onClick={handleClick}>
        <SearchOutlined fontSize={"small"} />
      </MIconButton>
    </Tooltip>
  );
}
