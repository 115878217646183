import * as React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Box from "@mui/material/Box";
import IntakeTemplateContentPayloadListItem, {
  IntakeTemplateContentPayloadListItemProps,
} from "./IntakeTemplateContentPayloadListItem";
import DragHandle from "../../../../common/DragHandle";
import {MHidden} from "../../../../@material-extend";

const IntakeTemplateContentPayloadListItemSortable = (props: IntakeTemplateContentPayloadListItemProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: props.item.uuid});
  const {disabled} = props;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <IntakeTemplateContentPayloadListItem
        {...props}
        startAction={
          !disabled && (
            <MHidden width={"smDown"}>
              <DragHandle {...listeners} {...attributes} />
            </MHidden>
          )
        }
      />
    </Box>
  );
};

export default IntakeTemplateContentPayloadListItemSortable;
