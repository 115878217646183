import * as React from "react";
import TextField from "@mui/material/TextField";
import {useIntl} from "react-intl";
import {ContentTypePayloadTableCell} from "../../../../../types/internal";
import Stack from "@mui/material/Stack";
import LightBulbOutlined from "@mui/icons-material/LightBulbOutlined";
import AssistantOutlined from "@mui/icons-material/AssistantOutlined";
import MoreOutlined from "@mui/icons-material/MoreOutlined";
import {useCallback, useMemo} from "react";
import TooltipIconButton from "../../../../common/TooltipIconButton";
import {ActionsMenuItem} from "../../../../menus/ActionsMenuGeneric";
import {MHidden} from "../../../../@material-extend";
import ActionsMenuIconButton from "../../../../menus/ActionsMenuIconButton";
import TitleOutlined from "@mui/icons-material/TitleOutlined";

export interface IntakeTemplateContentPayloadTableCellProps {
  cell: ContentTypePayloadTableCell;
  row: number;
  column: number;
  disabled?: boolean;
  inputRefHandler: (row: number, column: number, input: any) => void;
  itemChangeHandler: (row: number, column: number, item: ContentTypePayloadTableCell) => void;
  textChangeHandler: (row: number, column: number, text: string) => void;
  keyDownHandler: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, row: number, column: number) => void;
}

const IntakeTemplateContentPayloadTableCell = ({
  cell,
  row,
  column,
  disabled,
  inputRefHandler,
  itemChangeHandler,
  textChangeHandler,
  keyDownHandler,
}: IntakeTemplateContentPayloadTableCellProps) => {
  const intl = useIntl();

  const actions = useMemo<ActionsMenuItem[]>(
    () => [
      {
        id: "example",
        Icon: LightBulbOutlined,
        color: cell.example ? "error" : undefined,
        titleId: "example",
        disabled: disabled,
      },
      {
        id: "assistNotes",
        Icon: AssistantOutlined,
        color: cell.assistNotes ? "info" : undefined,
        titleId: "assistNotes",
        disabled: disabled,
      },
      {
        id: "extendedFormat",
        Icon: MoreOutlined,
        color: cell.extendedFormat ? "success" : undefined,
        titleId: "extendedFormat",
        disabled: disabled,
      },
      {
        id: "title",
        Icon: TitleOutlined,
        color: cell.title ? "primary" : undefined,
        titleId: "title",
        disabled: disabled,
      },
    ],
    [cell, disabled]
  );

  const actionsHandler = useCallback(
    (actionId: string): void => {
      switch (actionId) {
        case "example":
          itemChangeHandler(row, column, {...cell, example: !cell.example});
          break;
        case "assistNotes":
          itemChangeHandler(row, column, {...cell, assistNotes: !cell.assistNotes});
          break;
        case "extendedFormat":
          itemChangeHandler(row, column, {...cell, extendedFormat: !cell.extendedFormat});
          break;
        case "title":
          itemChangeHandler(row, column, {...cell, title: !cell.title});
          break;
      }
    },
    [cell, row, column, itemChangeHandler]
  );

  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"} flexGrow={1}>
      <TextField
        defaultValue={cell.payload}
        inputRef={(input) => inputRefHandler(row, column, input)}
        onChange={(e) => textChangeHandler(row, column, e.target.value)}
        fullWidth
        label={intl.formatMessage({id: "cell"})}
        disabled={disabled}
        inputProps={{
          maxLength: 500,
          onKeyDown: (e) => keyDownHandler(e, row, column),
        }}
        sx={{minWidth: 100}}
      />

      <MHidden width={"lgDown"}>
        {actions.map((action) => (
          <TooltipIconButton
            Icon={action.Icon}
            tooltipId={action.titleId}
            color={action.color}
            disabled={action.disabled}
            onClick={() => actionsHandler(action.id)}
            key={action.id}
          />
        ))}
      </MHidden>

      <MHidden width={"lgUp"}>
        <ActionsMenuIconButton actions={actions} fontSize={"medium"} onActionClick={actionsHandler} />
      </MHidden>
    </Stack>
  );
};

export default IntakeTemplateContentPayloadTableCell;
