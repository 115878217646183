import {buildReversedKeywordsRegex} from "./RegexUtils";
import {getLastRegExpMatch, reverseString} from "./StringUtils";
import {NegateKeywordType} from "../types/internal";
import {notEmpty} from "@crud-studio/react-crud-core";
import {first} from "lodash";

export const selectKeywords = async (
  keywords: string[],
  negateKeywords?: {keyword: string; type: NegateKeywordType}[]
): Promise<boolean> => {
  return Word.run(async (context): Promise<boolean> => {
    const documentBody = context.document.body;
    context.load(documentBody);

    await context.sync();

    const reversedText = reverseString(documentBody.text);
    // const textLength = reversedText.length;

    const regExp = buildReversedKeywordsRegex(keywords, negateKeywords, true);
    const match = getLastRegExpMatch(reversedText, regExp);
    if (!match) {
      return false;
    }

    const matchString = reverseString(first(match.filter(notEmpty)) || "");
    if (!matchString) {
      return false;
    }
    const range = context.document.body.search(matchString).getFirst();

    // const matchIndex = match.index;
    // const matchLength = match[0].length;
    // const range = await findRange(context, textLength - matchIndex - matchLength, textLength - matchIndex);

    range.select();

    await context.sync();

    return true;
  });
};

export const selectRegExp = async (regExp: RegExp): Promise<boolean> => {
  return Word.run(async (context): Promise<boolean> => {
    const documentBody = context.document.body;
    context.load(documentBody);

    await context.sync();

    const match = regExp.exec(documentBody.text);
    if (!match) {
      return false;
    }

    // const matchIndex = match.index;
    // const matchLength = match[0].length;
    // const range = await findRange(context, matchIndex, matchIndex + matchLength);

    const matchString = first(match.filter(notEmpty)) || "";
    if (!matchString) {
      return false;
    }
    const range = context.document.body.search(matchString).getFirst();

    range.select();

    await context.sync();

    return true;
  });
};
