import * as React from "react";
import {FunctionComponent} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import SeoIllustration from "../../svg/SeoIllustration";
import {AddTextLocation, addTextToDocument} from "../../../helpers/AddTextLocationUtils";

export interface EmptyKeywordsProps {}

const EmptyKeywords: FunctionComponent<EmptyKeywordsProps> = ({}) => {
  const intl = useIntl();

  const tryItHandler = async (): Promise<void> => {
    const textToAdd = intl.formatMessage({id: "tryItSentence"});
    await addTextToDocument(AddTextLocation.Start, textToAdd, {
      select: true,
    });
  };

  return (
    <Stack direction="column" alignItems="center">
      <Typography variant="h3" paragraph sx={{mt: 3, color: "text.primary"}}>
        <FormattedMessage id="welcomeToPlugin" />
      </Typography>
      <Typography sx={{textAlign: "center", color: "text.secondary"}}>
        <FormattedMessage id="welcomeExplanation" />
      </Typography>
      <Typography sx={{textAlign: "center", color: "text.secondary"}}>
        <FormattedMessage id="welcomeTryIt" />
      </Typography>

      <SeoIllustration sx={{height: 260, my: {xs: 3, sm: 6}}} />

      <Button size="large" variant="contained" onClick={tryItHandler}>
        <FormattedMessage id="tryItNow" />
      </Button>
    </Stack>
  );
};

export default EmptyKeywords;
