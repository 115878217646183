import {UrlInfo} from "../types/internal";

function asUrlInfos<T extends {[key: string]: UrlInfo}>(arg: T): T {
  return arg;
}

// Also update urls in firebase-messaging-sw.js
export const urls = asUrlInfos({
  // Error Root
  error: {
    url: "/error",
    path: "error",
    titleKey: "error",
  },
  // App Root
  app: {
    url: "/app",
    path: "app",
    titleKey: "homepage",
  },
  home: {
    url: "/app/home",
    path: "home",
    titleKey: "homepage",
  },
  search: {
    url: "/app/search",
    path: "search",
    titleKey: "search",
  },
  newIntake: {
    url: "/app/new-intake",
    path: "new-intake",
    titleKey: "newIntake",
  },
  support: {
    url: "/app/support",
    path: "support",
    titleKey: "support",
  },
  comingSoon: {
    url: "/app/coming-soon",
    path: "coming-soon",
    titleKey: "comingSoon",
  },
  termsOfService: {
    url: "/app/term-of-service",
    path: "term-of-service",
    titleKey: "termsOfService",
  },
  privacyPolicy: {
    url: "/app/privacy-policy",
    path: "privacy-policy",
    titleKey: "privacyPolicy",
  },
  // App Admin Root
  entity: {
    url: "/app/manage",
    path: "manage",
    titleKey: "manage",
  },
  entityGrid: {
    url: "/app/manage/grid/:entity",
    path: "grid/:entity",
    titleKey: "manage",
  },
  entityDetails: {
    url: "/app/manage/details/:entity",
    path: "details/:entity",
    titleKey: "details",
  },
  entityDetailsId: {
    url: "/app/manage/details/:entity/:id",
    path: "details/:entity/:id",
    titleKey: "details",
  },
  entityShareId: {
    url: "/app/share/:id",
    path: "share/:id",
    titleKey: "share",
  },
  entityPublishRequestId: {
    url: "/app/publish-request/:id",
    path: "publish-request/:id",
    titleKey: "publishRequests",
  },
  entityEditRequestId: {
    url: "/app/edit-request/:id",
    path: "edit-request/:id",
    titleKey: "editRequests",
  },
  migration: {
    url: "/app/migration",
    path: "migration",
    titleKey: "homepage",
  },
});
