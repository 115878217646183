import {MedicalField, TermCategory} from "../types/internal";
import {isArray, isEmpty, some} from "lodash";

export const isCategoriesFilterActive = (filters: {categories: TermCategory[]}): boolean => {
  return filters.categories.length < Object.values(TermCategory).length;
};

export const checkCategoriesFilter = (
  filterActive: boolean,
  filters: {categories: TermCategory[]},
  category: TermCategory
): boolean => {
  return filterActive && !filters.categories.includes(category);
};

export const isMedicalFieldsFilterActive = (filters: {medicalFields: MedicalField[]}): boolean => {
  return filters.medicalFields.length < Object.values(MedicalField).length;
};

export const checkMedicalFieldsFilter = (
  filterActive: boolean,
  filters: {medicalFields: MedicalField[]},
  medicalFields?: MedicalField | MedicalField[]
): boolean => {
  return (
    filterActive &&
    !!medicalFields &&
    ((isArray(medicalFields) &&
      !isEmpty(medicalFields) &&
      !some(medicalFields, (f) => filters.medicalFields.includes(f))) ||
      (!isArray(medicalFields) && !filters.medicalFields.includes(medicalFields)))
  );
};
