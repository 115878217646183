import * as React from "react";
import {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import useEntity from "../contexts/entity/hooks/useEntity";
import {BaseEntityRO, Entity} from "../types/entity";
import {Term, TermFilters} from "../types/internal";
import {termEntityId} from "../entities/term.entity";
import useEntityCrud from "../contexts/entity-crud/hooks/useEntityCrud";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {selectLoggedIn, selectUserId, selectUserProfile} from "../redux/auth/selector";
import {updateUserProfile} from "../redux/auth/slice";
import NiceModal from "@ebay/nice-modal-react";
import {isEmpty, uniq} from "lodash";
import NewTermsDialog from "../components/dialogs/NewTermsDialog";
import Confetti from "../components/common/Confetti";
import {publicUserId} from "../contexts/entity-crud/EntityCrudContext";
import EditRequestTermsDialog from "../components/dialogs/EditRequestTermsDialog";

interface TermsNotificationsManagerProps {}

const TermsNotificationsManager: FunctionComponent<TermsNotificationsManagerProps> = () => {
  const {getEntity} = useEntity();
  const {lastChangeTime, getItems} = useEntityCrud();

  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(selectLoggedIn);
  const userId = useAppSelector(selectUserId);
  const userProfile = useAppSelector(selectUserProfile);

  const [startConfetti, setStartConfetti] = useState<number>(0);

  const entity = useMemo<Entity<Term, TermFilters>>(() => getEntity(termEntityId), []);

  const updateNewTermsTime = useCallback((): void => {
    const now = new Date().getTime();
    dispatch(updateUserProfile({userProfile: {newTermsTime: now}}));
  }, []);

  const showNewTermsDialog = useCallback(
    (terms: Term[]): void => {
      updateNewTermsTime();
      setStartConfetti((currentStartConfetti) => currentStartConfetti + 1);
      NiceModal.show(NewTermsDialog, {terms: terms});
    },
    [updateNewTermsTime]
  );

  const updateEditRequestDialogIds = useCallback(
    (items: BaseEntityRO[]): void => {
      if (isEmpty(items)) {
        return;
      }
      dispatch(
        updateUserProfile({
          userProfile: {
            editRequestDialogIds: uniq([...items.map((item) => item.id), ...(userProfile?.editRequestDialogIds || [])]),
          },
        })
      );
    },
    [userProfile]
  );

  const showEditRequestTermsDialog = useCallback(
    (terms: Term[]): void => {
      updateEditRequestDialogIds(terms);
      NiceModal.show(EditRequestTermsDialog, {terms: terms});
    },
    [updateEditRequestDialogIds]
  );

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    const minTime = userProfile?.newTermsTime || 0;
    if (!minTime) {
      return;
    }

    (async () => {
      const items = await getItems(entity);
      if (!items) {
        return;
      }

      const newItems = entity
        .filterDataItems(items)
        .filter((item) => (item.publishTime || item.creationTime) > minTime && item.userIds?.includes(publicUserId));
      if (isEmpty(newItems)) {
        return;
      }

      showNewTermsDialog(newItems);
    })();
  }, [loggedIn]);
  // }, [lastChangeTime[entity.id], userProfile?.newTermsTime]);

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    (async () => {
      const items = await getItems(entity);
      if (!items) {
        return;
      }

      const newItems = items.filter(
        (item) =>
          item.userIds?.includes(publicUserId) &&
          item.userIds?.includes(userId) &&
          item.publishUserId !== userId &&
          !userProfile?.editRequestDialogIds?.includes(item.id)
      );
      if (isEmpty(newItems)) {
        return;
      }

      showEditRequestTermsDialog(newItems);
    })();
  }, [lastChangeTime[entity.id]]);

  useEffect(() => {
    if (loggedIn && userProfile && !userProfile.newTermsTime) {
      updateNewTermsTime();
    }
  }, [loggedIn, userProfile]);

  return <Confetti startConfetti={startConfetti} />;
};
export default TermsNotificationsManager;
