import * as React from "react";
import {ComponentType, FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {OverridableStringUnion} from "@mui/types";
import {IconButtonPropsColorOverrides} from "@mui/material/IconButton/IconButton";
import {SvgIconProps} from "@mui/material/SvgIcon";

export interface TooltipIconButtonProps {
  Icon: ComponentType<SvgIconProps>;
  color?: OverridableStringUnion<
    "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    IconButtonPropsColorOverrides
  >;
  activeColor?: OverridableStringUnion<
    "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    IconButtonPropsColorOverrides
  >;
  tooltipId?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TooltipIconButton: FunctionComponent<TooltipIconButtonProps> = ({
  Icon,
  color,
  activeColor,
  tooltipId,
  active,
  disabled,
  onClick,
}) => {
  return (
    <Tooltip title={tooltipId ? <FormattedMessage id={tooltipId} /> : ""}>
      <span>
        <IconButton
          color={active && activeColor ? activeColor : color || "inherit"}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default TooltipIconButton;
