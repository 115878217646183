import {Entity, EntityFactory} from "../types/entity";
import {DataEntityStatus, MedicalField, Term, TermCategory, TermFilters} from "../types/internal";
import {getTermCategoryLabelId} from "../helpers/TermUtils";
import TermForm from "../components/entity/forms/term/TermForm";
import {
  checkCategoriesFilter,
  checkMedicalFieldsFilter,
  isCategoriesFilterActive,
  isMedicalFieldsFilterActive,
} from "../helpers/FiltersUtils";
import {getMedicalFieldLabelId} from "../helpers/MedicalFieldUtils";
import {cleanEntityData, cleanSearchString, getDataEntityStatusLabelId} from "../helpers/EntityUtils";
import {some} from "lodash";
import {publicUserId} from "../contexts/entity-crud/EntityCrudContext";

export const termEntityId = "term";
export const termPrivateEntityId = "termPrivate";

export const termEntityFactory: EntityFactory<Term, TermFilters> = (intl): Entity<Term, TermFilters> => ({
  id: termEntityId,
  deprecatedIds: [termPrivateEntityId],
  titleId: "terms",
  collection: "terms",
  persist: true,
  creatable: true,
  deletable: true,
  shareable: true,
  publishable: true,
  roles: {},
  defaultOrderBy: {field: "title", direction: "asc"},
  actions: [],
  columns: [
    {
      field: "title",
      headerNameId: "title",
      minWidth: 100,
      sortComparator: (v1, v2) => {
        return (v1 as string).localeCompare(v2 as string, undefined, {numeric: true});
      },
    },
    {
      field: "category",
      headerNameId: "category",
      valueFormatter: (params) => {
        return intl.formatMessage({id: getTermCategoryLabelId(params.value as TermCategory)});
      },
      sortComparator: (v1, v2) => {
        const v1Str = intl.formatMessage({id: getTermCategoryLabelId(v1 as TermCategory)});
        const v2Str = intl.formatMessage({id: getTermCategoryLabelId(v2 as TermCategory)});
        return v1Str.localeCompare(v2Str);
      },
    },
    {
      field: "status",
      headerNameId: "status",
      valueFormatter: (params) => {
        return intl.formatMessage({id: getDataEntityStatusLabelId(params.value as DataEntityStatus)});
      },
      sortComparator: (v1, v2) => {
        const v1Str = intl.formatMessage({id: getDataEntityStatusLabelId(v1 as DataEntityStatus)});
        const v2Str = intl.formatMessage({id: getDataEntityStatusLabelId(v2 as DataEntityStatus)});
        return v1Str.localeCompare(v2Str);
      },
    },
    {
      field: "medicalFields",
      headerNameId: "medicalFields",
      valueFormatter: (params) => {
        return ((params.value || []) as MedicalField[])
          .map((medicalField) => intl.formatMessage({id: getMedicalFieldLabelId(medicalField)}))
          .join(", ");
      },
      sortComparator: (v1, v2) => {
        const v1Str = ((v1 || []) as MedicalField[])
          .map((medicalField) => intl.formatMessage({id: getMedicalFieldLabelId(medicalField)}))
          .join(", ");
        const v2Str = ((v2 || []) as MedicalField[])
          .map((medicalField) => intl.formatMessage({id: getMedicalFieldLabelId(medicalField)}))
          .join(", ");
        return v1Str.localeCompare(v2Str);
      },
    },
    // {
    //   field: "stages",
    //   headerNameId: "stages",
    //   valueFormatter: (params) => {
    //     return ((params.value || []) as TermStage[])
    //       .map((group) => intl.formatMessage({id: getStageTypeLabelId(group.type)}))
    //       .join(", ");
    //   },
    //   sortComparator: (v1, v2) => {
    //     const v1Str = ((v1 || []) as TermStage[])
    //       .map((group) => intl.formatMessage({id: getStageTypeLabelId(group.type)}))
    //       .join(", ");
    //     const v2Str = ((v2 || []) as TermStage[])
    //       .map((group) => intl.formatMessage({id: getStageTypeLabelId(group.type)}))
    //       .join(", ");
    //     return v1Str.localeCompare(v2Str);
    //   },
    // },
    {
      field: "lastUpdateTime",
      headerNameId: "lastUpdateTime",
      valueFormatter: (params) => {
        try {
          return `${intl.formatDate(params.value as number)} ${intl.formatTime(params.value as number)}`;
        } catch (e) {
          return "";
        }
      },
    },
  ],
  formComponent: TermForm,
  getItemTitle: (item) => item.title,
  generateEmptyItem: () => ({
    id: "",
    creationTime: 0,
    lastUpdateTime: 0,
    userIds: [],
    deleted: false,
    title: "",
    description: "",
    tooltip: "",
    category: TermCategory.Symptom,
    status: DataEntityStatus.Published,
    keywords: [],
    stages: [],
    medicalFields: [],
  }),
  generateSaveItem: (item, data) => {
    const now = new Date().getTime();
    return {
      ...item,
      ...cleanEntityData(data),
      ...(item.status !== DataEntityStatus.Published &&
      data.status === DataEntityStatus.Published &&
      item.userIds?.includes(publicUserId) &&
      !item.publishTime
        ? {publishTime: now}
        : {}),
    };
  },
  generateCloneItem: (item) => {
    item.title = item.title + " - " + intl.formatMessage({id: "clone"});
    item.status = DataEntityStatus.Draft;
    return item;
  },
  generateEmptyFilters: (): TermFilters => ({
    categories: [],
    medicalFields: [],
  }),
  generateFullFilters: (): TermFilters => ({
    categories: Object.values(TermCategory),
    medicalFields: Object.values(MedicalField),
  }),
  hasActiveFilters: (filters: TermFilters) =>
    filters.categories.length < Object.values(TermCategory).length ||
    filters.medicalFields.length < Object.values(MedicalField).length,
  hasEmptyFilters: (filters: TermFilters) => filters.categories.length === 0 || filters.medicalFields.length === 0,
  filterItems: (items, filters) => {
    const filterCategories = isCategoriesFilterActive(filters);
    const filterMedicalFields = isMedicalFieldsFilterActive(filters);
    const filterAny = filterCategories || filterMedicalFields;

    if (!filterAny) {
      return items;
    }

    return items.filter((item) => {
      if (checkCategoriesFilter(filterCategories, filters, item.category)) {
        return false;
      }
      if (checkMedicalFieldsFilter(filterMedicalFields, filters, item.medicalFields)) {
        return false;
      }
      return true;
    });
  },
  filterDataItems: (items) => {
    return items.filter((item) => item.status === "Published");
  },
  searchItems: (items, search) => {
    const cleanedSearch = cleanSearchString(search);
    if (!cleanedSearch) {
      return items;
    }
    return items.filter(
      (item) =>
        item.title.toLowerCase().includes(cleanedSearch) ||
        some(item.keywords, (keyword) => keyword.toLowerCase().includes(cleanedSearch))
    );
  },
});
