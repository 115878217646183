import * as React from "react";
import {IntakeTemplateContentPayloadProps} from "./IntakeTemplateContentPayload";
import TextField from "@mui/material/TextField";
import {useIntl} from "react-intl";

const IntakeTemplateContentPayloadText = ({
  type,
  field: {ref, ...field},
  fieldState: {invalid, error},
  disabled,
}: IntakeTemplateContentPayloadProps) => {
  const intl = useIntl();

  return (
    <TextField
      {...field}
      inputRef={ref}
      fullWidth
      multiline
      rows={3}
      label={intl.formatMessage({id: "text"})}
      type="text"
      inputProps={{
        maxLength: 2000,
      }}
      error={invalid}
      helperText={error?.message}
      disabled={disabled}
    />
  );
};

export default IntakeTemplateContentPayloadText;
