import {useContext} from "react";
import {EntityFiltersContext, EntityFiltersContextProps} from "../EntityFiltersContext";

const useEntityFilters = (): EntityFiltersContextProps => {
  const context = useContext(EntityFiltersContext);

  if (!context) throw new Error("EntityFiltersContext must be used inside EntityFiltersProvider");

  return context;
};
export default useEntityFilters;
