import * as React from "react";
import {FunctionComponent, useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {IntakeTemplate, Prep, Term, TermFilters} from "../../../types/internal";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TooltipIcon from "../../common/TooltipIcon";
import {termEntityId} from "../../../entities/term.entity";
import {AddTextLocation, addTextToDocument, clearDocument} from "../../../helpers/AddTextLocationUtils";
import useEntityFilters from "../../../contexts/entity-filters/hooks/useEntityFilters";
import {getIntakeTemplateHtml} from "../../../helpers/IntakeTemplateUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import useTheme from "@mui/material/styles/useTheme";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {
  localStorageKeyIntakeTemplateClearDocumentText,
  localStorageKeyIntakeTemplateUpdateFilters,
} from "../../../constants/localStorageKeys";
import useOffice from "../../../contexts/office/hooks/useOffice";
import useCopyToClipboard from "../../../helpers/useCopyToClipboard";
import {cloneDeep, find} from "lodash";
import {getPrepStageIntakeTemplateSection} from "../../../helpers/PrepUtils";
import {useSnackbar} from "notistack";
import useIntakeTemplateGenerator from "../../../contexts/intake-template-generator/hooks/useIntakeTemplateGenerator";
import Button from "@mui/material/Button";
import {printRawHtml} from "../../../helpers/PrintUtils";

export interface IntakeTemplateGeneratorFormProps {
  intakeTemplate?: IntakeTemplate;
  prep?: Prep;
}

const IntakeTemplateGeneratorForm: FunctionComponent<IntakeTemplateGeneratorFormProps> = ({intakeTemplate, prep}) => {
  const {getFilters, setFilters} = useEntityFilters();
  const intl = useIntl();
  const theme = useTheme();
  const {isOfficeActive} = useOffice();
  const copyToClipboard = useCopyToClipboard();
  const {enqueueSnackbar} = useSnackbar();
  const {
    addExamples,
    setAddExamples,
    addAssistNotes,
    setAddAssistNotes,
    addExtendedFormat,
    setAddExtendedFormat,
    addMedicalRecordFormat,
    setAddMedicalRecordFormat,
  } = useIntakeTemplateGenerator();

  const [generating, setGenerating] = useState<boolean>(false);
  const [updateFilters, setUpdateFilters] = useLocalStorageState<boolean>(
    localStorageKeyIntakeTemplateUpdateFilters,
    true
  );
  const [clearDocumentText, setClearDocumentText] = useLocalStorageState<boolean>(
    localStorageKeyIntakeTemplateClearDocumentText,
    false
  );

  const getNewIntakeHtml = useCallback((): string => {
    if (!intakeTemplate) {
      return "";
    }

    const aggregatedIntakeTemplate = cloneDeep(intakeTemplate);
    if (prep) {
      for (const prepStage of prep.stages) {
        const prepStageSection = getPrepStageIntakeTemplateSection(prepStage);
        const templateStage = find(aggregatedIntakeTemplate.sections, (section) => section.type === prepStage.type);
        if (templateStage) {
          templateStage.contents.push(...prepStageSection.contents);
        } else {
          aggregatedIntakeTemplate.sections.push(prepStageSection);
        }
      }
    }

    return getIntakeTemplateHtml(aggregatedIntakeTemplate, intl, {
      direction: theme.direction,
      example: addExamples,
      assistNotes: addAssistNotes,
      extendedFormat: addExtendedFormat,
      medicalRecordFormat: addMedicalRecordFormat,
    });
  }, [
    intakeTemplate,
    prep,
    addExamples,
    addAssistNotes,
    addExtendedFormat,
    addMedicalRecordFormat,
    intl,
    theme.direction,
  ]);

  const newIntake = useCallback(async (): Promise<void> => {
    const intakeTemplateHtml = getNewIntakeHtml();
    if (!intakeTemplateHtml) {
      return;
    }

    if (updateFilters) {
      setFilters<Term, TermFilters>(termEntityId, {
        ...getFilters<Term, TermFilters>(termEntityId),
        medicalFields: [intakeTemplate.medicalField],
      });
    }

    setGenerating(true);

    if (isOfficeActive) {
      if (clearDocumentText) {
        await clearDocument();
      }
      await addTextToDocument(AddTextLocation.End, intakeTemplateHtml);

      enqueueSnackbar(<FormattedMessage id="intakeCreatedSuccessfully" />, {variant: "success"});
    } else {
      copyToClipboard(intakeTemplateHtml, "text/html");
    }

    setGenerating(false);
  }, [getNewIntakeHtml, clearDocumentText, clearDocument, updateFilters, setFilters, copyToClipboard, setGenerating]);

  const printIntake = useCallback(async (): Promise<void> => {
    const intakeTemplateHtml = getNewIntakeHtml();
    if (!intakeTemplateHtml) {
      return;
    }

    printRawHtml(intakeTemplateHtml, theme.direction);
  }, [getNewIntakeHtml]);

  return (
    <Stack direction="column">
      <FormControlLabel
        control={
          <Checkbox checked={addExamples} onChange={(event, currentChecked) => setAddExamples(currentChecked)} />
        }
        label={
          <>
            <FormattedMessage id="addExamples" />
            <TooltipIcon tooltip={<FormattedMessage id="addExamplesExplanation" />} />
          </>
        }
      />
      <FormControlLabel
        control={
          <Checkbox checked={addAssistNotes} onChange={(event, currentChecked) => setAddAssistNotes(currentChecked)} />
        }
        label={
          <>
            <FormattedMessage id="addAssistNotes" />
            <TooltipIcon tooltip={<FormattedMessage id="addAssistNotesExplanation" />} />
          </>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={addExtendedFormat}
            onChange={(event, currentChecked) => setAddExtendedFormat(currentChecked)}
          />
        }
        label={
          <>
            <FormattedMessage id="addExtendedFormat" />
            <TooltipIcon tooltip={<FormattedMessage id="addExtendedFormatExplanation" />} />
          </>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={addMedicalRecordFormat}
            onChange={(event, currentChecked) => setAddMedicalRecordFormat(currentChecked)}
          />
        }
        label={
          <>
            <FormattedMessage id="addMedicalRecordFormat" />
            <TooltipIcon tooltip={<FormattedMessage id="addMedicalRecordFormatExplanation" />} />
          </>
        }
      />
      <FormControlLabel
        control={
          <Checkbox checked={updateFilters} onChange={(event, currentChecked) => setUpdateFilters(currentChecked)} />
        }
        label={
          <>
            <FormattedMessage id="applyRelevantFilters" />
            <TooltipIcon tooltip={<FormattedMessage id="applyRelevantFiltersExplanation" />} />
          </>
        }
      />
      {isOfficeActive && (
        <FormControlLabel
          control={
            <Checkbox
              checked={clearDocumentText}
              onChange={(event, currentChecked) => setClearDocumentText(currentChecked)}
            />
          }
          label={
            <>
              <FormattedMessage id="clearDocumentText" />
              <TooltipIcon tooltip={<FormattedMessage id="clearDocumentTextExplanation" />} />
            </>
          }
        />
      )}

      <Stack spacing={1} sx={{mt: 2}}>
        <LoadingButton variant="contained" fullWidth loading={generating} onClick={newIntake}>
          <FormattedMessage id={isOfficeActive ? "createIntake" : "copyIntake"} />
        </LoadingButton>

        {!isOfficeActive && (
          <Button variant="contained" color={"inherit"} fullWidth onClick={printIntake}>
            <FormattedMessage id="printIntake" />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default IntakeTemplateGeneratorForm;
