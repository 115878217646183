import * as React from "react";
import {FormattedMessage} from "react-intl";
import {FunctionComponent, ReactNode, useCallback} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {useModal} from "@ebay/nice-modal-react";

export type ConfirmationDialogProps = {
  title: string | ReactNode;
  text: string | ReactNode;
  continueText?: string | ReactNode;
  continueColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  onConfirm?: () => void;
};

const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = NiceModal.create(
  ({title, text, continueText, continueColor, onConfirm}) => {
    const modal = useModal();

    const confirmHandler = useCallback((): void => {
      onConfirm?.();

      modal.resolve(true);
      modal.hide();
    }, [onConfirm, modal]);

    const cancelHandler = useCallback((): void => {
      modal.resolve(false);
      modal.hide();
    }, [modal]);

    return (
      <Dialog
        open={modal.visible}
        onClose={cancelHandler}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <DialogTitleEnhanced onClose={cancelHandler}>{title}</DialogTitleEnhanced>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={cancelHandler}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="contained" color={continueColor || "primary"} onClick={confirmHandler}>
            {continueText || <FormattedMessage id={"continue"} />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
export default ConfirmationDialog;
