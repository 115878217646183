import * as React from "react";
import {useCallback, useMemo} from "react";
import {EntityFormComponentProps} from "../../../../types/entity";
import {
  DataEntityStatus,
  MedicalField,
  Term,
  TermCategory,
  StageType,
  TermFilters,
  TermStage,
} from "../../../../types/internal";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import {getStageTypes, getTermCategoryLabelId} from "../../../../helpers/TermUtils";
import MenuItem from "@mui/material/MenuItem";
import {getMedicalFieldLabelId} from "../../../../helpers/MedicalFieldUtils";
import {isEmpty} from "lodash";
import FormListHeader from "../common/FormListHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TermStageFormSortable from "./TermStageFormSortable";
import {useSnackbar} from "notistack";
import {maxTermStages} from "../../../../constants/defaultValues";
import {getDataEntityStatusLabelId} from "../../../../helpers/EntityUtils";
import SortableDndContext from "../../../dnd/SortableDndContext";

const TermForm = ({entity, item, disabled}: EntityFormComponentProps<Term, TermFilters>) => {
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();

  const {control} = useFormContext<Term>();
  const {fields, append, remove, move} = useFieldArray<Term>({name: "stages", control});

  const medicalFields: MedicalField[] | undefined = useWatch({control: control, name: `medicalFields`});

  const availableStageTypes = useMemo<StageType[]>(() => getStageTypes(medicalFields), [medicalFields]);
  const addStageDisabled = useMemo<boolean>(() => fields.length >= maxTermStages, [fields]);

  const getNewStageType = useCallback((): StageType => {
    for (let stageType of availableStageTypes) {
      if (!fields.find((f) => f["type"] === stageType)) {
        return stageType as StageType;
      }
    }
    return StageType.CurrentDiseaseQuestions;
  }, [fields, availableStageTypes]);

  const addStageHandler = useCallback((): void => {
    if (fields.length + 1 > maxTermStages) {
      enqueueSnackbar(<FormattedMessage id="reachedMaximumAllowed" values={{max: maxTermStages}} />, {
        variant: "info",
      });
      return;
    }

    append({
      type: getNewStageType(),
      clauses: [],
      medicalFields: [],
    } as TermStage);
  }, [append, fields, getNewStageType]);

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Controller
              name="title"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "title"})}
                    type="text"
                    autoFocus={!item.id}
                    inputProps={{
                      maxLength: 200,
                    }}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />

            <Controller
              name="category"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "category"})}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                    select
                  >
                    {Object.values(TermCategory).map((c) => (
                      <MenuItem key={c} value={c}>
                        <FormattedMessage id={getTermCategoryLabelId(c)} />
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />

            <Controller
              name="medicalFields"
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    fullWidth
                    label={intl.formatMessage({id: "medicalFields"})}
                    error={invalid}
                    helperText={error?.message || <FormattedMessage id="selectNonForAll" />}
                    disabled={disabled}
                    select
                    value={field?.value || []}
                    SelectProps={{
                      multiple: true,
                      renderValue: (value: MedicalField[]) =>
                        value
                          .map((medicalField) => intl.formatMessage({id: getMedicalFieldLabelId(medicalField)}))
                          .join(", "),
                    }}
                  >
                    {Object.values(MedicalField).map((m) => (
                      <MenuItem key={m} value={m}>
                        <FormattedMessage id={getMedicalFieldLabelId(m)} />
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />

            <Controller
              name="status"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "status"})}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                    select
                  >
                    {Object.values(DataEntityStatus).map((s) => (
                      <MenuItem key={s} value={s}>
                        <FormattedMessage id={getDataEntityStatusLabelId(s)} />
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />

            <Controller
              name="description"
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    fullWidth
                    multiline
                    rows={3}
                    label={intl.formatMessage({id: "description"})}
                    type="text"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />

            <Controller
              name="tooltip"
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    fullWidth
                    label={intl.formatMessage({id: "tooltip"})}
                    type="text"
                    inputProps={{
                      maxLength: 500,
                    }}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />

            <Controller
              name="keywords"
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    fullWidth
                    label={intl.formatMessage({id: "keywords"})}
                    type="text"
                    value={field?.value?.join(",")}
                    onChange={(e) => field?.onChange(e.target.value.split(","))}
                    inputProps={{
                      maxLength: 500,
                    }}
                    error={invalid}
                    helperText={error?.message || <FormattedMessage id="commaSeparatedValues" />}
                    disabled={disabled}
                  />
                );
              }}
            />
          </Stack>
        </CardContent>
      </Card>

      <FormListHeader
        title={<FormattedMessage id="stages" />}
        buttonText={<FormattedMessage id="addNewStage" />}
        buttonDisabled={addStageDisabled || disabled}
        onAddItem={addStageHandler}
        sx={{mt: 3}}
      />

      {isEmpty(fields) && (
        <Card sx={{mt: 3}}>
          <CardContent>
            <Typography variant="body2" sx={{color: "text.secondary"}}>
              <FormattedMessage id="noResultsFound" />
            </Typography>
          </CardContent>
        </Card>
      )}

      <SortableDndContext items={fields} move={move}>
        {fields.map((field, index) => (
          <TermStageFormSortable
            id={field.id}
            index={index}
            remove={remove}
            sx={{mt: 3}}
            disabled={disabled}
            key={field.id}
          />
        ))}
      </SortableDndContext>

      <FormListHeader
        buttonText={<FormattedMessage id="addNewStage" />}
        buttonDisabled={addStageDisabled || disabled}
        onAddItem={addStageHandler}
        sx={{mt: 3}}
      />
    </>
  );
};

export default TermForm;
