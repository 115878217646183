import * as React from "react";
import {FunctionComponent, PropsWithChildren} from "react";
import {FormattedMessage} from "react-intl";
import {useSnackbar} from "notistack";
import {CopyToClipboard} from "react-copy-to-clipboard";

export interface CopyTextComponentProps extends PropsWithChildren<any> {
  text: string;
  format?: "text/html" | "text/plain";
}

const CopyTextComponent: FunctionComponent<CopyTextComponentProps> = ({text, format = "text/plain", children}) => {
  const {enqueueSnackbar} = useSnackbar();

  const onCopy = (text: string, result: boolean): void => {
    if (text && result) {
      enqueueSnackbar(<FormattedMessage id="copiedSuccessfully" />, {variant: "success"});
    }
  };

  return (
    <CopyToClipboard text={text} onCopy={onCopy} options={{format: format}}>
      {children}
    </CopyToClipboard>
  );
};

export default CopyTextComponent;
