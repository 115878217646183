import {chain, replace} from "lodash";

export const reverseString = (str: string): string => {
  return chain(str).split("").reverse().join("").value();
};

export const getLastRegExpMatch = (str: string, regExp: RegExp): RegExpExecArray | undefined => {
  let match: RegExpExecArray | undefined = undefined;

  let m: RegExpExecArray | undefined = undefined;
  while ((m = regExp.exec(str))) {
    match = m;
  }

  return match;
};

export const replaceAll = (text: string, oldString: string, newString: string): string => {
  return replace(text, new RegExp(oldString, "g"), newString);
};
