import * as React from "react";
import {useCallback, useMemo} from "react";
import {EntityFormComponentProps} from "../../../../types/entity";
import {DataEntityStatus, MedicalField, StageType, IntakeTemplate, TermFilters} from "../../../../types/internal";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import MenuItem from "@mui/material/MenuItem";
import {getMedicalFieldLabelId} from "../../../../helpers/MedicalFieldUtils";
import {isEmpty} from "lodash";
import FormListHeader from "../common/FormListHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IntakeTemplateSectionFormSortable from "./IntakeTemplateSectionFormSortable";
import {useSnackbar} from "notistack";
import {maxIntakeTemplateSections} from "../../../../constants/defaultValues";
import {getDataEntityStatusLabelId} from "../../../../helpers/EntityUtils";
import SortableDndContext from "../../../dnd/SortableDndContext";
import {getStageTypes} from "../../../../helpers/TermUtils";

const IntakeTemplateForm = ({entity, item, disabled}: EntityFormComponentProps<IntakeTemplate, TermFilters>) => {
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();

  const {control} = useFormContext<IntakeTemplate>();
  const {fields, append, remove, move} = useFieldArray<IntakeTemplate>({name: "sections", control});

  const medicalField: MedicalField | undefined = useWatch({control: control, name: `medicalField`});

  const availableStageTypes = useMemo<StageType[]>(() => getStageTypes(medicalField), [medicalField]);
  const addSectionDisabled = useMemo<boolean>(() => fields.length >= maxIntakeTemplateSections, [fields]);

  const getNewStageType = useCallback((): StageType => {
    for (let stageType of availableStageTypes) {
      if (!fields.find((f) => f["type"] === stageType)) {
        return stageType as StageType;
      }
    }
    return StageType.CurrentDiseaseQuestions;
  }, [fields, availableStageTypes]);

  const addSectionHandler = useCallback((): void => {
    if (fields.length + 1 > maxIntakeTemplateSections) {
      enqueueSnackbar(<FormattedMessage id="reachedMaximumAllowed" values={{max: maxIntakeTemplateSections}} />, {
        variant: "info",
      });
      return;
    }

    append({
      type: getNewStageType(),
      title: "",
      contents: [],
      pageBreak: false,
    });
  }, [append, fields, getNewStageType]);

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Controller
              name="title"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "title"})}
                    type="text"
                    autoFocus={!item.id}
                    inputProps={{
                      maxLength: 50,
                    }}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />

            <Controller
              name="medicalField"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "medicalFields"})}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                    select
                    value={field?.value || []}
                  >
                    {Object.values(MedicalField).map((m) => (
                      <MenuItem key={m} value={m}>
                        <FormattedMessage id={getMedicalFieldLabelId(m)} />
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />

            <Controller
              name="status"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "status"})}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                    select
                  >
                    {Object.values(DataEntityStatus).map((s) => (
                      <MenuItem key={s} value={s}>
                        <FormattedMessage id={getDataEntityStatusLabelId(s)} />
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />
          </Stack>
        </CardContent>
      </Card>

      <FormListHeader
        title={<FormattedMessage id="sections" />}
        buttonText={<FormattedMessage id="addNewSection" />}
        buttonDisabled={addSectionDisabled || disabled}
        onAddItem={addSectionHandler}
        sx={{mt: 3}}
      />

      {isEmpty(fields) && (
        <Card sx={{mt: 3}}>
          <CardContent>
            <Typography variant="body2" sx={{color: "text.secondary"}}>
              <FormattedMessage id="noResultsFound" />
            </Typography>
          </CardContent>
        </Card>
      )}

      <SortableDndContext items={fields} move={move}>
        {fields.map((field, index) => (
          <IntakeTemplateSectionFormSortable
            id={field.id}
            index={index}
            remove={remove}
            sx={{mt: 3}}
            disabled={disabled}
            key={field.id}
          />
        ))}
      </SortableDndContext>

      <FormListHeader
        buttonText={<FormattedMessage id="addNewSection" />}
        buttonDisabled={addSectionDisabled || disabled}
        onAddItem={addSectionHandler}
        sx={{mt: 3}}
      />
    </>
  );
};

export default IntakeTemplateForm;
