import {BaseEntityRO, EntityPublishStatus, PublishableEntityRO} from "./entity";

export interface UrlInfo {
  url: string;
  path: string;
  titleKey: string;
}

export interface UserProfile extends BaseEntityRO {
  fullName?: string;
  activePrepId?: string;
  darkMode?: boolean;
  locale?: string;
  newTermsTime?: number;
  negateKeywords?: NegateKeyword[];
  editRequestDialogIds?: string[];
}

export interface Term extends PublishableEntityRO {
  title: string;
  description?: string;
  tooltip?: string;
  category: TermCategory;
  status: DataEntityStatus;
  medicalFields?: MedicalField[];
  keywords: string[];
  stages?: TermStage[];
}

export interface TermStage {
  type: StageType;
  clauses?: TermClause[];
  medicalFields?: MedicalField[];
}

export interface TermClause {
  text: string;
  tooltip?: string;
  medicalFields?: MedicalField[];
  checked: boolean;
  indent?: number;
}

export type TermFilters = {
  categories: TermCategory[];
  medicalFields: MedicalField[];
};

export interface IntakeTemplate extends PublishableEntityRO {
  title: string;
  medicalField: MedicalField;
  status: DataEntityStatus;
  sections: IntakeTemplateSection[];
}

export interface IntakeTemplateSection {
  type: StageType;
  title?: string;
  contents?: IntakeTemplateContent[];
  pageBreak?: boolean;
  extendedFormat?: boolean;
  medicalRecordFormat?: boolean;
}

export interface IntakeTemplateContent {
  type: ContentType;
  title?: string;
  payload: string;
  example?: boolean;
  assistNotes?: boolean;
  extendedFormat?: boolean;
}

export type ContentTypePayloadText = string;

export type ContentTypePayloadList = {
  items: ContentTypePayloadListItem[];
  permanent?: boolean;
};

export type ContentTypePayloadListItem = Omit<IntakeTemplateContent, "type" | "title"> & {
  uuid: string;
  indent?: number;
};

export type ContentTypePayloadTable = {
  rows: number;
  columns: number;
  cells: {[key: string]: ContentTypePayloadTableCell};
};

export type ContentTypePayloadTableCell = Omit<IntakeTemplateContent, "type" | "title"> & {
  uuid: string;
  title: boolean;
};

export interface Prep extends BaseEntityRO {
  title: string;
  medicalField?: MedicalField;
  stages: PrepStage[];
}

export interface PrepStage {
  uuid: string;
  type: StageType;
  comments: string;
  terms: PrepTerm[];
}

export interface PrepTerm {
  uuid: string;
  termId: string;
  title: string;
  clauses: PrepClause[];
}

export interface PrepClause {
  uuid: string;
  text: string;
  tooltip?: string;
  indent?: number;
}

export interface NegateKeyword {
  uuid: string;
  locale: string;
  keyword: string;
  type: NegateKeywordType;
}

export interface Share extends BaseEntityRO {
  entityId?: string;
  data?: BaseEntityRO;
}

export interface PublishRequest extends BaseEntityRO {
  status: EntityPublishStatus;
  targetId?: string;
  targetEntityId?: string;
  targetTitle?: string;
  fullName?: string;
  anonymous?: boolean;
  reason?: string;
}

export interface EditRequest extends BaseEntityRO {
  status: EntityPublishStatus;
  targetId?: string;
  targetEntityId?: string;
  targetTitle?: string;
  fullName?: string;
  reason?: string;
}

export enum DataEntityStatus {
  Draft = "Draft",
  Published = "Published",
  Archived = "Archived",
}

export enum TermCategory {
  Symptom = "Symptom",
  Disease = "Disease",
  Drug = "Drug",
  Age = "Age",
  SignInExam = "SignInExam",
  LabResult = "LabResult",
  // Other = "Other",
}

export enum MedicalField {
  InternalMedicine = "InternalMedicine",
  Paediatrics = "Paediatrics",
  Gynecology = "Gynecology",
  Surgery = "Surgery",
  Psychiatry = "Psychiatry",
  Neurology = "Neurology",
}

export enum StageType {
  CurrentDiseaseQuestions = "CurrentDiseaseQuestions",
  PregnancyAndChildbirth = "PregnancyAndChildbirth",
  BackgroundDisease = "BackgroundDisease",
  GeneralMedicalBackground = "GeneralMedicalBackground",
  RegularMedications = "RegularMedications",
  Vaccines = "Vaccines",
  Diet = "Diet",
  GrowthAndDevelopment = "GrowthAndDevelopment",
  RelevantFamilyBackground = "RelevantFamilyBackground",
  ScreeningTests = "ScreeningTests",
  GynecologicalHistory = "GynecologicalHistory",
  ObstetricHistory = "ObstetricHistory",
  CurrentPregnancy = "CurrentPregnancy",
  PhysicalExaminationEmphasis = "PhysicalExaminationEmphasis",
  LabEmphasis = "LabEmphasis",
  SummaryAndDiscussion = "SummaryAndDiscussion",
  Plan = "Plan",
  Other = "Other",
}

export enum ContentType {
  Text = "Text",
  List = "List",
  Table = "Table",
}

export enum NegateKeywordType {
  Word = "Word",
  Sentence = "Sentence",
}
