import * as React from "react";
import {useCallback, useRef, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import {MIconButton} from "../../../components/@material-extend";
import MenuPopover from "../../../components/common/MenuPopover";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";
import {urls} from "../../../routes/urls";
import NiceModal from "@ebay/nice-modal-react";
import ValidateIntakeDialog from "../../../components/dialogs/ValidateIntakeDialog";
import useOffice from "../../../contexts/office/hooks/useOffice";
import IconMenuItem from "../../../components/common/IconMenuItem";
import RuleOutlined from "@mui/icons-material/RuleOutlined";
import ContactPageOutlined from "@mui/icons-material/ContactPageOutlined";
import AddOutlined from "@mui/icons-material/AddOutlined";

export default function IntakePopover() {
  const navigate = useNavigate();
  const {isOfficeActive} = useOffice();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleNewIntake = useCallback((): void => {
    handleClose();
    navigate(urls.newIntake.url);
  }, []);

  const handleValidateIntake = useCallback((): void => {
    handleClose();
    NiceModal.show(ValidateIntakeDialog, {});
  }, []);

  return (
    <>
      <Tooltip title={<FormattedMessage id="intakes" />}>
        <MIconButton ref={anchorRef} onClick={handleOpen} color={open ? "primary" : "default"}>
          <ContactPageOutlined fontSize={"small"} />
        </MIconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 220}} arrow={false}>
        <IconMenuItem labelId={"newIntake"} Icon={AddOutlined} onClick={handleNewIntake} />

        {isOfficeActive && (
          <IconMenuItem labelId={"validateIntake"} Icon={RuleOutlined} onClick={handleValidateIntake} />
        )}
      </MenuPopover>
    </>
  );
}
