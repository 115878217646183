import createRootReducer from "./reducers";
import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import {PersistConfig, persistReducer} from "redux-persist";
import {uiSliceName} from "./ui/slice";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import {encryptTransform} from "redux-persist-transform-encrypt";
import {LocalStorageWrapper} from "@crud-studio/react-crud-core";
import {v4 as uuidv4} from "uuid";
import {AddTextLocation} from "../helpers/AddTextLocationUtils";
import {get, set} from "lodash";

const getStoreSecretKey = (): string => {
  let storeSecretKey = LocalStorageWrapper.get("storeSecretKey");
  if (!storeSecretKey) {
    storeSecretKey = uuidv4();
    LocalStorageWrapper.set("storeSecretKey", storeSecretKey);
  }
  return storeSecretKey;
};

const persistConfig: PersistConfig<any> = {
  key: "root",
  version: 2,
  storage: storage,
  blacklist: [uiSliceName],
  debug: false,
  migrate: (state, currentVersion) => {
    if (!get(state, "auth.addTextLocation")) {
      set(state, "auth.addTextLocation", AddTextLocation.AfterTerm);
    }
    return Promise.resolve(state);
  },
  transforms: [
    encryptTransform({
      secretKey: getStoreSecretKey(),
      onError: (e) => {},
    }),
  ],
};

const rootReducer = createRootReducer();
export type RootState = ReturnType<typeof rootReducer>;

const configureAppStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const reducers = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(sagaMiddleware),
    preloadedState: initialState,
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return {store, persistor};
};

export default configureAppStore;
