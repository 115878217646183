import * as React from "react";
import {forwardRef, ReactNode, useCallback} from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import useIsMobile from "../../helpers/useIsMobile";

interface TooltipIconProps {
  tooltip: string | ReactNode;
}

const TooltipIcon = forwardRef<HTMLDivElement, TooltipIconProps>(({tooltip}, ref) => {
  const isMobile = useIsMobile();

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isMobile) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [isMobile]
  );

  return (
    <Tooltip
      title={<div style={{whiteSpace: "pre-line"}}>{tooltip}</div>}
      arrow={false}
      placement={"top"}
      onClick={clickHandler}
      enterTouchDelay={isMobile ? 0 : undefined}
      leaveTouchDelay={isMobile ? 5000 : undefined}
      ref={ref}
    >
      <Box sx={{display: "inline", p: 0.75}}>
        <InfoOutlined color="primary" sx={{fontSize: isMobile ? 20 : 16, verticalAlign: "middle"}} />
      </Box>
    </Tooltip>
  );
});

export default TooltipIcon;
